import { useState, useEffect } from 'react';
import { getProfile, getUserSettingUpdate, setUserSettingUpdate } from '../../store/slicer/0503_room';
import { useSelector, useDispatch } from 'react-redux';
import * as CONST from '../../const';
import { getAccessToken, getRefreshToken, setAccessToken, logout } from '../../store/slicer/0101_session';
import toast from 'react-hot-toast';
import { FormattedMessage, useIntl } from "react-intl";
import { SetErrorMessage } from '../../store/slicer/0101_logout_message';

export default function UserMeetingBody(props) {
    const dispatch = useDispatch();
   

    //ミーティング
    const storeUserSetting = useSelector(getUserSettingUpdate);

    // プロフィール
    const storeProfile = useSelector(getProfile);

    var access_token = useSelector(getAccessToken);
    var refresh_token = useSelector(getRefreshToken);
    // 初期化
    const [state, setState] = useState(() => {
        return {
            check_toggle20: storeUserSetting.in_meeting.allow_participants_to_rename,
        }
    });

    const intl = useIntl();

    // onClickイベントハンドラ
    const handleOnClick = event => {

        //チェック状態保存
        var check_tmp = !state.check_toggle20;
        setState({ ...state, check_toggle20: check_tmp });

        //更新データ
        const senddata = { ...storeUserSetting, in_meeting: { ...storeUserSetting.in_meeting, allow_participants_to_rename: check_tmp } };
        const zoom_senddata = { in_meeting: { allow_participants_to_rename: check_tmp } };

        const fetchHttpRefresh = async () => {
            let senddata = {
            };
            let url_tokref = CONST.BASEURL + '/authorize/refresh';
            var httpResponse_tokref = await fetch( url_tokref, {
                method:'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + refresh_token
                },
                body: JSON.stringify(senddata),
            });
            switch (httpResponse_tokref.status) {
                case 200:
                    var httpResponse_tokref_json = await httpResponse_tokref.json();
                    access_token = httpResponse_tokref_json.access_token;
                    refresh_token = httpResponse_tokref_json.refresh_token;
                    // トークンをデコードする
                    //const decode_token = jwt(access_token);
                    // 問題ない場合は、storeに保存
                    dispatch(setAccessToken(access_token));
                    //dispatch(setRefreshToken(refresh_token));
                    fetchHttp();
                    return;                      
                default:
                    dispatch(SetErrorMessage('ERROR_0001'));
                    dispatch(logout());
                    return;
            }
        }
        //fetchHttpRefresh();
        //更新処理
        const fetchHttp = async () => {
        fetch(CONST.BASEURL + '/users/' + storeProfile.user_id + '/settings', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + access_token
            },
            body: JSON.stringify(zoom_senddata),
        })
            .then(response => {
                switch (response.status) {
                    case 204:
                        //成功通知
                        toast(intl.formatMessage({ id: 'SUCCESS_0001' }),{style:{background:  CONST.HOT_TOAST_SUCCESS}});

                        dispatch(setUserSettingUpdate(senddata));

                        break;
                    case 401:
                        fetchHttpRefresh();
                        return;
                    case 409:
                        dispatch(SetErrorMessage('ERROR_0039'));  
                        dispatch(logout());
                        return;
                        
                    default:
                        response.json().then(data => ({
                            data: data,
                            status: response.status
                        })
                        ).then(res => {
                            //Error出力
                            toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});


                            //チェック状態を戻す
                            setState({ ...state, check_toggle20: !check_tmp });

                        }).catch((err) => {
                            //Error出力
                            toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                        });
                }
            })
            .catch((err) => {
                //Error出力
                toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});


                //チェック状態を戻す
                setState({ ...state, check_toggle20: !check_tmp });
            });
        }
        fetchHttp();
    }

    // サイドエフェクト
    useEffect(() => {
        if (state.check_toggle20 !==storeUserSetting.in_meeting.allow_participants_to_rename) {
            setState({ ...state, check_toggle20: storeUserSetting.in_meeting.allow_participants_to_rename });
        }
    }, [state.check_toggle20, storeUserSetting.in_meeting.allow_participants_to_rename]);

    // チェック押下
    const handleChecktClick = event => {
        document.getElementById("toggle20").click();
    }

    // レンダリング
    return (
        <tbody class="lump">
            <tr>
                <td>
                    <div class="flex_box">
                        <p class="save_itme_name"><FormattedMessage id="meeting_basic_sentence43_lbl" /></p>
                        <div class="toggle-switch">
                            <input tabindex="-1" id="toggle20" class="toggle-input" type='checkbox' checked={state.check_toggle20} onChange={handleOnClick} />
                            <a tabindex="0" onClick={handleChecktClick} href="javascript:void(0);" class="toggle-label toggle-label-under" />
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <p><FormattedMessage id="meeting_basic_sentence44_lbl" values={{ br: <br /> }}/></p>
                </td>
            </tr>
        </tbody>
    );
}
