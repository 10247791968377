import { useState, useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserProfile, } from '../../store/slicer/0503_room';
import * as CONST from '../../const';
import { getAccessToken, getRefreshToken, setAccessToken, logout } from '../../store/slicer/0101_session';
import { SetErrorMessage } from '../../store/slicer/0101_logout_message';
import toast from 'react-hot-toast';
import { FormattedMessage, useIntl } from "react-intl";

export default function UserProfileBody() {
    const dispatch = useDispatch();
   


    // プロフィール
    const storeProfile = useSelector(getUserProfile);

    const intl = useIntl();

    var access_token = useSelector(getAccessToken);
    var refresh_token = useSelector(getRefreshToken);

    // 初期化
    const [state, setState] = useState(() => {
        let temp = {};
        temp.edit = false;
        temp.options = settimezoneList();
        temp.option_tag = (() => {
            let text = [];
            for (let i = 0; i < temp.options.length; i++) {
                let text_tmp = (
                    <option value={temp.options[i].value} data-text={temp.options[i].text}>{temp.options[i].text}</option>
                );
                text.push(text_tmp);
            }
            return text;
        })();

        temp.timezone = {};
        for (let i = 0; i < temp.options.length; i++) {
            if (temp.options[i].value === storeProfile.timezone) {
                temp.timezone = { value: temp.options[i].value, text: temp.options[i].text };
                break;
            }
        }

        temp.list = {
            value: temp.timezone.value,
            text: temp.timezone.text,
        }
        temp.focus_initial_escape = false;
        return temp;
    });

    //Timezone一覧
    function settimezoneList() {
        const options = CONST.TIMEZONE_VALUE.map(function (value, index) {
            return { value: CONST.TIMEZONE_VALUE[index], text: intl.formatMessage({ id: "TIMEZONE_" + ('0000' + (index + 1)).slice(-4) }), }
        });
        return options;
    };


    // セレクトボックス変更のイベント
    const handleSelectChange = event => {
        event.preventDefault(false);

        var obj = document.getElementById("time_zone_selecter");
        var text = obj.options[obj.selectedIndex].text;

        setState({ ...state, list: { value: event.target.value, text: text } });
    }

    // onClickイベントハンドラ
    const handleOnClick = event => {
        event.preventDefault(false);
        const type = event.target.getAttribute('data-type');
        const editing = state.edit ? false : true;

        switch (type) {
            case "edit":
                let list_tmp = {};
                for (let i = 0; i < state.options.length; i++) {
                    if (state.options[i].value===state.timezone.value) {
                        list_tmp = { value: state.options[i].value, text: state.options[i].text };
                        break;
                    }
                }
                setState({ ...state, edit: editing, list: { value: list_tmp.value, text: list_tmp.text }, focus_initial_escape: true  });
                break;

            case "cancel":
                setState({ ...state, edit: editing, list: { value: state.timezone.value, text: state.timezone.text } });
                break;

            case "submit":

                //更新データ
                let senddata = {
                    'timezone': state.list.value,
                };

                const fetchHttpRefresh = async () => {
                    let senddata = {
                    };
                    let url_tokref = CONST.BASEURL + '/authorize/refresh';
                    var httpResponse_tokref = await fetch( url_tokref, {
                        method:'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + refresh_token
                        },
                        body: JSON.stringify(senddata),
                    });
                    switch (httpResponse_tokref.status) {
                        case 200:
                            var httpResponse_tokref_json = await httpResponse_tokref.json();
                            access_token = httpResponse_tokref_json.access_token;
                            refresh_token = httpResponse_tokref_json.refresh_token;
                            // トークンをデコードする
                            //const decode_token = jwt(access_token);
                            // 問題ない場合は、storeに保存
                            dispatch(setAccessToken(access_token));
                            //dispatch(setRefreshToken(refresh_token));
                            fetchHttp();
                            return;                             
                        default:
                            dispatch(SetErrorMessage('ERROR_0001'));
                            dispatch(logout());
                            return;
                    }
                }
                //fetchHttpRefresh();
                //更新処理
                const fetchHttp = async () => {
                    fetch(CONST.BASEURL + '/users/' + storeProfile.user_id, {
                        method: 'PATCH',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: "Bearer " + access_token
                        },
                        body: JSON.stringify(senddata),
                    })
                    .then(response => {
                        switch (response.status) {
                            case 204:
                                //成功通知
                                toast(intl.formatMessage({ id: 'SUCCESS_0002' }),{style:{background:  CONST.HOT_TOAST_SUCCESS}});

                                setState({ ...state, edit: false, timezone: { value: state.list.value, text: state.list.text } });

                                break;
                            case 401:
                                fetchHttpRefresh();
                                return;
                            case 409:
                                dispatch(SetErrorMessage('ERROR_0039'));  
                                dispatch(logout());
                                return;
                            default:
                                response.json().then(data => ({
                                    data: data,
                                    status: response.status
                                })
                                ).then(res => {
                                    //Error出力
                                    toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                                }).catch((err) => {
                                    //Error出力
                                    toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                                });
                        }
                    })
                    .catch((err) => {
                        //Error出力
                        toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                    });
                }
                fetchHttp();
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        for (let i = 0; i < state.options.length; i++) {
            if (state.options[i].value===storeProfile.timezone) {
                setState({ ...state, timezone: { value: state.options[i].value, text: state.options[i].text } });
                break;
            }
        }
    }, [storeProfile.timezone]);

    // ダイアログ初期カーソル
    useLayoutEffect(() => {
        if (state.edit == true) {
            document.getElementById('time_zone_selecter').focus();
        } else if (state.edit == false && state.focus_initial_escape == true) {
            document.getElementById('edit_btn5').focus();
            setState({ ...state, focus_initial_escape: false });
        }
    }, [state.edit])


    // レンダリング
    switch (state.edit) {
        // 表示画面
        case false:
            return (
                <tbody class="lump">
                    <tr>
                        <td><p><FormattedMessage id="profile-view_datetime_lbl" /></p></td>
                        <td><p><FormattedMessage id="profile-view_timezone_lbl" />　{state.timezone.text}</p></td>
                        <td class="edit_button_cell"><div class="element_width_ms"><a id="edit_btn5" href="javascript:void(0);" class="buttons_common btn_wht" data-type="edit" onClick={handleOnClick}><FormattedMessage id="profile-view_edit_btn" /></a></div></td>
                    </tr>
                </tbody>
            );

        // 編集画面
        case true:
            return (
                <tbody class="lump" id="date_and_time">
                    <tr>
                        <td><label for="time_zone_selecter"><FormattedMessage id="profile-view_datetime_lbl" /></label></td>
                        <td class="timezone_cell">
                            <label for="time_zone_selecter" class="timezone"><FormattedMessage id="profile-view_timezone_lbl" /></label>
                            <div class="selecter_down_arrow select_option element_width_timezone blank_space_m">
                                <label>
                                    <img class="svg_img-Pulldown" src="img/EZTP_Pulldown.svg" alt="▽" />
                                    <select id="time_zone_selecter" value={state.list.value} onChange={handleSelectChange}>
                                        {state.option_tag}
                                    </select>
                                </label>
                            </div>
                        </td>
                        <td class="save_and_cancel">
                            <div class="delete_invisible_space">                               
                                <div class="inline_block element_width_ms"><FormattedMessage id="profile-edit_save_btn">{value => <input class="buttons_common btn_blue" type="submit" value={value} data-type="submit" onClick={handleOnClick} />}</FormattedMessage></div>
                                    <div class="inline_block element_width_ms blank_space_m"><button class="buttons_common btn_wht" data-type="cancel" onClick={handleOnClick}><FormattedMessage id="profile-edit_cancel_btn" /></button></div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            );
            

        default:
            break;
    }

    return (<tbody class="lump"></tbody>);
}
