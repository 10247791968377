import { useState }  from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from "react-intl";

import { setSideMenu } from '../../../store/slicer/0501_menu';
import Header from '../../0003_components/0001_header';
import Sidemenu from '../../0003_components/0002_sidemenu';
import MeettingRoomBody_01 from '../../0003_components/2101_meetting_room_body';
import MeettingRoomBody_02 from '../../0003_components/2102_meetting_room_body';
import { toast,Toaster } from 'react-hot-toast';


export default function MeettingRoom(props){
    const dispatch = useDispatch();

    // 初期化
	const [state, setState] = useState(() => {
		dispatch(setSideMenu(5));
		toast.dismiss();
		return 0;
	});

    return(
        <div class="wrapper">
			<title><FormattedMessage id="tab_title_meeting_room" /></title>
			<Header />
			<div class="container">
				<div class="contents">
					<Sidemenu />
					<div class="in_contents profile-view">
						<style jsx="true" />
					    <Toaster position="top-center" reverseOrder="false" containerStyle={{ marginTop:'75px'}} class="hot-toast-success"/>
						<div class="tab_box" style={{ marginTop: '60px'}}>
							<table class="in_tab_box_table">
								<MeettingRoomBody_01 />
								<MeettingRoomBody_02 />
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
    );
}