import * as CONST from '../../const';
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserProfile, getUserSettingUpdate } from '../../store/slicer/0503_room';
import { getAccessToken, getRefreshToken, setAccessToken, logout } from '../../store/slicer/0101_session';
import { SetErrorMessage } from '../../store/slicer/0101_logout_message';
import toast from 'react-hot-toast';
import InputMask from 'react-input-mask';
import { FormattedMessage, useIntl } from "react-intl";


export default function UserProfileBody() {
    const dispatch = useDispatch();
    

    // プロフィール
    const storeProfile = useSelector(getUserProfile);
    //ミーティング
    const storeSetting = useSelector(getUserSettingUpdate);

    const intl = useIntl();

    var access_token = useSelector(getAccessToken);
    var refresh_token = useSelector(getRefreshToken);
    // 初期化
    const [state, setState] = useState(() => {
        let temp = {};
        temp.edit = false;
        temp.pmi = storeProfile.pmi
        temp.use_pmi = storeProfile.use_pmi;
        temp.personal_meeting_url = storeProfile.personal_meeting_url;

        //描画用
        temp.pass_class1 = true;
        temp.pass_class2 = true;
        temp.pass_class3 = true;
        temp.pmi_tmp = storeProfile.pmi

        temp.pmi_str = '*** *** ' + storeProfile.pmi.toString().substr(7.3);
        temp.pmi_str2 = '*******' + storeProfile.pmi.toString().substr(7.3);
        temp.pmi_str3 = '******';
        temp.pmi_url = '*******';

        //PMIを使用するかどうか
        if (storeProfile.use_pmi) {
            temp.use_pmi_class = "usage_judgment available_use";
        } else {
            temp.use_pmi_class = "usage_judgment unavailable_use";
        }

        temp.form = [
            { message: 'EMPTY' },
        ];
        temp.focus_initial_escape = false;
        return temp;
    });

    //PMI入力
    const handle_Input_PMI = event => {

        //入力文字
        var text = event.target.value;
        var text_no = text.replace(/\s+/g, "");

        var text_digit3 = "";
        var text_digit6 = "";
        var text_digit10 = "";

        //数字のみ反映
        if (!isNaN(text_no)) {

            //3文字以上
            if (text_no.length > 3) {
                text_digit3 = text_no.substr(0, 3) + " ";
                text_digit6 = text_no.substr(3);

            } else {
                text_digit3 = text_no;
            }

            //6文字以上
            if (text_no.length > 6) {
                text_digit6 = text_no.substr(3, 3) + " ";
                text_digit10 = text_no.substr(6);
            }

            //10文字以上
            if (text_no.length > 10) {
                text_digit10 = text_no.substr(6, 4);
            }
            var pmi_text = text_digit3 + text_digit6 + text_digit10;
            setState({ ...state, pmi_tmp: pmi_text, });
        }
    }

    //PMI使用
    const handle_Input_Use_PMI = event => {
        if (state.use_pmi) {
            setState({ ...state, use_pmi: false, });
        } else {
            setState({ ...state, use_pmi: true, });
        }
    }

    //Password表示
    const handle_Input_Password = event => {

        //ID
        var btn_id = event.target.id;

        switch (btn_id) {
            case "Pass_disp1":
                

                //invisible
                if (state.pass_class1) {
                    event.target.setAttribute('class', 'masking_btn visible');
                    var str = state.pmi.toString().substr(0, 3) + " " + state.pmi.toString().substr(3, 3) + " " + state.pmi.toString().substr(6);
                    setState({ ...state, pass_class1: false, pmi_str: str });

                    //visible
                } else {
                    event.target.setAttribute('class', 'masking_btn invisible');
                    setState({ ...state, pass_class1: true, pmi_str: '*** *** ' + state.pmi.toString().substr(7.3) });
                }

                break;

            case "Pass_disp2":

                //invisible
                if (state.pass_class2) {
                    event.target.setAttribute('class', 'masking_btn visible');
                    setState({ ...state, pass_class2: false, pmi_str2: state.pmi, pmi_url: state.personal_meeting_url.split('=')[1] });

                    //visible
                } else {
                    event.target.setAttribute('class', 'masking_btn invisible');
                    setState({ ...state, pass_class2: true, pmi_str2: '******' + state.pmi.toString().substr(7.3), pmi_url: '******' });
                }

                break;

            case "Pass_disp3":


                //invisible
                if (state.pass_class3) {
                    event.target.setAttribute('class', 'masking_btn visible');
                    setState({ ...state, pass_class3: false, pmi_str3: storeSetting.schedule_meeting.pmi_password});

                    //visible
                } else {
                    event.target.setAttribute('class', 'masking_btn invisible');
                    setState({ ...state, pass_class3: true, pmi_str3: '******'});
                }

                break;
        }
    }


    // onClickイベントハンドラ
    const handleOnClick = event => {
        event.preventDefault(false);
        const type = event.target.getAttribute('data-type');
        const editing = state.edit ? false : true;
        let error = [...state.form];

        switch (type) {
            case "edit":

                if (state.pmi !==undefined) {
                    var pmi_str = state.pmi.toString();
                    var set_pmi = pmi_str.substr(0, 3) + " " + pmi_str.substr(3, 3) + " " + pmi_str.substr(6, 4);
                }
                setState({ ...state, edit: editing, pmi_tmp: set_pmi, focus_initial_escape: true});

                break;

            case "cancel":
                error[0]['message'] = 'EMPTY';
                setState({
                    ...state, edit: editing, use_pmi: storeProfile.use_pmi, pmi_tmp: state.pmi, form: error, pmi_str: '*** *** ' + state.pmi.toString().substr(7.3),
                    pmi_str2: '******' + state.pmi.toString().substr(7.3), pmi_url: '******', pass_class1: true, pass_class2: true, pmi_str3: '******', pass_class3: true });
                break;

            case "submit":

                //入力チェック
                var text = state.pmi_tmp.replace(/\s+/g, "");
                if (text.length < 10) {
                    error[0]['message'] = 'ERROR_0017';
                    document.getElementById('meeting_id').focus();
                    setState({ ...state, form: error, });
                    return;
                }

                if (text.length === 0) {
                    error[0]['message'] = 'ERROR_0017';
                    document.getElementById('meeting_id').focus();
                    setState({ ...state, form: error, });
                    return;
                }

                //更新データ
                let senddata = {
                    'pmi': text,
                    'use_pmi': state.use_pmi,
                };
                const fetchHttpRefresh = async () => {
                    let senddata = {
                    };
                    let url_tokref = CONST.BASEURL + '/authorize/refresh';
                    var httpResponse_tokref = await fetch(url_tokref, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + refresh_token
                        },
                        body: JSON.stringify(senddata),
                    });
                    switch (httpResponse_tokref.status) {
                        case 200:
                            var httpResponse_tokref_json = await httpResponse_tokref.json();
                            access_token = httpResponse_tokref_json.access_token;
                            refresh_token = httpResponse_tokref_json.refresh_token;
                            // トークンをデコードする
                            //const decode_token = jwt(access_token);
                            // 問題ない場合は、storeに保存
                            dispatch(setAccessToken(access_token));
                            //dispatch(setRefreshToken(refresh_token));
                            fetchHttp();
                            return;                              
                        default:
                            dispatch(SetErrorMessage('ERROR_0001'));
                            dispatch(logout());
                            return;
                    }
                }
                //fetchHttpRefresh();                
                //更新処理
                const fetchHttp = async () => {
                    fetch(CONST.BASEURL + '/users/' + storeProfile.user_id, {
                        method: 'PATCH',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: "Bearer " + access_token
                        },
                        body: JSON.stringify(senddata),
                    })
                        .then(response => {
                            switch (response.status) {
                                case 204:
                                    //成功通知
                                    error[0]['message'] = 'EMPTY';

                                    var pmi_class = "";
                                    if (state.use_pmi) {
                                        pmi_class = "usage_judgment available_use";
                                    } else {
                                        pmi_class = "usage_judgment unavailable_use";
                                    }

                                    setState({
                                        ...state, edit: false, pmi: text, form: error, pmi_str: '*** *** ' + text.toString().substr(7.3),
                                        pmi_str2: '******' + text.substr(7.3), pmi_url: '******', pass_class1: true, pass_class2: true, use_pmi_class: pmi_class,
                                        pmi_str3: '******', pass_class3: true
                                    });
                                    toast(intl.formatMessage({ id: 'SUCCESS_0002' }),{style:{background:  CONST.HOT_TOAST_SUCCESS}});
                                    break;
                                case 400:
                                    response.json().then(data => ({
                                        data: data,
                                        status: response.status
                                    })
                                    ).then(res => {
                                        switch (res.data.errors[0]["code"]) {
                                            case 2000:
                                                switch (res.data.errors[0]["z-code"]) {
                                                    case 3016:
                                                        error[0]['message'] = 'ERROR_0012';
                                                        document.getElementById('meeting_id').focus();
                                                        setState({ ...state, form: error });
                                                        break;
                                                    default:
                                                        //Error出力
                                                        toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                                                        break;
                                                }
                                        }
                                    })
                                        .catch((err) =>
                                            toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}})
                                        )
                                    break;
                                case 401:
                                    fetchHttpRefresh();
                                    return
                                case 409:
                                    dispatch(SetErrorMessage('ERROR_0039'));  
                                    dispatch(logout());
                                    return; 

                                default:
                                    response.json().then(data => ({
                                        data: data,
                                        status: response.status
                                    })
                                    ).then(res => {
                                        //Error出力
                                        toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                                    }).catch((err) => {
                                        //Error出力
                                        toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                                    });
                            }
                        })
                        .catch((err) => {
                            //Error出力
                            toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                        });
                }
                fetchHttp();
                break;
			default:
				break;
		}
    }

    useEffect(() => {

        var pmi_str = '*** *** ' + storeProfile.pmi.toString().substr(7.3);
        var pmi_str2 = '******' + storeProfile.pmi.toString().substr(7.3);

        var use_pmi_class = "";
        //PMIを使用するかどうか
        if (storeProfile.use_pmi) {
            use_pmi_class = "usage_judgment available_use";
        } else {
            use_pmi_class = "usage_judgment unavailable_use";
        }

        setState({ ...state, pmi: storeProfile.pmi, use_pmi: storeProfile.use_pmi, pmi_tmp: storeProfile.pmi, pmi_str: pmi_str, pmi_str2: pmi_str2, use_pmi_class: use_pmi_class, personal_meeting_url: storeProfile.personal_meeting_url });

    }, [storeProfile.pmi, storeProfile.use_pmi]);

    // ダイアログ初期カーソル
    useLayoutEffect(() => {
        if (state.edit == true) {
            document.getElementById('meeting_id').focus();
        } else if (state.edit == false && state.focus_initial_escape == true) {
            document.getElementById('edit_btn2').focus();
            setState({ ...state, focus_initial_escape: false });
        }
    }, [state.edit])


    // レンダリング 
    switch (state.edit) {
        // 表示画面
        case false:
            return (
                <tbody class="lump">
                    <tr>
                        <td><p><FormattedMessage id="profile-view_pmi_lbl" /></p></td>
                        <td><input class="masking" id="masking1" disabled="disabled" type="text" value={state.pmi_str} />
                            <div class="inline_block"><button class="masking_btn invisible" id="Pass_disp1" onClick={handle_Input_Password}></button></div>
                        </td>
                        <td></td>
                    </tr>

                    {storeSetting.schedule_meeting.embed_password_in_join_link &&
                        <tr>
                            <td></td>
                            <td colspan="2">
                                <span>{'https://zoom.us/j/' + state.pmi_str2 + '?pwd=' + state.pmi_url}</span>
                                <div class="inline_block"><button class="masking_btn invisible" id="Pass_disp2" onClick={handle_Input_Password}></button></div>
                            </td>
                        </tr>
                    }

                    {!storeSetting.schedule_meeting.embed_password_in_join_link &&
                        <tr>
                        <td></td>
                        <td colspan="2">
                            <span>{'https://zoom.us/j/' + state.pmi_str2}</span>
                            <div class="inline_block"><button class="masking_btn invisible" id="Pass_disp2" onClick={handle_Input_Password}></button></div>
                        </td>

                        </tr>
                    }

                    {!storeSetting.schedule_meeting.embed_password_in_join_link &&
                        <tr>
                            <td></td>
                        <td colspan="2">
                            <span><FormattedMessage id="profile-view_pmi_password" /></span>
                            <span class="blank_space_m">
                                {state.pmi_str3}
                            </span>
                            <div class="inline_block"><button class="masking_btn invisible" id="Pass_disp3" onClick={handle_Input_Password}></button></div>
                        </td>
                        </tr>
                    }


                    <tr>
                        <td></td>
                        <td><p class={state.use_pmi_class}><FormattedMessage id="profile-view_sentence1_lbl" /></p></td>
                        <td class="edit_button_cell"><div class="element_width_ms"><a id="edit_btn2" href="javascript:void(0);" class="buttons_common btn_wht" data-type="edit" onClick={handleOnClick} ><FormattedMessage id="profile-view_edit_btn" /></a></div></td>
                    </tr>
                </tbody>
            );

        // 編集画面
        case true:
            return (
                <tbody class="lump" id="personal_meeting_id">
                    <tr>
                        <td><label for="meeting_id"><FormattedMessage id="profile-view_pmi_lbl" /></label></td>
                        <td colspan="2">
                            <div class="element_width_l"><InputMask mask="999 999 9999" id="meeting_id" class={state.form[0].message !== 'EMPTY' ? 'inputMask has-error' : 'inputMask'} value={state.pmi_tmp} onChange={handle_Input_PMI} maskChar={null} /></div>
                        </td>
                    </tr>
                    <tr class="has-error" style={{ display: state.form[0].message !=='EMPTY' ? '' : 'none' }}>
                        <td>
                        </td>
                        <div class="has-error" style={{ display: state.form[0].message !=='EMPTY' ? '' : 'none' }}><FormattedMessage id={state.form[0].message} /></div>
                    </tr>                    

                    <tr class="flexible_height_row">
                        <td></td>
                        <td class="flexible_height">
                            <label class="instant_meeting"><input type="checkbox" checked={state.use_pmi} onChange={handle_Input_Use_PMI} /><FormattedMessage id="profile-view_sentence1_lbl" /></label>
                        </td>
                        <td class="save_and_cancel">
                            <div class="delete_invisible_space">
                                <div class="inline_block element_width_ms"><FormattedMessage id="profile-edit_save_btn">{value => <input class="buttons_common btn_blue" type="submit" value={value} data-type="submit" onClick={handleOnClick} />}</FormattedMessage></div>                                   
                                <div class="inline_block element_width_ms blank_space_m"><button class="buttons_common btn_wht" data-type="cancel" onClick={handleOnClick}><FormattedMessage id="profile-edit_cancel_btn" /></button></div>                                
                            </div>
                        </td>
                    </tr>
                </tbody>
            );
            

        default:
            break;
    }

    return (<tbody class="lump"></tbody>);
}
