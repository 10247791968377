import { useState }  from 'react';
import { useDispatch } from 'react-redux';
import { setSideMenu } from '../../../store/slicer/0501_menu';

import Header from '../../0003_components/0001_header';
import Sidemenu from '../../0003_components/0002_sidemenu';

import LocationSearch from '../../0003_components/1301_location_search';
import Pagination from '../../0003_components/0003_pagination';
import { toast,Toaster } from 'react-hot-toast';

export default function Location(props) {
	const dispatch = useDispatch();

	// 初期化
	const [state, setState] = useState(() => {
		dispatch(setSideMenu(2));
		toast.dismiss();
		return 0;
	});

	return (
		<div class="wrapper">
			<Header />
			<div class="container">
				<div class="contents">
					<Sidemenu />
					<div class="in_contents base-initial">
						<style jsx="true" />
						<Toaster position="top-center" reverseOrder="false" containerStyle={{ marginTop:'75px'}} />
						<LocationSearch/>
						<Pagination />
					</div>
				</div>
			</div>
		</div>
	);
}
