import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout, checkLogin, getUserData } from '../../store/slicer/0101_session';
import { setSideMenu } from '../../store/slicer/0501_menu';
import { FormattedMessage } from "react-intl";
import { getUserDataOfMeetingRoom, logoutMeetingRoom, checkLoginOfMeetingRoom } from '../../store/slicer/2201_meetingroom_session';
import { useRouteMatch } from 'react-router';

export default function Header(props) {
	const dispatch = useDispatch();
	const storeUser = useSelector(getUserData);
	const storeUserInMeetingRoom = useSelector(getUserDataOfMeetingRoom);
	const storeLoggedin = useSelector(checkLogin);
	const storeLoggedinOfMeetingRoom = useSelector(checkLoginOfMeetingRoom);
	const partsOnShow = props.partsOnShow;
	const match = useRouteMatch();


	// ログアウトボタン押下
	const handleOnClick = useCallback(event => {
		dispatch(logout());
	}, [dispatch]);

	//会議室情報ページログアウト
	const handleOnClickForMeetingRoom = useCallback(event => {
		dispatch(logoutMeetingRoom());
	}, [dispatch]);

	// ロゴ押下
	const handleLogoClick = event => {
		dispatch(setSideMenu(0));
	};

	// 描画
	switch(partsOnShow){
		case 'check_password':
			return (
				<header class="header_main">
					<div class="header_logo" ><a><img id="logo" src={`${process.env.PUBLIC_URL}/img/EZTP_logo.svg`} alt="" /></a></div>
					<div class="customer_name"><span class="name"><FormattedMessage id="meeting-list_title_txt" /></span></div>
					<div class="information_box" style={{visibility: 'hidden'}}>
						<span class="mail">{storeUser.email ? storeUser.email: ""}</span>
						<Link class="buttons_sign_out btn_wht" to="/login" onClick={handleOnClick}><FormattedMessage id="main_signout_btn" /></Link>
					</div>
				</header>
			);
		case 'meeting_room':
			return(
				<header class="header_main">
					<div class="header_logo" ><a><img id="logo" src={`${process.env.PUBLIC_URL}/img/EZTP_logo.svg`} alt="" /></a></div>
					<div class="customer_name"><span class="name">{storeUserInMeetingRoom.name ? storeUserInMeetingRoom.name: ""}</span></div>
					<div class="information_box">
						<span class="mail"></span>
						{ storeLoggedinOfMeetingRoom && 
							<Link class="buttons_sign_out btn_wht" to={'/meeting-info/' + match.params.id} onClick={handleOnClickForMeetingRoom} ><FormattedMessage id="main_signout_btn" /></Link>
						}
					</div>
				</header>
			);	
		
		default:
			return(
				<header class="header_main">
					<div class="header_logo" onClick={handleLogoClick}><Link to="/"><img id="logo" src={`${process.env.PUBLIC_URL}/img/EZTP_logo.svg`} alt="" /></Link></div>
					<div class="customer_name"><span class="name">{storeUser.name ? storeUser.name: ""}</span></div>
					<div class="information_box">
						<span class="mail">{storeUser.email ? storeUser.email: ""}</span>
						{storeLoggedin &&
							<Link class="buttons_sign_out btn_wht" to="/login" onClick={handleOnClick}><FormattedMessage id="main_signout_btn" /></Link>
						}
					</div>
				</header>
			);

	}

}
