import { FormattedMessage, useIntl } from "react-intl";
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import copy from 'copy-to-clipboard'
import { getUserData } from '../../store/slicer/0101_session';
import { Link } from "react-router-dom";
import * as CONST from '../../const';
import toast from 'react-hot-toast';

export default function MeettingRoomBody(){
    const dispatch = useDispatch();
    const storeUser = useSelector(getUserData);
    const intl = useIntl();

    //copy Url
    const handleOnClick = event =>{
        let url = document.getElementById("meeting_room_url").innerText
        copy(url);
        toast(intl.formatMessage({ id: 'SUCCESS_0008' }),{style:{background: CONST.HOT_TOAST_SUCCESS},});
    }

    return(
        <tbody class="lump table-space">
            <tr>
                <td><p><FormattedMessage id="meeting-room_url_txt" /></p></td>
                <td><Link id="meeting_room_url" to={{ pathname: "/meeting-info/" + storeUser.account }} target="_blank" style={{textDecoration:'underline'}}>{window.location.host}/#/meeting-info/{storeUser.account}</Link></td>
                <td>
                    <div class="inline_block element_width_m">
                        <a href="javascript:void(0);" class="buttons_common btn_wht" onClick={handleOnClick} >
                            <FormattedMessage id="meeting-room_copyUrl_btn" />
                        </a>
                    </div>                                   
                </td>
            </tr>
        </tbody>
    );

}