import { createSlice } from '@reduxjs/toolkit';


// Redux Toolkitを使用すると、reducersで「変更」ロジックを記述できます。 
// 「draft state」への変更を検出しそれらの変更に基づいて
// まったく新しい不変の状態を生成するImmerライブラリを使用するため
// 実際には状態を変更しません。
export const languageSlice = createSlice({
	name: 'language',
	initialState: {
		lang: getInitLang(),
	},
	reducers: {
		setLanguage: (state, action) => {
			state.lang = action.payload;
		},
	},
});


export const { setLanguage } = languageSlice.actions;




// サンク：非同期ロジックの実行
// dispatch(incrementByAmount(amount))
// 最初の引数として dispatch関数を使用してサンクが呼び出されます。
// その後、非同期コードを実行し、他のアクションをディスパッチできます
/*
export const incrementAsync = amount => dispatch => {
  setTimeout(() => {
	dispatch(incrementByAmount(amount));
  }, 1000);
};
*/

// セレクター：state から値を取得する
// useSelector((state) => state.counter.value) のようにインラインで定義もできる

export const getLanguage = state => state.language.lang;


// レデューサーをエクスポートする
export default languageSlice.reducer;

function getInitLang() {
	var language = ((window.navigator.languages && window.navigator.languages[0]) ||
		window.navigator.language ||
		window.navigator.userLanguage ||
		window.navigator.browserLanguage).substr(0,2).toLowerCase();

	switch (language) {
		case 'ja':
			break;
		case 'en':
			break;
		case 'fr':
			break;
		case 'de':
			break;
		default:
			language = 'ja';
	}
	return language;
}