
import { FormattedMessage, useIntl } from "react-intl";
import React, { useState, useCallback,useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as CONST from '../../const';
import { getAccessToken, getRefreshToken, setAccessToken, logout, getUserData } from '../../store/slicer/0101_session';
import { SetErrorMessage } from '../../store/slicer/0101_logout_message';
import toast from 'react-hot-toast';
import InputMask from 'react-input-mask';


export default function MeettingRoomBody(){
    const dispatch = useDispatch();
    const intl = useIntl();
    const storeUser = useSelector(getUserData);
    var access_token = useSelector(getAccessToken);
    var refresh_token = useSelector(getRefreshToken);

    // 初期化
    const [state, setState] = useState(() => {
        let temp = {};
		temp.edit = false;
		temp.old_host_key = "";	
		temp.new_host_key = "";	
		temp.options = [{}];

		//masking
		temp.host_key_str = "*********";
		temp.pass_class = true;
		temp.form = [
			{ message: 'EMPTY' },	
		];
		temp.focus_initial_escape = false;
        const fetchHttpRefresh = async () => {
            let senddata = {
            };
            let url_tokref = CONST.BASEURL + '/authorize/refresh';
            var httpResponse_tokref = await fetch( url_tokref, {
                method:'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + refresh_token
                },
                body: JSON.stringify(senddata),
            });
            switch (httpResponse_tokref.status) {
                case 200:
                    var httpResponse_tokref_json = await httpResponse_tokref.json();
                    access_token = httpResponse_tokref_json.access_token;
                    refresh_token = httpResponse_tokref_json.refresh_token;
                    // トークンをデコードする
                    //const decode_token = jwt(access_token);
                    // 問題ない場合は、storeに保存
                    dispatch(setAccessToken(access_token));
                    //dispatch(setRefreshToken(refresh_token));
                    getPassword()
                    return;                             
                default:
                    dispatch(SetErrorMessage('ERROR_0001'));
                    dispatch(logout());
                    return;
            }
        }
        fetchHttpRefresh()
        return temp;
    });

    // パスワード取得
    const getPassword = async () => {
        fetch(CONST.BASEURL + '/customers/' + storeUser.id , {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + access_token
            }
        })
        .then(response =>
            response.json().then(data => ({
                data: data,
                status: response.status
            })
            ).then(res => {
                switch (res.status) {
                    case 200:
                        setState({ ...state, old_host_key:res.data.meeting_information_password});
                        return;
                    case 400:
                        //fetchHttpRefresh();
                        return;
                    case 404:
                        dispatch(SetErrorMessage('ERROR_0039'));  
                        return;   
                    default:
                        //Error出力
                        toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                        break;
                }

            }).catch((err) => {
                //Error出力
                toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

            })
        )
        .catch((err) => {
            //Error出力
            toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

        });
    }

    const handle_Input_Password = event => {

        //invisible
        if (state.pass_class) {
            event.target.setAttribute('class', 'masking_btn visible');
            setState({ ...state, pass_class: false,host_key_str:state.old_host_key});

        //visible
        } else {
            event.target.setAttribute('class', 'masking_btn invisible');
            setState({ ...state, pass_class: true,host_key_str:"*********" });

        }
    }

    const handleOnClick = useCallback(event => {
		event.preventDefault(false);
		const type = event.target.getAttribute('data-type');
		const editing = state.edit ? false : true;
		let error = [...state.form];

		switch (type) {
			case "edit":
				setState({ ...state, edit: editing, new_host_key: state.old_host_key, focus_initial_escape: true });
				break;
			case "cancel":
				error[0]['message'] = 'EMPTY';
				setState({ ...state, edit: editing, new_host_key: "", form: error, host_key_str: "*********", pass_class: true });
				break;
			case "submit":
                let view = document.getElementById('newPwd');
                //入力チェック
                if(state.new_host_key.length == 0){
                    error[0]['message'] = 'ERROR_0020';
                    view.focus();
                    setState({ ...state, form: error, });
                    return;
                }

                const fetchHttpRefresh = async () => {
                    let senddata = {
                    };
                    let url_tokref = CONST.BASEURL + '/authorize/refresh';
                    var httpResponse_tokref = await fetch( url_tokref, {
                        method:'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + refresh_token
                        },
                        body: JSON.stringify(senddata),
                    });
                    switch (httpResponse_tokref.status) {
                        case 200:
                            var httpResponse_tokref_json = await httpResponse_tokref.json();
                            access_token = httpResponse_tokref_json.access_token;
                            refresh_token = httpResponse_tokref_json.refresh_token;
                            // トークンをデコードする
                            //const decode_token = jwt(access_token);
                            // 問題ない場合は、storeに保存
                            dispatch(setAccessToken(access_token));
                            //dispatch(setRefreshToken(refresh_token));
                            fetchHttp();
                            return;                             
                        default:
                            dispatch(SetErrorMessage('ERROR_0001'));
                            dispatch(logout());
                            return;
                    }
                }
                //fetchHttpRefresh();
                //更新処理
                const fetchHttp = async () => {
                    let senddata = {
                        'customer_id': storeUser.id,
                        'meeting_information_password': state.new_host_key
                    };
                    fetch(CONST.BASEURL + '/customers', {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: "Bearer " + access_token
                        },
                        body: JSON.stringify(senddata),
                    })
                    .then(response => {
                        switch (response.status) {
                            case 204:
                                //成功通知
                                toast(intl.formatMessage({ id: 'SUCCESS_0005' }),{style:{background:  CONST.HOT_TOAST_SUCCESS}});
                                setState({ ...state, edit: false, old_host_key: state.new_host_key, pass_class: true,host_key_str:"*********" });
                                break;
                            case 401:
                                fetchHttpRefresh();
                                return;
                            case 409:
                                dispatch(SetErrorMessage('ERROR_0039'));  
                                dispatch(logout());
                                return;
                            default:
                                response.json().then(data => ({
                                    data: data,
                                    status: response.status
                                })
                                ).then(res => {
                                    //Error出力
                                    toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                                }).catch((err) => {
                                    //Error出力
                                    toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                                });
                        }
                    })
                    .catch((err) => {
                        //Error出力
                        toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                    });
                }
                fetchHttp();
                break;
        }
    });

    // 表示用パスワード変更テキストボックスのイベント
	const handleInputChange = event => {
        let error = [...state.form];
        error[0]['message'] = 'EMPTY';
		setState({ ...state, new_host_key: event.target.value });
	};

    // 描画
	switch (state.edit) {
		// 表示画面
		case false:
			return(
                <tbody class="lump table-space">
                    <tr>
                        <td><p><FormattedMessage id="meeting-room_password_txt" /></p></td>
                        <td>
                            <div class="flex_box left_justified">
                                <span class="blank_right_m">{state.host_key_str}</span>
                                <div class="meeting-room_masking">
                                    <button class="masking_btn invisible" id="pass_class" onClick={handle_Input_Password}></button>
                                </div>
                            </div>    
                        </td>
                        <td>
                            <div class="element_width_m">
                                <a href="javascript:void(0);" class="buttons_common btn_wht" id="edit_btn1" data-type="edit" onClick={handleOnClick} >
                                    <FormattedMessage id="meeting-room_edit_btn" />
                                </a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            );
        
        case true:
            return(
                <tbody class="lump table-space">
                    <tr>
                        <td><p><FormattedMessage id="meeting-room_password_txt" /></p></td>
                        <td>
							<div class="flex_box">
                                <div class="element_width_m">
                                    <input id="newPwd" class={state.form[0].message !=='EMPTY' ? 'has-error' : ''} value={state.new_host_key} onChange={handleInputChange}/>
                                </div>
							</div>
						</td>
						<td class="save_and_cancel">
                            <div class="inline_block element_width_m"><FormattedMessage id="meeting-room_save_btn">{value => <input class="buttons_common btn_blue" value={value} data-type="submit" onClick={handleOnClick} />}</FormattedMessage></div>
							<div class="inline_block element_width_m blank_space_m"><button class="buttons_common btn_wht" data-type="cancel" onClick={handleOnClick}><FormattedMessage id="meeting-room_cancel_btn" /></button></div>
						</td>
                    </tr>
                    <tr>
                        <td></td>
                        <div class="has-error" style={{ display: state.form[0].message !=='EMPTY' ? '' : 'none' }}><FormattedMessage id={state.form[0].message} /></div>
                    </tr>
                </tbody> 
            );
    }

}