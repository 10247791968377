import * as CONST from '../../const';
import { useState, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserData, getAccessToken, getRefreshToken, setAccessToken, logout } from '../../store/slicer/0101_session';
import { SetErrorMessage } from '../../store/slicer/0101_logout_message';
import { getProfile, setProfile, getUserProfile } from '../../store/slicer/0503_room';
import toast from 'react-hot-toast';
import { FormattedMessage, useIntl } from "react-intl";


export default function UserProfileBody(props) {
	const dispatch = useDispatch();

	// セッション
	const storeUser = useSelector(getUserData);
	
	// プロフィール
	const storeProfile = useSelector(getProfile);
	const storeUserProfile = useSelector(getUserProfile);

	const intl = useIntl();

	var access_token = useSelector(getAccessToken);
	var refresh_token = useSelector(getRefreshToken);	
	// 初期化
	const [state, setState] = useState(() => {
		setLocationList();
		let temp = {};

		temp.edit = false;
		temp.options = [];

		temp.text = storeProfile.user_name;
		temp.list = {
			value: storeProfile.location_id,
			text: storeProfile.location_name,
		}
		temp.old = {
			text: storeProfile.user_name,
			list: {
				value: storeProfile.location_id,
				text: storeProfile.location_name,
			}
		}
		temp.form = [
			{ message: 'EMPTY' },
		];
		temp.focus_initial_escape = false;
		return temp;
	});


	// 拠点一覧取得
	function setLocationList() {
		let options = [];
		let list = { value: "", text: "" };

		const fetchHttpRefresh = async () => {
			let senddata = {};
			let url_tokref = CONST.BASEURL + '/authorize/refresh';
			var httpResponse_tokref = await fetch( url_tokref, {
				method:'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': "Bearer " + refresh_token
				},
				body: JSON.stringify(senddata),
			});
			switch (httpResponse_tokref.status) {
				case 200:
					var httpResponse_tokref_json = await httpResponse_tokref.json();
					access_token = httpResponse_tokref_json.access_token;
					refresh_token = httpResponse_tokref_json.refresh_token;
					// トークンをデコードする
					//const decode_token = jwt(access_token);
					// 問題ない場合は、storeに保存
					dispatch(setAccessToken(access_token));
					//dispatch(setRefreshToken(refresh_token));
					fetchHttp();
					return;				       
				default:
					dispatch(SetErrorMessage('ERROR_0001'));
					dispatch(logout());
					return;
			}
		}
		//fetchHttpRefresh();
		const fetchHttp = async () => {
		fetch(CONST.BASEURL + '/customers/' + storeUser.id + '/locations', {
			method:'GET',
			headers: {
				Authorization: "Bearer " + access_token
			}
		})
		.then(response => 
			response.json().then(data => ({
				data: data,
				status: response.status
			})
			).then(res => {
				switch (res.status) {
				case 200:
					// データ取得
					for (let i = 0; i < res.data.locations.length; i++) {
						options[i] = { 
							value: res.data.locations[i].location_id.trim(),
							text: res.data.locations[i].location_name,
						};
						if (res.data.locations[i].location_name===state.list.text) {
							list ={value: res.data.locations[i].location_id.trim(), text: res.data.locations[i].location_name};
						}
					}
					setState({...state, options: options, list:list });
					return;
				case 401:
					fetchHttpRefresh();
					return
				case 409:					
					dispatch(SetErrorMessage('ERROR_0039'));					
					dispatch(logout());
					return; 
				default:
					toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

					break;
				}
			}).catch((err) => {
				//Error出力
				toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

			})
		)
		.catch((err) => {
			toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

		});
		}
		fetchHttp();
	
	};



	// onClickイベントハンドラ
	const handleOnClick = event => {
		event.preventDefault(false);
		let error = [...state.form];
		const type = event.target.getAttribute('data-type');
		const editing = state.edit ? false: true;
		const text = state.text;

		let list = {value:'', text:''};
		let update_user = {};
		let senddata = {};

		switch (type) {
			case "edit":
				for (let i=0; i < state.options.length; i++) {
					if (state.options[i].text===state.list.text) {
						list ={value: state.options[i].value, text: state.options[i].text};
						break;
					}
				}
				const old = { text: text, list: list }
				setState({ ...state, edit: editing, text: text, list: list, old: old, focus_initial_escape: true });
				break;

			case "cancel":
				error[0]['message'] = 'EMPTY';	
				setState({ ...state, edit: editing, text: state.old.text, list: state.old.list, form: error, });
				break;

			case "submit":
				if (state.text.length === 0) {
					error[0]['message'] = 'ERROR_0023';
					document.getElementById('meeting_room_name').focus();
					setState({ ...state, form: error, });
					return;
				}
				// ユーザープロファイル更新
				senddata = {
					'location_id': state.list.value, 
					'user_name': state.text, 
					'customer_id': storeUser.id,
				};

				const fetchHttpRefresh = async () => {
					let url_tokref = CONST.BASEURL + '/authorize/refresh';
					var httpResponse_tokref = await fetch( url_tokref, {
						method:'POST',
						headers: {
							'Content-Type': 'application/json',
							'Authorization': "Bearer " + refresh_token
						},
						body: JSON.stringify(senddata),
					});
					switch (httpResponse_tokref.status) {
						case 200:
							var httpResponse_tokref_json = await httpResponse_tokref.json();
							access_token = httpResponse_tokref_json.access_token;
							refresh_token = httpResponse_tokref_json.refresh_token;
							// トークンをデコードする
							//const decode_token = jwt(access_token);
							// 問題ない場合は、storeに保存
							dispatch(setAccessToken(access_token));
							//dispatch(setRefreshToken(refresh_token));
							fetchHttp();
							return;						      
						default:
							dispatch(SetErrorMessage('ERROR_0001'));
							dispatch(logout());
							return;
					}
				}
				//fetchHttpRefresh();

				// ユーザープロファイル更新
				const fetchHttp = async () => {
					fetch(CONST.BASEURL + '/users/' + storeUserProfile.user_id , {
						method:'PATCH',
						headers: {
							'Content-Type': 'application/json',
							Authorization: "Bearer " + access_token
						},
						body: JSON.stringify(senddata),
					})
					.then(response => {
						switch (response.status) {
							case 204:
								//成功通知
								error[0]['message'] = 'EMPTY';
								toast(intl.formatMessage({ id: 'SUCCESS_0002' }),{style:{background:  CONST.HOT_TOAST_SUCCESS}});

								// 正常終了
								setState({ ...state, edit: false, text: text, form: error,});
								update_user = {...storeProfile, user_name: text, location_name: state.list.text, };
								dispatch(setProfile(update_user));
								return;	
							case 400:
								response.json().then(data => ({
									data: data,
									status: response.status
								})
								).then(res => {
									switch (res.data.errors[0]["code"]) {
										case 3000:
											error[0]['message'] = 'ERROR_0008';
											document.getElementById('meeting_room_name').focus();
											setState({ ...state, form: error });
											break;
										default:
											//Error出力
											toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

											break;
									}
								})
									.catch((err) =>
										toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}})
									)
								break;
							case 401:
								fetchHttpRefresh();
								return
							case 409:
								dispatch(SetErrorMessage('ERROR_0039'));  
								dispatch(logout());
								return;
							default:
								response.json().then(data => ({
									data: data,
									status: response.status
								})
								).then(res => {
									switch (res.status) {
										case 400:
											//Error出力
											toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

											break;
										default:
											//Error出力
											toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

											break;
									}
								}).catch((err) => {
									//Error出力
									toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

								});
						}
					})
					.catch((err) => {
						//Error出力
						toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

					});
				}
				fetchHttp();
				break;
			default:
				break;
		}
	};



	// セレクトボックス変更のイベント
	const handleSelectChange = event =>{
		event.preventDefault(false);
		let list = {};

		if (event.target.value==='') {
			list ={value:'', text: ''};
			setState({...state, list:list });
			return;
		}

		for (let i=0; i < state.options.length; i++) {
			if (state.options[i].value===event.target.value) {
				list ={value:state.options[i].value, text: state.options[i].text};
				break;
			}
		}
		setState({...state, list:list });
	}

	// 会議室名の変更テキストボックスのイベント
	const handleInputChange = event => {
		setState({...state, text: event.target.value});
	};

	// ダイアログ初期カーソル
	useLayoutEffect(() => {
		if (state.edit == true) {
			document.getElementById('meeting_room_name').focus();
		} else if (state.edit == false && state.focus_initial_escape == true){
			document.getElementById('edit_btn1').focus();
			setState({ ...state, focus_initial_escape: false });
        }
	}, [state.edit])

	// 描画
	switch (state.edit) {
	// 表示画面
	case false:
		return (
			<tbody class="lump">
				<tr>
					<td><p><FormattedMessage id="profile-view_username_lbl" /></p></td>
					<td class="flex_box left_justified"><span>{state.text}</span></td>
					<td class="usage_period"><span><FormattedMessage id="profile-view_usage_period_lbl" /></span><span class="blank_space_m">{storeProfile.use_start_date}&nbsp;～&nbsp;{storeProfile.use_end_date}</span></td>
				</tr>
				<tr>
					<td><p><FormattedMessage id="profile-view_locationname_lbl" /></p></td>
					<td>
						<div class="flex_box">
							<p>{state.list.text}</p>
							<div class="inline_block element_width_ms">
								<a href="javascript:void(0);" class="buttons_common btn_wht" id="edit_btn1" data-type="edit" onClick={handleOnClick} >
									<FormattedMessage id="profile-view_edit_btn" />
								</a>
							</div>
						</div>
					</td>
				</tr>
			</tbody>
		);
	

	// 編集画面
	case true:
		return (
			<tbody class="lump" id="place_and_time">
				<tr>
					<td><label class="edit_label" for="meeting_room_name"><FormattedMessage id="profile-view_username_lbl" /></label></td>
					<td class="flex_box left_justified">
						<div class="element_width_l inline_block">
							<input id="meeting_room_name" class={state.form[0].message !=='EMPTY' ? 'has-error' : ''} type="text" maxlength='36' value={state.text} onChange={handleInputChange} />
						</div>
					</td>
					<td class="usage_period">
						<span><FormattedMessage id="profile-view_usage_period_lbl" /></span><span class="blank_space_m">{storeProfile.use_start_date}&nbsp;～&nbsp;{storeProfile.use_end_date}</span>
					</td>
				</tr>		
				<tr class="has-error" style={{ display: state.form[0].message !=='EMPTY' ? '' : 'none' }}>
					<td>
					</td>
					<div class="has-error" style={{ display: state.form[0].message !=='EMPTY' ? '' : 'none' }}><FormattedMessage id={state.form[0].message} /></div>
				</tr>				
				<tr>
					<td><label for="base_name"><FormattedMessage id="profile-view_locationname_lbl" /></label></td>
					<td>
						<div class="flex_box">
							<div class="selecter_down_arrow select_option element_width_l inline_block">
								<label>
									<img class="svg_img-Pulldown" src="img/EZTP_Pulldown.svg" alt="▽" />
									<select id="base_name" value={state.list.value} onChange={handleSelectChange}>
										<option value="" data-name=""></option>
										{
											state.options.map((data, index) => {
												return (
													<option value={data.value} data-name={data.text}>{data.text}</option>
												);
											})
										}
									</select>
								</label>
							</div>
							<div class="delete_invisible_space">
								<div class="inline_block element_width_ms"><FormattedMessage id="profile-edit_save_btn">{value => <input class="buttons_common btn_blue" type="submit" value={value} data-type="submit" onClick={handleOnClick} />}</FormattedMessage></div>
								<div class="inline_block element_width_ms blank_space_m"><button class="buttons_common btn_wht" data-type="cancel" onClick={handleOnClick}><FormattedMessage id="profile-edit_cancel_btn" /></button></div>
							</div>
						</div>
					</td>
				</tr>
			</tbody>
		);
	

	default:
		break;
	}

	return (<tbody class="lump"></tbody>);
}
