import * as CONST from '../../const'
import React, { useState, useEffect, useCallback, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { getAccessToken, getRefreshToken, setAccessToken, logout } from '../../store/slicer/0101_session';
import { FormattedMessage, useIntl } from "react-intl";
import toast from 'react-hot-toast';
import { SetErrorMessage } from '../../store/slicer/0101_logout_message';
import { setRecords, getRecords } from '../../store/slicer/1002_record_details';

export default function RecordDetails(props) {
	const dispatch = useDispatch();
	const loc = useLocation();
	const history = useHistory();
	const getRecord = useSelector(getRecords);



	var access_token = useSelector(getAccessToken);
	var refresh_token = useSelector(getRefreshToken);

	const intl = useIntl();

	// 初期化
	const [state, setState] = useState(() => {

		let meeting_id = "";
		var text_digit1 = "";
		var text_digit2 = "";
		var text_digit3 = "";
		var text = "";

		if (loc.state !== undefined && loc.state.meeting_id !== undefined) {
			meeting_id = loc.state.meeting_id;

			//ミーティングID文字列を３つに分割
			text = String(meeting_id);
			if (text.length < 11) {
				text_digit1 = text.substr(0, 3) + " ";
				text_digit2 = text.substr(3, 3) + " ";
				text_digit3 = text.substr(6);
			} else {
				text_digit1 = text.substr(0, 3) + " ";
				text_digit2 = text.substr(3, 4) + " ";
				text_digit3 = text.substr(7);
			}
			meeting_id = text_digit1 + text_digit2 + text_digit3;
		} else {
			meeting_id = getRecord.meeting_id;
		}

		let meeting_uuid = '';
		if (loc.state !== undefined && loc.state.meeting_uuid !== undefined) {
			meeting_uuid = loc.state.meeting_uuid;
		} else {
			meeting_uuid = getRecord.meeting_uuid;
		}

		let auto_delete_cmr_days = 0;
		if (loc.state !== undefined && loc.state.auto_delete_cmr_days !== undefined) {
			auto_delete_cmr_days = loc.state.auto_delete_cmr_days;
		} else {
			auto_delete_cmr_days = 0;
		}

		dispatch(setRecords({ meeting_id: meeting_id, meeting_uuid: meeting_uuid, auto_delete_cmr_days: auto_delete_cmr_days }));

		return {
			meeting_id: meeting_id,
			meeting_uuid: meeting_uuid,
			auto_delete_cmr_days: auto_delete_cmr_days,
			deleteDays: '',
			start_time: '',
			timezone: '',
			share_url: '',
			recording_count: '',
			recordingGroup: [{}],
			recordingFiles: [{}],
			settings: '',
			share_flag: false,
			share_status: false,
			share_type: '',
			share_password_flag: false,
			share_password_edit_flag: false,
			share_old_password: '',
			share_new_password: '',
			share_information: '',
			share_information_flag: false,
			delete_flag: false,
			delete_recording_id: '',
			delete_recording_groupid: '',
			crud_flag: false,
		}
	});

	// 表示リスト作成
	useEffect(() => {

		let meeting_id = state.meeting_id;
		let meeting_uuid = state.meeting_uuid;
		let topic = '';
		let start_time = '';
		let timezone = '';
		let share_url = '';
		let recording_count = '';
		let recordingFiles = [{}];

		let group_file_size = 0;
		let group_file_time = 0;
		let group_share_url = 0;

		/**
		 * ファイルサイズを適切な単位に変換
		 */
		const UnitChangeForByte = function (size) {
			const { target, unit } = getTarget(size)
			let newSize = target !== null ? Math.round((size / target) * Math.pow(10, 2)) / Math.pow(10, 2) : size
			return Math.round(newSize) + unit
		}

		/**
		 * 入力されたファイルサイズを元に計算につかうバイト数と単位を返却
		 */
		const getTarget = function (size) {
			const kb = 1024
			const mb = Math.pow(kb, 2)
			const gb = Math.pow(kb, 3)
			const tb = Math.pow(kb, 4)

			const returnData = (target, unit) => ({ target, unit })
			if (size >= tb) return returnData(tb, 'TB')
			if (size >= gb) return returnData(gb, 'GB')
			if (size >= mb) return returnData(mb, 'MB')
			if (size >= kb) return returnData(kb, 'KB')
			return returnData(null, 'byte')
		}

		const fetchHttpRefresh = async () => {
			let senddata = {};
			let url_tokref = CONST.BASEURL + '/authorize/refresh';
			var httpResponse_tokref = await fetch(url_tokref, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': "Bearer " + refresh_token
				},
				body: JSON.stringify(senddata),
			});
			switch (httpResponse_tokref.status) {
				case 200:
					var httpResponse_tokref_json = await httpResponse_tokref.json();
					access_token = httpResponse_tokref_json.access_token;
					refresh_token = httpResponse_tokref_json.refresh_token;
					// トークンをデコードする
					//const decode_token = jwt(access_token);
					// 問題ない場合は、storeに保存
					dispatch(setAccessToken(access_token));
					//dispatch(setRefreshToken(refresh_token));
					fetchHttp();
					return;
				default:
					dispatch(SetErrorMessage('ERROR_0001'));
					dispatch(logout());
					return;
			}
		}
		//fetchHttpRefresh();
		// レコーディング情報取得
		var url = CONST.BASEURL + '/meetings/' + meeting_uuid + '/recordings';
		const fetchHttp = async () => {
			fetch(url, {
				method: 'GET',
				headers: {
					Authorization: "Bearer " + access_token
				}
			})
				.then(response => response.json().then(data => ({
					data: data,
					status: response.status
				})
				).then((res) => {
					switch (res.status) {
						case 200:
							// データ取得
							let counter = 0;
							let recordingGroup = [{}];
							let recordingGroupID = 1;
							let group_file_cnt = 0;
							let recordingGroupIndex = 0;
							let check_recording_start = new Date(res.data.recording_files[0].recording_start);

							topic = res.data.topic;
							start_time = new Date(res.data.start_time).toLocaleString({ timeZone: 'Asia/Tokyo' }).slice(0, -3);
							let dispStartDate = new Date(start_time);
							start_time = dispStartDate.getFullYear() + '/' + ('00' + (dispStartDate.getMonth() + 1)).slice(-2) + '/' + ('00' + dispStartDate.getDate()).slice(-2)
								+ ' ' + ('00' + dispStartDate.getHours()).slice(-2) + ':' + ('00' + dispStartDate.getMinutes()).slice(-2);

							// timezoneを多言語対応して表示する
							const timezoneArray = CONST.TIMEZONE_VALUE.map(function (value, index) {
								return { value: CONST.TIMEZONE_VALUE[index], text: intl.formatMessage({ id: "TIMEZONE_" + ('0000' + (index + 1)).slice(-4) }), }
							});

							timezone = '';
							for (let i = 0; i < timezoneArray.length; i++) {
								if (timezoneArray[i].value === res.data.timezone) {
									timezone = timezoneArray[i].text;
									break;
								}
							}
							share_url = res.data.share_url;
							recording_count = res.data.recording_count;

							for (let i = 0; i < res.data.recording_count; i++) {

								let t1 = new Date(res.data.recording_files[i].recording_end);
								let t2 = new Date(res.data.recording_files[i].recording_start);
								let diff = t1.getTime() - t2.getTime()

								if (check_recording_start.getTime() !== t2.getTime()) {
									//HH部分取得
									let diffHour = group_file_time / (1000 * 60 * 60);
									//MM部分取得
									let diffMinute = (diffHour - Math.floor(diffHour)) * 60;
									//SS部分取得
									let diffSecond = (diffMinute - Math.floor(diffMinute)) * 60;
									group_file_time =
										('00' + Math.floor(diffHour)).slice(-2) + ':' + ('00' + Math.floor(diffMinute)).slice(-2) + ':' + ('00' + Math.round(diffSecond)).slice(-2)

									group_share_url = res.data.share_url + '?startTime=' + check_recording_start.getTime();
									let groupBody = {
										recordingGroupID: recordingGroupID,
										group_file_cnt: group_file_cnt,
										group_file_size: UnitChangeForByte(group_file_size),
										group_file_time: group_file_time,
										group_share_url: group_share_url,
									};

									recordingGroup.[recordingGroupIndex++] = groupBody;
									recordingGroupID = recordingGroupID + 1;
									group_file_cnt = 1;
									group_file_size = res.data.recording_files[i].file_size;
									group_file_time = diff;
								} else {
									group_file_cnt = group_file_cnt + 1;
									group_file_size = group_file_size + res.data.recording_files[i].file_size;
								}
								res.data.recording_files[i].recordingGroupID = recordingGroupID;
								res.data.recording_files[i].dispFileSize = UnitChangeForByte(res.data.recording_files[i].file_size);
								recordingFiles[counter++] = res.data.recording_files[i];
								check_recording_start = t2;
								group_file_time = diff;
							}

							let deleteLimit = new Date(start_time);
							deleteLimit.setDate(deleteLimit.getDate() + state.auto_delete_cmr_days);
							let deleteDays = Math.ceil((deleteLimit - new Date()) / 86400000);

							//HH部分取得
							let diffHour = group_file_time / (1000 * 60 * 60);
							//MM部分取得
							let diffMinute = (diffHour - Math.floor(diffHour)) * 60;
							//SS部分取得
							let diffSecond = (diffMinute - Math.floor(diffMinute)) * 60;
							group_file_time =
								('00' + Math.floor(diffHour)).slice(-2) + ':' + ('00' + Math.floor(diffMinute)).slice(-2) + ':' + ('00' + Math.round(diffSecond)).slice(-2)
							group_share_url = res.data.share_url + '?startTime=' + check_recording_start.getTime();
							let groupBody = {
								recordingGroupID: recordingGroupID,
								group_file_cnt: group_file_cnt,
								group_file_size: UnitChangeForByte(group_file_size),
								group_file_time: group_file_time,
								group_share_url: group_share_url,
							};

							recordingGroup.[recordingGroupIndex++] = groupBody;

							// 表示用リストデータを設定する
							setState({
								...state, meeting_id: meeting_id, meeting_uuid: meeting_uuid, deleteDays: deleteDays, topic: topic, start_time: start_time,
								timezone: timezone, share_url: share_url, recording_count: recording_count,
								recordingGroup: recordingGroup, recordingFiles: recordingFiles, crud_flag: false
							});

							break;
						case 401:
							fetchHttpRefresh();
							return;
						case 409:
							dispatch(SetErrorMessage('ERROR_0039'));
							dispatch(logout());
							return;
						default:
							//Error出力
							toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

							break;
					}
				})
				)
				.catch((err) => {
					//Error出力
					toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

				});
		}
		fetchHttp();

		document.addEventListener("keydown", escFunction, false);

	}, [state.crud_flag]);

	// 共有画面が出ているときはESCキーで画面を終了させる
	const escFunction = useCallback((event) => {
		if (event.keyCode === 27 && state.share_flag === false) {
			// 表示用リストデータを設定する
			setState({ ...state, crud_flag: true });
		}
	}, []);

	// 共有を閉じる
	const handleUpdateSettings = () => {
		// 表示用リストデータを設定する
		setState({ ...state, crud_flag: true, share_flag: false, share_password_edit_flag: false });
	}

	// 一括ダウンロード
	const handlealldownload = event => {
		var spanedSec = 0;
		var id;
		var recordingGroupID = parseInt(event.currentTarget.id);
		let x = 0;
		var recordingFiles = [{}];

		for (let i = 0; i < state.recordingFiles.length; i++) {
			if (recordingGroupID === state.recordingFiles[i].recordingGroupID) {
				recordingFiles[x] = state.recordingFiles[i];
				x++;
			}
		}

		var repeatFunc = function () {
			if (spanedSec >= recordingFiles.length) {
				return;
			}
			downloadFile(recordingFiles[spanedSec].download_url);
			spanedSec++;
			clearTimeout(id);
			id = setTimeout(repeatFunc, 3000);
		};
		repeatFunc();
	}

	function downloadFile(downloadURL) {
		var link = document.createElement('a');
		document.body.appendChild(link);
		link.href = downloadURL;
		// link.href = downloadURL + '?access_token=' + ZOOMのアクセストークン;これならパスコード不要
		link.click();
		document.body.removeChild(link);
	}

	// 共有可能リンクのクリップボードコピー
	const handleSharingUrlCopy = event => {
		var textField = document.createElement('textarea');
		textField.innerText = event.target.id;
		document.body.appendChild(textField);
		textField.select();
		document.execCommand('copy');
		//navigator.clipboard.writeText(textField.value);
		textField.remove();
		toast(intl.formatMessage({ id: 'record-details_clipboard_copy' }),{style:{background:  CONST.HOT_TOAST_SUCCESS}});
	}

	// 共有情報のクリップボードコピー
	const handleSharingInfoCopy = event => {
		var textField = document.createElement('textarea');
		textField.value = state.share_information;
		document.body.appendChild(textField);
		textField.select();
		document.execCommand('copy');
		//navigator.clipboard.writeText(textField.value);
		textField.remove();
		toast(intl.formatMessage({ id: 'record-details_clipboard_copy' }),{style:{background:  CONST.HOT_TOAST_SUCCESS}});
	}

	//共有ポップアップ表示
	const handleShare = event => {

		setState({ ...state, share_flag: true });

		const fetchHttpRefresh = async () => {
			let senddata = {};
			let url_tokref = CONST.BASEURL + '/authorize/refresh';
			var httpResponse_tokref = await fetch(url_tokref, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': "Bearer " + refresh_token
				},
				body: JSON.stringify(senddata),
			});
			switch (httpResponse_tokref.status) {
				case 200:
					var httpResponse_tokref_json = await httpResponse_tokref.json();
					access_token = httpResponse_tokref_json.access_token;
					refresh_token = httpResponse_tokref_json.refresh_token;
					// トークンをデコードする
					//const decode_token = jwt(access_token);
					// 問題ない場合は、storeに保存
					dispatch(setAccessToken(access_token));
					//dispatch(setRefreshToken(refresh_token));
					fetchHttp();
					return;
				default:
					dispatch(SetErrorMessage('ERROR_0001'));
					dispatch(logout());
					return;
			}
		}
		//fetchHttpRefresh();
		// レコーディング設定取得
		var url = CONST.BASEURL + '/meetings/' + state.meeting_uuid + '/recordings/settings';
		const fetchHttp = async () => {
			fetch(url, {
				method: 'GET',
				headers: {
					Authorization: "Bearer " + access_token
				}
			})
				.then(response => response.json().then(data => ({
					data: data,
					status: response.status
				})
				).then((res) => {
					switch (res.status) {
						case 200:
							// データ取得
							let share_status = true;
							let share_password_flag = true;
							let share_information = '';
							let share_password = '';
							let share_type = res.data.share_recording
							if (share_type === "none") {
								share_status = false;
								share_password_flag = false;
							} else {
								share_information += intl.formatMessage({ id: "record-share_sentence4_lbl" }) + state.topic + '\n';
								share_information += intl.formatMessage({ id: "record-share_sentence5_lbl" }) + state.start_time + ' ' + state.timezone + '\n\n';
								share_information += intl.formatMessage({ id: "record-share_sentence6_lbl" }) + state.share_url + '\n\n';

								share_password = res.data.password
								if (share_password.length > 0) {
									share_information += intl.formatMessage({ id: "record-share_sentence7_lbl" }) + share_password + '\n';
								} else {
									share_password_flag = false;
								}
							}
							setState({
								...state, share_flag: true, share_status: share_status, share_type: share_type, settings: res.data,
								share_password_flag: share_password_flag, share_old_password: share_password, share_new_password: share_password,
								share_information: share_information
							});
							break;
						case 401:
							fetchHttpRefresh();
							return;
						case 409:
							dispatch(SetErrorMessage('ERROR_0039'));
							dispatch(logout());
							return;
						default:
							toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

							break;
					}
				})
				)
				.catch((err) => {
					toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

				});
		}
		fetchHttp();

	};

	//一括削除ポップアップ表示
	const handleAllDelete = event => {
		setState({ ...state, delete_recording_id: '', delete_recording_groupid: event.currentTarget.id, delete_flag: true });
	};

	//削除ポップアップ表示
	const handleDelete = event => {
		setState({ ...state, delete_recording_id: event.currentTarget.id, delete_recording_groupid: '', delete_flag: true });
	};

	//削除 キャンセルボタン押下
	const handleDeleteCancel = event => {
		setState({ ...state, delete_flag: false });
	};

	// 削除処理
	const delete_recordings = () => {

		var delete_recordingFiles = [{}];
		var recording_count = state.recording_count;
		var delete_recording_count = 0;
		let x = 0;

		if (state.delete_recording_id !== '') {
			delete_recordingFiles[0] = state.delete_recording_id;
		} else {
			for (let i = 0; i < state.recordingFiles.length; i++) {
				var recordingGroupID = parseInt(state.delete_recording_groupid);
				if (recordingGroupID === state.recordingFiles[i].recordingGroupID) {
					delete_recordingFiles[x] = state.recordingFiles[i].recording_id;
					x++;
				}
			}
		}

		for (let i = 0; i < delete_recordingFiles.length; i++) {
			const fetchHttpRefresh = async () => {
				let senddata = {
				};
				let url_tokref = CONST.BASEURL + '/authorize/refresh';
				var httpResponse_tokref = await fetch(url_tokref, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': "Bearer " + refresh_token
					},
					body: JSON.stringify(senddata),
				});
				switch (httpResponse_tokref.status) {
					case 200:
						var httpResponse_tokref_json = await httpResponse_tokref.json();
						access_token = httpResponse_tokref_json.access_token;
						refresh_token = httpResponse_tokref_json.refresh_token;
						// トークンをデコードする
						//const decode_token = jwt(access_token);
						// 問題ない場合は、storeに保存
						dispatch(setAccessToken(access_token));
						//dispatch(setRefreshToken(refresh_token));
						fetchHttp();
						return;
					default:
						dispatch(SetErrorMessage('ERROR_0001'));
						dispatch(logout());
						return;
				}
			}
			//fetchHttpRefresh();
			// レコーディング情報削除API
			var url = CONST.BASEURL + '/meetings/' + state.meeting_uuid + '/recordings/' + delete_recordingFiles[i];
			const fetchHttp = async () => {
				fetch(url, {
					method: 'DELETE',
					headers: {
						'Content-type': 'application/json',
						Authorization: "Bearer " + access_token
					},
				})
					.then(res => {
						if (res.status === 204) {

							toast(intl.formatMessage({ id: 'SUCCESS_0003' }),{style:{background:  CONST.HOT_TOAST_SUCCESS}});

							delete_recording_count = delete_recording_count + 1;
							// レコーディングファイル数が０になる場合は、/recordingに移行
							if (delete_recordingFiles.length === recording_count)
								history.push('/recording');

							// 問題ない場合は、リロード
							setState({ ...state, delete_recording_id: '', delete_flag: false, crud_flag: true, allcheckflag: false });
						}
						else if (res.status === 401) {
							fetchHttpRefresh();
							return;
						}
						else if (res.status === 409) {
							dispatch(SetErrorMessage('ERROR_0039'));
							dispatch(logout());
							return;
						}
						else {
							toast(intl.formatMessage({ id: 'ERROR_0014' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

						}
					})
					.catch((err) => {
						toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

					});
			}
			fetchHttp();
		}
	}

	// 「この記録を共有する」のonClickイベントハンドラ
	const handleShareOnClick = event => {

		//チェック状態をstateに反映
		var check_tmp = !state.share_status;
		let share_type = '';
		let apiBody = '';

		var share_password_flag = state.share_password_flag;
		let share_old_password = state.share_old_password

		if (check_tmp) {
			share_type = 'publicly';
		} else {
			share_type = 'none';
		}
		apiBody = JSON.stringify({ share_recording: share_type });

		const fetchHttpRefresh = async () => {
			let senddata = {
			};
			let url_tokref = CONST.BASEURL + '/authorize/refresh';
			var httpResponse_tokref = await fetch(url_tokref, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': "Bearer " + refresh_token
				},
				body: JSON.stringify(senddata),
			});
			switch (httpResponse_tokref.status) {
				case 200:
					var httpResponse_tokref_json = await httpResponse_tokref.json();
					access_token = httpResponse_tokref_json.access_token;
					refresh_token = httpResponse_tokref_json.refresh_token;
					// トークンをデコードする
					//const decode_token = jwt(access_token);
					// 問題ない場合は、storeに保存
					dispatch(setAccessToken(access_token));
					//dispatch(setRefreshToken(refresh_token));
					fetchHttp();
					return;
				default:
					dispatch(SetErrorMessage('ERROR_0001'));
					dispatch(logout());
					return;
			}
		}
		//fetchHttpRefresh();
		// レコーディング設定更新API
		var url = CONST.BASEURL + '/meetings/' + state.meeting_uuid + '/recordings/settings';
		const fetchHttp = async () => {
			fetch(url, {
				method: 'PATCH',
				headers: {
					'Content-type': 'application/json',
					Authorization: "Bearer " + access_token
				},
				body: apiBody
			})
				.then(res => {
					if (res.status === 204) {
						toast(intl.formatMessage({ id: 'SUCCESS_0005' }),{style:{background:  CONST.HOT_TOAST_SUCCESS}});
						setState({ ...state, share_status: check_tmp, share_type: share_type });
					}
					else if (res.status === 401) {
						fetchHttpRefresh();
						return;
					}
					else if (res.status === 409) {
						dispatch(SetErrorMessage('ERROR_0039'));
						dispatch(logout());
						return;
					}
					else {
						toast(intl.formatMessage({ id: 'ERROR_0016' }),{style:{background:  CONST.HOT_TOAST_ERROR}});
					}
				})
				.catch((err) => {
					toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

				});
		}
		fetchHttp();
	}

	// 「パスコードの保護」のonClickイベントハンドラ
	const handleSharePasswordOnClick = event => {

		//チェック状態をstateに反映
		var check_tmp = !state.share_password_flag;

		setState({ ...state, share_password_flag: check_tmp });

		if (state.share_old_password === '')
			setState({ ...state, share_password_flag: check_tmp, share_password_edit_flag: true });
		else
			setState({ ...state, share_password_flag: check_tmp, share_password_edit_flag: false });

		let share_new_password = state.share_new_password;

		if (!state.share_password_flag) {
			return;
		} else {
			share_new_password = '';
		}

		let apiBody = '';
		apiBody = JSON.stringify({ password: share_new_password });

		const fetchHttpRefresh = async () => {
			let senddata = {
			};
			let url_tokref = CONST.BASEURL + '/authorize/refresh';
			var httpResponse_tokref = await fetch(url_tokref, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': "Bearer " + refresh_token
				},
				body: JSON.stringify(senddata),
			});
			switch (httpResponse_tokref.status) {
				case 200:
					var httpResponse_tokref_json = await httpResponse_tokref.json();
					access_token = httpResponse_tokref_json.access_token;
					refresh_token = httpResponse_tokref_json.refresh_token;
					// トークンをデコードする
					//const decode_token = jwt(access_token);
					// 問題ない場合は、storeに保存
					dispatch(setAccessToken(access_token));
					//dispatch(setRefreshToken(refresh_token));
					fetchHttp();
					return;
				default:
					dispatch(SetErrorMessage('ERROR_0001'));
					dispatch(logout());
					return;
			}
		}
		//fetchHttpRefresh();
		// レコーディング設定更新API
		var url = CONST.BASEURL + '/meetings/' + state.meeting_uuid + '/recordings/settings';
		const fetchHttp = async () => {
			fetch(url, {
				method: 'PATCH',
				headers: {
					'Content-type': 'application/json',
					Authorization: "Bearer " + access_token
				},
				body: apiBody
			})
				.then(res => {
					if (res.status === 204) {
						toast(intl.formatMessage({ id: 'SUCCESS_0005' }),{style:{background:  CONST.HOT_TOAST_SUCCESS}});

						// テキストエリア書き直し
						let share_information = "";
						share_information += intl.formatMessage({ id: "record-share_sentence4_lbl" }) + state.topic + '\n';
						share_information += intl.formatMessage({ id: "record-share_sentence5_lbl" }) + state.start_time + ' ' + state.timezone + '\n\n';
						share_information += intl.formatMessage({ id: "record-share_sentence6_lbl" }) + state.share_url + '\n\n';

						//setState({ ...state, share_password_edit_flag: false, share_password_flag: false, share_new_password: "" });
						setState({ ...state, share_new_password: "", share_old_password: "", share_password_edit_flag: false, share_password_flag: false, share_information: share_information, });
					}
					else if (res.status === 401) {
						fetchHttpRefresh();
						return;
					}
					else if (res.status === 409) {
						dispatch(SetErrorMessage('ERROR_0039'));
						dispatch(logout());
						return;
					}
					else {
						toast(intl.formatMessage({ id: 'ERROR_0016' }),{style:{background:  CONST.HOT_TOAST_ERROR}});
					}
				})
				.catch((err) => {
					toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

				});
		}
		fetchHttp();
	}

	//パスコード表示 
	const handleInputPassword = event => {

		if (state.share_password_edit_flag) {
			setState({ ...state, share_password_edit_flag: false, });
		} else {
			setState({ ...state, share_password_edit_flag: true, });
		}
	}

	//	// テキストボックス（パスコード）入力ハンドラ
	const handleInputNewPassword = event => {
		event.preventDefault(true);
		setState({ ...state, share_new_password: event.target.value });
	}

	// 「パスコードの保護」のonClickイベントハンドラ
	const handleInputPasswordChange = useCallback(event => {
		let error = false;
		const reg_result = state.share_new_password.match(/^(?=.*?[a-z])(?=.*?\d)(?=.*?[!-/:-@[-`{-~])[!-~]{8,}$/i);
		error = (reg_result === null) ? true : error;
		if (error) {
			toast(intl.formatMessage({ id: 'ERROR_0019' }),{style:{background:  CONST.HOT_TOAST_ERROR}});
			document.getElementById('key').focus();
			return;
		}


		let share_recording = state.share_type;
		let share_new_password = state.share_new_password;

		let apiBody = JSON.stringify({ share_recording: share_recording, password: share_new_password });

		const fetchHttpRefresh = async () => {
			let senddata = {
			};
			let url_tokref = CONST.BASEURL + '/authorize/refresh';
			var httpResponse_tokref = await fetch(url_tokref, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': "Bearer " + refresh_token
				},
				body: JSON.stringify(senddata),
			});
			switch (httpResponse_tokref.status) {
				case 200:
					var httpResponse_tokref_json = await httpResponse_tokref.json();
					access_token = httpResponse_tokref_json.access_token;
					refresh_token = httpResponse_tokref_json.refresh_token;
					// トークンをデコードする
					//const decode_token = jwt(access_token);
					// 問題ない場合は、storeに保存
					dispatch(setAccessToken(access_token));
					//dispatch(setRefreshToken(refresh_token));
					fetchHttp();
					return;
				default:
					dispatch(SetErrorMessage('ERROR_0001'));
					dispatch(logout());
					return;
			}
		}
		//fetchHttpRefresh();
		// レコーディング設定更新API
		var url = CONST.BASEURL + '/meetings/' + state.meeting_uuid + '/recordings/settings';
		const fetchHttp = async () => {
			fetch(url, {
				method: 'PATCH',
				headers: {
					'Content-type': 'application/json',
					Authorization: "Bearer " + access_token
				},
				body: apiBody
			})
				.then(res => {
					if (res.status === 204) {
						toast(intl.formatMessage({ id: 'SUCCESS_0005' }),{style:{background:  CONST.HOT_TOAST_SUCCESS}});

						// テキストエリア書き直し
						let share_information = "";
						share_information += intl.formatMessage({ id: "record-share_sentence4_lbl" }) + state.topic + '\n';
						share_information += intl.formatMessage({ id: "record-share_sentence5_lbl" }) + state.start_time + ' ' + state.timezone + '\n\n';
						share_information += intl.formatMessage({ id: "record-share_sentence6_lbl" }) + state.share_url + '\n\n';
						share_information += intl.formatMessage({ id: "record-share_sentence7_lbl" }) + share_new_password + '\n';

						// 表示用リストデータを設定する
						setState({ ...state, share_password_edit_flag: false, share_old_password: share_new_password, share_new_password: share_new_password, share_information: share_information, });
					}
					else if (res.status === 401) {
						fetchHttpRefresh();
						return;
					}
					else if (res.status === 409) {
						dispatch(SetErrorMessage('ERROR_0039'));
						dispatch(logout());
						return;
					}
					else {
						toast(intl.formatMessage({ id: 'ERROR_0016' }),{style:{background:  CONST.HOT_TOAST_ERROR}});
					}
				})
				.catch((err) => {
					toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

				});
		}
		fetchHttp();

	});

	// 「パスコードの保護（キャンセル）」のonClickイベントハンドラ
	const handleInputPasswordCancel = useCallback(event => {
		event.preventDefault(false);
		let share_old_password = state.share_old_password;

		if (share_old_password == '') {
			setState({ ...state, share_password_edit_flag: false, share_password_flag: false, share_new_password: "" });
		} else {
			setState({ ...state, share_password_edit_flag: false, share_password_flag: true, share_new_password: share_old_password });
		}

	});

	// 「Sharing Information」のonClickイベントハンドラ
	const handleShareringInformationOnClick = event => {

		//選択状態をstateに反映
		if (state.share_information_flag) {
			setState({ ...state, share_information_flag: false });
		} else {
			setState({ ...state, share_information_flag: true });
		}

	}

	// チェック押下
	const handleChecktClick = event => {
		const check_id = event.target.getAttribute('id');

		if (check_id === "record_share_chk") {
			document.getElementById("toggle36").click();
		} else if (check_id === "passcode_chk") {
			document.getElementById("toggle37").click();
        }
	}

	// ダイアログ初期カーソル
	useLayoutEffect(() => {
		if (state.share_flag == true && state.share_password_edit_flag == false) {
			document.getElementById('record_share_chk').focus();
		} else if (state.delete_flag == true) {
			document.getElementById('delete_cancel_btn').focus();
		} else if (state.share_password_edit_flag == true) {
			document.getElementById('key').focus();
        }
	}, [state.share_flag, state.delete_flag, state.share_password_edit_flag])

	// ENTERイベント
	const keydownEnter = event => {
		if (window.event.keyCode === 13) {
			// パスコード保存
			handleInputPasswordChange();
		}
	}

	// 描画
	return (
		<div>
			<title><FormattedMessage id="tab_title_record_details" /></title>
			<header class="in_contents_header blank_above_m">
				<div class="flex_box record_header">
					<h2 class="record_title">{state.topic}</h2>
					<div class="element_width_ms"><button class="buttons_common btn_wht" onClick={handleShare} ><FormattedMessage id="record-details_share_btn" /></button></div>
				</div>
			</header>
			<div class="record_contents blank_above_m">
				<div class="record_description">
					<div class="flex_box left_justified">
						<p>{state.start_time}</p>
						<p class="blank_space_xxl">{state.timezone}</p>
						<p class="blank_space_xl">ID：{state.meeting_id}</p>
					</div>
					<p class="blank_above_s">
						<div style={{ display: state.auto_delete_cmr_days === 0 ? 'none' : '' }}>
							<FormattedMessage id="record-details_sentence1_lbl" values={{ days: state.deleteDays }} />
						</div>
					</p>
				</div>
				{
					state.recordingGroup.map((data, index) => {
						let recordingGroupID = data.recordingGroupID;
						return (
							<table class="record_detail">
								<tbody>
									<tr>
										<th colspan="4"><FormattedMessage id="record-details_recording" />{data.recordingGroupID}</th>
									</tr>
									<tr>
										<td><FormattedMessage id="record-details_playtime_lbl" values={{ times: data.group_file_time }} /></td>
										<td><div class="element_width_ml"><FormattedMessage id="record-details_filisize_lbl" values={{ count: data.group_file_cnt, size: data.group_file_size }} /></div></td>
										<td>
											<div class="flex_box left_justified">
												<div class="element_width_l inline_block">
													<button class="buttons_common btn_blue download_btn" id={data.recordingGroupID} onClick={handlealldownload}><img src="img/EZTP_download.svg" alt="" /><FormattedMessage id="record-details_download_btn" values={{ count: data.group_file_cnt }} /></button>
												</div>
												<div class="element_width_l blank_space_ml inline_block"><button class="buttons_common btn_wht link_btn" id={data.group_share_url} onClick={handleSharingUrlCopy} ><img src="img/EZTP_link.svg" alt="" /><FormattedMessage id="record-details_shareablelink_btn" /></button></div>
												<div class="blank_space_ml inline_block"><button class="buttons_common trash_btn" id={data.recordingGroupID} onClick={handleAllDelete}><img src="img/EZTP_del.svg" alt="" /></button></div>
											</div>
										</td>
									</tr>
									{
										state.recordingFiles.map((data, index) => {

											if (recordingGroupID === data.recordingGroupID) {
												let isAudioOnly = true;
												let audio_text = <FormattedMessage id="record-details_videoaudio_lbl" /> // "映像＋音声";
												if (data.recording_type === "audio_only") {
													isAudioOnly = false;
													audio_text = <FormattedMessage id="record-details_audio_lbl" />      // "音声のみ";
												}
												return (
													<tr>
														<td>
															<a href={data.play_url} target="_blank" rel="noopener noreferrer">
																<img class="desvg-movie" src="img/EZTP_audio.svg" style={{ display: isAudioOnly ? 'none' : '' }} alt="" />
																<img class="desvg-movie" src="img/EZTP_video_audio.svg" style={{ display: isAudioOnly ? '' : 'none' }} alt="" />
																{audio_text}
															</a>
														</td>
														<td><FormattedMessage id="record-details_filisize_lbl" values={{ count: 1, size: data.dispFileSize }} /></td>
														<td>
															<div class="flex_box left_justified">
																<div class="element_width_l inline_block">
																	<a class="buttons_common btn_blue download_btn" href={data.download_url} target="_blank" rel="noopener noreferrer"><img src="img/EZTP_download.svg" alt="" /><FormattedMessage id="record-details_download_btn" values={{ count: 1 }} /></a>
																</div>
																<div class="element_width_l blank_space_ml inline_block"><button class="buttons_common btn_wht link_btn" id={data.download_url} onClick={handleSharingUrlCopy} ><img src="img/EZTP_link.svg" alt="" /><FormattedMessage id="record-details_shareablelink_btn" /></button></div>
																<div class="blank_space_ml inline_block"><button class="buttons_common trash_btn" onClick={handleDelete} id={data.recording_id} ><img src="img/EZTP_del.svg" alt="" /></button></div>
															</div>
														</td>
													</tr>
												);
											}
										})
									}
								</tbody>
							</table>
						);
					})
				}
				{state.delete_flag && <div id="record_delete" >
					<div>
						<nav>
							<div class="in_contents contact-edit" id="delete_confirm">
								<div id="modal_overlay"></div>
								<div id="modal_container">
										<header class="modal_header">
											<h2><FormattedMessage id="record-details_selectdelete_btn" /></h2>
										</header>
										<div class="modal_contents blank_above_m">
											<div class="flex_box right_justified blank_above_m">
												<div class="element_width_ms">
													<FormattedMessage id="record-details_delete_btn">
														{value => <input class="buttons_common btn_blue" type="button" value={value} onClick={delete_recordings}></input>}
													</FormattedMessage>
												</div>
												<div class="element_width_ms blank_space_m">
													<FormattedMessage id="record-details_cancel_btn">
														{value => <input id="delete_cancel_btn" class="buttons_common btn_wht" type="button" value={value} onClick={handleDeleteCancel}></input>}
													</FormattedMessage>
												</div>
											</div>
										</div>
								</div>
							</div>
						</nav>
					</div>
				</div>
				}
				{state.share_flag && <div id="record_share" >
					<div>
						<nav>
							<div class="in_contents record-share" id="share_confirm">
								<div id="modal_overlay"></div>
								<div id="modal_container">
									<header class="modal_header">
										<h2><FormattedMessage id="record-share_sentence1_lbl" /></h2>
									</header>
									<div class="modal_contents blank_above_l">
										<div class="flex_box">
											<span><FormattedMessage id="record-share_sentence2_lbl" /></span>
											<div class="toggle-switch">
												<input tabindex="-1" id="toggle36" class="toggle-input" type='checkbox' checked={state.share_status} onChange={handleShareOnClick} />
												<a tabindex="0" id="record_share_chk" onClick={handleChecktClick} for="toggle36" class="toggle-label" />
											</div>
										</div>
										<div style={{ display: state.share_status ? 'none' : '' }} class="left_justified blank_above_ms">
											<span><FormattedMessage id="record-share_sentence9_lbl" /></span>
										</div>
										<div style={{ display: state.share_status ? '' : 'none' }}>
											<div class="flex_box blank_above_ms">
												<span><FormattedMessage id="record-share_sentence10_lbl" /></span>
												<div class="toggle-switch">
													<input tabindex="-1" id="toggle37" class="toggle-input link_sharing" type='checkbox' checked={state.share_password_flag} onChange={handleSharePasswordOnClick} />
													<a tabindex="0" id="passcode_chk" onClick={handleChecktClick} for="toggle37" class="toggle-label" />
												</div>
											</div>
											<div style={{ display: state.share_password_flag ? '' : 'none' }}>
												<div style={{ display: state.share_password_edit_flag ? 'none' : '' }}>
													<div class="flex_box left_justified blank_above_ms">
														<div class="flex_box">
															<div>
																<input class="masking" id="masking37" disabled="disabled" type="password" value={state.share_old_password}></input>
																<div class="inline_block"><button class="masking_btn invisible" onClick={handleInputPassword}></button></div>
															</div>
														</div>
													</div>
												</div>
												<div style={{ display: state.share_password_edit_flag ? '' : 'none' }}>
														<div class="flex_box left_justified blank_above_ms">
															<div class="flex_box">
																<div class="flex_box left_justified">
																	<div class="element_width_m">

																	<input onKeyDown={keydownEnter} id="key" type="text" maxlength='32' value={state.share_new_password} onChange={handleInputNewPassword}></input>

																	</div>
																</div>
																<div class="delete_invisible_space width_limit">
																	<div class="inline_block element_width_ms">
																		<FormattedMessage id="record-share_save_btn">
																		{value => <input id="pass_code_btn" class="buttons_common btn_blue" type="button" data-type="submit" onClick={handleInputPasswordChange} value={value}></input>}
																		</FormattedMessage>
																	</div>
																	<div class="inline_block element_width_ms blank_space_m">
																		<button class="buttons_common btn_wht" data-type="cancel" onClick={handleInputPasswordCancel}><FormattedMessage id="record-share_cancel_btn" /></button>
																	</div>
																</div>
															</div>
														</div>
												</div>
											</div>
											<div class="flex_box blank_above_ms">
												<span><FormattedMessage id="record-share_sentence8_lbl" /></span>
												<div class="inline_block" style={{ display: state.share_information_flag ? '' : 'none' }}>
													<FormattedMessage id="record-share_display_btn">
														{value => <button class="pulldown buttons_common display_toggle_btn" type="button" value={value} onClick={handleShareringInformationOnClick}><FormattedMessage id="record-share_display_non_btn" /></button>}
													</FormattedMessage>
												</div>
												<div class="inline_block" style={{ display: state.share_information_flag ? 'none' : '' }}>
													<FormattedMessage id="record-share_display_non_btn">
														{value => <button class="pulldown buttons_common display_toggle_btn" type="button" value={value} onClick={handleShareringInformationOnClick}><FormattedMessage id="record-share_display_btn" /></button>}
													</FormattedMessage>
												</div>
											</div>
											<div style={{ display: state.share_information_flag ? '' : 'none' }}>
												<div class="sharing_information blank_above_ms">
													<textarea rows="10" cols="70" value={state.share_information} disabled></textarea>
												</div>
											</div>
										</div>
										<div class="flex_box blank_above_ms">
											<div class="element_width_ls inline_block">
												<button id="copy" class="buttons_common btn_wht" onClick={handleSharingInfoCopy} style={{ display: state.share_status ? '' : 'none' }}><FormattedMessage id="record-copysharing_btn" /></button>
											</div>
											<div class="element_width_ms">
												<FormattedMessage id="record-complete_btn">
													{value => <input class="buttons_common btn_blue" type="button" value={value} onClick={handleUpdateSettings}></input>}
												</FormattedMessage>
											</div>
										</div>
									</div>
								</div>
							</div>
						</nav>
					</div>
				</div>
				}
			</div>
		</div>
	);

}
