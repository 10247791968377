import * as CONST from '../../const';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clearMessagebox, getVisible } from '../../store/slicer/0102_messagebox';
import Header from '../0003_components/0001_header';
import MessageBox from '../0003_components/0004_message_ok_only';
import { FormattedMessage, useIntl } from "react-intl";
import { getLanguage } from '../../store/slicer/0103_language';
import toast, { Toaster } from 'react-hot-toast';


export default function Forget(props) {
	const dispatch = useDispatch();
	const storeLanguage = useSelector(getLanguage);
	const lang = storeLanguage === 'ja' ? 'jp': storeLanguage;
	const intl = useIntl();

	// メッセージボックス
	const storeVisible = useSelector(getVisible);

	// 初期化
	const [state, setState] = useState(() => {
		toast.dismiss();
		// メッセージボックスの初期化
		dispatch(clearMessagebox());

		return {
			account: '',
			sended: false,
			form: [
				{ message: 'EMPTY' },
			],
		}
	});

	// 入力のイベント
	const handleInputChange = event => {
		setState({...state, account: event.target.value});
	};

	// 再度試すリンク
	const handleOnClick = event => {
		setState({...state, sended: false, account: ''});
	};

	// サブミット
	const handleSubmit = event => {
		event.preventDefault(false);
		let error = [...state.form];

		error[0]['message'] = 'EMPTY';

		if (state.account === '') {
			error[0]['message'] = 'ERROR_0004';
			document.getElementById('account').focus();
			setState({ ...state, form: error, });
			return;
		}

		// パスワード変更メール送信
		fetch(CONST.BASEURL + '/customers/' + state.account + '/mails/password?lang=' + lang, {
			method:'POST',
			headers: {
			},
		})
		.then(response => {
			switch (response.status) {
				case 200:
					setState({...state, sended: true, account: '', message: ''});
					break;
				case 401:
					error[0]['message'] = 'ERROR_0002';
					document.getElementById('account').focus();
					setState({ ...state, form: error, });
					break;

				default:
					toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

					error[0]['message'] = 'EMPTY';
					setState({ ...state, form: error, });

					break;
			}
		})
			.catch((err) => {
				toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

				error[0]['message'] = 'EMPTY';
				setState({ ...state, form: error, });
		});

	};




	// 描画
	switch (state.sended) {
	// 送信前
	case false:
		return (
			<div class="before_login">
				<title><FormattedMessage id="tab_title_mail_send" /></title>
				<div class="wrapper">
					{storeVisible && <div id="modal_overlay"></div>}
					<Header />
					<div class="container">
						<div class="contents">
							<Toaster position="top-center" reverseOrder="false" containerStyle={{ marginTop:'75px'}} />
							{storeVisible && <MessageBox /> }
							{!storeVisible && 
								<div class="before_login_box">
									<div class="operation_box">
									<h1><FormattedMessage id="send-email-1_sentence1_lbl" /></h1>
									<dl>
										<dd class="blank_above_ms">
										<p><FormattedMessage id="send-email-1_sentence2_lbl" values={{ br: <br /> }} /></p>
										</dd>
										<dd class="blank_above_l">
											<FormattedMessage id="send-email-1_account_txt">{placeholder => <input class={state.form[0].message !== 'EMPTY' ? 'has-error' : ''} type="text" name="account" id="account" value={state.account} placeholder={placeholder} onChange={handleInputChange} autoFocus />}</FormattedMessage>
											<div class="has-error" style={{ display: state.form[0].message !== 'EMPTY' ? '' : 'none' }}><FormattedMessage id={state.form[0].message} /></div></dd>
										<dd class="blank_above_l"><div class="element_width_ms inline_block"><FormattedMessage id="send-email-1_send_btn">{placeholder => <input class="before_login_buttons btn_blue inline_block " type="button" value={placeholder} onClick={handleSubmit} />}</FormattedMessage></div></dd>
									</dl>
								</div>
							</div>
							}
						</div>
					</div>

				</div>
			</div>
		);
		

	// 送信後
	case true:
		return (
			<div class="before_login">
				<title><FormattedMessage id="tab_title_send_complete" /></title>
				<div class="wrapper">
					<Header />

					<div class="container">
						<div class="contents">
							<div class="before_login_box">
								<div class="operation_box">
									<h1><FormattedMessage id="send-email-2_sentence1_lbl" /></h1>
									<dl>
										<dd class="blank_above_m">
											<p><FormattedMessage id="send-email-2_sentence2_lbl" values={{ br: <br /> }} /></p>
										</dd>
										<dd class="blank_above_m">
											<p><FormattedMessage id="send-email-2_sentence3_lbl" values={{ br: <br /> }} /><a href="javascript:void(0);" class="cursor_a_link" onClick={handleOnClick}><FormattedMessage id="send-email-2_retry_lnk" /></a></p>
										</dd>
									</dl>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		);
	

	default:
		break;
	}
	return (
		<tbody class="lump"></tbody>
	);


}
