import { useState, useEffect } from 'react';
import { getProfile, getUserSettingUpdate, setUserSettingUpdate } from '../../store/slicer/0503_room';
import { useSelector, useDispatch } from 'react-redux';
import * as CONST from '../../const';
import { getAccessToken, getRefreshToken, setAccessToken, logout } from '../../store/slicer/0101_session';
import toast from 'react-hot-toast';
import { FormattedMessage, useIntl } from "react-intl";
import { SetErrorMessage } from '../../store/slicer/0101_logout_message';

export default function UserMeetingBody(props) {
    const dispatch = useDispatch();
   

    //ミーティング
    const storeSetting = useSelector(getUserSettingUpdate);

    // プロフィール
    const storeProfile = useSelector(getProfile);

    const intl = useIntl();

    var access_token = useSelector(getAccessToken);
    var refresh_token = useSelector(getRefreshToken);
    // 初期化
    const [state, setState] = useState(() => {
        return {
            check_toggle18: storeSetting.in_meeting.screen_sharing,
            share_screen: storeSetting.in_meeting.who_can_share_screen,
            share_screen_tmp: storeSetting.in_meeting.who_can_share_screen,
        }
    });

    // onClickイベントハンドラ
    const handleOnClick = event => {

        //チェック状態保存
        var check_tmp = !state.check_toggle18;
        setState({ ...state, check_toggle18: check_tmp });

        //更新データ

        const remote_control = storeSetting.in_meeting.remote_control;
        const senddata = { ...storeSetting, in_meeting: { ...storeSetting.in_meeting, screen_sharing: check_tmp, remote_control: remote_control } };
        const zoom_senddata = { in_meeting: { screen_sharing: check_tmp } };

        const fetchHttpRefresh = async () => {
            let senddata = {
            };
            let url_tokref = CONST.BASEURL + '/authorize/refresh';
            var httpResponse_tokref = await fetch(url_tokref, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + refresh_token
                },
                body: JSON.stringify(senddata),
            });
            switch (httpResponse_tokref.status) {
                case 200:
                    var httpResponse_tokref_json = await httpResponse_tokref.json();
                    access_token = httpResponse_tokref_json.access_token;
                    refresh_token = httpResponse_tokref_json.refresh_token;
                    // トークンをデコードする
                    //const decode_token = jwt(access_token);
                    // 問題ない場合は、storeに保存
                    dispatch(setAccessToken(access_token));
                    //dispatch(setRefreshToken(refresh_token));
                    fetchHttp();
                    return;                        
                default:
                    dispatch(SetErrorMessage('ERROR_0001'));
                    dispatch(logout());
                    return;
            }
        }
        //fetchHttpRefresh();
        //更新処理
        const fetchHttp = async () => {
            fetch(CONST.BASEURL + '/users/' + storeProfile.user_id + '/settings', {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: "Bearer " + access_token
                },
                body: JSON.stringify(zoom_senddata),
            })
                .then(response => {
                    switch (response.status) {
                        case 204:
                            //成功通知
                            toast(intl.formatMessage({ id: 'SUCCESS_0001' }),{style:{background:  CONST.HOT_TOAST_SUCCESS}});

                            dispatch(setUserSettingUpdate(senddata));
                            break;
                        case 401:
                            fetchHttpRefresh();
                            return;
                        case 409:
                            dispatch(SetErrorMessage('ERROR_0039'));  
                            dispatch(logout());
                            return;

                        default:
                            response.json().then(data => ({
                                data: data,
                                status: response.status
                            })
                            ).then(res => {
                                //Error出力
                                toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});


                                //チェック状態を戻す
                                setState({ ...state, check_toggle18: !check_tmp });

                            }).catch((err) => {
                                //Error出力
                                toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                            });
                    }
                })
                .catch((err) => {
                    //Error出力
                    toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});


                    //チェック状態を戻す
                    setState({ ...state, check_toggle18: !check_tmp });
                });
        }
        fetchHttp();
    }

    //チェックボタン(開始者)の初期状態
    function checkbox_init() {

        if (state.share_screen_tmp==="host") {
            return true;
        } else {
            return false;
        }
    }

    //チェック状態(共有者)
    function check_share_screen(word) {

        if (state.share_screen_tmp===word) {
            return true;
        } else {
            return false;
        }
    }

    //チェックボタン(共有者)押下
    const handle_checkbox_share_screen = event => {

        var tmp = event.target.id;

        var obj_host = document.getElementById("host_only");
        var obj_all = document.getElementById("all_participants");

        //保存
        setState({ ...state, share_screen_tmp: tmp });
    }

    //保存 表示切替
    function checkbox_function() {
        if (state.share_screen !==state.share_screen_tmp) {

            return (
                <div class="save_and_cancel delete_invisible_space">
                    <br />
                    <div class="inline_block element_width_ms">
                        <FormattedMessage id="meeting_detail_save_btn">
                            {value => <input class="buttons_common btn_blue" type="submit" value={value} data-type="submit" onClick={handle_Click} />}
                        </FormattedMessage>
                    </div>
                    <div class="inline_block element_width_ms blank_space_m">
                        <button class="buttons_common btn_wht" data-type="cancel" onClick={handle_Click}>
                            <FormattedMessage id="meeting_detail_cancel_btn" />
                        </button>
                    </div>
                </div>
            );

        } else {
            return;
        }
    }

    //保存・キャンセル押下
    const handle_Click = event => {
        event.preventDefault(false);
        const type = event.target.getAttribute('data-type');
        

        switch (type) {
            case "cancel":

                setState({ ...state, share_screen_tmp: state.share_screen});
                break;

            case "submit":

                //更新データ
                const senddata = {
                    ...storeSetting, in_meeting: {
                        ...storeSetting.in_meeting, who_can_share_screen: state.share_screen_tmp
                    }
                };
                const zoom_senddata = { in_meeting: { who_can_share_screen: state.share_screen_tmp } };

                const fetchHttpRefresh = async () => {
                    let senddata = {
                    };
                    let url_tokref = CONST.BASEURL + '/authorize/refresh';
                    var httpResponse_tokref = await fetch(url_tokref, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + refresh_token
                        },
                        body: JSON.stringify(senddata),
                    });
                    switch (httpResponse_tokref.status) {
                        case 200:
                            var httpResponse_tokref_json = await httpResponse_tokref.json();
                            access_token = httpResponse_tokref_json.access_token;
                            refresh_token = httpResponse_tokref_json.refresh_token;
                            // トークンをデコードする
                            //const decode_token = jwt(access_token);
                            // 問題ない場合は、storeに保存
                            dispatch(setAccessToken(access_token));
                            //dispatch(setRefreshToken(refresh_token));
                            fetchHttp();
                            return;                           
                        default:
                            dispatch(SetErrorMessage('ERROR_0001'));
                            dispatch(logout());
                            return;
                    }
                }
                //fetchHttpRefresh();
                //更新処理
                const fetchHttp = async () => {
                    fetch(CONST.BASEURL + '/users/' + storeProfile.user_id + '/settings', {
                        method: 'PATCH',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: "Bearer " + access_token
                        },
                        body: JSON.stringify(zoom_senddata),
                    })
                        .then(response => {
                            switch (response.status) {
                                case 204:
                                    //成功通知
                                    toast(intl.formatMessage({ id: 'SUCCESS_0001' }),{style:{background:  CONST.HOT_TOAST_SUCCESS}});

                                    //保存
                                    dispatch(setUserSettingUpdate(senddata));
                                    setState({ ...state, share_screen: state.share_screen_tmp});

                                    break;
                                case 401:
                                    fetchHttpRefresh();
                                    return;
                                case 409:
                                    dispatch(SetErrorMessage('ERROR_0039'));  
                                    dispatch(logout());
                                    return;

                                default:
                                    response.json().then(data => ({
                                        data: data,
                                        status: response.status
                                    })
                                    ).then(res => {
                                        //Error出力
                                        toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                                    }).catch((err) => {
                                        //Error出力
                                        toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                                    });
                            }
                        })
                        .catch((err) => {
                            //Error出力
                            toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                        });
                }
                fetchHttp();

                break;
        }
    }

    //追加設定表示
    function display_who_can_share() {

        //ONの場合のみ
        if (state.check_toggle18) {
            return (
                <div>
                    <p><FormattedMessage id="meeting_basic_sentence21_lbl" /></p>
                    <p>
                        <label class="radio_label">
                            <input class="meeting_radio" type="radio" name="share" value="host_only" id="host" checked={check_share_screen("host")} onChange={handle_checkbox_share_screen} />
                            <FormattedMessage id="meeting_basic_sentence22_rbt" />
                        </label>
                        <label class="radio_label blank_space_ml">
                            <input class="meeting_radio" type="radio" name="share" value="all_participants" id="all" checked={check_share_screen("all")} onChange={handle_checkbox_share_screen} />
                            <FormattedMessage id="meeting_basic_sentence23_rbt" />
                        </label>
                    </p>

                    {checkbox_function()}
                </div>
            );

        } else {
            return;
        }
    }

    // チェック押下
    const handleChecktClick = event => {
        document.getElementById("toggle18").click();
    }

    // レンダリング
    return (
        <tbody class="lump">
            <tr>
                <td>
                    <div class="flex_box">
                        <p class="save_itme_name"><FormattedMessage id="meeting_basic_sentence19_lbl" /></p>
                        <div class="toggle-switch">
                            <input tabindex="-1" id="toggle18" class="toggle-input" type='checkbox' checked={state.check_toggle18} onChange={handleOnClick} />
                            <a tabindex="0" onClick={handleChecktClick} href="javascript:void(0);" class="toggle-label" />
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <p><FormattedMessage id="meeting_basic_sentence20_lbl" values={{ br: <br /> }} /></p>
                    {display_who_can_share()}
                </td>
            </tr>
        </tbody>
    );
}
