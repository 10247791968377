import * as CONST from '../../const'
import { useState, useCallback, useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUserData, getAccessToken, getRefreshToken, setAccessToken, logout } from '../../store/slicer/0101_session';
import { setPageNumber, setPageMax, getPage } from '../../store/slicer/0502_page';
import { setSearch, getRecord  } from '../../store/slicer/1001_record';
import { FormattedMessage, useIntl } from "react-intl";
import toast from 'react-hot-toast';
import { getLanguage } from '../../store/slicer/0103_language';
import DatePicker, { registerLocale } from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns/getYear';
import de from "date-fns/locale/de";
import fr from "date-fns/locale/fr";
import ja from "date-fns/locale/ja";
import { SetErrorMessage } from '../../store/slicer/0101_logout_message';

export default function RecordList(props) {
	const dispatch = useDispatch();
	

	// セッション
	const storeUser = useSelector(getUserData);
	

	// Record・Page・Language
	const storeRecord = useSelector(getRecord);
	const storePage = useSelector(getPage);
	const storeLanguage = useSelector(getLanguage);

	var access_token = useSelector(getAccessToken);
	var refresh_token = useSelector(getRefreshToken);

    const intl = useIntl();

	const [fromDate, setFromDate] = useState(new Date());
	const [toDate, setToDate] = useState(new Date());

	// 初期化
	const [state, setState] = useState(() => {
		// 初期化
		dispatch(setSearch({ locationList:'', roomList:'', fromDate:'', toDate:'', topic:'' }));

		// カレンダー入力部品の多言語対応初期化
		switch (storeLanguage) {
			case 'ja':
				registerLocale("ja", ja);
				break;
			case 'de':
				registerLocale("de", de);
				break;
			case 'fr':
				registerLocale("fr", fr);
				break;
			default:
				break;
		}

		let locationOptions = [
			{value: '', name:'', text: intl.formatMessage({ id: 'record-initial_location_pll' }) },
		];

		const fetchHttpRefresh = async () => {
			let senddata = {};
			let url_tokref = CONST.BASEURL + '/authorize/refresh';
			var httpResponse_tokref = await fetch( url_tokref, {
				method:'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': "Bearer " + refresh_token
				},
				body: JSON.stringify(senddata),
			});
			switch (httpResponse_tokref.status) {
				case 200:
					var httpResponse_tokref_json = await httpResponse_tokref.json();
					access_token = httpResponse_tokref_json.access_token;
					refresh_token = httpResponse_tokref_json.refresh_token;
					// トークンをデコードする
					//const decode_token = jwt(access_token);
					// 問題ない場合は、storeに保存
					dispatch(setAccessToken(access_token));
					//dispatch(setRefreshToken(refresh_token));
					fetchHttp();
					return;				       
				default:
					dispatch(SetErrorMessage('ERROR_0001'));
					dispatch(logout());
					return;
			}
		}
		//fetchHttpRefresh();
		// 拠点一覧取得
		let url = CONST.BASEURL + '/customers/' + storeUser.id + '/locations';
		const fetchHttp = async () => {
			fetch(url , {
				method:'GET',
				headers: {
					Authorization: "Bearer " + access_token
				}
			})
			.then(response => 
				response.json().then(data => ({
					data: data,
					status: response.status
				})
				).then(res => {
					switch (res.status) {
					case 200:
						// データ取得
						for (let i = 0; i < res.data.locations.length; i++) {
							locationOptions[i+1] = { 
								value: res.data.locations[i].location_id.trim(),
								name: "",
								text: res.data.locations[i].location_name.trim(),
							};
						}
						// 初期化
						setState({...state, locationList: locationOptions });
						break;
					case 401:
						fetchHttpRefresh();
						return;
					case 409:
						dispatch(SetErrorMessage('ERROR_0039'));  
						dispatch(logout());
						return; 
					default:
						//Error出力
						toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

						break;
					}
				}).catch((err) => {
					//Error出力
					toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

				})
			)
			.catch((err) => {
				//Error出力
				toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

			});
		}
		fetchHttp();

		let roomOptions = [];

		// ユーザー一覧取得
		let url2 = CONST.BASEURL + '/customers/' + storeUser.id + '/users?page_size=99999';
		if (storeRecord.locationList !== undefined && storeRecord.locationList !== '') {
			url2 +=  '?location_id=' + storeRecord.locationList;
		}
		const fetchHttpRefresh1 = async () => {
			let senddata = {};
			let url_tokref = CONST.BASEURL + '/authorize/refresh';
			var httpResponse_tokref = await fetch( url_tokref, {
				method:'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': "Bearer " + refresh_token
				},
				body: JSON.stringify(senddata),
			});
			switch (httpResponse_tokref.status) {
				case 200:
					var httpResponse_tokref_json = await httpResponse_tokref.json();
					access_token = httpResponse_tokref_json.access_token;
					refresh_token = httpResponse_tokref_json.refresh_token;
					// トークンをデコードする
					//const decode_token = jwt(access_token);
					// 問題ない場合は、storeに保存
					dispatch(setAccessToken(access_token));
					//dispatch(setRefreshToken(refresh_token));
					fetchHttp1();
					return;				       
				default:
					dispatch(SetErrorMessage('ERROR_0001'));
					dispatch(logout());
					return;
			}
		}

		//fetchHttpRefresh1();
		const fetchHttp1 = async () => {
		fetch( url2 , {
			method:'GET',
			headers: {
				Authorization: "Bearer " + access_token
			}
		})
		.then(response => 
			response.json().then(data => ({
				data: data,
				status: response.status
			})
			).then(res => {
				switch (res.status) {
				case 200:
					// データ取得
					for (let i = 0; i < res.data.users.length; i++) {
						roomOptions[i+1] = { 
							value: res.data.users[i].user_id.trim(),
							name: "",
							text: res.data.users[i].user_name.trim(),
						};
					}
					// 初期化
					setState({...state, locationList: state.locationList, roomList: roomOptions , user_setting_flag: false});
					set_auto_delete_cmr_days( res.data.users[0].user_id ) 
					dispatch(setSearch({ locationList:storeRecord.locationList, roomList: res.data.users[0].user_id,
						fromDate: state.fromDate, toDate: state.toDate, topic: state.topic, record_flag:false }));
					dispatch(setPageNumber(1));
					break;
				case 401:
					fetchHttpRefresh1();
					return;
				case 409:
					dispatch(SetErrorMessage('ERROR_0039'));  
					dispatch(logout());
					return; 
				default:
					//Error出力
					toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

					break;
				}
			}).catch((err) => {
				//Error出力
				toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

			})
		)
		.catch((err) => {
			//Error出力
			toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

		});
		}
		fetchHttp1();

		// 終了日の初期設定
		let now = new Date();
		let toDate = now.getFullYear() + '/' + (now.getMonth()+1) + '/' + now.getDate();

		return {
			locationList: locationOptions,
			roomList: roomOptions,
			fromDate: '',
			toDate: toDate,
			topic: '',
			data: [{}],
			draw_data: [{}],
			auto_delete_cmr_days: 0,
			page_size: 50,
			page_number: 1,
			page_max: 1,
			allcheckflag: false,
			user_setting_flag: false,
			delete_flag: false,
			crud_flag: false,
			disabled: true,
			search_form: '',
			search_flg: false,
		}
	});

	// 表示リスト作成
	useEffect(() => {
		// 表示リストを一時クリア
		setState({ ...state, draw_data: [] });

		// 会議室絞り込み条件が未設定の場合は検索しない
		if (storeRecord.roomList === undefined || storeRecord.roomList === '') {
			return;
		}
		
		// ユーザー設定が取得できていない場合は検索しない
		if (!storeRecord.record_flag) {
			set_auto_delete_cmr_days(storeRecord.roomList)
			return;
		}
		

		let now_page;
		now_page = storePage.page_number;
		if (now_page < 1) {
			now_page = 1;
		}

		let auto_delete_cmr_days = 0;

		// レコーディング一覧取得
		let url = CONST.BASEURL + '/users/' + storeRecord.roomList + '/recordings/?page_size=' + state.page_size + '&page_number=' + storePage.page_number
		
		// 開始日絞り込み条件
		if (storeRecord.fromDate !== undefined && storeRecord.fromDate !== '') {
			url += '&from=' + storeRecord.fromDate.replace( /\//g, '-' );
		}
		// 終了日絞り込み条件
		if (storeRecord.toDate !== undefined && storeRecord.toDate !== '') {
			url += '&to=' + storeRecord.toDate.replace( /\//g, '-' );
		}
		// トピック絞り込み条件
		if (storeRecord.topic !== undefined && storeRecord.topic !== '') {
			url += '&topic=' + storeRecord.topic;
		}

		const fetchHttpRefresh = async () => {
			let senddata = {};
			let url_tokref = CONST.BASEURL + '/authorize/refresh';
			var httpResponse_tokref = await fetch( url_tokref, {
				method:'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': "Bearer " + refresh_token
				},
				body: JSON.stringify(senddata),
			});
			switch (httpResponse_tokref.status) {
				case 200:
					var httpResponse_tokref_json = await httpResponse_tokref.json();
					access_token = httpResponse_tokref_json.access_token;
					refresh_token = httpResponse_tokref_json.refresh_token;
					// トークンをデコードする
					//const decode_token = jwt(access_token);
					// 問題ない場合は、storeに保存
					dispatch(setAccessToken(access_token));
					//dispatch(setRefreshToken(refresh_token));
					fetchHttp();
					return;				        
				default:
					dispatch(SetErrorMessage('ERROR_0001'));
					dispatch(logout());
					return;
			}
		}
		//fetchHttpRefresh();
		const fetchHttp = async () => {
			fetch( url, {
				method:'GET',
				headers: {
					'Authorization': "Bearer " + access_token 
				}
			})
			.then(response => 
				response.json().then(data => ({
					data: data,
					status: response.status
				})
				).then(res => {
					switch (res.status) {
					case 200:
						let error = state.search_form;
						error = ""

						let drawdata = []
						if (state.auto_delete_cmr_days !== 0 ) {
							auto_delete_cmr_days = state.auto_delete_cmr_days;
						}

						//検索結果の表示用リストデータを設定する
						drawdata = res.data.meetings;
						for (let i = 0; i < drawdata.length; i++) {
							drawdata[i].check = false;
							let dispStartDate = new Date(drawdata[i].start_time).toLocaleString({ timeZone: 'Asia/Tokyo' });
							let dispStartDate2 = new Date(dispStartDate);
							dispStartDate = dispStartDate2.getFullYear() + '/' + ('00' + (dispStartDate2.getMonth()+1)).slice(-2) + '/' + ('00' + dispStartDate2.getDate()).slice(-2)
								+ ' ' + ('00' + dispStartDate2.getHours()).slice(-2) + ':' + ('00' + dispStartDate2.getMinutes()).slice(-2);

							drawdata[i].dispStartDate = dispStartDate;
							if (auto_delete_cmr_days !== 0) {
								let deleteLimit = new Date(dispStartDate);
								deleteLimit.setDate(deleteLimit.getDate() + auto_delete_cmr_days);
								drawdata[i].auto_delete_cmr_days = auto_delete_cmr_days;
								drawdata[i].deleteLimit = intl.formatMessage({ id: 'record-initial_delete_daysafter_lbl' }).replace('{after_days}', Math.ceil((deleteLimit - new Date()) / 86400000));
							}
						}

						// ページ数を算出する
						const page_max = Math.ceil(res.data.total_records / state.page_size);

						// 最大ページ数を変更
						dispatch(setPageMax(page_max));
						
						if (drawdata.length === 0) {
							error = intl.formatMessage({ id: 'ERROR_0006' });
							document.getElementById('sbox2').focus();
						}

						setState({ ...state, draw_data: drawdata, auto_delete_cmr_days: auto_delete_cmr_days, crud_flag: false, disabled: true , search_form: error});
						break;
					case 401:
						fetchHttpRefresh();
						return;
					case 409:
						dispatch(SetErrorMessage('ERROR_0039'));  
						dispatch(logout());
						return; 
					default:
						//Error出力
						toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

						break;
					}
				}).catch((err) => {
					//Error出力
					toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

				})
			)
			.catch((err) => {
				//Error出力
				toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

			});
		}
		fetchHttp();

	}, [storeRecord.roomList, storeRecord.fromDate, storeRecord.toDate, storeRecord.topic, storePage.page_number, storeRecord.record_flag, state.crud_flag, state.search_flg]);

	// 自動的削除日数の取得処理
	function set_auto_delete_cmr_days( user_id ) 
	{
		// 会議室絞り込み条件が未設定の場合は検索しない
		if (user_id === undefined || user_id === '') {
			return;
		}

		//fetchHttpRefresh();
		////////////////////////////////
		// ユーザー設定取得
		////////////////////////////////
		let auto_delete_cmr_days = 0;
		let url1 = CONST.BASEURL + '/users/' + user_id + '/settings';
			
		const fetchHttpRefresh1 = async () => {
			let senddata = {};
			let url_tokref = CONST.BASEURL + '/authorize/refresh';
			var httpResponse_tokref = await fetch( url_tokref, {
				method:'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': "Bearer " + refresh_token
				},
				body: JSON.stringify(senddata),
			});
			switch (httpResponse_tokref.status) {
				case 200:
					var httpResponse_tokref_json = await httpResponse_tokref.json();
					access_token = httpResponse_tokref_json.access_token;
					refresh_token = httpResponse_tokref_json.refresh_token;
					// トークンをデコードする
					//const decode_token = jwt(access_token);
					// 問題ない場合は、storeに保存
					dispatch(setAccessToken(access_token));
					//dispatch(setRefreshToken(refresh_token));
					fetchHttp1();
					return;				        
				default:
					dispatch(SetErrorMessage('ERROR_0001'));
					dispatch(logout());
					return;
			}
		}
		//fetchHttpRefresh1();
		const fetchHttp1 = async () => {
		fetch( url1 , {
			method:'GET',
			headers: {
				Authorization: "Bearer " + access_token
			}
		})
		.then(response => 
			response.json().then(data => ({
				data: data,
				status: response.status
			})
			).then(res => {
				switch (res.status) {
				case 200:
					// ZOOMユーザーが存在しない,設定項目が取得できない場合
					if (res.data.code === 1001 || res.data.recording.auto_delete_cmr === undefined) {
						let error = intl.formatMessage({ id: 'ERROR_0006' });
						document.getElementById('sbox2').focus();
						setState({ ...state,  draw_data: [], user_setting_flag: false, auto_delete_cmr_days: 0 , search_form: error});
						break;
					}
					// ユーザー設定の項目をセット
					if (res.data.recording.auto_delete_cmr) {
						auto_delete_cmr_days = res.data.recording.auto_delete_cmr_days;
						}

						setState({ ...state, user_setting_flag: true, auto_delete_cmr_days: auto_delete_cmr_days });
						dispatch(setSearch({
							locationList: storeRecord.locationList, roomList: storeRecord.roomList,
							fromDate: storeRecord.fromDate, toDate: storeRecord.toDate, topic: storeRecord.topic, record_flag: true
						}));
					break;
				case 401:
					fetchHttpRefresh1();
					return;
				case 409:
					dispatch(SetErrorMessage('ERROR_0039'));  
					dispatch(logout());
					return;
				default:
					//Error出力
					toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

					break;
				}
			}).catch((err) => {
				//Error出力
				toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

			})
		)
		.catch((err) => {
			//Error出力
			toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

		});
		}
		fetchHttp1();
	}
	
	// 拠点名セレクトボックス変更ハンドラ
	const handleLocationSelectChange = useCallback(event => {
		event.preventDefault(true);
		
		let location_id = event.target.value;
		let roomOptions = [];
		
		// ユーザー一覧取得
		let url = CONST.BASEURL + '/customers/' + storeUser.id + '/users?page_size=99999';
		if (location_id !==undefined && location_id !=='') {
			url +=  '&location_id=' + location_id;
		}
		const fetchHttpRefresh = async () => {
			let senddata = {};
			let url_tokref = CONST.BASEURL + '/authorize/refresh';
			var httpResponse_tokref = await fetch( url_tokref, {
				method:'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': "Bearer " + refresh_token
				},
				body: JSON.stringify(senddata),
			});
			switch (httpResponse_tokref.status) {
				case 200:
					var httpResponse_tokref_json = await httpResponse_tokref.json();
					access_token = httpResponse_tokref_json.access_token;
					refresh_token = httpResponse_tokref_json.refresh_token;
					// トークンをデコードする
					//const decode_token = jwt(access_token);
					// 問題ない場合は、storeに保存
					dispatch(setAccessToken(access_token));
					//dispatch(setRefreshToken(refresh_token));
					fetchHttp();
					return;				        
				default:
					dispatch(SetErrorMessage('ERROR_0001'));
					dispatch(logout());
					return;
			}
		}
		//fetchHttpRefresh();
		const fetchHttp = async () => {
		fetch( url , {
			method:'GET',
			headers: {
				Authorization: "Bearer " + access_token
			}
		})
		.then(response => 
			response.json().then(data => ({
				data: data,
				status: response.status
			})
			).then(res => {
				switch (res.status) {
				case 200:
					if (res.data.users.length === 0) {
						toast(intl.formatMessage({ id: 'ERROR_0040' }),{style:{background:  CONST.HOT_TOAST_ERROR}}); // 実データではありえないケース
						return;
					}
					
					// データ取得
					for (let i = 0; i < res.data.users.length; i++) {
						roomOptions[i+1] = { 
							value: res.data.users[i].user_id.trim(),
							name: "",
							text: res.data.users[i].user_name.trim(),
						};
					}
					// 初期化
					setState({ ...state, locationList: state.locationList, roomList: roomOptions, });
					if (res.data.users[0].user_id !== storeRecord.roomList) {
						dispatch(setSearch({
							locationList: location_id, roomList: res.data.users[0].user_id,
							fromDate: state.fromDate, toDate: state.toDate, topic: state.topic, record_flag: false
						}));
						dispatch(setPageNumber(1));
					} else {
						dispatch(setSearch({
							locationList: location_id, roomList: res.data.users[0].user_id,
							fromDate: state.fromDate, toDate: state.toDate, topic: state.topic, record_flag: storeRecord.record_flag
						}));
						dispatch(setPageNumber(1));
                     }
					break;
				case 401:
					fetchHttpRefresh();
					return;
				case 409:
					dispatch(SetErrorMessage('ERROR_0039'));  
					dispatch(logout());
					return;
				default:
					//Error出力
					toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

					break;
				}
			}).catch((err) => {
				//Error出力
				toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

			})
		)
		.catch((err) => {
			//Error出力
			toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

		});
		}
		fetchHttp();
		
	});

	// 会議室名セレクトボックス変更ハンドラ
	const handleRoomSelectChange = useCallback(event => {
		event.preventDefault(true);
		setState({...state, user_setting_flag: false });
		dispatch(setSearch({ locationList: storeRecord.locationList, roomList: event.target.value, 
			fromDate: state.fromDate, toDate: state.toDate, topic: state.topic, record_flag: false }));
		dispatch(setPageNumber(1));
	}, [storeRecord.roomList]);

	// テキストボックス入力ハンドラ
	const handleInputTopic = event => {
		event.preventDefault(true);
		setState({...state, topic: event.target.value});
	};


	// テキストボックス（開始日）クリックハンドラ
	const handleFromDatePicker = event => {
        var obj = document.getElementById("fromDatePicker");
        obj.click();
	};

	// テキストボックス（終了日）クリックハンドラ
	const handleToDatePicker = event => {
        var obj = document.getElementById("toDatePicker");
        obj.click();
	};

	const handleChangeFromDate  = (date) => {
		let input_fromDate = date.getFullYear() + "/" + ( date.getMonth() + 1) + "/" + date.getDate();
		setState({...state, fromDate: input_fromDate});
	};

	const handleChangeToDate  = (date) => {
		let input_toDate = date.getFullYear() + "/" + ( date.getMonth() + 1) + "/" + date.getDate();
		setState({...state, toDate: input_toDate});
	};

	// サブミットボタンハンドラ
	const handleSubmit= event => {
		event.preventDefault(false);
		
		dispatch(setSearch({ locationList: storeRecord.locationList, roomList: storeRecord.roomList, 
			fromDate: state.fromDate, toDate: state.toDate, topic: state.topic, crud_flag: true, record_flag: storeRecord.record_flag
		}));
		dispatch(setPageNumber(1));

		if (state.search_flg) {
			setState({ ...state, search_flg: false });
		} else {
			setState({ ...state, search_flg: true });
		}
	};

	// 全選択Checkbox押下処理
	const handleCheckAllCheckbox = event => {
		let drawdate_checkall = [{}];
		let checkdrawdata = [{}];
		let allcheck = false;

		checkdrawdata = state.draw_data;

		drawdate_checkall = state.draw_data;
		if (state.allcheckflag) {
			for (let i = 0; i < drawdate_checkall.length; i++) {
				drawdate_checkall[i].check = false;
				allcheck = false;
			} 
		} else {
			for (let i = 0; i < drawdate_checkall.length; i++) {
				drawdate_checkall[i].check = true;
				allcheck = true;
			} 
		}

		//チェックボックスにチェックが入っていないと削除ボタンを押せなくする
		let delete_check = true;
		for (let i = 0; i < checkdrawdata.length; i++) {
			if (checkdrawdata[i].check) {
				delete_check = false;
				break;
			}
		}

		setState({ ...state, draw_data: drawdate_checkall, allcheckflag: allcheck, disabled: delete_check });
	}

	// 選択Checkbox（個別）押下処理
	const handleChangeCheckbox = event => {
		const index = event.target.getAttribute('data-index');

		let checkdrawdata = [{}];
		let flag = false;				//リストのチェックボックスの値
		let AllorNotAll = false;		//全チェックのチェックボックスの値

		flag = state.draw_data[index].check ? false : true;

		checkdrawdata = state.draw_data;
		checkdrawdata[index].check = flag;

		//すべてにチェックが入っていなかったらallからチェックを外す。すべてにチェックが入っていたらallにチェックを入れる
		for (let i = 0; i < checkdrawdata.length; i++) {
			if (!checkdrawdata[i].check) {
				AllorNotAll = true;
				break;
			}
		}
		if (AllorNotAll) {
			AllorNotAll = false;
		} else {
			AllorNotAll = true;
		}
		
		//チェックボックスにチェックが入っていないと削除ボタンを押せなくする
		let delete_check = true;
		for (let i = 0; i < checkdrawdata.length; i++) {
			if (checkdrawdata[i].check) {
				delete_check = false;
				break;
			}
		}

		setState({ ...state, draw_data: checkdrawdata, allcheckflag: AllorNotAll, disabled: delete_check });
	}

	//グループ削除ポップアップ表示
	const handleDelete = event => {
		setState({ ...state, delete_flag: true });
	};

	//削除 キャンセルボタン押下
	const handleDeleteCancel = event => {
		setState({ ...state, delete_flag: false });
	};

	// 削除処理
	const delete_meeting_recordings = () => {
	
		setState({ ...state, delete_flag: false });

		
		let delete_meeting_id_data = [{}];
		let drawdate_refresh = [{}];
		let x = 0;
		for (let i = 0; i < state.draw_data.length; i++) {
			if (state.draw_data[i].check) {
				delete_meeting_id_data[x] = { "meeting_id": state.draw_data[i].meeting_uuid };
				x++;
			}
		}

		const fetchHttpRefresh = async () => {
			let senddata = {};
			let url_tokref = CONST.BASEURL + '/authorize/refresh';
			var httpResponse_tokref = await fetch( url_tokref, {
				method:'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': "Bearer " + refresh_token
				},
				body: JSON.stringify(senddata),
			});
			switch (httpResponse_tokref.status) {
				case 200:
					var httpResponse_tokref_json = await httpResponse_tokref.json();
					access_token = httpResponse_tokref_json.access_token;
					refresh_token = httpResponse_tokref_json.refresh_token;
					// トークンをデコードする
					//const decode_token = jwt(access_token);
					// 問題ない場合は、storeに保存
					dispatch(setAccessToken(access_token));
					//dispatch(setRefreshToken(refresh_token));
					fetchHttp();
					return;				       
				default:
					dispatch(SetErrorMessage('ERROR_0001'));
					dispatch(logout());
					return;
			}
		}

		//fetchHttpRefresh();
		const fetchHttp = async () => {
			fetch(CONST.BASEURL + '/meetings/recordings', {
				method: 'DELETE',
				headers: {
					'Content-type': 'application/json',
					Authorization: "Bearer " + access_token
				},
				body: JSON.stringify({
					"meetings": delete_meeting_id_data
				})
			})
			.then(res => {
				//すべてのチェックを外す
				drawdate_refresh = state.draw_data;
				for (let i = 0; i < drawdate_refresh.length; i++) {
					drawdate_refresh[i].check = false;
				}

				if (res.status === 204) {
					//成功メッセージ
					toast(intl.formatMessage({ id: 'SUCCESS_0003' }),{style:{background:  CONST.HOT_TOAST_SUCCESS}});
					// 問題ない場合は、リロード
	                   setState({ ...state, draw_data: drawdate_refresh, delete_flag: false, crud_flag: true, allcheckflag: false });
					dispatch(setPageNumber(1));
			   }
				else if (res.status === 401) {
					fetchHttpRefresh();
					return;
				}
				else if (res.status === 409) {
					dispatch(SetErrorMessage('ERROR_0039'));  
					dispatch(logout());
					return;
				}
				else {
					res.json().then(data => ({
						data: data,
						})
					).then(res => {
						//Error出力
						let errMessage = intl.formatMessage({ id: 'ERROR_0036' }).replace('{success_cnt}', res.data.success_cnt).replace('{error_cnt}', res.data.error_cnt);
						toast(errMessage,{style:{background:  CONST.HOT_TOAST_ERROR}});

						// 途中まで成功している場合があるので、画面はリロード
						setState({ ...state, draw_data: drawdate_refresh, delete_flag: false, crud_flag: true, allcheckflag: false });
					}).catch((err) => {
						//Error出力
						toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

					})
				}
			})
		.catch((err) => {
			//Error出力
			toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

		});
		}
		fetchHttp();
	
	}

	// ダイアログ初期カーソル
	useLayoutEffect(() => {
		if (state.delete_flag == true) {
			document.getElementById('delete_cancel_btn').focus();
		}
	}, [state.delete_flag])

	// 描画
	return (
		<div class="scroll_range">
			<title><FormattedMessage id="tab_title_record_list" /></title>
			<header class="in_contents_header">
				<div class="flex_box left_justified">
					<div class="selecter_down_arrow select_option element_width_xl">
						<label>
							<img class="svg_img-Pulldown" src="img/EZTP_Pulldown.svg" alt="▽" />
							<select value={storeRecord.locationList} id="location_select" onChange={handleLocationSelectChange}>
								{	state.locationList.map((data, index) => {
										return (
											<option value={data.value} >{data.text}</option>
										);
									})
								}
							</select>
						</label>
					</div>
					<div class="selecter_down_arrow select_option element_width_xl blank_space_xl">
						<label>
							<img class="svg_img-Pulldown" src="img/EZTP_Pulldown.svg" alt="▽" />
							<select value={storeRecord.roomList} id="room_select" onChange={handleRoomSelectChange}>
								{	state.roomList.map((data, index) => {
										return (
											<option value={data.value} >{data.text}</option>
										);
									})
								}
							</select>
						</label>
					</div>
				</div>
				<div class="flex_box left_justified blank_above_m">
					<div class="flex_box element_width_xl">
						<div class="element_width_m with_calendar">
							<FormattedMessage id="record-initial_startdate_cal">
								{placeholder => <input name="s" class="calendar_text" type="text" placeholder={placeholder} maxlength='10' value={state.fromDate} onClick={handleFromDatePicker} autocomplete="off"/>}
							</FormattedMessage>
							<DatePicker
								id="fromDatePicker"
								locale={storeLanguage}
								maxDate={new Date()}
								selected={fromDate}
								onSelect={handleChangeFromDate}
								onChange={date => setFromDate(date)}
								customInput={
		 							<img id="fromDate" class="svg_img-calendar" src={`${process.env.PUBLIC_URL}/img/EZTP_calendar.svg`} alt=""/>
								}
								renderCustomHeader={({
									date,
									decreaseMonth,
									increaseMonth,
									prevMonthButtonDisabled,
									nextMonthButtonDisabled
								}) => {
									return (
										<div style={{ margin: 6, display: "flex", justifyContent: "center", textAlign  : 'center', fontWeight : 'bold' }}>
											{/* 前月ボタン */}
											<button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</button>
											<div class="element_width_ml"><FormattedMessage id="record-initial_calendar_date" values={{year: getYear(date), month: getMonth(date)+1 }}/></div>
											{/* 次月ボタン */}
											<button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</button>
										</div>
									);
									}
								}
							/>
						</div>
						<div class="line_decoration2"></div>
						<div class="element_width_m with_calendar">
							<FormattedMessage id="record-initial_enddate_cal">
								{placeholder => <input name="s" type="text" class="calendar_text"  placeholder={placeholder} maxlength='10' value={state.toDate} onClick={handleToDatePicker} autocomplete="off"/>}
							</FormattedMessage>
							<DatePicker
								id="toDatePicker"
								locale={storeLanguage}
								maxDate={new Date()}
								selected={toDate}
								onSelect={handleChangeToDate}
								onChange={date => setToDate(date)}
								customInput={
									<img id="toDate" class="svg_img-calendar" src={`${process.env.PUBLIC_URL}/img/EZTP_calendar.svg`} alt=""/>
								}
								renderCustomHeader={({
									date,
									decreaseMonth,
									increaseMonth,
									prevMonthButtonDisabled,
									nextMonthButtonDisabled
								}) => {
									return (
										<div style={{ margin: 6, display: "flex", justifyContent: "center", textAlign  : 'center', fontWeight : 'bold' }}>
											{/* 前月ボタン */}
											<button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{"<"}</button>
											<div class="element_width_ml"><FormattedMessage id="record-initial_calendar_date" values={{year: getYear(date), month: getMonth(date)+1 }}/></div>
											{/* 次月ボタン */}
											<button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</button>
										</div>
									);
									}
								}
							/>
						</div>
					</div>
					<div class="element_width_xl blank_space_xl">
						<div class="search_box">
							<button tabIndex="-1" class="search_box" type="button" onClick={handleSubmit} id="sbtn2"><img src={`${process.env.PUBLIC_URL}/img/EZTP_search.svg`} alt=""/></button>
							<FormattedMessage id="record-initial_topic_txt">
								{placeholder => <input name="s" id="sbox2" type="text" placeholder={placeholder} maxlength='64' value={state.topic} onChange={handleInputTopic} />}
							</FormattedMessage>
						</div>
					</div>
					<div class="element_width_s inline_block blank_space_m">
						<FormattedMessage id="record-initial_search_btn">
							{value => <input class="buttons_common btn_blue" type="button" value={value} onClick={handleSubmit} />} 
							</FormattedMessage>
					</div>
				</div>
			</header>
			<div class="search_result_table_box">
				<table class="search_result_table record_initial_table">
					<tbody>
						<tr>
							<th><input class="select_all record_ckb" type="checkbox" name="all" checked={state.allcheckflag} onClick={handleCheckAllCheckbox}></input></th>
							<th><span><FormattedMessage id="record-initial_selectall_chk" /></span></th>
							<th><span><FormattedMessage id="record-initial_starttime_lbl" /></span></th>
							<th><span><FormattedMessage id="record-initial_filecount_lbl" /></span></th>
							<th><span><FormattedMessage id="record-initial_delete_deadline_lbl" /></span></th>
							<th><div class="element_width_l inline_block right_justified">
									<FormattedMessage id="record-initial_selectdelete_btn">
										{value => <input class="buttons_common btn_wht" type="button" id={"deletebtn"} onClick={handleDelete} disabled={state.disabled} value={value} />}
									</FormattedMessage>
								</div>
							</th>
						</tr>
						{
							(() => {
								if (state.search_form !=='') {
									return (
										<tr>
											<td colspan="4">
												<div class="has-error">{state.search_form}</div>
											</td>
											<td />
										</tr>
									);
								}
							})()
						}
						{
							state.draw_data.map((data, index) => {
								if (data.dispStartDate === undefined) {
									return (<tr />);
								}
								if (data.recording_count <= 0) {
									return (
										<tr>
											<td></td>
											<td><label>{data.topic}</label></td>
											<td>{data.dispStartDate}</td>
											<td>0</td>
											<td>{data.deleteLimit}</td>
											<td></td>
										</tr>
									);
								} else {
									return (
										<tr>
											<td><input class="select_all record_ckb" name="checklist" type="checkbox" checked={data.check} data-index={index} data-record_list_id={data.meeting_id} onClick={handleChangeCheckbox}></input></td>
											<td><Link to={{ pathname: "/recording_details", state: { meeting_id: data.meeting_id, meeting_uuid: data.meeting_uuid, auto_delete_cmr_days: data.auto_delete_cmr_days } }}>{data.topic}</Link></td>
											<td>{data.dispStartDate}</td>
											<td>{data.recording_count}</td>
											<td>{data.deleteLimit}</td>
											<td></td>
										</tr>
									);
                                }

							})
						}
					</tbody>
				</table>
				{state.delete_flag && <div id="record_delete" >
					<div>
						<nav>
							<div class="in_contents contact-edit" id="delete_confirm">
								<div id="modal_overlay"></div>
								<div id="modal_container">
									<header class="modal_header">
										<h2><FormattedMessage id="record-initial_selectdelete_btn" /></h2>
									</header>
									<div class="modal_contents blank_above_m">
										<span><FormattedMessage id="record-delete_sentence2_lbl" values={{ br: <br /> }} /></span>
										<div class="flex_box right_justified blank_above_m">
											<div class="element_width_ms ">
												<FormattedMessage id="record-initial_delete_btn">
													{value => <input class="buttons_common btn_blue" type="button" value={value} onClick={delete_meeting_recordings}></input>}
												</FormattedMessage>
											</div>
											<div class="element_width_ms blank_space_m">
												<FormattedMessage id="record-initial_cancel_btn">
													{value => <input id="delete_cancel_btn" class="buttons_common btn_wht" type="button" value={value} onClick={handleDeleteCancel}></input>}
												</FormattedMessage>
											</div>
										</div>
									</div>
								</div>
							</div>
						</nav>
					</div>
				</div>
				}
			</div>
		</div>
	);

}
