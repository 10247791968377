import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { setProfileMenu, getProfileMenu } from '../../store/slicer/0501_menu';
import { getProfile } from '../../store/slicer/0503_room';


export default function UserProfileHeader(props) {
	const dispatch = useDispatch();
	const storeProfile = useSelector(getProfile);
	const storeProfileMenu = useSelector(getProfileMenu);


	// onClickイベントハンドラ
	const handleOnClick = useCallback(event => {
		const num = event.target.getAttribute('data-pos');
		dispatch(setProfileMenu({top: num, side: num === 2 ? 4: 0}));
	}, []);

	// レンダリング
	switch (storeProfileMenu.top) {
	case 0:
		return (
			<header class="in_contents_header">
				<title><FormattedMessage id="tab_title_profile" /></title>
				<nav class="in_contents_header_tab">
					<ul class="c-tabs_list">
						<li class="c-tabs_item current_tab"><span><FormattedMessage id="profile-view_title1_lbl" /></span></li>
						<li class="c-tabs_item"><Link onClick={handleOnClick} data-pos="1" to={{pathname:"/user_meeting", state:{data:storeProfile}}}><FormattedMessage id="profile-view_title2_lbl" /></Link></li>
						<li class="c-tabs_item"><Link onClick={handleOnClick} data-pos="2" to={{pathname:"/user_recording", state:{data:storeProfile}}}><FormattedMessage id="profile-view_title3_lbl" /></Link></li>
					</ul>
				</nav>
			</header>
		);
		

	case 1:
		return (
			<header class="in_contents_header">
				<title><FormattedMessage id="tab_title_meeting_setting" /></title>
				<nav class="in_contents_header_tab">
					<ul class="c-tabs_list">
						<li class="c-tabs_item"><Link onClick={handleOnClick} data-pos="0" to={{pathname:"/user_profile", state:{data:storeProfile}}}><FormattedMessage id="profile-view_title1_lbl" /></Link></li>
						<li class="c-tabs_item current_tab"><span><FormattedMessage id="profile-view_title2_lbl" /></span></li>
						<li class="c-tabs_item"><Link onClick={handleOnClick} data-pos="2" to={{pathname:"/user_recording", state:{data:storeProfile}}}><FormattedMessage id="profile-view_title3_lbl" /></Link></li>
					</ul>
				</nav>
			</header>
		);
		
	case 2:
		return (
			<header class="in_contents_header">
				<title><FormattedMessage id="tab_title_record_setting" /></title>
				<nav class="in_contents_header_tab">
					<ul class="c-tabs_list">
						<li class="c-tabs_item"><Link onClick={handleOnClick} data-pos="0" to={{pathname:"/user_profile", state:{data:storeProfile}}}><FormattedMessage id="profile-view_title1_lbl" /></Link></li>
						<li class="c-tabs_item"><Link onClick={handleOnClick} data-pos="1" to={{pathname:"/user_meeting", state:{data:storeProfile}}}><FormattedMessage id="profile-view_title2_lbl" /></Link></li>
						<li class="c-tabs_item current_tab"><span><FormattedMessage id="profile-view_title3_lbl" /></span></li>
					</ul>
				</nav>
			</header>
		);
		
	}
	return (<header class="in_contents_header"><nav class="in_contents_header_tab"></nav></header>);
}

