import { createSlice } from '@reduxjs/toolkit';


// Redux Toolkitを使用すると、reducersで「変更」ロジックを記述できます。 
// 「draft state」への変更を検出しそれらの変更に基づいて
// まったく新しい不変の状態を生成するImmerライブラリを使用するため
// 実際には状態を変更しません。
export const messageboxSlice = createSlice({
	name: 'messagebox',
	initialState: {
		visible: false,
		title: '',
		message: '',
		result: '',
		value: {},
	},
	reducers: {
		clearMessagebox: (state) => {
			state.visible = false;
			state.title = '';
			state.message = '';
			state.result = 'start';
			state.value = {};
		},
		clearValue: (state) => {
			state.value = {};
		},
		setTitleMessage: (state, action) => {
			state.title = action.payload.title;
			state.message = action.payload.message;
			state.result = 'start';
		},
		setValue: (state, action) => {
			const data = action.payload;
			Object.keys(data).forEach(function(value) {
				state.value[value] = this[value];
			}, data);
		},
		setVisible: (state, action) => {
			state.visible = action.payload;
		},
		setResult: (state, action) => {
			state.result = action.payload;
			state.visible = false;
		},
	},
});

export const { clearMessagebox, clearValue, setTitleMessage, setValue, setVisible, setResult } = messageboxSlice.actions;


// サンク：非同期ロジックの実行
// dispatch(incrementByAmount(amount))
// 最初の引数として dispatch関数を使用してサンクが呼び出されます。
// その後、非同期コードを実行し、他のアクションをディスパッチできます
/*
export const incrementAsync = amount => dispatch => {
  setTimeout(() => {
    dispatch(incrementByAmount(amount));
  }, 1000);
};
*/

// セレクター：state から値を取得する
// useSelector((state) => state.counter.value) のようにインラインで定義もできる

export const getMessagebox = state => state.messagebox;
export const getVisible = state => state.messagebox.visible;
export const getResult = state => state.messagebox.result;
export const getValue = state => state.messagebox.value;


// レデューサーをエクスポートする
export default messageboxSlice.reducer;

