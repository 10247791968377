import React, { useState, useEffect, useLayoutEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserData, getAccessToken, getRefreshToken, setAccessToken, logout } from '../../store/slicer/0101_session';
import * as CONST from '../../const';
import { setPageNumber, setPageMax, getPage } from '../../store/slicer/0502_page';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { FormattedMessage, useIntl } from "react-intl";
import { SetErrorMessage } from '../../store/slicer/0101_logout_message';


export default function GroupList() {
    const dispatch = useDispatch();
    const userdata = useSelector(getUserData);
    const storePage = useSelector(getPage);

    const intl = useIntl();


    // 初期化
    const [state, setState] = useState(() => {
        return {
            data: [{}],
            drawdata: [{}],
            input_keyword: '',
            search_keyword: '',
            group_id: '',
            page_size: 50,
            page_number: 1,
            page_max: 1,
            allcheckflag: false,
            delete_flag: false,
            crud_flag: false,
            disabled: true,
            search_form: '',
            form: [
                { message: 'EMPTY' },
            ],
        }
    });

    var access_token = useSelector(getAccessToken);
    var refresh_token = useSelector(getRefreshToken);
    // 表示リスト作成
    useEffect(() => {
        let now_page;
        now_page = storePage.page_number;
        if (now_page < 1) {
            now_page = 1;
        }

		const fetchHttpRefresh = async () => {
			let senddata = {
			};
			let url_tokref = CONST.BASEURL + '/authorize/refresh';
			var httpResponse_tokref = await fetch( url_tokref, {
				method:'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': "Bearer " + refresh_token
				},
				body: JSON.stringify(senddata),
			});
			switch (httpResponse_tokref.status) {
				case 200:
					var httpResponse_tokref_json = await httpResponse_tokref.json();
					access_token = httpResponse_tokref_json.access_token;
			
					refresh_token = httpResponse_tokref_json.refresh_token;
					// トークンをデコードする
					//const decode_token = jwt(access_token);
					// 問題ない場合は、storeに保存
					dispatch(setAccessToken(access_token));
					//dispatch(setRefreshToken(refresh_token));
					fetchHttp();
                    return;                      
				default:
                    dispatch(SetErrorMessage('ERROR_0001'));
                    dispatch(logout());
                    return;
			}
		}
		//fetchHttpRefresh();

        // 連絡先一覧取得
        const fetchHttp = async () => {
        fetch(CONST.BASEURL + '/customers/' + userdata.id + '/groups?page_size=' + state.page_size + '&page_number=' + now_page + '&search=' + state.search_keyword, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + access_token
            }
        })
            .then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    switch (res.status) {
                        case 200:
                            let drawdata = []
                            let error = [...state.form];
                            error[0]['message'] = 'EMPTY';

                            //検索結果の表示用リストデータを設定する
                            drawdata = res.data.groups;

                            for (let i = 0; i < drawdata.length; i++) {
                                drawdata[i].check = false;
                            }

                            // ページ数を算出する
                            const page_max = Math.ceil(res.data.groups_count / state.page_size);

                            // 最大ページ数を変更
                            dispatch(setPageMax(page_max));

                            if (drawdata.length === 0) {
                                error[0]['message'] = 'ERROR_0006';
                                document.getElementById('sbox2').focus();
                            }

                            setState({ ...state, drawdata: drawdata, crud_flag: false, disabled: true, form: error, allcheckflag: false });
                            return;

                        case 401:
                            fetchHttpRefresh();
                            return;
                        case 409:
                            dispatch(SetErrorMessage('ERROR_0039'));  
                            dispatch(logout());
                            return;

                        default:
                            toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                            break;
                    }

                }).catch((err) => {
                    //Error出力
                    toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                })
            )
            .catch((err) => {
                toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

            });
        }
        fetchHttp();
    }, [state.search_keyword, state.crud_flag, storePage.page_number]);

    // 検索テキストボックス入力処理
    const handleInputSearch = event => {
        setState({ ...state, input_keyword: event.target.value });
    };

    // サブミット
    const handleSubmit = event => {
        event.preventDefault(false);
        setState({ ...state, search_keyword: state.input_keyword, crud_flag: true });
        dispatch(setPageNumber(1));
    };

    //チェックボックス全選択
    const handleCheckAllCheckbox = event => {
        let drawdate_checkall = [{}];
        let checkdrawdata = [{}];
        let allcheck = false;

        checkdrawdata = state.drawdata;

        drawdate_checkall = state.drawdata;
        if (state.allcheckflag === false) {
            for (let i = 0; i < drawdate_checkall.length; i++) {
                drawdate_checkall[i].check = true;
            }
            allcheck = true;
        } else {
            for (let i = 0; i < drawdate_checkall.length; i++) {
                drawdate_checkall[i].check = false;
            }
            allcheck = false;
        }


        //チェックボックスにチェックが入っていないと削除ボタンを押せなくする
        let delete_check = true;
        for (let i = 0; i < checkdrawdata.length; i++) {
            if (checkdrawdata[i].check === true) {
                delete_check = false;
                break;
            }
        }

        setState({ ...state, drawdata: drawdate_checkall, allcheckflag: allcheck, disabled: delete_check });
    }

    //チェックボックスの値が変化したら、配列に追加または削除をする
    const handleChangeCheckbox = event => {
      
        const index = event.target.getAttribute('data-index');

        let checkdrawdata = [{}];
        let flag = false;//リストのチェックボックスの値
        let AllorNotAll = false;//全チェックのチェックボックスの値

        flag = state.drawdata[index].check ? false : true;

        checkdrawdata = state.drawdata;
        checkdrawdata[index].check = flag;

        //すべてにチェックが入っていなかったらallからチェックを外す。すべてにチェックが入っていたらallにチェックを入れる
        for (let i = 0; i < checkdrawdata.length; i++) {
            if (checkdrawdata[i].check === false) {
                AllorNotAll = true;
                break;
            }
        }
        if (AllorNotAll === true) {
            AllorNotAll = false;
        } else {
            AllorNotAll = true;
        }



        //チェックボックスにチェックが入っていないと削除ボタンを押せなくする
        let delete_check = true;
        for (let i = 0; i < checkdrawdata.length; i++) {
            if (checkdrawdata[i].check === true) {
                delete_check = false;
                break;
            }
        }


        setState({ ...state, drawdata: checkdrawdata, allcheckflag: AllorNotAll, disabled: delete_check });
    }

    //連絡先削除ポップアップ表示
    const handleDelete = event => {
        setState({ ...state, delete_flag: true });
    };

    //　削除ダイアログ　削除
    function delete_group() {
        let delete_group_id_data = [{}];
        let drawdate_refresh = [{}];
        let x = 0;
        for (let i = 0; i < state.drawdata.length; i++) {
            if (state.drawdata[i].check) {
                delete_group_id_data[x] = { "group_id": state.drawdata[i].group_id };
                x++;
            }
        }
        const fetchHttpRefresh = async () => {
            let senddata = {
            };
            let url_tokref = CONST.BASEURL + '/authorize/refresh';
            var httpResponse_tokref = await fetch( url_tokref, {
                method:'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + refresh_token
                },
                body: JSON.stringify(senddata),
            });
            switch (httpResponse_tokref.status) {
                case 200:
                    var httpResponse_tokref_json = await httpResponse_tokref.json();
                    access_token = httpResponse_tokref_json.access_token;
                    refresh_token = httpResponse_tokref_json.refresh_token;
                    // トークンをデコードする
                    //const decode_token = jwt(access_token);
                    // 問題ない場合は、storeに保存
                    dispatch(setAccessToken(access_token));
                    //dispatch(setRefreshToken(refresh_token));
                    fetchHttp();
                    return;                      
                default:
                    dispatch(SetErrorMessage('ERROR_0001'));
                    dispatch(logout());
                    return;
            }
        }
        //fetchHttpRefresh();
        const fetchHttp = async () => {
        fetch(CONST.BASEURL + '/customers/' + userdata.id + '/groups', {
            method: 'DELETE',
            headers: {
                'Content-type': 'application/json',
                Authorization: "Bearer " + access_token
            },
            body: JSON.stringify({
                "groups": delete_group_id_data
            })
        })
            .then(res => {
                 switch (res.status) {
                    case 204:
                        //削除に成功したので配列を空にする
                        delete_group_id_data = 0;

                        //削除に成功したのですべてのチェックを外す
                        drawdate_refresh = state.drawdata;
                        for (let i = 0; i < drawdate_refresh.length; i++) {
                            drawdate_refresh[i].check = false;
                        }
                        toast(intl.formatMessage({ id: 'SUCCESS_0003' }),{style:{background:  CONST.HOT_TOAST_SUCCESS}});
                        // 問題ない場合は、リロード                    
                        setState({ ...state, drawdata: drawdate_refresh, delete_flag: false, crud_flag: true, allcheckflag: false });
                        dispatch(setPageNumber(1));
                        break;
                    case 401:
                        fetchHttpRefresh();
                        return;
                    case 409:
                        dispatch(SetErrorMessage('ERROR_0039'));  
                        dispatch(logout());
                        return; 
                    default:
                        toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                        break;
                }
            })

            .catch((err) =>
                toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}})
            )
        }
        fetchHttp();

    }

    //削除 キャンセルボタン押下
    const handleDeleteCancel = event => {
        setState({ ...state, delete_flag: false });
    };

    // ダイアログ初期カーソル
    useLayoutEffect(() => {
        if (state.delete_flag == true) {
            document.getElementById('delete_cancel_btn').focus();
        }
    }, [state.delete_flag])

	// 描画
    return (
        <div class="scroll_range">
            <title><FormattedMessage id="tab_title_group_list" /></title>
            <header class="in_contents_header">
                <div class="flex_box">
                    <div class="element_width_xl">
                        <div class="search_box">
                            <button tabIndex="-1" class="search_box" onClick={handleSubmit} type="button" id="sbtn2"><img src={`${process.env.PUBLIC_URL}/img/EZTP_search.svg`} alt=""/></button>
                            <FormattedMessage id="group-initial_groupname_txt">{placeholder => <input id="sbox2" name="s" type="text" placeholder={placeholder} maxlength='64' value={state.input_keyword} onChange={handleInputSearch} />}</FormattedMessage>
                        </div>
                    </div>
                    <div class="flex_box right_justified element_width_free">
                        <div class="element_width_l inline_block"><Link to={{ pathname: "/group_add", state: { group_id: "", group_name: "" } }} class="buttons_common btn_blue"><FormattedMessage id="group-initial_addgroup_btn"/></Link></div>
                    </div>
                </div>
            </header>
            <div class="search_result_table_box">
                <table class="search_result_table management_information_table">
                    <tbody>
                        <tr>
                            <th><input class="select_all group_ckb" type="checkbox" name="all" checked={state.allcheckflag} onClick={handleCheckAllCheckbox}></input></th>
                            <th><FormattedMessage id="group-initial_selectall_chk"></FormattedMessage></th>
                            <th><FormattedMessage id="group-initial_membercount_lbl"></FormattedMessage></th>
                            <th></th>
                            <th>
                                <div class="element_width_l inline_block right_justified">                                    
                                    <FormattedMessage id="group-initial_selectdelete_btn">{value => <input class="buttons_common btn_wht" type="button" id={"deletebtn"} onClick={handleDelete} disabled={state.disabled} value={value} />}</FormattedMessage>
								</div>
							</th>
					    </tr>
						{
							(() => {
								if (state.form[0].message !=='EMPTY') {
									return (
										<tr>
											<td colspan="4">
												<div class="has-error"><FormattedMessage id={state.form[0].message} /></div>
											</td>
											<td />
										</tr>
									);
								}
							})()
						}
                        {
                            state.drawdata.map((data, index) => {
                                if (data.group_name === undefined) {
                                    return (<tr />);
                                }
                                return (
                                    <tr>
                                        <td><input class="select_all group_ckb" name="checklist" type="checkbox" checked={data.check} data-index={index} data-group_list_id={data.group_id} onClick={handleChangeCheckbox}></input></td>
                                        <td><Link to={{ pathname: "/group_edit", state: { group_id: data.group_id, group_name: data.group_name } }}>{data.group_name}</Link></td>
                                        <td>{data.members_count}</td>
                                        <td></td>
                                        <td>
                                            <div class="element_width_m inline_block"><Link to={{ pathname: "/group_edit", state: { group_id: data.group_id, group_name: data.group_name } }} class="buttons_common btn_wht"><FormattedMessage id="group-initial_edit_btn"></FormattedMessage></Link></div>
                                        </td>
                                    </tr>
                                );
                            })
                        }
				    </tbody>
                </table>
			    {state.delete_flag && <div id="group_delete" >
				    <div>
					    <nav>
						    <div class="in_contents contact-edit" id="delete_confirm">
							    <div id="modal_overlay"></div>
							    <div id="modal_container">
									<header class="modal_header">
                                        <h2><FormattedMessage id="group-initial_selectdelete_btn"></FormattedMessage></h2>
									</header>
                                    <div class="modal_contents blank_above_m">
                                        <div class="inline_block element_width_xl"><span><FormattedMessage id="group-delete_sentence2_lbl" values={{ br: <br /> }} /></span></div>
                                        <div class="flex_box right_justified blank_above_m">
                                            <div class="element_width_ms"><FormattedMessage id="group-initial_delete_btn">{value => <input class="buttons_common btn_blue" type="button" value={value} onClick={delete_group}></input>}</FormattedMessage></div>
                                            <div class="element_width_ms blank_space_m"><FormattedMessage id="group-initial_cancel_btn">{value => <input id="delete_cancel_btn" class="buttons_common btn_wht" type="button" value={value} onClick={handleDeleteCancel}></input>}</FormattedMessage></div>
										</div>
									</div>
							    </div>
						    </div>
					    </nav>
				    </div>
			    </div>
			    }
		    </div>
        </div>
    );
}
