import { useState }  from 'react';
import { useDispatch } from 'react-redux';
import { setSideMenu } from '../../../store/slicer/0501_menu';

import Header from '../../0003_components/0001_header';
import Sidemenu from '../../0003_components/0002_sidemenu';

import ContactsSearch from '../../0003_components/1601_contacts_search';
import Pagination from '../../0003_components/0003_pagination';

import { toast,Toaster } from 'react-hot-toast';


export default function Contact(props) {
	const dispatch = useDispatch();

	// 初期化
	const [state, setState] = useState(() => {
		dispatch(setSideMenu(3));
		toast.dismiss();
		return 0;
	});

	return (
		<div class="wrapper">
			<Header />
			<div class="container">
				<div class="contents">
					<Sidemenu />
					<style jsx="true" />
					<Toaster position="top-center" reverseOrder="false" containerStyle={{ marginTop:'75px'}} />
					<div class="in_contents contact-initial">
						<ContactsSearch />
						<Pagination />
					</div>
				</div>
			</div>
		</div>
	);
}