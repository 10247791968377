import * as CONST from '../../../const';
import { useState }  from 'react';
import jwt from 'jwt-decode';
import { useSelector, useDispatch } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import { FormattedMessage, useIntl } from "react-intl";
import Header from '../../0003_components/0001_header';
import { loginMeetingRoom, getSignCheckForMeetingRoom } from '../../../store/slicer/2201_meetingroom_session';
import { useHistory } from 'react-router-dom';
import { Redirect, useRouteMatch } from 'react-router';
import { SetErrorMessage } from '../../../store/slicer/0101_logout_message';
import { getAccessTokenForMeetingRoom, checkLoginOfMeetingRoom } from '../../../store/slicer/2201_meetingroom_session';
import { setLanguage, getLanguage } from '../../../store/slicer/0103_language';


export default function MeetingRoomCheck(props){
    const dispatch = useDispatch();
	const history = useHistory();
	const storeLanguage = useSelector(getLanguage);
	const storeSignCheck = useSelector(getSignCheckForMeetingRoom);
	const intl = useIntl();
	const match = useRouteMatch();
	const isLogin = useSelector(checkLoginOfMeetingRoom);
	var access_token = useSelector(getAccessTokenForMeetingRoom);

    // 初期化
	const [state, setState] = useState(() => {

		//会社名を取得する
		const fetchHttp = async () => {
			let url = CONST.BASEURL + '/customers/' + match.params.id + '/customer_name'
			fetch(url, {
				method: 'GET',
				headers: {
					Authorization: "Bearer " + access_token
				}
			})
			.then(response =>
				response.json().then(data => ({
					data: data,
					status: response.status
				})
				).then(res => {
					switch (res.status) {
						case 200:
							setState({ ...state,customerName:res.data.customer_name,isExistAccount: true });
							return;
						
						case 404:
							setState({ ...state,customerName:res.data.customer_name,isExistAccount: false });
							dispatch(SetErrorMessage('ERROR_0039'));  
							return;   

						default:
							//Error出力
							toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

							break;
					}

				}).catch((err) => {
					//Error出力
					toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

				})
			)
			.catch((err) => {
				//Error出力
				toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

			});
			
		}
		fetchHttp();
		return {
			customerName:'',		
			account: match.params.id,
			password: '',
			type: 'password',
			chk1: storeSignCheck !== undefined ? storeSignCheck: false,
			isExistAccount: false,
			form: [
				{message: 'EMPTY'}
			],
		}
	});

    //password表示・非表示
	const Masking_Password = event => {
		if (state.type === 'password') {			
			event.target.setAttribute('class', 'masking_btn visible');
			setState({ ...state, type: 'text' });
		} else {
			event.target.setAttribute('class', 'masking_btn invisible');
			setState({ ...state, type: 'password' });
		}
	}

    // ENTERイベント
	const keydownEnter = event => {
		if (window.event.keyCode === 13) {
			// ログイン
			handleSubmit()
		}
	};

    // 入力のイベント
	const handleInputChange = event => {
		setState({...state, [event.target.id]: event.target.value,});
	};
	const handleCheckboxChange = event => {
		setState({...state, [event.target.id]: event.target.checked,});
	};

    const handleSubmit = event => {	
		let error = [...state.form];

		error[0]['message'] = 'EMPTY';
	
		if (state.password === '') {
			error[0]['message'] = 'ERROR_0020';
			document.getElementById('password').focus();
			setState({...state, form: error, });
			return;
		}

		// ログイン処理
		fetch(CONST.BASEURL + '/authorize', {
			method:'POST',
			headers: {
				'Content-type':'application/json'
			},
			body: JSON.stringify({
				account: match.params.id,
				password: state.password,
				role: 'customer_user'
			})
		})
		.then(response => 
			response.json().then(data => ({
				data: data,
				status: response.status
			})
			).then(res => {
				switch (res.status) {
					case 200:
						const access_token = res.data.access_token;
						const refresh_token = res.data.refresh_token;
						// トークンをデコードする
						const decode_token = jwt(access_token);
						let id = decode_token.user_claims.customer_id;

						// 問題ない場合は、storeに保存してログイン後のページに偏移
						dispatch(loginMeetingRoom({
							access_token: access_token,
							refresh_token: refresh_token,
							signinForEver: state.chk1,
							id: decode_token.user_claims.customer_id,
							name: decode_token.user_claims.customer_name,
							role: decode_token.user_claims.role,
							account: decode_token.user_claims.account,
							email: decode_token.user_claims.email,
						}));

						setState({...state, form: error, });
						history.push('/meeting_list/' + state.account);
						break;

					case 401:
						error[0]['message'] = 'ERROR_0041';
						document.getElementById('password').focus();
						setState({...state, form: error, });
						break;

					default:
						toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

						error[0]['message'] = 'EMPTY';
						setState({ ...state, form: error, });
						break;
				}

			}).catch((err) => {
				//Error出力
				toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

			})
		)
		.catch((err) => {
			toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

			error[0]['message'] = 'EMPTY';
			setState({ ...state, form: error });
		});
	};

	//言語切り替え用のダミーボタン
	const handle_dammy = event => {
		dispatch(setLanguage(event.target.value));
	}

    return(
    	<>
		<div class="wrapper">
			<title><FormattedMessage id="meeting-list-check_title_txt"/></title>
			<Header partsOnShow="check_password"/>
			<div class="container">
				<div class="contents">
					<div class="before_login_box">
						<style jsx="true" />
						{isLogin && <Redirect to={'/meeting_list/' + match.params.id}></Redirect>}
						<Toaster position="top-center" reverseOrder="false" containerStyle={{ marginTop:'75px'}} />
						<div class="operation_box meeting-room-check">
							<dl>
								<dd><span>{state.customerName}</span></dd>
								<dd class="blank_above_l">
									<div class="meeting-room-check_masking" >
										<button tabIndex="-1" type="button" class="masking_btn invisible" onClick={Masking_Password} />
									</div>
									<FormattedMessage id="meeting-list-check_placeholder">
										{placeholder => <input onKeyDown={keydownEnter} id="password" class={state.form[0].message !== 'EMPTY' ? 'has-error' : ''} type={state.type} name="pass" placeholder={placeholder} value={state.password} onChange={handleInputChange} style={{width: '300px',verticalAlign: 'baseline'}}/>}
									</FormattedMessage>
								
									<div class="has-error" style={{ display: state.form[0].message !== 'EMPTY' ? '' : 'none' }}>
										<FormattedMessage id={state.form[0].message} values={{ br: <br /> }} />
									</div>
								</dd>									
								
								<dd class="blank_above_m">
									<label class="ckbox_label">
										<input id="chk1" class="vertical_center" type="checkbox" name="" checked={state.chk1} onChange={handleCheckboxChange} /><FormattedMessage id="meeting-list-check_password_save_txt" />
									</label>
								</dd>
								<dd class="blank_above_l">
									<a>
										<FormattedMessage id="meeting-list-check_show_btn">{value => <input class="buttons_sign_in btn_blue" type="button" value={value} onClick={handleSubmit} disabled={!state.isExistAccount}/>}
										</FormattedMessage>
									</a>
								</dd>
							</dl>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="policy_terms_link">
			<div>
				<p class="policy_terms_link_p">
					<select class="lang_selection_list" name="lang" onChange={handle_dammy} value={storeLanguage} style={{marginLeft:0}}>
						<option value="ja">日本語</option>
						<option value="en">English</option>
						<option value="de">Deutsche</option>
						<option value="fr">Français</option>
					</select>
				</p>
			</div>
		</div>
		</>

		
    );

}