import * as CONST from '../../const'
import { useState, useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { getUserData, getAccessToken, getRefreshToken, setAccessToken, logout } from '../../store/slicer/0101_session';
import { FormattedMessage, useIntl } from "react-intl";
import toast from 'react-hot-toast';
import { SetErrorMessage } from '../../store/slicer/0101_logout_message';
import { setGroup, getGroup } from '../../store/slicer/1901_group';

export default function GroupEdit(props) {
	const dispatch = useDispatch();
	const loc = useLocation();
	const history = useHistory();
	const getGroups = useSelector(getGroup);
	// セッション
	const storeUser = useSelector(getUserData);
	const storeAccesToken = useSelector(getAccessToken);

	const intl = useIntl();

    var access_token = useSelector(getAccessToken);
    var refresh_token = useSelector(getRefreshToken);

	// 初期化
	const [state, setState] = useState(() => {
		
		let mode = true;
		let title = "group-edit_sentence1_lbl";
		let group_id = "";
		let group_name = "";

		if (loc.state !== undefined) {
			// 画面遷移時
			group_id = loc.state.group_id;
			group_name = loc.state.group_name;

			if (group_id === "" && group_name === "") {
				title = "group-additional_sentence1_lbl";
			} else {
				mode = false;
			}
		} else {
			// 画面更新時
			group_id = getGroups.group_id;
			group_name = getGroups.group_name;

			if (group_id === "" && group_name === "") {
				title = "group-additional_sentence1_lbl";
			} else {
				mode = false;
			}
        }

		dispatch(setGroup({ group_id: group_id, group_name: group_name }));
		
		return {
			title: title,
            input_keyword: '',
            search_keyword: '',
			contacts_all: [],
			contacts_group: [],
			contacts_not_group: [],
			isAddMode: mode,
			group_id: group_id,
			group_name: group_name,
			input_group_name: group_name,
			error_flag: false,
			error_message: "",
			delete_flag: false,
			form: [
				{ message: 'EMPTY' },
			],
		}

	});

	// 表示リスト作成
	useEffect(() => {
		let contactsAllData = [];
		let contactsData = [];
		let groupContactsData = [];
		let checkGroupContactsData = [];

		if (state.isAddMode) {
			const fetchHttpRefresh = async () => {
				let senddata = {
				};
				let url_tokref = CONST.BASEURL + '/authorize/refresh';
				var httpResponse_tokref = await fetch( url_tokref, {
					method:'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': "Bearer " + refresh_token
					},
					body: JSON.stringify(senddata),
				});
				switch (httpResponse_tokref.status) {
					case 200:
						var httpResponse_tokref_json = await httpResponse_tokref.json();
						access_token = httpResponse_tokref_json.access_token;
						refresh_token = httpResponse_tokref_json.refresh_token;

						// 問題ない場合は、storeに保存
						dispatch(setAccessToken(access_token));
						fetchHttp();
						return;					       
					default:
						dispatch(SetErrorMessage('ERROR_0001'));
						dispatch(logout());
						return;
				}
			}
	
			// 連絡先一覧取得
			var url = CONST.BASEURL + '/customers/' + storeUser.id + '/contacts?page_size=99999&page_number=1' + '&search=' + state.search_keyword;
			const fetchHttp = async () => {
			fetch( url , {
				method:'GET',
				headers: {
					Authorization: "Bearer " + storeAccesToken
				}
			})
			.then(response => 
				response.json().then(data => ({
					data: data,
					status: response.status
				})
				).then(res => {
					switch (res.status) {
						case 200:
							// データ取得
							let counter1 = 0;
							let counter2 = 0;
							let counter3 = 0;
							let counter4 = 0;
						
							// グループ設定済みの場合は、設定済みのリストデータを設定する
							if ( state.contacts_group.length > 0 ) {
								checkGroupContactsData =  [];
								for (let i = 0; i < state.contacts_group.length; i++) {
									checkGroupContactsData[counter1++] = state.contacts_group[i].contact_id;
								}
							}

							for (let i = 0; i < res.data.contacts.length; i++) {
								contactsAllData[counter2++] = res.data.contacts[i];
								let contact_id = res.data.contacts[i].contact_id
								if (checkGroupContactsData.includes(contact_id)) {
									groupContactsData[counter3++] = res.data.contacts[i];
								} else {
									contactsData[counter4++] = res.data.contacts[i];
								}
							}
					
							// グループ設定済みの場合は、設定済みのリストデータを設定する
							if ( state.contacts_group.length !== 0) {
								groupContactsData = state.contacts_group;
							}

							// 表示用リストデータを設定する
							setState({ ...state,  contacts_all: contactsAllData, contacts_group: groupContactsData,
										 contacts_not_group: contactsData, init_flag: true });
					
							return;
						case 401:
							fetchHttpRefresh();
							return;
						case 409:
							dispatch(SetErrorMessage('ERROR_0039'));  
							dispatch(logout());
							return;
						default:
								toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

							return;
					}

				}).catch((err) => {
					//Error出力
					toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

				})
				)
				.catch((err) => {
					toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

				});
			}
			fetchHttp();
		}
		else {
			const fetchHttpRefresh = async () => {
				let senddata = {};
				let url_tokref = CONST.BASEURL + '/authorize/refresh';
				var httpResponse_tokref = await fetch( url_tokref, {
					method:'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': "Bearer " + refresh_token
					},
					body: JSON.stringify(senddata),
				});
				switch (httpResponse_tokref.status) {
					case 200:
						var httpResponse_tokref_json = await httpResponse_tokref.json();
						access_token = httpResponse_tokref_json.access_token;
						refresh_token = httpResponse_tokref_json.refresh_token;

						// 問題ない場合は、storeに保存
						dispatch(setAccessToken(access_token));
						fetchHttp();
						return;					      
					default:
						dispatch(SetErrorMessage('ERROR_0001'));
						dispatch(logout());
						return;
				}
			}
			//fetchHttpRefresh();

			// グループ情報取得
			var url = CONST.BASEURL + '/customers/' + storeUser.id + '/groups/' + state.group_id;
			const fetchHttp = async () => {
				fetch(url , {
					method:'GET',
					headers: {
						Authorization: "Bearer " + access_token
					}
					})
					.then(response =>
						response.json().then(data => ({
						data: data,
						status: response.status
					})
					).then(res => {
	 					switch (res.status) {
							case 200:
								let counter = 0;
								for (let i = 0; i < res.data.total_members; i++) {
									checkGroupContactsData[counter++] = res.data.members[i].contact_id;
								}

								// 連絡先一覧取得
								var url = CONST.BASEURL + '/customers/' + storeUser.id + '/contacts?page_size=99999&page_number=1' + '&search=' + state.search_keyword;
								fetch( url , {
									method:'GET',
									headers: {
										Authorization: "Bearer " + access_token
									}
								})
								.then(response => 
									response.json().then(data => ({
										data: data,
										status: response.status
									})
									).then(res => {
										switch (res.status) {
										case 200:
											// データ取得
											let counter1 = 0;
											let counter2 = 0;
											let counter3 = 0;
											let counter4 = 0;
											
											// グループ設定済みの場合は、設定済みのリストデータを設定する
											if ( state.contacts_group.length > 0 ) {
												checkGroupContactsData =  [];
												for (let i = 0; i < state.contacts_group.length; i++) {
													checkGroupContactsData[counter1++] = state.contacts_group[i].contact_id;
												}
											}

												for (let i = 0; i < res.data.contacts.length; i++) {
												contactsAllData[counter2++] = res.data.contacts[i];
												let contact_id = res.data.contacts[i].contact_id
												if (checkGroupContactsData.includes(contact_id)) {
													groupContactsData[counter3++] = res.data.contacts[i];
												}
												else {
													contactsData[counter4++] = res.data.contacts[i];
												}
											}
											
											// グループ設定済みの場合は、設定済みのリストデータを設定する
											if ( state.contacts_group.length !== 0) {
												groupContactsData = state.contacts_group;
											}

											// 表示用リストデータを設定する
											setState({ ...state,  contacts_all: contactsAllData, contacts_group: groupContactsData, contacts_not_group: contactsData, init_flag: true });
											return;
										default:
											toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

											return;
										}
									}).catch((err) => {
										//Error出力
										toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

									})
								)
								.catch((err) => {
									toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

								});
								break;
							case 401:
								fetchHttpRefresh();
								return;
							case 409:
								dispatch(SetErrorMessage('ERROR_0039'));  
								dispatch(logout());
								return;

							default:
								toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

								break;
						}
					}).catch((err) => {
						//Error出力
						toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

					})
				)
				.catch((err) => {
					toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

				});
			}
			fetchHttp();
		}
	}, [state.search_keyword]);

    // 検索テキストボックス入力処理
    const handleInputSearch = event => {
        setState({ ...state, input_keyword: event.target.value });
    };

    // サブミット
    const handleSubmit = event => {
        event.preventDefault(false);
        setState({ ...state, search_keyword: state.input_keyword });
    };

	// テキストボックス（グループ名）入力ハンドラ
	const handleInputGroupNameChange = event => {
		event.preventDefault(true);
		setState({...state, input_group_name: event.target.value});
	};

	// 保存ボタン押下
	const handleSave = event => {
		let error = [...state.form];
		let apiBody = '';

		error[0]['message'] = 'EMPTY';

		if (state.input_group_name.trim()==="") {
			error[0]['message'] = 'ERROR_0005';
			document.getElementById('group_name').focus();
			setState({ ...state, form: error, });
			return;
		}

		if ( state.group_id === '' && state.contacts_group.length === 0 ) {
			apiBody = JSON.stringify({ group_name: state.input_group_name });
		}
		else {
			if ( state.group_id !== '' && state.contacts_group.length === 0 ) {
				apiBody = JSON.stringify({ group_id: state.group_id, group_name: state.input_group_name });
			}
			else {
				apiBody = '{ "group_id": "' + state.group_id + '", "group_name": "' + state.input_group_name + '", '
				let contact_id =  '"members": [';
				for (let i = 0; i < state.contacts_group.length; i++) {
					contact_id = contact_id + ' { "contact_id" : "' + state.contacts_group[i].contact_id + '" } ,' ;
				}
				contact_id = contact_id.slice( 0, -1 ) + ']';
				apiBody = apiBody + contact_id + '}';
			}
		}

		const fetchHttpRefresh = async () => {
			let senddata = {};
			let url_tokref = CONST.BASEURL + '/authorize/refresh';
			var httpResponse_tokref = await fetch( url_tokref, {
				method:'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': "Bearer " + refresh_token
				},
				body: JSON.stringify(senddata),
			});
			switch (httpResponse_tokref.status) {
				case 200:
					var httpResponse_tokref_json = await httpResponse_tokref.json();
					access_token = httpResponse_tokref_json.access_token;
					refresh_token = httpResponse_tokref_json.refresh_token;

					// 問題ない場合は、storeに保存
					dispatch(setAccessToken(access_token));
					fetchHttp();
					return;				       
				default:
					dispatch(SetErrorMessage('ERROR_0001'));
					dispatch(logout());
					return;
			}
		}

		//fetchHttpRefresh();
		// グループ操作API
		var url = CONST.BASEURL + '/customers/' + storeUser.id + '/groups';
		const fetchHttp = async () => {
			fetch(url, {
				method: 'POST',
				headers: {
					'Content-type': 'application/json',
					Authorization: "Bearer " + access_token
				},
				body: apiBody
			})
				.then(response => {
					let error = [...state.form];
					switch (response.status) {
						case 201:
							
							if (state.group_id === "") {
								error[0]['message'] = 'EMPTY';
								setState({ ...state, form: error });
								toast(intl.formatMessage({ id: 'SUCCESS_0004' }),{style:{background:  CONST.HOT_TOAST_SUCCESS}});
							}
							else {
								toast(intl.formatMessage({ id: 'SUCCESS_0005' }),{style:{background:  CONST.HOT_TOAST_SUCCESS}});
                            }
							// 問題ない場合は、/groupに移行
							history.push({pathname: '/group',state:{referrer: '/group_edit'}})
							break;
						case 400:
							response.json().then(data => ({
								data: data,
								status: response.status
							})
							).then(res => {
								switch (res.data.errors[0]["code"]) {
									case 3020:
										error[0]['message'] = 'ERROR_0010';
										document.getElementById('group_name').focus();
										setState({ ...state, form: error });
										break;
									default:
										if (state.group_id === "") {
											toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

										} else {
											toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

										}
										break;
								}
							}).catch((err) => {
								//Error出力
								toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

							});
							break;
						case 401:
							fetchHttpRefresh();
							return;
						case 409:
							dispatch(SetErrorMessage('ERROR_0039'));  
							dispatch(logout());
							return;
						default:
							toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

							break;
					}
				})
				.catch((err) => {
					toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

				});
		}
		fetchHttp();
	}
	
	//エラー確認ボタン押下
	const handleErrorConfirm = event => {
		setState({ ...state, error_flag: false });
	};

	//グループ削除ポップアップ表示
	const handleDelete = event => {
		setState({ ...state, delete_flag: true });
	};

	//削除 キャンセルボタン押下
	const handleDeleteCancel = event => {
		setState({ ...state, delete_flag: false });
	};

	// 削除処理
	const delete_group = () => {
		let delete_group_id_data  = [{}];
		setState({ ...state, delete_flag: false });
		delete_group_id_data[0] = { "group_id": state.group_id }; 
		const fetchHttpRefresh = async () => {
			let senddata = {
			};
			let url_tokref = CONST.BASEURL + '/authorize/refresh';
			var httpResponse_tokref = await fetch( url_tokref, {
				method:'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': "Bearer " + refresh_token
				},
				body: JSON.stringify(senddata),
			});
			switch (httpResponse_tokref.status) {
				case 200:
					var httpResponse_tokref_json = await httpResponse_tokref.json();
					access_token = httpResponse_tokref_json.access_token;
					refresh_token = httpResponse_tokref_json.refresh_token;

					// 問題ない場合は、storeに保存
					dispatch(setAccessToken(access_token));
					fetchHttp();
					return;				      
				default:
					dispatch(SetErrorMessage('ERROR_0001'));
					dispatch(logout());
					return;
			}
		}

		//fetchHttpRefresh();
		// グループ削除API
		var url = CONST.BASEURL + '/customers/' + storeUser.id + '/groups';
		const fetchHttp = async () => {
			fetch( url , {
				method: 'DELETE',
				headers: {
					'Content-type': 'application/json',
					Authorization: "Bearer " + access_token
				},
	            body: JSON.stringify({
	                "groups": delete_group_id_data
	            })
			})
				.then(res => {
					if (res.status === 204) {
						toast(intl.formatMessage({ id: 'SUCCESS_0003' }),{style:{background:  CONST.HOT_TOAST_SUCCESS}});
						// 問題ない場合は、/groupに移行
						history.push({pathname: '/group',state:{referrer: '/group_edit'}})
						return;
					}
					else if (res.status === 401) {
						fetchHttpRefresh();
						return;
					}
					else if (res.status === 409) {
						dispatch(SetErrorMessage('ERROR_0039'));  
						dispatch(logout());
						return; 
					}
					else {
						toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

					}
				})
			.catch((err) => {
				toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

			});
		}
		fetchHttp();
	}

	const [selectedLeftList, setSelectedLeftList] = useState([]);
	const [selectedRightList, setSelectedRightList] = useState([]);
	const handleLeftPickListChange = e => {
		let { options } = e.target;
		options = Array.apply(null, options)
		const selectedValues = options.filter(x => x.selected).map(x => x.value);
		setSelectedLeftList(selectedValues);
	}
	const handleRightPickListChange = e => {
		let { options } = e.target;
		options = Array.apply(null, options)
		const selectedValues = options.filter(x => x.selected).map(x => x.value);
		setSelectedRightList(selectedValues);
	}

	// PickList 右移動ボタン押下
	function pickToRightBtn() {
		var leftList = state.contacts_group;
		var leftList_new = [];
		var rightList = state.contacts_not_group;

		for (let i = 0; i < leftList.length; i++) {
			if ( selectedLeftList.includes(leftList[i].contact_id) )
			{
				rightList.push(leftList[i])
			
			} else {
				leftList_new.push(leftList[i])
			}
		}

		setState({ ...state, contacts_group: leftList_new, contacts_not_group: rightList, init_flag: true });
		setSelectedLeftList([]);

		document.getElementById("picklist_left").selectedIndex = -1;
	}

	// PickList 左移動ボタン押下
	function pickToLeftBtn() {
		var leftList = state.contacts_group;
		var rightList = state.contacts_not_group;
		var rightList_new = [];

		for (let i = 0; i < rightList.length; i++) {
			if ( selectedRightList.includes(rightList[i].contact_id) )
			{
				leftList.push(rightList[i])
			
			} else {
				rightList_new.push(rightList[i])
			}
		}

		setState({ ...state, contacts_group: leftList, contacts_not_group: rightList_new, init_flag: true });
		setSelectedRightList([]);
		document.getElementById("picklist_right").selectedIndex = -1;
	}

	// ダイアログ初期カーソル
	useLayoutEffect(() => {
		if (state.delete_flag == true) {
			document.getElementById('delete_cancel_btn').focus();
		}
	}, [state.delete_flag])

	// 描画
	return (
		<div class="in_contents group-edit">
			<title><FormattedMessage id="tab_title_group_edit" /></title>
			<div class="group_edit">
				<header class="in_contents_header">
					<div class="record_header">
						<h2 class="record_title"><FormattedMessage id={state.title} /></h2>
					</div>
				</header>
				<div class="group_edit_box blank_above_m">
					<div class="flex_box left_justified group_edit_control">
						<FormattedMessage id="group-initial_selectall_chk">{value => <div><input id="group_name" type="text" placeholder={value} maxlength='64' value={state.input_group_name} onChange={handleInputGroupNameChange} autoFocus/></div>}</FormattedMessage>
						<div class="search_box blank_space_button-group">
							<button tabIndex="-1" class="search_box" type="button" onClick={handleSubmit} id="sbtn2"><img src={`${process.env.PUBLIC_URL}/img/EZTP_search.svg`} alt=""/></button>
							<FormattedMessage id="group-edit_emailcontactname_txt">{value => <input id="sbox2" name="s" type="text" placeholder={value} maxlength='256' value={state.input_keyword} onChange={handleInputSearch} />}</FormattedMessage>
						</div>
					</div>													
					<div class="has-error" style={{ display: state.form[0].message !=='EMPTY' ? '' : 'none' }}><FormattedMessage id={state.form[0].message} /></div>
					<div id="list" class="blank_above_m picklist">
						<div class="select selected">
							<p></p>
							<select
								multiple
								id="picklist_left" size="15"
								onChange={handleLeftPickListChange}>
								{state.contacts_group.map((data, index) => {
									if (data.contact_id === undefined) {
										return (<option />);
									}
									return (
										<option value={data.contact_id}>{data.contact_name} {data.email}</option>
									);
								})
								}
							</select>
						</div>
						<div class="button-group">
							<input class="item_movement_btn add" type="button" id={"pickToLeftBtn"} onClick={pickToLeftBtn} />
							<input class="item_movement_btn remove blank_above_m" type="button" id={"pickToRightBtn"} onClick={pickToRightBtn} />
						</div>
						<div class="select available">
							<p></p>
							<select
								multiple
								id="picklist_right" size="15"
								onChange={handleRightPickListChange}>
								{state.contacts_not_group.map((data, index) => {
									if (data.contact_id === undefined) {
										return (<option />);
									}
									return (
										<option value={data.contact_id}>{data.contact_name} {data.email}</option>
									);
								})
								}
							</select>
						</div>

					</div>
					<div class="control_buttons flex_box blank_above_ms">
						<div class="element_width_ms"><FormattedMessage id="group-additional_save_btn">{value => <input class="buttons_common btn_blue" type="button" id={"editbtn"} value={value} onClick={handleSave} />}</FormattedMessage></div>
						<div class="element_width_ms"><Link to={{ pathname: "/group" }} class="buttons_common btn_wht"><FormattedMessage id="group-additional_cancel_btn"></FormattedMessage></Link></div>
						<div class="element_width_ms"><FormattedMessage id="group-additional_delete_btn">{value => <input class="buttons_common btn_blue" type="button" id={"deletebtn"} value={value} onClick={handleDelete} style={{ display: state.isAddMode ? 'none' : '' }} />}</FormattedMessage></div>
					</div>
					{state.delete_flag && <div id="group_delete" >
							<div>
								<nav>
									<div class="in_contents contact-edit" id="delete_confirm">
										<div id="modal_overlay"></div>
										<div id="modal_container">
											<header class="modal_header">
											<h2><FormattedMessage id="group-delete_sentence1_lbl"></FormattedMessage></h2>
											</header>
											<div class="modal_contents blank_above_m">
											<div class="flex_box right_justified blank_above_m">
												<div class="element_width_ms"><FormattedMessage id="group-edit_delete_btn">{value => <input class="buttons_common btn_blue" type="button" value={value} onClick={delete_group}></input>}</FormattedMessage></div>
												<div class="element_width_ms blank_space_m"><FormattedMessage id="group-additional_cancel_btn">{value => <input id="delete_cancel_btn" class="buttons_common btn_wht" type="button" value={value} onClick={handleDeleteCancel}></input>}</FormattedMessage></div>
												</div>
											</div>
										</div>
									</div>
								</nav>
							</div>
						</div>
					}
					{state.error_flag && <div id="group_edit_error" >
							<div>
								<nav>
									<div class="in_contents contact-edit" id="edit_confirm">
										<div id="modal_overlay"></div>
										<div id="modal_container">
											<header class="modal_header">
												<h2>項目入力エラー</h2>
											</header>
											<div class="modal_contents blank_above_m">
												<div class="inline_block element_width_xl">{state.error_message}</div> 
												<div class="inline_block element_width_xl blank_above_ms"></div>
												<div class="flex_box right_justified blank_above_m">
												<div class="element_width_ms"><input class="buttons_common btn_blue" type="button" value="OK" onClick={handleErrorConfirm} /></div>
												</div>
											</div>
										</div>
									</div>
								</nav>
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	);
}
