const LangEN = {
	'EMPTY': "",
	'signin_signin_lbl': "Sign In",
	'signin_account_txt': "Account",
	'signin_password_txt': "Password",
	'signin_forget_password_lnk': "If you forget your password",
	'signin_stay_password_chk': "Keep signed in",
	'signin_privacy_policy_lnk': "Privacy Policy",
	'signin_terms_of_use_lnk': "Terms of Service",
	'signin_signin_btn': "Sign In",
	'send-email-1_sentence1_lbl': "Did you forget your password?",
	'send-email-1_sentence2_lbl': "Don't worry. Resetting your password is easy, just tell us the account you registered with EZT.",
	'send-email-1_account_txt': "Account",
	'send-email-1_send_btn': "Send",
	'send-email-2_sentence1_lbl': "Reset the password",
	'send-email-2_sentence2_lbl': "An e-mail containing the reset password has been sent to the address registered on the EZT service. Please click the reset password link to set a new password.",
	'send-email-2_sentence3_lbl': "Please check the email app you are using. If you don't see the email, check your spam folder,",
	'send-email-2_retry_lnk': " or try again.",
	'password-reset-1_sentence2_lbl': "{customer_name}, please set a new password for login.",
	'password-reset-1_password_txt': "New password",
	'password-reset-1_sentence3_lbl': "Password must:",
	'password-reset-1_sentence4_lbl': "- Have at least 8 characters",
	'password-reset-1_sentence5_lbl': "- Have at least 1 letter (a, b, c...){br}- Have at least 1 number (1, 2, 3...){br}- Include both uppercase and lowercase characters",
	'password-reset-1_passwordchk_txt': "Confirmation of new password",
	'password-reset-1_save_btn': "Save Changes",
	'password-reset-1_sentence1_lbl': "Reset the password",
	'password-reset-2_sentence1_lbl': "Password reset completed.",
	'password-reset-2_sentence2_lbl': "{customer_name}, your password has been changed successfully.",
	'password-reset-2_intoezt_btn': "Sign In to EZT",
	'main_signout_btn': "Sign Out",
	'main_title1_lbl': "Room",
	'main_title2_lnk': "List",
	'main_title3_lnk': "Recording",
	'main_title4_lbl': "Management Information",
	'main_title5_lnk': "Location",
	'main_title6_lnk': "Contact Information",
	'main_title7_lnk': "Group",
	'main_title8_lnk': "Meeting Room",
	'main_privacy_policy_lnk': "Privacy Policy",
	'main_terms_of_use_lnk': "Terms of Service",
	'list_location_pll': "Select a location",
	'list_locationuser_txt': "Search by location name or meeting room name",
	'list_locationname_lbl': "Location Name",
	'list_username_lbl': "Meeting Room Name",
	'list_startdate_lbl': "Usage start date",
	'list_enddate_lbl': "Usage end date",
	'list_profile_btn': "Profile",
	'list_setting_btn': "Setting",
	'profile-view_title1_lbl': "Profile",
	'profile-view_title2_lbl': "Meeting",
	'profile-view_title3_lbl': "Recording",
	'profile-view_username_lbl': "Meeting Room Name",
	'profile-view_locationname_lbl': "Location Name",
	'profile-view_usage_period_lbl': "Usage Period",
	'profile-view_pmi_lbl': "Personal Meeting ID",
	'profile-view_pmi_password': "Passcode",
	'profile-view_sentence1_lbl': "Use this ID for instant meeting",
	'profile-view_language_lbl': "Language",
	'profile-view_datetime_lbl': "Date and Time",
	'profile-view_timezone_lbl': "Time Zone",
	'profile-view_calendar_lbl': "Calendar Integration",
	'profile-view_signin-password_lbl': "Sign-In Password",
	'profile-view_edit_btn': "Edit",
	'profile-view_calendar_btn': "Configure the service for calendar",
	'profile-edit_sentence3_lbl': "We support the following services: Google calendar, Microsoft Exchanger, Microsoft Office 365",
	'profile-edit_new_password1_txt': "New password",
	'profile-edit_sentence4_lbl': "Confirmation of new password",
	'profile-edit_new_password2_txt': "New password",
	'profile-edit_sentence5_lbl': "Please set the password as follows:{br}- 8 characters or more.{br}- Use at least one lowercase letter (a to z) and one uppercase letter (A to Z) and one number (0 to 9). ",
	'profile-edit_setting_password_txt': "Unlock Passcode",
	'profile-edit_host_key_txt': "Host Key",
	'profile-edit_host_key_setting_txt': "Host Key is a 6-digit number.",
	'profile-edit_save_btn': "Save Changes",
	'profile-edit_cancel_btn': "Cancel",
	'settings_title1_lbl': "Setting",
	'settings_title2_lbl': "Security",
	'settings_title3_lbl': "Schedule Meeting",
	'settings_title4_lbl': "Basic",
	'settings_title5_lbl': "Advanced",
	'settings_title6_lbl': "Recording",
	'settings_applies_btn': "Applies To All Rooms",
	'meeting_security_lbl': "Security",
	'meeting_security_sentence1_lbl': "Waiting Room",
	'meeting_security_sentence2_lbl': "When a participant attends a meeting, put the participant in the waiting room and ask the host to allow the participant to enter the meeting individually.Enabling the waiting room automatically disables the setting that allows participants to join before the host.",
	'meeting_security_sentence3_lbl': "Embed passcode in invite link for one-click join",
	'meeting_security_sentence4_lbl': "Meeting passcode will be encrypted and included in the invite link to allow participants to join with just one click without having to enter the passcode.",
	'meeting_security_sentence5_lbl': "Passcode for Personal Meeting ID",
	'meeting_security_passcode_txt': "Passcode",
	'meeting_security_edit_btn': "Edit",
	'meeting_security_save_btn': "Save Changes",
	'meeting_security_cancel_btn': "Cancel",
	'meeting_schedule_lbl': "Schedule Meeting",
	'meeting_schedule_sentence1_lbl': "Host video",
	'meeting_schedule_sentence2_lbl': "Start meetings with host video on",
	'meeting_schedule_sentence3_lbl': "Participants video",
	'meeting_schedule_sentence4_lbl': "Start meetings with participant video on. Participants can change this during the meeting.",
	'meeting_schedule_sentence9_lbl': "Enable Personal Meeting ID",
	'meeting_schedule_sentence10_lbl': "A Personal Meeting ID (PMI) is a 9 to 11 digit number that is assigned to your account. You can visit Personal Meeting Room to change your personal meeting settings.",
	'meeting_schedule_sentence11_lbl': "Use Personal Meeting ID (PMI) when scheduling a meeting",
	'meeting_schedule_sentence12_lbl': "You can visit Personal Meeting Room to change your Personal Meeting settings.",
	'meeting_schedule_sentence13_lbl': "Use Personal Meeting ID (PMI) when starting an instant meeting",
	'meeting_basic_lbl': "Basic",
	'meeting_basic_sentence1_lbl': "Chat",
	'meeting_basic_sentence2_lbl': "Allow meeting participants to send a message to all participants.",
	'meeting_basic_sentence5_lbl': "Auto saving chats",
	'meeting_basic_sentence6_lbl': "Automatically save all in-meeting chats so that hosts do not need to manually save the text of the chat after the meeting starts.",
	'meeting_basic_sentence7_lbl': "Sound notification when someone joins or leaves",
	'meeting_basic_sentence8_lbl': "File transfer",
	'meeting_basic_sentence9_lbl': "Hosts and participants can send files through the in-meeting chat.",
	'meeting_basic_sentence19_lbl': "Screen sharing",
	'meeting_basic_sentence20_lbl': "Allow host and participants to share their screen or content during meetings",
	'meeting_basic_sentence21_lbl': "Who can share?",
	'meeting_basic_sentence22_rbt': "Host Only",
	'meeting_basic_sentence23_rbt': "All Participants",
	'meeting_basic_sentence38_lbl': "Remote control",
	'meeting_basic_sentence39_lbl': "During screen sharing, the person who is sharing can allow others to control the shared content",
	'meeting_basic_sentence43_lbl': "Allow participants to rename themselves",
	'meeting_basic_sentence44_lbl': "Allow meeting participants and webinar panelists to rename themselves.",
	'meeting_detail_sentence1_lbl': "Advanced",
	'meeting_detail_sentence4_lbl': "Show a \"Join from your browser\" link",
	'meeting_detail_sentence5_lbl': "Allow participants to bypass the Zoom application download process, and join a meeting directly from their browser. This is a workaround for participants who are unable to download, install, or run applications. Note that the meeting experience from the browser is limited.",
	'meeting_detail_save_btn': "Save Changes",
	'meeting_detail_cancel_btn': "Cancel",
	'recording_sentence1_lbl': "Recording",
	'recording_sentence2_lbl': "Cloud recording",
	'recording_sentence3_lbl': "Allow hosts to record and save the meeting / webinar in the cloud",
	'recording_sentence4_chk': "Record active speaker with shared screen",
	'recording_sentence5_chk': "Record gallery view with shared screen",
	'recording_sentence10_chk': "Record an audio only file",
	'recording_sentence11_chk': "Save chat messages from the meeting / webinar",
	'recording_sentence12_lbl': "Advanced cloud recording settings",
	'recording_sentence13_chk': "Add a timestamp to the recording",
	'recording_sentence17_chk': "Audio transcript",
	'recording_save_btn': "Save Changes",
	'recording_cancel_btn': "Cancel",
	'record-initial_location_pll': "Select Location",
	'record-initial_startdate_cal': "Start Date",
	'record-initial_enddate_cal': "End Date",
	'record-initial_calendar_date': " {year} Year  {month} Month",
	'record-initial_topic_txt': "Search by Topic",
	'record-initial_search_btn': "Search",
	'record-initial_selectdelete_btn': "Delete Selected",
	'record-delete_sentence2_lbl': "The selected row will be deleted. {br} Do you want to continue?",
	'record-initial_selectall_chk': "Topic",
	'record-initial_starttime_lbl': "Start Date and Time",
	'record-initial_filecount_lbl': "Number of files",
	'record-initial_delete_deadline_lbl': "Delete Deadline",
	'record-initial_delete_daysafter_lbl': "after {after_days} days",
	'record-initial_delete_btn': "Delete",
	'record-initial_cancel_btn': "Cancel",
	'record-details_share_btn': "Share",
	'record-details_recording': "Recording",
	'record-details_sentence1_lbl': "This recording will be deleted automatically in {days} days.",
	'record-details_playtime_lbl': "Playback time { times  }",
	'record-details_filisize_lbl': " {count} File ({size})",
	'record-details_download_btn': "Download（ {count} File）",
	'record-details_shareablelink_btn': "Copy shareable link",
	'record-details_videoaudio_lbl': "Video + Audio",
	'record-details_audio_lbl': "Audio only",
	'record-details_selectdelete_btn': "Delete Recording",
	'record-details_delete_btn': "Delete",
	'record-details_cancel_btn': "Cancel",
	'record-details_clipboard_copy': "Sharable link has been copied to the clipboard.",
	'record-share_sentence1_lbl': "Share this cloud recording",
	'record-share_sentence2_lbl': "Share this recording",
	'record-share_display_btn': "Show",
	'record-share_display_non_btn': "Hide",
	'record-share_sentence4_lbl': "Topic:",
	'record-share_sentence5_lbl': "Date:",
	'record-share_sentence6_lbl': "Meeting Recording:",
	'record-share_sentence7_lbl': "Access Passcode:",
	'record-share_sentence8_lbl': "Sharing Information",
	'record-share_sentence9_lbl': "No one is allowed to access this record.",
	'record-share_sentence10_lbl': "Protect Passcode",
	'record-share_save_btn': "Save Changes",
	'record-share_cancel_btn': "Cancel",
	'record-share_clipboard_copy': "Sharable information has been copied to the clipboard.",
	'record-copysharing_btn': "Copy Sharing Information",
	'record-complete_btn': "Complete",
	'base-initial_locationuser_txt': "Search by Location Name",
	'base-initial_addlocation_btn': "Add Location",
	'base-initial_locationname_lbl': "Location Name",
	'base-initial_roomcount_lbl': "Number of rooms",
	'base-initial_selectdelete_btn': "Delete Selected",
	'base-initial_edit_btn': "Edit",
	'base-additional_sentence1_lbl': "Add Location",
	'base-additional_locationname_txt': "Location Name",
	'base-edit_sentence1_lbl': "Edit Location",
	'base-edit_locationname_txt': "Location Name",
	'base-edit_save_btn': "Save Changes",
	'base-edit_addlocation_btn': "Add",
	'base-edit_cancel_btn': "Cancel",
	'base-delete_sentence1_lbl': "Delete Location",
	'base-delete_sentence2_lbl': "The selected row will be deleted. {br} Do you want to continue?",
	'base-delete_delete_lbl': "Delete",
	'base-delete_cancel_lbl': "Cancel",
	'contact-initial_emailcontactname_txt': "Search by Mail Address, Name",
	'contact-initial_import_bll': "Import",
	'contact-initial_import_addition': "Add",
	'contact-initial_import_washing': "Reversal Entries",
	'contact-initial_export_btn': "Export",
	'contact-initial_addcontact_btn': "Add Contact",
	'contact-initial_selectdelete_btn': "Delete Selected",
	'contact-initial_selectall_chk': "Name",
	'contact-initial_email_lbl': "Mail Address",
	'contact-initial_edit_btn': "Edit",
	'contact-additional_sentence1_lbl': "Add Contact",
	'contact-additional_email_txt': "Mail Address",
	'contact-additional_contactname_txt': "Name",
	'contact-additional_addcontact_btn': "Add",
	'contact-additional_cancel_btn': "Cancel",
	'contact-edit_sentence1_lbl': "Edit Contact",
	'contact-edit_email_txt': "Mail Address",
	'contact-edit_contactname_txt': "Name",
	'contact-edit_save_btn': "Save Changes",
	'contact-edit_cancel_btn': "Cancel",
	'contact-delete_sentence1_lbl': "Delete Contact",
	'contact-delete_sentence2_lbl': "The selected row will be deleted. {br} Do you want to continue?",
	'contact-delete_delete_btn': "Delete",
	'contact-delete_cancel_btn': "Cancel",
	'contact-csv_name': "Name",
	'contact-csv_sentence': "Contacts",
	'contact-csv_washing_title': "Message",
	'contact-csv_washing_message': "Replacing the contacts will delete all the existing contacts before importing a new contacts file. All the contacts registered in the {br} group will also be deleted.{br} Do you want to continue?",
	'contact-import_ok_btn': "OK",
	'contact-import_cancel_btn': "Cancel",
	'group-initial_groupname_txt': "Search by Group Name",
	'group-initial_addgroup_btn': "Add Group",
	'group-initial_selectdelete_btn': "Delete Selected",
	'group-delete_sentence2_lbl': "The selected row will be deleted. {br} Do you want to continue?",
	'group-initial_selectall_chk': "Group Name",
	'group-initial_membercount_lbl': "Number of members",
	'group-initial_edit_btn': "Edit",
	'group-initial_delete_btn': "Delete",
	'group-initial_cancel_btn': "Cancel",
	'group-additional_sentence1_lbl': "Add Group",
	'group-additional_save_btn': "Save Changes",
	'group-additional_cancel_btn': "Cancel",
	'group-additional_delete_btn': "Delete",
	'group-edit_sentence1_lbl': "Edit Group",
	'group-edit_delete_btn': "Delete",
	'group-edit_emailcontactname_txt': "Search by Mail Address, Name",
	'group-delete_sentence1_lbl': "Delete Group",
	'expiration-date_check': "Reset the password",
	'expiration-date_message': "Expiry date is over.",
	'tab_title_signin': "Sign In",
	'tab_title_mail_send': "Send Email",
	'tab_title_send_complete': "Sent successfully.",
	'tab_title_password_reset': "Password Reset",
	'tab_title_reset_complete': "Password reset successfully.",
	'tab_title_meeting_list': "List of Meeting Rooms",
	'tab_title_profile': "Profile",
	'tab_title_meeting_setting': "Meeting Setting",
	'tab_title_record_setting': "Recording Setting",
	'tab_title_record_list': "List of Recordings",
	'tab_title_record_details': "Recording Details",
	'tab_title_location_list': "List of Locations",
	'tab_title_contact_list': "List of Contacts",
	'tab_title_group_list': "List of Groups",
	'tab_title_group_edit': "Registration of Group",
	'tab_title_meeting_room': "Meeting Room",
	'meeting-room_url_txt': "URL of Meeting Room",
	'meeting-room_copyUrl_btn': "Copy the URL",
	'meeting-room_edit_btn': "Edit",
	'meeting-room_save_btn': "Save Changes",
	'meeting-room_cancel_btn': "Cancel",
	'meeting-room_password_txt': "Password to display",
	'meeting-list-check_title_txt': "Meeting Room",
	'meeting-list-check_placeholder': "Password",
	'meeting-list-check_password_save_txt': "Do not ask password again.",
	'meeting-list-check_show_btn': "Show",
	'meeting-list_title_txt': "Meeting Room",
	'meeting-list_locationname_txt': "Location Name",
	'meeting-list_meetingRoom_txt': "Meeting Room Name",
	'meeting-list_meetingID_txt': "Meeting ID",
	'meeting-list_passcode_txt': "Passcode",
	'meeting-list_goto_meeting_btn': "Join the Meeting",
	'meeting-list_email_copy_btn': "Copy the invitation",
	'meeting-list_disabled_pmi': "PMI is not used.",
	'meeting-list_location_pll': "Select a location",
	'meeting-list_locationuser_txt': "Search by location name or meeting room name",
	'meeting-list_email_txt': "Join the meeting from EZT or Zoom.",
	'meeting-list_email_id': "Meeting ID:",
	'meeting-list_email_pwd': "Passcode:",
	'ERROR_0001': "System error",
	'ERROR_0002': "Incorrect account.",
	'ERROR_0003': "Incorrect account. Please enter your sign-in information again or request an email with information to access your account.",
	'ERROR_0004': "Please enter your account information.",
	'ERROR_0005': "Group Name must be entered.",
	'ERROR_0006': "The account cannot be found.",
	'ERROR_0008': "This Meeting Room Name is already in use.",
	'ERROR_0009': "This Location Name is already registered. Please specify another Location Name.",
	'ERROR_0010': "This Group Name is already in use.",
	'ERROR_0012': "This Personal Meeting ID is already in use.",
	'ERROR_0013': "This email address is already in use.",
	'ERROR_0014': "Failed to delete data.",
	'ERROR_0015': "Failed to register data.",
	'ERROR_0016': "Failed to update data.",
	'ERROR_0017': "Personal Meeting ID must be entered and be a 10-digit number.",
	'ERROR_0019': "Your new password does not meet the password policy requirements.",
	'ERROR_0020': "Please enter the password.",
	'ERROR_0021': "Host Key must be entered and be a 6-digit number. It cannot be in a simple format like the following example: 111111, 222222, 123456",
	'ERROR_0022': "Email address must be entered.",
	'ERROR_0023': "Meeting Room Name must be entered.",
	'ERROR_0024': "Confirm password doesn't match.",
	'ERROR_0025': "Location Name must be entered.",
	'ERROR_0026': "Name must be entered.",
	'ERROR_0027': "New password must be entered.",
	'ERROR_0028': "The new password must be different from the old password.",
	'ERROR_0029': "Unlock Passcode must be entered.",
	'ERROR_0030': "Select csv file.",
	'ERROR_0031': "Failed to export data.",
	'ERROR_0032': "Failed to import data.",
	'ERROR_0033': "There is no data to export.",
	'ERROR_0034': "This file cannot be read.",
	'ERROR_0036': "{success_cnt} of the selected items were deleted but {error_cnt} of the selected items could not be deleted.",
	'ERROR_0037': "Please enter the correct email address.",
	'ERROR_0038': "If cloud recording is enabled, you must select one or more files to record in the cloud.",
	'ERROR_0039': "Unauthorized access has been detected.",
	'ERROR_0040': "You cannot change the location because the number of rooms becomes 0.",
	'ERROR_0041': "The password is incorrect. Enter the correct password.",
	'SUCCESS_0001': "The settings have been updated.",
	'SUCCESS_0002': "Saved the user information successfully.",
	'SUCCESS_0003': "Deleted data successfully.",
	'SUCCESS_0004': "Registered data successfully.",
	'SUCCESS_0005': "Updated data successfully.",
	'SUCCESS_0006': "Exported data successfully.",
	'SUCCESS_0007': "Imported data successfully.",
	'SUCCESS_0008': "Copied the URL.",
	'SUCCESS_0009': "Copied the invitation email.",
	'TIMEZONE_0001': "(GMT-11:00) Midway Island, Samoa",
	'TIMEZONE_0002': "(GMT-11:00) Pago Pago",
	'TIMEZONE_0003': "(GMT-10:00) Hawaii",
	'TIMEZONE_0004': "(GMT-8:00) Alaska",
	'TIMEZONE_0005': "(GMT-8:00) Juneau",
	'TIMEZONE_0006': "(GMT-7:00) Vancouver",
	'TIMEZONE_0007': "(GMT-7:00) Pacific Time (US and Canada)",
	'TIMEZONE_0008': "(GMT-7:00) Tijuana",
	'TIMEZONE_0009': "(GMT-7:00) Arizona",
	'TIMEZONE_0010': "(GMT-6:00) Edmonton",
	'TIMEZONE_0011': "(GMT-6:00) Mountain Time (US and Canada)",
	'TIMEZONE_0012': "(GMT-6:00) Mazatlan",
	'TIMEZONE_0013': "(GMT-6:00) Saskatchewan",
	'TIMEZONE_0014': "(GMT-6:00) Guatemala",
	'TIMEZONE_0015': "(GMT-6:00) El Salvador",
	'TIMEZONE_0016': "(GMT-6:00) Managua",
	'TIMEZONE_0017': "(GMT-6:00) Costa Rica",
	'TIMEZONE_0018': "(GMT-6:00) Tegucigalpa",
	'TIMEZONE_0019': "(GMT-6:00) Chihuahua",
	'TIMEZONE_0020': "(GMT-5:00) Winnipeg",
	'TIMEZONE_0021': "(GMT-5:00) Central Time (US and Canada)",
	'TIMEZONE_0022': "(GMT-5:00) Mexico City",
	'TIMEZONE_0023': "(GMT-5:00) Panama",
	'TIMEZONE_0024': "(GMT-5:00) Bogota",
	'TIMEZONE_0025': "(GMT-5:00) Lima",
	'TIMEZONE_0026': "(GMT-5:00) Monterrey",
	'TIMEZONE_0027': "(GMT-4:00) Montreal",
	'TIMEZONE_0028': "(GMT-4:00) Eastern Time (US and Canada)",
	'TIMEZONE_0029': "(GMT-4:00) Indiana (East)",
	'TIMEZONE_0030': "(GMT-4:00) Puerto Rico",
	'TIMEZONE_0031': "(GMT-4:00) Caracas",
	'TIMEZONE_0032': "(GMT-4:00) Santiago",
	'TIMEZONE_0033': "(GMT-4:00) La Paz",
	'TIMEZONE_0034': "(GMT-4:00) Guyana",
	'TIMEZONE_0035': "(GMT-3:00) Halifax",
	'TIMEZONE_0036': "(GMT-3:00) Montevideo",
	'TIMEZONE_0037': "(GMT-3:00) Recife",
	'TIMEZONE_0038': "(GMT-3:00) Buenos Aires, Georgetown",
	'TIMEZONE_0039': "(GMT-3:00) Sao Paulo",
	'TIMEZONE_0040': "(GMT-3:00) Atlantic Time (Canada)",
	'TIMEZONE_0041': "(GMT-2:30) Newfoundland and Labrador",
	'TIMEZONE_0042': "(GMT-2:00) Greenland",
	'TIMEZONE_0043': "(GMT-1:00) Cape Verde Islands",
	'TIMEZONE_0044': "(GMT+0:00) Azores",
	'TIMEZONE_0045': "(GMT+0:00) Universal Time UTC",
	'TIMEZONE_0046': "(GMT+0:00) Greenwich Mean Time",
	'TIMEZONE_0047': "(GMT+0:00) Reykjavik",
	'TIMEZONE_0048': "(GMT+0:00) Nouakchott",
	'TIMEZONE_0049': "(GMT+1:00) Casablanca",
	'TIMEZONE_0050': "(GMT+1:00) Dublin",
	'TIMEZONE_0051': "(GMT+1:00) London",
	'TIMEZONE_0052': "(GMT+1:00) Lisbon",
	'TIMEZONE_0053': "(GMT+1:00) West Central Africa",
	'TIMEZONE_0054': "(GMT+1:00) Algiers",
	'TIMEZONE_0055': "(GMT+1:00) Tunis",
	'TIMEZONE_0056': "(GMT+2:00) Belgrade, Bratislava, Ljubljana",
	'TIMEZONE_0057': "(GMT+2:00) Sarajevo, Skopje, Zagreb",
	'TIMEZONE_0058': "(GMT+2:00) Oslo",
	'TIMEZONE_0059': "(GMT+2:00) Copenhagen",
	'TIMEZONE_0060': "(GMT+2:00) Brussels",
	'TIMEZONE_0061': "(GMT+2:00) Amsterdam, Berlin, Rome, Stockholm, Vienna",
	'TIMEZONE_0062': "(GMT+2:00) Amsterdam",
	'TIMEZONE_0063': "(GMT+2:00) Rome",
	'TIMEZONE_0064': "(GMT+2:00) Stockholm",
	'TIMEZONE_0065': "(GMT+2:00) Vienna",
	'TIMEZONE_0066': "(GMT+2:00) Luxembourg",
	'TIMEZONE_0067': "(GMT+2:00) Paris",
	'TIMEZONE_0068': "(GMT+2:00) Zurich",
	'TIMEZONE_0069': "(GMT+2:00) Madrid",
	'TIMEZONE_0070': "(GMT+2:00) Harare, Pretoria",
	'TIMEZONE_0071': "(GMT+2:00) Warsaw",
	'TIMEZONE_0072': "(GMT+2:00) Prague Bratislava",
	'TIMEZONE_0073': "(GMT+2:00) Budapest",
	'TIMEZONE_0074': "(GMT+2:00) Tripoli",
	'TIMEZONE_0075': "(GMT+2:00) Cairo",
	'TIMEZONE_0076': "(GMT+2:00) Johannesburg",
	'TIMEZONE_0077': "(GMT+2:00) Khartoum",
	'TIMEZONE_0078': "(GMT+3:00) Helsinki",
	'TIMEZONE_0079': "(GMT+3:00) Nairobi",
	'TIMEZONE_0080': "(GMT+3:00) Sofia",
	'TIMEZONE_0081': "(GMT+3:00) Istanbul",
	'TIMEZONE_0082': "(GMT+3:00) Athens",
	'TIMEZONE_0083': "(GMT+3:00) Bucharest",
	'TIMEZONE_0084': "(GMT+3:00) Nicosia",
	'TIMEZONE_0085': "(GMT+3:00) Beirut",
	'TIMEZONE_0086': "(GMT+3:00) Damascus",
	'TIMEZONE_0087': "(GMT+3:00) Jerusalem",
	'TIMEZONE_0088': "(GMT+3:00) Amman",
	'TIMEZONE_0089': "(GMT+3:00) Moscow",
	'TIMEZONE_0090': "(GMT+3:00) Baghdad",
	'TIMEZONE_0091': "(GMT+3:00) Kuwait",
	'TIMEZONE_0092': "(GMT+3:00) Riyadh",
	'TIMEZONE_0093': "(GMT+3:00) Bahrain",
	'TIMEZONE_0094': "(GMT+3:00) Qatar",
	'TIMEZONE_0095': "(GMT+3:00) Aden",
	'TIMEZONE_0096': "(GMT+3:00) Djibouti",
	'TIMEZONE_0097': "(GMT+3:00) Mogadishu",
	'TIMEZONE_0098': "(GMT+3:00) Kiev",
	'TIMEZONE_0099': "(GMT+3:00) Minsk",
	'TIMEZONE_0100': "(GMT+4:00) Dubai",
	'TIMEZONE_0101': "(GMT+4:00) Muscat",
	'TIMEZONE_0102': "(GMT+4:00) Baku, Tbilisi, Yerevan",
	'TIMEZONE_0103': "(GMT+4:30) Tehran",
	'TIMEZONE_0104': "(GMT+4:30) Kabul",
	'TIMEZONE_0105': "(GMT+5:00) Yekaterinburg",
	'TIMEZONE_0106': "(GMT+5:00) Islamabad, Karachi, Tashkent",
	'TIMEZONE_0107': "(GMT+5:30) India",
	'TIMEZONE_0108': "(GMT+5:30) Mumbai, Kolkata, New Delhi",
	'TIMEZONE_0109': "(GMT+5:30) Asia/Colombo",
	'TIMEZONE_0110': "(GMT+5:45) Kathmandu",
	'TIMEZONE_0111': "(GMT+6:00) Almaty",
	'TIMEZONE_0112': "(GMT+6:00) Dacca",
	'TIMEZONE_0113': "(GMT+6:00) Astana, Dhaka",
	'TIMEZONE_0114': "(GMT+6:30) Rangoon",
	'TIMEZONE_0115': "(GMT+7:00) Novosibirsk",
	'TIMEZONE_0116': "(GMT+7:00) Krasnoyarsk",
	'TIMEZONE_0117': "(GMT+7:00) Bangkok",
	'TIMEZONE_0118': "(GMT+7:00) Vietnam",
	'TIMEZONE_0119': "(GMT+7:00) Jakarta",
	'TIMEZONE_0120': "(GMT+8:00) Irkutsk, Ulaanbaatar",
	'TIMEZONE_0121': "(GMT+8:00) Beijing, Shanghai",
	'TIMEZONE_0122': "(GMT+8:00) Hong Kong SAR",
	'TIMEZONE_0123': "(GMT+8:00) Taipei",
	'TIMEZONE_0124': "(GMT+8:00) Kuala Lumpur",
	'TIMEZONE_0125': "(GMT+8:00) Singapore",
	'TIMEZONE_0126': "(GMT+8:00) Perth",
	'TIMEZONE_0127': "(GMT+9:00) Yakutsk",
	'TIMEZONE_0128': "(GMT+9:00) Seoul",
	'TIMEZONE_0129': "(GMT+9:00) Osaka, Sapporo, Tokyo",
	'TIMEZONE_0130': "(GMT+9:30) Darwin",
	'TIMEZONE_0131': "(GMT+9:30) Adelaide",
	'TIMEZONE_0132': "(GMT+10:00) Vladivostok",
	'TIMEZONE_0133': "(GMT+10:00) Guam, Port Moresby",
	'TIMEZONE_0134': "(GMT+10:00) Brisbane",
	'TIMEZONE_0135': "(GMT+10:00) Canberra, Melbourne, Sydney",
	'TIMEZONE_0136': "(GMT+10:00) Hobart",
	'TIMEZONE_0137': "(GMT+11:00) Magadan",
	'TIMEZONE_0138': "(GMT+11:00) Solomon Islands",
	'TIMEZONE_0139': "(GMT+11:00) New Caledonia",
	'TIMEZONE_0140': "(GMT+12:00) Kamchatka",
	'TIMEZONE_0141': "(GMT+12:00) Fiji Islands, Marshall Islands",
	'TIMEZONE_0142': "(GMT+12:00) Auckland, Wellington",
	'TIMEZONE_0143': "(GMT+13:00) Independent State of Samoa",
};
export default LangEN;
