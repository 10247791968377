import { useState, useEffect } from 'react';
import { getProfile, getUserSettingUpdate, setUserSettingUpdate, setUserSettingDefault } from '../../store/slicer/0503_room';
import { useSelector, useDispatch } from 'react-redux';
import * as CONST from '../../const';
import { getAccessToken, getRefreshToken, setAccessToken, logout } from '../../store/slicer/0101_session';
import toast from 'react-hot-toast';
import { FormattedMessage, useIntl } from "react-intl";
import { SetErrorMessage } from '../../store/slicer/0101_logout_message';

export default function UserRecordingBody(props) {
    const dispatch = useDispatch();

    //ミーティング
    const storeSetting = useSelector(getUserSettingUpdate);

    // プロフィール
    const storeProfile = useSelector(getProfile);

    const intl = useIntl();

    var access_token = useSelector(getAccessToken);
    var refresh_token = useSelector(getRefreshToken);
    // 初期化
    const [state, setState] = useState(() => {
        return {
            check_toggle29: storeSetting.recording.cloud_recording,

            record_speaker_view: storeSetting.recording.record_speaker_view,
            record_gallery_view: storeSetting.recording.record_gallery_view,
            record_audio_file: storeSetting.recording.record_audio_file,
            save_chat_text: storeSetting.recording.save_chat_text,
            show_timestamp: storeSetting.recording.show_timestamp,
            recording_audio_transcript: storeSetting.recording.recording_audio_transcript,

            record_speaker_view_tmp: storeSetting.recording.record_speaker_view,
            record_gallery_view_tmp: storeSetting.recording.record_gallery_view,
            record_audio_file_tmp: storeSetting.recording.record_audio_file,
            save_chat_text_tmp: storeSetting.recording.save_chat_text,
            show_timestamp_tmp: storeSetting.recording.show_timestamp,
            recording_audio_transcript_tmp: storeSetting.recording.recording_audio_transcript,
            form: [
                { message: 'EMPTY' },
            ]

        }
    });

    // onClickイベントハンドラ
    const handleOnClick = event => {

        //チェック状態保存
        var check_tmp = !state.check_toggle29;
        setState({ ...state, check_toggle29: check_tmp });

        //更新データ
        const senddata = {
            "recording": {
                "cloud_recording": check_tmp
            }
        }

        const fetchHttpRefresh2 = async () => {
            let senddata = {
            };
            let url_tokref = CONST.BASEURL + '/authorize/refresh';
            var httpResponse_tokref = await fetch(url_tokref, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + refresh_token
                },
                body: JSON.stringify(senddata),
            });
            switch (httpResponse_tokref.status) {
                case 200:
                    var httpResponse_tokref_json = await httpResponse_tokref.json();
                    access_token = httpResponse_tokref_json.access_token;
                    refresh_token = httpResponse_tokref_json.refresh_token;

                    // 問題ない場合は、storeに保存
                    dispatch(setAccessToken(access_token));
                    fetchHttp2();
                    return;                       
                default:
                    dispatch(SetErrorMessage('ERROR_0001'));
                    dispatch(logout());
                    return;
            }
        }

        const fetchHttpRefresh = async () => {
            let senddata = {
            };
            let url_tokref = CONST.BASEURL + '/authorize/refresh';
            var httpResponse_tokref = await fetch( url_tokref, {
                method:'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + refresh_token
                },
                body: JSON.stringify(senddata),
            });
            switch (httpResponse_tokref.status) {
                case 200:
                    var httpResponse_tokref_json = await httpResponse_tokref.json();
                    access_token = httpResponse_tokref_json.access_token;
                    refresh_token = httpResponse_tokref_json.refresh_token;
                    // トークンをデコードする
                    //const decode_token = jwt(access_token);
                    // 問題ない場合は、storeに保存
                    dispatch(setAccessToken(access_token));
                    //dispatch(setRefreshToken(refresh_token));
                    fetchHttp();
                    return;                      
                default:
                    dispatch(SetErrorMessage('ERROR_0001'));
                    dispatch(logout());
                    return;
            }
        }


        // ユーザー設定取得
        const fetchHttp2 = async () => {
            fetch(CONST.BASEURL + '/users/' + storeProfile.user_id + '/settings', {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + access_token
                },
            })
            .then(response => {
                switch (response.status) {
                    case 200:
                        response.json().then(data => ({
                            data: data,
                            status: response.status
                        })
                        ).then(res => {
                            // ユーザー設定の項目をセット
                            dispatch(setUserSettingDefault(res.data));
                            dispatch(setUserSettingUpdate(res.data));   

                            let error = [...state.form];
                            error[0]['message'] = 'EMPTY';
                            setState({ ...state, form: error });
                            return;
                        }).catch((err) => {
                            //Error出力
                            toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                        });
                        break;
                    case 401:
                        fetchHttpRefresh2();
                        return;
                    case 409:
                        dispatch(SetErrorMessage('ERROR_0039'));  
                        dispatch(logout());
                        return; 

                    default:
                        response.json().then(data => ({
                            data: data,
                            status: response.status
                        })
                        ).then(res => {
                            toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                        }).catch((err) => {
                            //Error出力
                            toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                        });
                        break;
                }
            })
            .catch((err) => {
                toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

            });
        }
        //fetchHttp2();


        //fetchHttpRefresh();
        //更新処理
        const fetchHttp = async () => {
            fetch(CONST.BASEURL + '/users/' + storeProfile.user_id + '/settings', {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: "Bearer " + access_token
                },
                body: JSON.stringify(senddata),
            })
            .then(response => {
                switch (response.status) {
                    case 204:
                        //成功通知
                        toast(intl.formatMessage({ id: 'SUCCESS_0001' }),{style:{background:  CONST.HOT_TOAST_SUCCESS}});                        

                        var tmp = { ...storeSetting, recording: { ...storeSetting.recording, cloud_recording: check_tmp } };
                        dispatch(setUserSettingUpdate(tmp));
                        fetchHttp2();
                        
                        break;
                    case 401:
                        fetchHttpRefresh();
                        return;
                    case 409:
                        dispatch(SetErrorMessage('ERROR_0039'));  
                        dispatch(logout());
                        return; 
                        
                    default:
                        response.json().then(data => ({
                            data: data,
                            status: response.status
                        })
                        ).then(res => {
                            //Error出力
                            toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});


                            //チェック状態を戻す
                            setState({ ...state, check_toggle29: !check_tmp });

                            

                        }).catch((err) => {
                            //Error出力
                            toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                        });
                }
            })
            .catch((err) => {
                //Error出力
                toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});


                //チェック状態を戻す
                setState({ ...state, check_toggle29: !check_tmp });              
            });
        }
        fetchHttp();









    }

    //追加設定項目表示
    function display_cloud() {
        if (state.check_toggle29) {
            return (
                <div>
                    <p>
                        <label class="ckbox_label">
                            <input class="vertical_center" type='checkbox' id='record_speaker_view_tmp' checked={state.record_speaker_view_tmp} onChange={handle_checkbox} />
                            <FormattedMessage id="recording_sentence4_chk" />
                        </label>
                    </p>
                    <p>
                        <label class="ckbox_label">
                        <input class="vertical_center" type='checkbox' id='record_gallery_view_tmp' checked={state.record_gallery_view_tmp} onChange={handle_checkbox} />
                        <FormattedMessage id="recording_sentence5_chk" /></label></p>

                    <p><label class="ckbox_label">
                        <input class="vertical_center" type='checkbox' id='record_audio_file_tmp' checked={state.record_audio_file_tmp} onChange={handle_checkbox} />
                        <FormattedMessage id="recording_sentence10_chk" /></label></p>
                    <p><label class="ckbox_label">
                        <input class="vertical_center" type='checkbox' id='save_chat_text_tmp' checked={state.save_chat_text_tmp} onChange={handle_checkbox} />
                        <FormattedMessage id="recording_sentence11_chk" /></label></p>
                    <br />
                    <div class="flex_box">
                        <p class="save_itme_name"><FormattedMessage id="recording_sentence12_lbl" /></p>
                    </div>

                    <p>
                        <label class="ckbox_label">
                            <input class="vertical_center" type='checkbox' id='show_timestamp_tmp' checked={state.show_timestamp_tmp} onChange={handle_checkbox} />
                            <FormattedMessage id="recording_sentence13_chk" />
                        </label>
                    </p>
                    <p>
                        <label class="ckbox_label">
                            <input class="vertical_center" type='checkbox' id='recording_audio_transcript_tmp' checked={state.recording_audio_transcript_tmp} onChange={handle_checkbox} />
                            <FormattedMessage id="recording_sentence17_chk" />
                            <td></td>
                            <div class="has-error" style={{ display: state.form[0].message !=='EMPTY' ? '' : 'none' }}><FormattedMessage id={state.form[0].message} /></div>                    
                        </label>                        
                    </p>                    
                    <tr>
                        {checkbox_function()}
                    </tr>                    
                </div>
            );
        } else {

        }
    }

    //保存 表示切替
    function checkbox_function() {
        if ((state.record_speaker_view !==state.record_speaker_view_tmp) || (state.record_gallery_view !==state.record_gallery_view_tmp) ||
            (state.record_audio_file !==state.record_audio_file_tmp) || (state.save_chat_text !==state.save_chat_text_tmp) ||
            (state.show_timestamp !==state.show_timestamp_tmp) || (state.recording_audio_transcript !==state.recording_audio_transcript_tmp)
        ) {

            return (
                <div class="save_and_cancel delete_invisible_space">
                    <br />
                    <div class="inline_block element_width_ms">
                        <FormattedMessage id="recording_save_btn">
                            {value => <input class="buttons_common btn_blue" type="submit" value={value} data-type="submit" onClick={handle_Click} disabled={checkbox_init()} />}
                        </FormattedMessage>
                        </div>
                    <div class="inline_block element_width_ms blank_space_m"><button class="buttons_common btn_wht" data-type="cancel" onClick={handle_Click}>
                        <FormattedMessage id="recording_cancel_btn" /></button></div>
                </div>
            );

        } else {
            return;
        }
    }

    //保存・キャンセル押下
    const handle_Click = event => {
        event.preventDefault(false);
        const type = event.target.getAttribute('data-type');
        

        switch (type) {
            case "cancel":

                setState({
                    ...state,
                    record_speaker_view_tmp: state.record_speaker_view,
                    record_gallery_view_tmp: state.record_gallery_view,
                    record_audio_file_tmp: state.record_audio_file,
                    save_chat_text_tmp: state.save_chat_text,
                    show_timestamp_tmp: state.show_timestamp,
                    recording_audio_transcript_tmp: state.recording_audio_transcript,
                });
                break;

            case "submit":

                //更新データ
                const senddata = {
                    ...storeSetting, recording: {
                        ...storeSetting.recording,
                        record_speaker_view: state.record_speaker_view_tmp,
                        record_gallery_view: state.record_gallery_view_tmp,
                        record_audio_file: state.record_audio_file_tmp,
                        save_chat_text: state.save_chat_text_tmp,
                        show_timestamp: state.show_timestamp_tmp,
                        recording_audio_transcript: state.recording_audio_transcript_tmp
                    }
                };

                //更新データ
                const zoom_senddata = {
                    "recording": {
                        "record_speaker_view": state.record_speaker_view_tmp,
                        "record_gallery_view": state.record_gallery_view_tmp,
                        "record_audio_file": state.record_audio_file_tmp,
                        "save_chat_text": state.save_chat_text_tmp,
                        "show_timestamp": state.show_timestamp_tmp,
                        "recording_audio_transcript": state.recording_audio_transcript_tmp
                    }
                }

                const fetchHttpRefresh = async () => {
                    let senddata = {
                    };
                    let url_tokref = CONST.BASEURL + '/authorize/refresh';
                    var httpResponse_tokref = await fetch( url_tokref, {
                        method:'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + refresh_token
                        },
                        body: JSON.stringify(senddata),
                    });
                    switch (httpResponse_tokref.status) {
                        case 200:
                            var httpResponse_tokref_json = await httpResponse_tokref.json();
                            access_token = httpResponse_tokref_json.access_token;
                            refresh_token = httpResponse_tokref_json.refresh_token;
                            // トークンをデコードする
                            //const decode_token = jwt(access_token);
                            // 問題ない場合は、storeに保存
                            dispatch(setAccessToken(access_token));
                            //dispatch(setRefreshToken(refresh_token));
                            fetchHttp();
                            return;
                        case 409:
                            dispatch(logout());
                            return;    
                        default:
                            return;
                    }
                }
                //fetchHttpRefresh();
                //更新処理
                const fetchHttp = async () => {
                fetch(CONST.BASEURL + '/users/' + storeProfile.user_id + '/settings', {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: "Bearer " + access_token
                    },
                    body: JSON.stringify(zoom_senddata),
                })
                    .then(response => {
                        switch (response.status) {
                            case 204:
                                //成功通知が必要であれば
                                toast(intl.formatMessage({ id: 'SUCCESS_0005' }),{style:{background:  CONST.HOT_TOAST_SUCCESS}});
                                
                                //保存
                                dispatch(setUserSettingUpdate(senddata));
                                setState({
                                    ...state,
                                    record_speaker_view: state.record_speaker_view_tmp,
                                    record_gallery_view: state.record_gallery_view_tmp,
                                    record_audio_file: state.record_audio_file_tmp,
                                    save_chat_text: state.save_chat_text_tmp,
                                    show_timestamp: state.show_timestamp_tmp,
                                    recording_audio_transcript: state.recording_audio_transcript_tmp,
                                });                                

                                break;
                            case 401:
                                fetchHttpRefresh();
                                return
                            case 409:
                                dispatch(SetErrorMessage('ERROR_0039'));
                                dispatch(logout());
                                return;                                 
                            default:
                                response.json().then(data => ({
                                    data: data,
                                    status: response.status
                                })
                                ).then(res => {

                                    //Error出力
                                    toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                                }).catch((err) => {
                                    //Error出力
                                    toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                                });
                        }
                    })
                    .catch((err) => {
                        toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                    });
                }
                fetchHttp();
                
                break;
        }
    }

    //チェックボタン押下
    const handle_checkbox = event => {

        //保存
        var tmp = event.target.id;
        let check_data = { ...state, [tmp]: event.target.checked };

        //クラウド記録にチェックが入っていなければエラーメッセージ表示
        let error = [...state.form];

        if (check_data.record_speaker_view_tmp === false && check_data.record_gallery_view_tmp === false && check_data.record_audio_file_tmp === false && check_data.save_chat_text_tmp === false) {
            error[0]['message'] = 'ERROR_0038';
            setState({ ...state, form: error, [event.target.id]: event.target.checked, });
        } else {
            error[0]['message'] = 'EMPTY';           
            setState({ ...state, form: error, [event.target.id]: event.target.checked, });            
        }
    }

    //クラウド記録にチェックが入っていなければ保存ボタンは押下不可
    function checkbox_init() {

        if (state.record_speaker_view_tmp === false && state.record_gallery_view_tmp === false && state.record_audio_file_tmp === false && state.save_chat_text_tmp === false) {
            return true;
        } else {
            return false;
        }
    }

    // サイドエフェクト
    useEffect(() => {
        if ((state.check_toggle29 !==storeSetting.recording.cloud_recording) || (state.record_speaker_view !==storeSetting.recording.record_speaker_view) || (state.record_gallery_view !==storeSetting.recording.record_gallery_view)
            || (state.record_audio_file !==storeSetting.recording.record_audio_file) || (state.save_chat_text !==storeSetting.recording.save_chat_text) || (state.show_timestamp !==storeSetting.recording.show_timestamp)
            || (state.recording_audio_transcript !==storeSetting.recording.recording_audio_transcript)) {
            setState({
                ...state, check_toggle29: storeSetting.recording.cloud_recording,
                record_speaker_view: storeSetting.recording.record_speaker_view,
                record_gallery_view: storeSetting.recording.record_gallery_view,
                record_audio_file: storeSetting.recording.record_audio_file,
                save_chat_text: storeSetting.recording.save_chat_text,
                show_timestamp: storeSetting.recording.show_timestamp,
                recording_audio_transcript: storeSetting.recording.recording_audio_transcript,

                record_speaker_view_tmp: storeSetting.recording.record_speaker_view,
                record_gallery_view_tmp: storeSetting.recording.record_gallery_view,
                record_audio_file_tmp: storeSetting.recording.record_audio_file,
                save_chat_text_tmp: storeSetting.recording.save_chat_text,
                show_timestamp_tmp: storeSetting.recording.show_timestamp,
                recording_audio_transcript_tmp: storeSetting.recording.recording_audio_transcript
            });
        }
    }, [storeSetting.recording.cloud_recording, storeSetting.recording.record_speaker_view, storeSetting.recording.record_gallery_view, storeSetting.recording.record_audio_file, storeSetting.recording.save_chat_text,
        storeSetting.recording.show_timestamp, storeSetting.recording.recording_audio_transcript,]);

    // チェック押下
    const handleChecktClick = event => {
        document.getElementById("toggle29").click();
    }

    // レンダリング
    return (
        <tbody class="lump">
            <tr>
                <td>
                    <div class="flex_box">
                        <p class="save_itme_name"><FormattedMessage id="recording_sentence2_lbl" /></p>
                        <div class="toggle-switch">
                            <input tabindex="-1" id="toggle29" class="toggle-input" type='checkbox' checked={state.check_toggle29} onChange={handleOnClick} />
                            <a tabindex="0" onClick={handleChecktClick} href="javascript:void(0);" class="toggle-label" />
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <p><FormattedMessage id="recording_sentence3_lbl" /></p>
                    {display_cloud()}
                </td>
            </tr>            
        </tbody>
    );
}
