// 定数定義
export const SCROLL_MARGIN = 150;
//API接続先
export const BASEURL = 'https://api.elmo-ezt.com/api/v1';
//プライバシーポリシー
export const PRIVACY_POLICY_URL = 'https://elmo-play.com/privacy.html';
//利用規約
export const TERMS_OF_USE_URL = 'https://elmo-play.com/terms.html';

export const TIMEZONE_VALUE = [
    "Pacific/Midway",
    "Pacific/Pago_Pago",
    "Pacific/Honolulu",
    "America/Anchorage",
    "America/Juneau",
    "America/Vancouver",
    "America/Los_Angeles",
    "America/Tijuana",
    "America/Phoenix",
    "America/Edmonton",
    "America/Denver",
    "America/Mazatlan",
    "America/Regina",
    "America/Guatemala",
    "America/El_Salvador",
    "America/Managua",
    "America/Costa_Rica",
    "America/Tegucigalpa",
    "America/Chihuahua",
    "America/Winnipeg",
    "America/Chicago",
    "America/Mexico_City",
    "America/Panama",
    "America/Bogota",
    "America/Lima",
    "America/Monterrey",
    "America/Montreal",
    "America/New_York",
    "America/Indianapolis",
    "America/Puerto_Rico",
    "America/Caracas",
    "America/Santiago",
    "America/La_Paz",
    "America/Guyana",
    "America/Halifax",
    "America/Montevideo",
    "America/Araguaina",
    "America/Argentina/Buenos_Aires",
    "America/Sao_Paulo",
    "Canada/Atlantic",
    "America/St_Johns",
    "America/Godthab",
    "Atlantic/Cape_Verde",
    "Atlantic/Azores",
    "UTC",
    "Etc/Greenwich",
    "Atlantic/Reykjavik",
    "Africa/Nouakchott",
    "Africa/Casablanca",
    "Europe/Dublin",
    "Europe/London",
    "Europe/Lisbon",
    "Africa/Bangui",
    "Africa/Algiers",
    "Africa/Tunis",
    "Europe/Belgrade",
    "CET",
    "Europe/Oslo",
    "Europe/Copenhagen",
    "Europe/Brussels",
    "Europe/Berlin",
    "Europe/Amsterdam",
    "Europe/Rome",
    "Europe/Stockholm",
    "Europe/Vienna",
    "Europe/Luxembourg",
    "Europe/Paris",
    "Europe/Zurich",
    "Europe/Madrid",
    "Africa/Harare",
    "Europe/Warsaw",
    "Europe/Prague",
    "Europe/Budapest",
    "Africa/Tripoli",
    "Africa/Cairo",
    "Africa/Johannesburg",
    "Africa/Khartoum",
    "Europe/Helsinki",
    "Africa/Nairobi",
    "Europe/Sofia",
    "Europe/Istanbul",
    "Europe/Athens",
    "Europe/Bucharest",
    "Asia/Nicosia",
    "Asia/Beirut",
    "Asia/Damascus",
    "Asia/Jerusalem",
    "Asia/Amman",
    "Europe/Moscow",
    "Asia/Baghdad",
    "Asia/Kuwait",
    "Asia/Riyadh",
    "Asia/Bahrain",
    "Asia/Qatar",
    "Asia/Aden",
    "Africa/Djibouti",
    "Africa/Mogadishu",
    "Europe/Kiev",
    "Europe/Minsk",
    "Asia/Dubai",
    "Asia/Muscat",
    "Asia/Baku",
    "Asia/Tehran",
    "Asia/Kabul",
    "Asia/Yekaterinburg",
    "Asia/Tashkent",
    "Asia/Calcutta",
    "Asia/Kolkata",
    "Asia/Colombo",
    "Asia/Kathmandu",
    "Asia/Almaty",
    "Asia/Dacca",
    "Asia/Dhaka",
    "Asia/Rangoon",
    "Asia/Novosibirsk",
    "Asia/Krasnoyarsk",
    "Asia/Bangkok",
    "Asia/Saigon",
    "Asia/Jakarta",
    "Asia/Irkutsk",
    "Asia/Shanghai",
    "Asia/Hong_Kong",
    "Asia/Taipei",
    "Asia/Kuala_Lumpur",
    "Asia/Singapore",
    "Australia/Perth",
    "Asia/Yakutsk",
    "Asia/Seoul",
    "Asia/Tokyo",
    "Australia/Darwin",
    "Australia/Adelaide",
    "Asia/Vladivostok",
    "Pacific/Port_Moresby",
    "Australia/Brisbane",
    "Australia/Sydney",
    "Australia/Hobart",
    "Asia/Magadan",
    "SST",
    "Pacific/Noumea",
    "Asia/Kamchatka",
    "Pacific/Fiji",
    "Pacific/Auckland",
    "Pacific/Apia"
];

//トースト背景色
export const HOT_TOAST_SUCCESS = '#CCE6FE';
export const HOT_TOAST_ERROR = '#FEB4C0';

//ユーザー一覧取得設定値
export const TARGET_USERS_ALL = 0;
export const TARGET_USERS_ALIVE = 1;
