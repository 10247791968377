import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setSideMenu, getSideMenu } from '../../store/slicer/0501_menu';
import { clearProfile } from '../../store/slicer/0503_room';
import { clearPage } from '../../store/slicer/0502_page';
import { FormattedMessage } from "react-intl";
import * as CONST from '../../const';

export default function Sidemenu(props) {
	const dispatch = useDispatch();
	const storeMenu = useSelector(getSideMenu);

	// onClickイベントハンドラ
	const handleOnClick = useCallback(event => {
		const num = event.target.getAttribute('data-pos');
		dispatch(clearProfile());
		dispatch(clearPage());
		dispatch(setSideMenu(num));
	}, []);


	// 描画
	switch (storeMenu) {
	case 0:
		return (
			<nav class="sidebar">
				<p class="category_parents text_hidden"><FormattedMessage id="main_title1_lbl" /></p>
				<ul class="text_hidden">
					<li><Link onClick={handleOnClick} data-pos="0" to="/room" class="current"><FormattedMessage id="main_title2_lnk" /></Link></li>
					<li><Link onClick={handleOnClick} data-pos="1" to="/recording"><FormattedMessage id="main_title3_lnk" /></Link></li>
				</ul>
				<p class="category_parents text_hidden"><FormattedMessage id="main_title4_lbl" /></p>
				<ul class="text_hidden">
					<li><Link onClick={handleOnClick} data-pos="2" to="/location"><FormattedMessage id="main_title5_lnk" /></Link></li>
					<li><Link onClick={handleOnClick} data-pos="3" to="/contact"><FormattedMessage id="main_title6_lnk" /></Link></li>
					<li><Link onClick={handleOnClick} data-pos="4" to="/group"><FormattedMessage id="main_title7_lnk" /></Link></li>
					<li><Link onClick={handleOnClick} data-pos="5" to="/meetting_room"><FormattedMessage id="main_title8_lnk" /></Link></li>
				</ul>
				<p class="category_parents text_hidden policy_link_side"><a class="side_link_policy" href={CONST.PRIVACY_POLICY_URL} target="_blank"><FormattedMessage id="main_privacy_policy_lnk" /></a></p>
				<p class="category_parents text_hidden terms_link_side"><a class="side_link_terms" href={CONST.TERMS_OF_USE_URL} target="_blank"><FormattedMessage id="main_terms_of_use_lnk" /></a></p>
			</nav>
		);
		

	case 1:
		return (
			<nav class="sidebar">
				<p class="category_parents text_hidden"><FormattedMessage id="main_title1_lbl" /></p>
				<ul class="text_hidden">
					<li><Link onClick={handleOnClick} data-pos="0" to="/room"><FormattedMessage id="main_title2_lnk" /></Link></li>
					<li><Link onClick={handleOnClick} data-pos="1" class="current" to="/recording"><FormattedMessage id="main_title3_lnk" /></Link></li>
				</ul>
				<p class="category_parents text_hidden"><FormattedMessage id="main_title4_lbl" /></p>
				<ul class="text_hidden">
					<li><Link onClick={handleOnClick} data-pos="2" to="/location"><FormattedMessage id="main_title5_lnk" /></Link></li>
					<li><Link onClick={handleOnClick} data-pos="3" to="/contact"><FormattedMessage id="main_title6_lnk" /></Link></li>
					<li><Link onClick={handleOnClick} data-pos="4" to="/group"><FormattedMessage id="main_title7_lnk" /></Link></li>
					<li><Link onClick={handleOnClick} data-pos="5" to="/meetting_room"><FormattedMessage id="main_title8_lnk" /></Link></li>
				</ul>
				<p class="category_parents text_hidden policy_link_side"><a class="side_link_policy" href={CONST.PRIVACY_POLICY_URL} target="_blank"><FormattedMessage id="main_privacy_policy_lnk" /></a></p>
				<p class="category_parents text_hidden terms_link_side"><a class="side_link_terms" href={CONST.TERMS_OF_USE_URL} target="_blank"><FormattedMessage id="main_terms_of_use_lnk" /></a></p>
			</nav>
		);
		

	case 2:
		return (
			<nav class="sidebar">
				<p class="category_parents text_hidden"><FormattedMessage id="main_title1_lbl" /></p>
				<ul class="text_hidden">
					<li><Link onClick={handleOnClick} data-pos="0" to="/room"><FormattedMessage id="main_title2_lnk" /></Link></li>
					<li><Link onClick={handleOnClick} data-pos="1" to="/recording"><FormattedMessage id="main_title3_lnk" /></Link></li>
				</ul>
				<p class="category_parents text_hidden"><FormattedMessage id="main_title4_lbl" /></p>
				<ul class="text_hidden">
					<li><Link onClick={handleOnClick} data-pos="2" class="current" to="/location"><FormattedMessage id="main_title5_lnk" /></Link></li>
					<li><Link onClick={handleOnClick} data-pos="3" to="/contact"><FormattedMessage id="main_title6_lnk" /></Link></li>
					<li><Link onClick={handleOnClick} data-pos="4" to="/group"><FormattedMessage id="main_title7_lnk" /></Link></li>
					<li><Link onClick={handleOnClick} data-pos="5" to="/meetting_room"><FormattedMessage id="main_title8_lnk" /></Link></li>
				</ul>
				<p class="category_parents text_hidden policy_link_side"><a class="side_link_policy" href={CONST.PRIVACY_POLICY_URL} target="_blank"><FormattedMessage id="main_privacy_policy_lnk" /></a></p>
				<p class="category_parents text_hidden terms_link_side"><a class="side_link_terms" href={CONST.TERMS_OF_USE_URL} target="_blank"><FormattedMessage id="main_terms_of_use_lnk" /></a></p>
			</nav>
		);
		

	case 3:
		return (
			<nav class="sidebar">
				<p class="category_parents text_hidden"><FormattedMessage id="main_title1_lbl" /></p>
				<ul class="text_hidden">
					<li><Link onClick={handleOnClick} data-pos="0" to="/room"><FormattedMessage id="main_title2_lnk" /></Link></li>
					<li><Link onClick={handleOnClick} data-pos="1" to="/recording"><FormattedMessage id="main_title3_lnk" /></Link></li>
				</ul>
				<p class="category_parents text_hidden"><FormattedMessage id="main_title4_lbl" /></p>
				<ul class="text_hidden">
					<li><Link onClick={handleOnClick} data-pos="2" to="/location"><FormattedMessage id="main_title5_lnk" /></Link></li>
					<li><Link onClick={handleOnClick} data-pos="3" class="current" to="/contact"><FormattedMessage id="main_title6_lnk" /></Link></li>
					<li><Link onClick={handleOnClick} data-pos="4" to="/group"><FormattedMessage id="main_title7_lnk" /></Link></li>
					<li><Link onClick={handleOnClick} data-pos="5" to="/meetting_room"><FormattedMessage id="main_title8_lnk" /></Link></li>
				</ul>
				<p class="category_parents text_hidden policy_link_side"><a class="side_link_policy" href={CONST.PRIVACY_POLICY_URL} target="_blank"><FormattedMessage id="main_privacy_policy_lnk" /></a></p>
				<p class="category_parents text_hidden terms_link_side"><a class="side_link_terms" href={CONST.TERMS_OF_USE_URL} target="_blank"><FormattedMessage id="main_terms_of_use_lnk" /></a></p>
			</nav>
		);


	case 4:
		return (
			<nav class="sidebar">
				<p class="category_parents text_hidden"><FormattedMessage id="main_title1_lbl" /></p>
				<ul class="text_hidden">
					<li><Link onClick={handleOnClick} data-pos="0" to="/room"><FormattedMessage id="main_title2_lnk" /></Link></li>
					<li><Link onClick={handleOnClick} data-pos="1" to="/recording"><FormattedMessage id="main_title3_lnk" /></Link></li>
				</ul>
				<p class="category_parents text_hidden"><FormattedMessage id="main_title4_lbl" /></p>
				<ul class="text_hidden">
					<li><Link onClick={handleOnClick} data-pos="2" to="/location"><FormattedMessage id="main_title5_lnk" /></Link></li>
					<li><Link onClick={handleOnClick} data-pos="3" to="/contact"><FormattedMessage id="main_title6_lnk" /></Link></li>
					<li><Link onClick={handleOnClick} data-pos="4" class="current" to="/group"><FormattedMessage id="main_title7_lnk" /></Link></li>
					<li><Link onClick={handleOnClick} data-pos="5" to="/meetting_room"><FormattedMessage id="main_title8_lnk" /></Link></li>
				</ul>
				<p class="category_parents text_hidden policy_link_side"><a class="side_link_policy" href={CONST.PRIVACY_POLICY_URL} target="_blank"><FormattedMessage id="main_privacy_policy_lnk" /></a></p>
				<p class="category_parents text_hidden terms_link_side"><a class="side_link_terms" href={CONST.TERMS_OF_USE_URL} target="_blank"><FormattedMessage id="main_terms_of_use_lnk" /></a></p>
			</nav>
		);

		case 5:
			return (
				<nav class="sidebar">
					<p class="category_parents text_hidden"><FormattedMessage id="main_title1_lbl" /></p>
					<ul class="text_hidden">
						<li><Link onClick={handleOnClick} data-pos="0" to="/room"><FormattedMessage id="main_title2_lnk" /></Link></li>
						<li><Link onClick={handleOnClick} data-pos="1" to="/recording"><FormattedMessage id="main_title3_lnk" /></Link></li>
					</ul>
					<p class="category_parents text_hidden"><FormattedMessage id="main_title4_lbl" /></p>
					<ul class="text_hidden">
						<li><Link onClick={handleOnClick} data-pos="2" to="/location"><FormattedMessage id="main_title5_lnk" /></Link></li>
						<li><Link onClick={handleOnClick} data-pos="3" to="/contact"><FormattedMessage id="main_title6_lnk" /></Link></li>
						<li><Link onClick={handleOnClick} data-pos="4" to="/group"><FormattedMessage id="main_title7_lnk" /></Link></li>
						<li><Link onClick={handleOnClick} data-pos="5" to="/meetting_room" class="current"><FormattedMessage id="main_title8_lnk" /></Link></li>
					</ul>
					<p class="category_parents text_hidden policy_link_side"><a class="side_link_policy" href={CONST.PRIVACY_POLICY_URL} target="_blank"><FormattedMessage id="main_privacy_policy_lnk" /></a></p>
					<p class="category_parents text_hidden terms_link_side"><a class="side_link_terms" href={CONST.TERMS_OF_USE_URL} target="_blank"><FormattedMessage id="main_terms_of_use_lnk" /></a></p>
				</nav>
			);	
		

	default:
		return (<div></div>);
	
	}
}
