import { createSlice } from '@reduxjs/toolkit';


// Redux Toolkitを使用すると、reducersで「変更」ロジックを記述できます。 
// 「draft state」への変更を検出しそれらの変更に基づいて
// まったく新しい不変の状態を生成するImmerライブラリを使用するため
// 実際には状態を変更しません。
export const logout_messageSlice = createSlice({
	name: 'logout_message',
	initialState: {
		init_messasage_id: ''
	},
	reducers: {
		SetErrorMessage: (state, action) => {
			state.init_messasage_id = action.payload;
		},
	},
});

export const { SetErrorMessage } = logout_messageSlice.actions;


// サンク：非同期ロジックの実行
// dispatch(incrementByAmount(amount))
// 最初の引数として dispatch関数を使用してサンクが呼び出されます。
// その後、非同期コードを実行し、他のアクションをディスパッチできます
/*
export const incrementAsync = amount => dispatch => {
  setTimeout(() => {
	dispatch(incrementByAmount(amount));
  }, 1000);
};
*/

// セレクター：state から値を取得する
// useSelector((state) => state.counter.value) のようにインラインで定義もできる

export const GetErrorMessage = state => state.logout_message.init_messasage_id;


// レデューサーをエクスポートする
export default logout_messageSlice.reducer;

