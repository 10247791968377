import { createSlice } from '@reduxjs/toolkit';


// Redux Toolkitを使用すると、reducersで「変更」ロジックを記述できます。 
// 「draft state」への変更を検出しそれらの変更に基づいて
// まったく新しい不変の状態を生成するImmerライブラリを使用するため
// 実際には状態を変更しません。
export const roomSlice = createSlice({
	name: 'room',
	initialState: {
		list: '',
		text: '',
		profile: {
			user_id: '',
			user_name: '',
			location_name: '',
			account: '',
			use_start_date: '',
			use_end_date: '',
			profile: {
				customer_id: '',
				user_id: '',
				user_name: '',
				location_id: '',
				location_name: '',
				type: '',
				timezone: '',
				language: '',
				host_key: '',
				personal_meeting_url: '',
				pmi: '',
				use_pmi: '',
				setting_password: '',
				vanity_url:'',
			},
			setting: {
				in_meeting: {
					waiting_room: false,
					chat: false,
					auto_saving_chat: false,
					auto_saving_chat: false,
					entry_exit_chime: 'none',
					file_transfer: false,
					screen_sharing: false,
					who_can_share_screen: 'host',
					remote_control: false,
					allow_participants_to_rename: false,
					show_a_join_from_your_browser_link: false
				}, 
				schedule_meeting: {
					embed_password_in_join_link: false,
					host_video: false,
					participants_video: false,
					personal_meeting: false,
					use_pmi_for_scheduled_meetings: false,
					use_pmi_for_instant_meetings: false,
					pmi_password: "",
				}, 
				recording: {
					cloud_recording: '',
					record_speaker_view: '',
					record_gallery_view: '',
					record_audio_file: '',
					save_chat_text: '',
					show_timestamp: '',
					recording_audio_transcript: '',
				},
			},
		},
		update_setting: {
			in_meeting: {
				waiting_room: false,
				chat: false,
				auto_saving_chat: false,
				auto_saving_chat: false,
				entry_exit_chime: 'none',
				file_transfer: false,
				screen_sharing: false,
				who_can_share_screen: 'host',
				remote_control: false,
				allow_participants_to_rename: false,
				show_a_join_from_your_browser_link: false
			}, 
			schedule_meeting: {
				embed_password_in_join_link: false,
				host_video: false,
				participants_video: false,
				personal_meeting: false,
				use_pmi_for_scheduled_meetings: false,
				use_pmi_for_instant_meetings: false,
				pmi_password: "",
			}, 
			recording: {
				cloud_recording: '',
				record_speaker_view: '',
				record_gallery_view: '',
				record_audio_file: '',
				save_chat_text: '',
				show_timestamp: '',
				recording_audio_transcript: '',
			},
		},
	},
	reducers: {
		clearRoom: (state) => {
			state.list = '';
			state.text = '';

			state.profile.user_id = '';
			state.profile.user_name = '';
			state.profile.location_name = '';
			state.profile.account = '';
			state.profile.use_start_date = '';
			state.profile.use_end_date = '';
		},
		clearProfile: (state) => {
			state.profile.user_id = '';
			state.profile.user_name = '';
			state.profile.location_name = '';
			state.profile.account = '';
			state.profile.use_start_date = '';
			state.profile.use_end_date = '';

			state.profile.profile.customer_id = '';
			state.profile.profile.user_id = '';
			state.profile.profile.user_name = '';
			state.profile.profile.location_id = '';
			state.profile.profile.location_name = '';
			state.profile.profile.type = '';
			state.profile.profile.timezone = '';
			state.profile.profile.language = '';
			state.profile.profile.host_key = '';
			state.profile.profile.personal_meeting_url = '';
			state.profile.profile.pmi = '';
			state.profile.profile.use_pmi = '';
			state.profile.profile.setting_password = '';

			state.profile.setting.in_meeting.waiting_room = false;
			state.profile.setting.in_meeting.chat = false;
			state.profile.setting.in_meeting.auto_saving_chat = false;
			state.profile.setting.in_meeting.auto_saving_chat = false;
			state.profile.setting.in_meeting.entry_exit_chime = 'none';
			state.profile.setting.in_meeting.file_transfer = false;
			state.profile.setting.in_meeting.screen_sharing = false;
			state.profile.setting.in_meeting.who_can_share_screen = 'host';
			state.profile.setting.in_meeting.remote_control = false;
			state.profile.setting.in_meeting.allow_participants_to_rename = false;
			state.profile.setting.in_meeting.show_a_join_from_your_browser_link = false;

			state.profile.setting.schedule_meeting.embed_password_in_join_link = false;
			state.profile.setting.schedule_meeting.host_video = false;
			state.profile.setting.schedule_meeting.participants_video = false;
			state.profile.setting.schedule_meeting.personal_meeting = false;
			state.profile.setting.schedule_meeting.use_pmi_for_scheduled_meetings = false;
			state.profile.setting.schedule_meeting.use_pmi_for_instant_meetings = false;
			state.profile.setting.schedule_meeting.pmi_password = "";

			state.profile.setting.recording.cloud_recording = '';
			state.profile.setting.recording.record_speaker_view = '';
			state.profile.setting.recording.record_gallery_view = '';
			state.profile.setting.recording.record_audio_file = '';
			state.profile.setting.recording.save_chat_text = '';
			state.profile.setting.recording.show_timestamp = '';
			state.profile.setting.recording.recording_audio_transcript = '';

			state.update_setting.in_meeting.waiting_room = false;
			state.update_setting.in_meeting.chat = false;
			state.update_setting.in_meeting.auto_saving_chat = false;
			state.update_setting.in_meeting.auto_saving_chat = false;
			state.update_setting.in_meeting.entry_exit_chime = 'none';
			state.update_setting.in_meeting.file_transfer = false;
			state.update_setting.in_meeting.screen_sharing = false;
			state.update_setting.in_meeting.who_can_share_screen = 'host';
			state.update_setting.in_meeting.remote_control = false;
			state.update_setting.in_meeting.allow_participants_to_rename = false;
			state.update_setting.in_meeting.show_a_join_from_your_browser_link = false;


			state.update_setting.schedule_meeting.embed_password_in_join_link = false;
			state.update_setting.schedule_meeting.host_video = false;
			state.update_setting.schedule_meeting.participants_video = false;
			state.update_setting.schedule_meeting.personal_meeting = false;
			state.update_setting.schedule_meeting.use_pmi_for_scheduled_meetings = false;
			state.update_setting.schedule_meeting.use_pmi_for_instant_meetings = false;
			state.update_setting.schedule_meeting.pmi_password = "";

			state.update_setting.recording.cloud_recording = '';
			state.update_setting.recording.record_speaker_view = '';
			state.update_setting.recording.record_gallery_view = '';
			state.update_setting.recording.record_audio_file = '';
			state.update_setting.recording.save_chat_text = '';
			state.update_setting.recording.show_timestamp = '';
			state.update_setting.recording.recording_audio_transcript = '';

		},
		setSearch: (state, action) => {
			state.list = action.payload.list;
			state.text = action.payload.text;
		},
		setProfile: (state, action) => {
			state.profile.user_id = action.payload.user_id;
			state.profile.user_name = action.payload.user_name;
			state.profile.location_name = action.payload.location_name;
			state.profile.account = action.payload.account;
			state.profile.use_start_date = action.payload.use_start_date;
			state.profile.use_end_date = action.payload.use_end_date;
		},
		setUserProfile: (state, action) => {
			state.profile.profile.customer_id = action.payload.customer_id;
			state.profile.profile.user_id = action.payload.user_id;
			state.profile.profile.user_name = action.payload.user_name;
			state.profile.profile.location_id = action.payload.location_id;
			state.profile.profile.location_name = action.payload.location_name;
			state.profile.profile.type = action.payload.type;
			state.profile.profile.timezone = action.payload.timezone;
			state.profile.profile.language = action.payload.language;
			state.profile.profile.host_key = action.payload.host_key;
			state.profile.profile.personal_meeting_url = action.payload.personal_meeting_url;
			state.profile.profile.pmi = action.payload.pmi;
			state.profile.profile.use_pmi = action.payload.use_pmi;
			state.profile.profile.setting_password = action.payload.setting_password;
			state.profile.profile.vanity_url = action.payload.vanity_url;
		},
		setUserSettingDefault: (state, action) => {
			// in meeting
			state.profile.setting.in_meeting.waiting_room = action.payload.in_meeting.waiting_room;
			state.profile.setting.in_meeting.chat = action.payload.in_meeting.chat;
			state.profile.setting.in_meeting.auto_saving_chat = action.payload.in_meeting.auto_saving_chat;
			state.profile.setting.in_meeting.auto_saving_chat = action.payload.in_meeting.auto_saving_chat;
			state.profile.setting.in_meeting.entry_exit_chime = action.payload.in_meeting.entry_exit_chime;
			state.profile.setting.in_meeting.file_transfer = action.payload.in_meeting.file_transfer;
			state.profile.setting.in_meeting.screen_sharing = action.payload.in_meeting.screen_sharing;
			state.profile.setting.in_meeting.who_can_share_screen = action.payload.in_meeting.who_can_share_screen;
			state.profile.setting.in_meeting.remote_control = action.payload.in_meeting.remote_control;
			state.profile.setting.in_meeting.allow_participants_to_rename = action.payload.in_meeting.allow_participants_to_rename;
			state.profile.setting.in_meeting.show_a_join_from_your_browser_link = action.payload.in_meeting.show_a_join_from_your_browser_link;

			// schedule
			state.profile.setting.schedule_meeting.embed_password_in_join_link = action.payload.schedule_meeting.embed_password_in_join_link;
			state.profile.setting.schedule_meeting.host_video = action.payload.schedule_meeting.host_video;
			state.profile.setting.schedule_meeting.participants_video = action.payload.schedule_meeting.participants_video;
			state.profile.setting.schedule_meeting.personal_meeting = action.payload.schedule_meeting.personal_meeting;
			state.profile.setting.schedule_meeting.use_pmi_for_scheduled_meetings = action.payload.schedule_meeting.use_pmi_for_scheduled_meetings;
			state.profile.setting.schedule_meeting.use_pmi_for_instant_meetings = action.payload.schedule_meeting.use_pmi_for_instant_meetings;
			state.profile.setting.schedule_meeting.pmi_password = action.payload.schedule_meeting.pmi_password;

			// recording
			state.profile.setting.recording.cloud_recording = action.payload.recording.cloud_recording;
			state.profile.setting.recording.record_speaker_view = action.payload.recording.record_speaker_view;
			state.profile.setting.recording.record_gallery_view = action.payload.recording.record_gallery_view;
			state.profile.setting.recording.record_audio_file = action.payload.recording.record_audio_file;
			state.profile.setting.recording.save_chat_text = action.payload.recording.save_chat_text;
			state.profile.setting.recording.recording_audio_transcript = action.payload.recording.recording_audio_transcript;

		},
		setUserSettingUpdate: (state, action) => {
			// in meeting
			state.update_setting.in_meeting.waiting_room = action.payload.in_meeting.waiting_room;
			state.update_setting.in_meeting.chat = action.payload.in_meeting.chat;
			state.update_setting.in_meeting.auto_saving_chat = action.payload.in_meeting.auto_saving_chat;
			state.update_setting.in_meeting.auto_saving_chat = action.payload.in_meeting.auto_saving_chat;
			state.update_setting.in_meeting.entry_exit_chime = action.payload.in_meeting.entry_exit_chime;
			state.update_setting.in_meeting.file_transfer = action.payload.in_meeting.file_transfer;
			state.update_setting.in_meeting.screen_sharing = action.payload.in_meeting.screen_sharing;
			state.update_setting.in_meeting.who_can_share_screen = action.payload.in_meeting.who_can_share_screen;
			state.update_setting.in_meeting.remote_control = action.payload.in_meeting.remote_control;
			state.update_setting.in_meeting.allow_participants_to_rename = action.payload.in_meeting.allow_participants_to_rename;
			state.update_setting.in_meeting.show_a_join_from_your_browser_link = action.payload.in_meeting.show_a_join_from_your_browser_link;

			// schedule
			state.update_setting.schedule_meeting.embed_password_in_join_link = action.payload.schedule_meeting.embed_password_in_join_link;
			state.update_setting.schedule_meeting.host_video = action.payload.schedule_meeting.host_video;
			state.update_setting.schedule_meeting.participants_video = action.payload.schedule_meeting.participants_video;
			state.update_setting.schedule_meeting.personal_meeting = action.payload.schedule_meeting.personal_meeting;
			state.update_setting.schedule_meeting.use_pmi_for_scheduled_meetings = action.payload.schedule_meeting.use_pmi_for_scheduled_meetings;
			state.update_setting.schedule_meeting.use_pmi_for_instant_meetings = action.payload.schedule_meeting.use_pmi_for_instant_meetings;
			state.update_setting.schedule_meeting.pmi_password = action.payload.schedule_meeting.pmi_password;

			// recording
			state.update_setting.recording.cloud_recording = action.payload.recording.cloud_recording;
			state.update_setting.recording.record_speaker_view = action.payload.recording.record_speaker_view;
			state.update_setting.recording.record_gallery_view = action.payload.recording.record_gallery_view;
			state.update_setting.recording.record_audio_file = action.payload.recording.record_audio_file;
			state.update_setting.recording.save_chat_text = action.payload.recording.save_chat_text;
			state.update_setting.recording.show_timestamp = action.payload.recording.show_timestamp;
			state.update_setting.recording.recording_audio_transcript = action.payload.recording.recording_audio_transcript;
		},
	},
});

export const { clearRoom, clearProfile, setSearch, setProfile, setUserProfile, setUserSettingDefault, setUserSettingUpdate } = roomSlice.actions;


// サンク：非同期ロジックの実行
// dispatch(incrementByAmount(amount))
// 最初の引数として dispatch関数を使用してサンクが呼び出されます。
// その後、非同期コードを実行し、他のアクションをディスパッチできます
/*
export const incrementAsync = amount => dispatch => {
  setTimeout(() => {
    dispatch(incrementByAmount(amount));
  }, 1000);
};
*/

// セレクター：state から値を取得する
// useSelector((state) => state.counter.value) のようにインラインで定義もできる

export const getRoom = state => state.room;
export const getProfile = state => state.room.profile;
export const getUserProfile = state => state.room.profile.profile;
export const getUserSettingDefault = state => state.room.profile.setting;
export const getUserSettingUpdate = state => state.room.update_setting;


// レデューサーをエクスポートする
export default roomSlice.reducer;

