import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserData, getAccessToken, getRefreshToken, setAccessToken, logout } from '../../store/slicer/0101_session';
import { SetErrorMessage } from '../../store/slicer/0101_logout_message';
import * as CONST from '../../const';
import { setPageNumber, setPageMax, getPage } from '../../store/slicer/0502_page';
import encoding from 'encoding-japanese';
import { FormattedMessage, useIntl } from "react-intl";
import toast from 'react-hot-toast';

export default function ContactsSearch() {
    const dispatch = useDispatch();
    const userdata = useSelector(getUserData);
    const storePage = useSelector(getPage);

    const intl = useIntl();

    var access_token = useSelector(getAccessToken);
    var refresh_token = useSelector(getRefreshToken);

    // 初期化
    const [state, setState] = useState(() => {
        return {
            data: [{}],
            drawdata: [{}],
            input_keyword: '',
            search_keyword: '',
            contact_id: '',
            contact_name: '',
            email: '',
            page_size: 50,
            page_number: 1,
            page_max: 1,
            allcheckflag: false,
            create_flag: false,
            update_flag: false,
            delete_flag: false,
            import_flag: false,
            crud_flag: false,
            disabled: true,
            search_form: '',
            form_mail: '',
            form_name: '',
        }
    });

    // 表示リスト作成
    useEffect(() => {
        let now_page;
        now_page = storePage.page_number;
        if (now_page < 1) {
            now_page = 1;
        }

        const fetchHttpRefresh = async () => {
            let senddata = {
            };
            let url_tokref = CONST.BASEURL + '/authorize/refresh';
            var httpResponse_tokref = await fetch(url_tokref, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + refresh_token
                },
                body: JSON.stringify(senddata),
            });
            switch (httpResponse_tokref.status) {
                case 200:
                    var httpResponse_tokref_json = await httpResponse_tokref.json();
                    access_token = httpResponse_tokref_json.access_token;
                    refresh_token = httpResponse_tokref_json.refresh_token;
                    // トークンをデコードする
                    //const decode_token = jwt(access_token);
                    // 問題ない場合は、storeに保存
                    dispatch(setAccessToken(access_token));
                    //dispatch(setRefreshToken(refresh_token));
                    fetchHttp();
                    return;                       
                default:
                    dispatch(SetErrorMessage('ERROR_0001'));
                    dispatch(logout());
                    return;
            }
        }
        //fetchHttpRefresh();

        // 連絡先一覧取得
        const fetchHttp = async () => {
            fetch(CONST.BASEURL + '/customers/' + userdata.id + '/contacts?page_size=' + state.page_size + '&page_number=' + now_page + '&search=' + state.search_keyword, {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + access_token
                }
            })
                .then(response =>
                    response.json().then(data => ({
                        data: data,
                        status: response.status
                    })
                    ).then(res => {
                        switch (res.status) {
                            case 200:
                                let drawdata = []
                                let error = state.search_form;
                                error = ""

                                //検索結果の表示用リストデータを設定する                           
                                drawdata = res.data.contacts;

                                for (let i = 0; i < drawdata.length; i++) {
                                    drawdata[i].check = false;
                                }

                                // ページ数を算出する
                                const page_max = Math.ceil(res.data.contacts_count / state.page_size);

                                // 最大ページ数を変更
                                dispatch(setPageMax(page_max));

                                if (drawdata.length === 0) {
                                    error = intl.formatMessage({ id: 'ERROR_0006' });
                                    document.getElementById('sbox2').focus();
                                }
                                setState({ ...state, drawdata: drawdata, crud_flag: false, disabled: true, search_form: error, allcheckflag: false});
                                return;
                            case 401:
                                fetchHttpRefresh();
                                return;
                            case 409:
                                dispatch(SetErrorMessage('ERROR_0039'));                               
                                dispatch(logout());
                                return; 
        
                            default:
                                toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                                break;
                        }

                    }).catch((err) => {
                        //Error出力
                        toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                    })
                )
                .catch((err) => {
                    toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}})
                });
        }
        fetchHttp();
    }, [state.search_keyword, state.crud_flag, storePage.page_number]);

    // 検索テキストボックス入力処理
    const handleInputSearch = event => {
        setState({ ...state, input_keyword: event.target.value });
    };

    //追加、編集テキストボックス入力処理
    const handleInputChange = event => {
        setState({ ...state, [event.target.id]: event.target.value, });
    };

    // サブミット
    const handleSubmit = event => {
        event.preventDefault(false);
        setState({ ...state, search_keyword: state.input_keyword, crud_flag: true });
        dispatch(setPageNumber(1));
    };

    //連絡先追加ポップアップ表示
    const handleCreate = event => {
        setState({ ...state, create_flag: true });
    };

    //追加 キャンセルボタン押下
    const handleCreateCancel = event => {
        setState({ ...state, create_flag: false, crud_flag: true, contact_name: "", email: "", form_mail: "", form_name: "" });
    };

    //　連絡先追加ダイアログ　追加
    function create_contact() {

        let form_mail = state.form_mail
        let form_name = state.form_name

        form_mail = '';
        form_name = '';

        if (state.email.trim()==="") {
            form_mail = intl.formatMessage({ id: 'ERROR_0022' });
            document.getElementById('email').focus();
        }
        if (state.contact_name.trim()==="") {
            form_name = intl.formatMessage({ id: 'ERROR_0026' });
            if (state.email.trim() === "") {
                document.getElementById('email').focus();
            } else {
                document.getElementById('contact_name').focus();
            }
        }

        if (state.contact_name.trim()==="" || state.email.trim()==="") {
            setState({ ...state, form_mail: form_mail, form_name: form_name });
            return;
        }
        const fetchHttpRefresh = async () => {
            let senddata = {
            };
            let url_tokref = CONST.BASEURL + '/authorize/refresh';
            var httpResponse_tokref = await fetch(url_tokref, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + refresh_token
                },
                body: JSON.stringify(senddata),
            });
            switch (httpResponse_tokref.status) {
                case 200:
                    var httpResponse_tokref_json = await httpResponse_tokref.json();
                    access_token = httpResponse_tokref_json.access_token;
                    refresh_token = httpResponse_tokref_json.refresh_token;
                    // トークンをデコードする
                    //const decode_token = jwt(access_token);
                    // 問題ない場合は、storeに保存
                    dispatch(setAccessToken(access_token));
                    //dispatch(setRefreshToken(refresh_token));
                    fetchHttp();
                    return;                       
                default:
                    dispatch(SetErrorMessage('ERROR_0001'));
                    dispatch(logout());
                    return;
            }
        }
        //fetchHttpRefresh();
        const fetchHttp = async () => {
            fetch(CONST.BASEURL + '/customers/' + userdata.id + '/contacts', {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    Authorization: "Bearer " + access_token
                },
                body: JSON.stringify({
                    contact_name: state.contact_name,
                    email: state.email
                })
            })
                .then(response => {
                    switch (response.status) {
                        case 201:                            
                        toast(intl.formatMessage({ id: 'SUCCESS_0004' }),{style:{background:  CONST.HOT_TOAST_SUCCESS}});
                        document.getElementById('email').focus();
                            // 問題ない場合は、リロード                        
                            setState({ ...state, contact_name: "", email: "", form_mail: "", form_name: "" });
                            dispatch(setPageNumber(1));
                            break;
                        case 400:
                            response.json().then(data => ({
                                data: data,
                                status: response.status
                            })
                            ).then(res => {
                                let error = state.form_mail;
                                switch (res.data.errors[0]["code"]) {
                                    case 3010:                                       
                                        error = intl.formatMessage({ id: 'ERROR_0013' });
                                        document.getElementById('email').focus();
                                        if (state.contact_name === '') {
                                            setState({ ...state, form_mail: error });
                                        } else {
                                            setState({ ...state, form_mail: error, form_name: "" });
                                        }
                                        break;
                                    default:
                                        //Error出力
                                        error = intl.formatMessage({ id: 'ERROR_0037' });
                                        document.getElementById('email').focus();
                                        if (state.contact_name === '') {
                                            setState({ ...state, form_mail: error });
                                        } else {
                                            setState({ ...state, form_mail: error, form_name: "" });
                                        }
                                        break;
                                }
                            })
                            .catch((err) =>
                                toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}})
                           )
                            break;
                        case 401:
                            fetchHttpRefresh();
                            return;
                        case 409:
                            dispatch(SetErrorMessage('ERROR_0039'));  
                            dispatch(logout());
                            return; 
                        default:
                            //Error出力                         
                            toast(intl.formatMessage({ id: 'ERROR_0015' }),{style:{background:  CONST.HOT_TOAST_ERROR}});
                            break;
                    }
                })
                .catch((err) =>
                    toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}})
            )
        }
        fetchHttp();
    }

    //連絡先修正ポップアップ表示
    const handleUpdate = event => {
        const contact_id = event.target.getAttribute('data-contact_id');
        const contact_name = event.target.getAttribute('data-contact_name');
        const email = event.target.getAttribute('data-email');
        setState({ ...state, update_flag: true, contact_id: contact_id, contact_name: contact_name, email: email });
    };

    //　編集ダイアログ　追加
    function update_contact() {
        let form_mail = state.form_mail
        let form_name = state.form_name

        form_mail = '';
        form_name = '';

        if (state.email.trim()==="") {
            form_mail = intl.formatMessage({ id: 'ERROR_0022' });
            document.getElementById('email').focus();
        }
        if (state.contact_name.trim()==="") {
            form_name = intl.formatMessage({ id: 'ERROR_0026' });
            if (state.email.trim() === "") {
                document.getElementById('email').focus();
            } else {
                document.getElementById('contact_name').focus();
            }
        }

        if (state.contact_name.trim()==="" || state.email.trim()==="") {
            setState({ ...state, form_mail: form_mail, form_name: form_name });
            return;
        }
        const fetchHttpRefresh = async () => {
            let senddata = {
            };
            let url_tokref = CONST.BASEURL + '/authorize/refresh';
            var httpResponse_tokref = await fetch(url_tokref, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + refresh_token
                },
                body: JSON.stringify(senddata),
            });
            switch (httpResponse_tokref.status) {
                case 200:
                    var httpResponse_tokref_json = await httpResponse_tokref.json();
                    access_token = httpResponse_tokref_json.access_token;
                    refresh_token = httpResponse_tokref_json.refresh_token;
                    // トークンをデコードする
                    //const decode_token = jwt(access_token);
                    // 問題ない場合は、storeに保存
                    dispatch(setAccessToken(access_token));
                    //dispatch(setRefreshToken(refresh_token));
                    fetchHttp();
                    return;                      
                default:
                    dispatch(SetErrorMessage('ERROR_0001'));
                    dispatch(logout());
                    return;
            }
        }
        //fetchHttpRefresh();
        const fetchHttp = async () => {
            fetch(CONST.BASEURL + '/customers/' + userdata.id + '/contacts/' + state.contact_id, {
                method: 'PATCH',
                headers: {
                    'Content-type': 'application/json',
                    Authorization: "Bearer " + access_token
                },
                body: JSON.stringify({
                    contact_name: state.contact_name,
                    email: state.email
                })
            })
                .then(response => {
                    switch (response.status) {
                        case 204:
                            //成功通知
                            toast(intl.formatMessage({ id: 'SUCCESS_0005' }),{style:{background:  CONST.HOT_TOAST_SUCCESS}})
                            // 問題ない場合は、リロード                           
                            setState({ ...state, update_flag: false, crud_flag: true, contact_name: "", email: "", form_mail: "", form_name: "" });
                            dispatch(setPageNumber(1));
                            break;
                        case 400:
                            response.json().then(data => ({
                                data: data,
                                status: response.status
                            })
                            ).then(res => {
                                let error = state.form_mail;
                                switch (res.data.errors[0]["code"]) {
                                    case 3010:                                        
                                        error = intl.formatMessage({ id: 'ERROR_0013' })
                                        document.getElementById('email').focus();
                                        if (state.contact_name === '') {
                                            setState({ ...state, form_mail: error });
                                        } else {
                                            setState({ ...state, form_mail: error, form_name: "" });
                                        }
                                        break;
                                    default:
                                        //Error出力
                                        error = intl.formatMessage({ id: 'ERROR_0037' });
                                        document.getElementById('email').focus();
                                        if (state.contact_name === '') {
                                            setState({ ...state, form_mail: error });
                                        } else {
                                            setState({ ...state, form_mail: error, form_name: "" });
                                        }
                                        break;                                       
                                }
                            })
                            .catch((err) =>
                                toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}})
                            )
                            break;
                        case 401:
                            fetchHttpRefresh();
                            return;
                        case 409:
                            dispatch(SetErrorMessage('ERROR_0039'));  
                            dispatch(logout());
                            return; 
                        default:
                            toast(intl.formatMessage({ id: 'ERROR_0016' }),{style:{background:  CONST.HOT_TOAST_ERROR}})
                            break;
                    }
                })
                .catch((err) =>
                    toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}})
            )
        }
        fetchHttp();
        return {
            contact_id: '',
        }
    }

    //編集 キャンセルボタン押下
    const handleUpdateCancel = event => {
        setState({ ...state, update_flag: false, contact_name: "", email: "", form_mail: "", form_name: "" });
    };

    //チェックボックス全選択
    const handleCheckAllCheckbox = event => {
        let drawdate_checkall = [{}];
        let checkdrawdata = [{}];
        let allcheck = false;

        checkdrawdata = state.drawdata;

        drawdate_checkall = state.drawdata;
        if (state.allcheckflag === false) {
            for (let i = 0; i < drawdate_checkall.length; i++) {
                drawdate_checkall[i].check = true;
            }
            allcheck = true;
        } else {
            for (let i = 0; i < drawdate_checkall.length; i++) {
                drawdate_checkall[i].check = false;
            }
            allcheck = false;
        }


        //チェックボックスにチェックが入っていないと削除ボタンを押せなくする
        let delete_check = true;
        for (let i = 0; i < checkdrawdata.length; i++) {
            if (checkdrawdata[i].check === true) {
                delete_check = false;
                break;
            }
        }

        setState({ ...state, drawdata: drawdate_checkall, allcheckflag: allcheck, disabled: delete_check });
    }

    //チェックボックスの値が変化したら、配列に追加または削除をする
    const handleChangeCheckbox = event => {        
        const index = event.target.getAttribute('data-index');

        let checkdrawdata = [{}];
        let flag = false;//リストのチェックボックスの値
        let AllorNotAll = false;//全チェックのチェックボックスの値

        flag = state.drawdata[index].check ? false : true;

        checkdrawdata = state.drawdata;
        checkdrawdata[index].check = flag;

        //すべてにチェックが入っていなかったらallからチェックを外す。すべてにチェックが入っていたらallにチェックを入れる
        for (let i = 0; i < checkdrawdata.length; i++) {
            if (checkdrawdata[i].check === false) {
                AllorNotAll = true;
                break;
            }
        }
        if (AllorNotAll === true) {
            AllorNotAll = false;
        } else {
            AllorNotAll = true;
        }

        //チェックボックスにチェックが入っていないと削除ボタンを押せなくする
        let delete_check = true;
        for (let i = 0; i < checkdrawdata.length; i++) {
            if (checkdrawdata[i].check === true) {
                delete_check = false;
                break;
            }
        }


        setState({ ...state, drawdata: checkdrawdata, allcheckflag: AllorNotAll, disabled: delete_check });
    }

    //連絡先削除ポップアップ表示
    const handleDelete = event => {
        setState({ ...state, delete_flag: true });
    };

    //　削除ダイアログ　削除
    function delete_contact() {
        let delete_contact_id_data = [{}];
        let drawdate_refresh = [{}];
        let x = 0;
        for (let i = 0; i < state.drawdata.length; i++) {
            if (state.drawdata[i].check) {
                delete_contact_id_data[x] = { "contact_id": state.drawdata[i].contact_id };
                x++;
            }
        }

        const fetchHttpRefresh = async () => {
            let senddata = {
            };
            let url_tokref = CONST.BASEURL + '/authorize/refresh';
            var httpResponse_tokref = await fetch(url_tokref, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + refresh_token
                },
                body: JSON.stringify(senddata),
            });
            switch (httpResponse_tokref.status) {
                case 200:
                    var httpResponse_tokref_json = await httpResponse_tokref.json();
                    access_token = httpResponse_tokref_json.access_token;
                    refresh_token = httpResponse_tokref_json.refresh_token;
                    // トークンをデコードする
                    //const decode_token = jwt(access_token);
                    // 問題ない場合は、storeに保存
                    dispatch(setAccessToken(access_token));
                    //dispatch(setRefreshToken(refresh_token));
                    fetchHttp();
                    return;                   
                default:
                    dispatch(SetErrorMessage('ERROR_0001'));
                    dispatch(logout());
                    return;
            }
        }
        //fetchHttpRefresh();
        const fetchHttp = async () => {
            fetch(CONST.BASEURL + '/customers/' + userdata.id + '/contacts', {
                method: 'DELETE',
                headers: {
                    'Content-type': 'application/json',
                    Authorization: "Bearer " + access_token
                },
                body: JSON.stringify({
                    "contacts": delete_contact_id_data
                })
            })
            .then(res => {
                switch (res.status) {
                    case 204:
                        //削除に成功したので配列を空にする
                        delete_contact_id_data = 0;


                        //削除に成功したのですべてのチェックを外す
                        drawdate_refresh = state.drawdata;
                        for (let i = 0; i < drawdate_refresh.length; i++) {
                            drawdate_refresh[i].check = false;
                        }

                        //成功通知
                        toast(intl.formatMessage({ id: 'SUCCESS_0003' }),{style:{background:  CONST.HOT_TOAST_SUCCESS}});

                        // 問題ない場合は、リロード                    
                        setState({ ...state, drawdata: drawdate_refresh, delete_flag: false, crud_flag: true, allcheckflag: false });
                        dispatch(setPageNumber(1));
                        break;
                    case 401:
                        fetchHttpRefresh();
                        return;
                    case 409:
                        dispatch(SetErrorMessage('ERROR_0039'));  
                        dispatch(logout());
                        return; 
                    default:
                        //Error出力
                        toast(intl.formatMessage({ id: 'ERROR_0014' }),{style:{background:  CONST.HOT_TOAST_ERROR}}) 
                        break;
                }
            })
            .catch((err) =>
                toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}})
            )
        }
        fetchHttp();

    }

    //削除 キャンセルボタン押下
    const handleDeleteCancel = event => {
        setState({ ...state, delete_flag: false });
    };

    //連絡先インポート処理
    const handleInport = event => {
        //選択ファイル情報取得
        var input = event.target;

        //未選択
        if (input.files.length===0) {
            return;
        }

        //ファイル名
        var file = input.files[0];
        var file_name_list = file.name.split('.');
        var file_extension = "";

        //拡張子
        if (file_name_list.length >= 2) {
            file_extension = file_name_list[file_name_list.length - 1];
        }

        //ファイルコントロール クリア
        var obj_import = document.getElementById("import");
        obj_import.value = "";

        //csv以外ならError
        if (file_extension !=="csv") {
            toast(intl.formatMessage({ id: 'ERROR_0030' }),{style:{background:  CONST.HOT_TOAST_ERROR}});
            return;
        }

        //FileReaderのインスタンスを作成する
        var reader = new FileReader();

        //読込み
        reader.readAsArrayBuffer(file);

        // 読みんこんだ後のコールバック
        reader.onload = () => {
            var array = new Uint8Array(reader.result);

            // 文字コードを取得
            switch (encoding.detect(array)) {
                case 'UTF16':
                    try {
                        // 16ビット符号なし整数値配列と見なす
                        array = new Uint16Array(reader.result);
                        break;
                    } catch (error) {
                        toast(intl.formatMessage({ id: 'ERROR_0034' }),{style:{background:  CONST.HOT_TOAST_ERROR}});
                        return
                    };
                case 'UTF32':
                    try {
                        // 32ビット符号なし整数値配列と見なす
                        array = new Uint32Array(reader.result);
                        break;
                    } catch (error) {
                        toast(intl.formatMessage({ id: 'ERROR_0034' }),{style:{background:  CONST.HOT_TOAST_ERROR}});
                        return
                    };

            }

            // Unicodeの数値配列に変換
            var unicodeArray = encoding.convert(array, 'UNICODE');
            // Unicodeの数値配列を文字列に変換
            var text = encoding.codeToString(unicodeArray);

            //Maxバイトチェック         
            var line = text.split("\n");
            for (let i = 1; i < line.length; i++) {
                
                var contact_name = line[i].split(",");

                if (contact_name.length === 2) {
                    //氏名のMaxバイトチェック         
                    if (contact_name[0].length > 36) {
                        toast(intl.formatMessage({ id: 'ERROR_0032' }),{style:{background:  CONST.HOT_TOAST_ERROR}});
                        return;
                    }

                    //メールアドレスのMaxバイトチェック
                    if (contact_name[1].length > 256) {
                        toast(intl.formatMessage({ id: 'ERROR_0032' }),{style:{background:  CONST.HOT_TOAST_ERROR}});
                        return;
                    }
                }
            }

            //Import
            import_api(text);
        };
    }

    function import_api(text) {
        //インポート方式
        var import_type = 'add';
        var obj_select_import = document.getElementById("select_import");
        var select_import_value = obj_select_import.value;

        if (select_import_value==='washing') {
            import_type = 'replace';
        }

        //URL
        var url = CONST.BASEURL + '/customers/' + userdata.id + '/contacts/import?regist_type=' + import_type;
        const fetchHttpRefresh = async () => {
            let senddata = {};
            let url_tokref = CONST.BASEURL + '/authorize/refresh';
            var httpResponse_tokref = await fetch(url_tokref, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + refresh_token
                },
                body: JSON.stringify(senddata),
            });
            switch (httpResponse_tokref.status) {
                case 200:
                    var httpResponse_tokref_json = await httpResponse_tokref.json();
                    access_token = httpResponse_tokref_json.access_token;
                    refresh_token = httpResponse_tokref_json.refresh_token;
                    // トークンをデコードする
                    //const decode_token = jwt(access_token);
                    // 問題ない場合は、storeに保存
                    dispatch(setAccessToken(access_token));
                    //dispatch(setRefreshToken(refresh_token));
                    fetchHttp();
                    return;                    
                default:
                    dispatch(SetErrorMessage('ERROR_0001'));
                    dispatch(logout());
                    return;
            }
        }
        //fetchHttpRefresh();
        //API
        const fetchHttp = async () => {
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'text/csv',
                    Authorization: "Bearer " + access_token
                },
                body: text
            })
            .then(res => {
                //更新完了
                switch (res.status) {
                    case 201:
                        //成功通知
                        toast(intl.formatMessage({ id: 'SUCCESS_0007' }),{style:{background:  CONST.HOT_TOAST_SUCCESS}});
                        setState({ ...state, crud_flag: true });
                        break;
                    case 401:
                        fetchHttpRefresh();
                        return;
                    case 409:
                        dispatch(SetErrorMessage('ERROR_0039'));  
                        dispatch(logout());
                        return; 
                    default:
                        //エラー
                        toast(intl.formatMessage({ id: 'ERROR_0032' }),{style:{background:  CONST.HOT_TOAST_ERROR}});
                        break;
                }

            })
            .catch((err) => {
                toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}})
            })
        }
        fetchHttp();
    }

    //連絡先エクスポート処理
    const handleExport = event => {
        const fetchHttpRefresh = async () => {
            let senddata = {};
            let url_tokref = CONST.BASEURL + '/authorize/refresh';
            var httpResponse_tokref = await fetch(url_tokref, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + refresh_token
                },
                body: JSON.stringify(senddata),
            });
            switch (httpResponse_tokref.status) {
                case 200:
                    var httpResponse_tokref_json = await httpResponse_tokref.json();
                    access_token = httpResponse_tokref_json.access_token;
                    refresh_token = httpResponse_tokref_json.refresh_token;
                    // トークンをデコードする
                    //const decode_token = jwt(access_token);
                    // 問題ない場合は、storeに保存
                    dispatch(setAccessToken(access_token));
                    //dispatch(setRefreshToken(refresh_token));
                    fetchHttp();
                    return;                   
                default:
                    dispatch(SetErrorMessage('ERROR_0001'));
                    dispatch(logout());
                    return;
            }
        }
        //fetchHttpRefresh();
        const fetchHttp = async () => {
            fetch(CONST.BASEURL + '/customers/' + userdata.id + '/contacts/export', {
                method: 'POST',
                headers: {
                    Authorization: "Bearer " + access_token
                }
            })
            .then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                     switch (res.status) {
                        case 200:

                             let csv_data = "";

                            //グループに所属していない連絡先を追加
                             for (let i = 0; i < res.data.not_group_contacts.length; i++) {
                                csv_data = csv_data + res.data.not_group_contacts[i][0] + "," + res.data.not_group_contacts[i][1] + "\r\n"
                             }

                            //すべてのデータが0件の場合
                            if (res.data.not_group_contacts.length===0) {
                                let search_form = state.search_form
                                search_form = intl.formatMessage({ id: 'ERROR_0033' });
                                setState({ ...state, search_form: search_form });
                                return
                             }


                             let header_list = []
                             header_list[0] = intl.formatMessage({ id: 'contact-csv_name' })
                             header_list[1] = intl.formatMessage({ id: 'contact-csv_sentence' }) + "\r\n"



                             var bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
                             const fileName = res.data.file_name
                             const data_export = new Blob([bom, header_list, csv_data], { type: 'text/csv' });
                             const jsonURL = window.URL.createObjectURL(data_export);
                             const link = document.createElement('a');
                             document.body.appendChild(link);
                             link.href = jsonURL;
                             link.setAttribute('download', fileName);
                             link.click();
                             document.body.removeChild(link);
                             toast(intl.formatMessage({ id: 'SUCCESS_0006' }),{style:{background:  CONST.HOT_TOAST_SUCCESS}});
                             break;
                        case 401:
                            fetchHttpRefresh();
                             return;
                         case 409:
                             dispatch(SetErrorMessage('ERROR_0039'));  
                             dispatch(logout());
                             return;
                        default:
                            toast(intl.formatMessage({ id: 'ERROR_0031' }),{style:{background:  CONST.HOT_TOAST_ERROR}});
                            break;
                    }
                }).catch((err) => {
                    //Error出力
                    toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                })
            )
            .catch((err) =>
                toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}})
            )
        }
        fetchHttp();
    };

    //インポートAタグ押下
    const handleInportClick = event => {

        var obj_select_import = document.getElementById("select_import").value;

        if (obj_select_import == "addition") {
            //hiddenのインポートボタン押下
            document.getElementById("import").click(); 
        } else if (obj_select_import == "washing") {
            handleWashingOpen()
        }
    }
    
    //連絡先洗替ポップアップ表示
    const handleWashingOpen = event => {
        setState({ ...state, import_flag: true });
    };

    //連絡先洗替OK
    const handleWashing = event => {
        document.getElementById("import").click();
        setState({ ...state, import_flag: false });
    };

    //連絡先洗替キャンセル
    const handleImportCancel = event => {
        setState({ ...state, import_flag: false });
    };

    // ダイアログ初期カーソル
    useLayoutEffect(() => {
        if (state.create_flag == true) {
            document.getElementById('email').focus();
        } else if (state.update_flag == true) {
            document.getElementById('email').focus();
        } else if (state.delete_flag == true) {
            document.getElementById('delete_cancel_btn').focus();
        }
    }, [state.create_flag, state.update_flag, state.delete_flag])

    return (
        <div class="scroll_range">
            <title><FormattedMessage id="tab_title_contact_list" /></title>
            <header class="in_contents_header">
                <div class="flex_box">
                    <div class="element_width_xl">
                        <div class="search_box">
                            <button class="" onClick={handleSubmit} type="button" id="sbtn2" tabIndex="-1"><img src="img/EZTP_search.svg" alt=""></img></button>
                            <FormattedMessage id="contact-initial_emailcontactname_txt">{placeholder => <input id="sbox2" name="s" type="text" placeholder={placeholder} maxlength='256' value={state.input_keyword} onChange={handleInputSearch} />}</FormattedMessage>
                        </div>
                    </div>
                    <div class="flex_box right_justified element_width_free inline_block blank_space_m">
                        <div class="element_width_m">
                            <a class="buttons_common btn_wht" onClick={handleInportClick} href="javascript:void(0);"><FormattedMessage id="contact-initial_import_bll" /></a>
                            <p hidden><input class="buttons_common btn_wht" type="file" name="import" accept="" id="import" onChange={handleInport} /></p>
                        </div>
                        <div class="selecter_down_arrow select_option element_width_ml blank_space_ml">
                            <label>
                                <img class="svg_img-Pulldown" src="img/EZTP_Pulldown.svg" alt="▽" />
                                <select id="select_import">
                                    <FormattedMessage id="contact-initial_import_addition">{value => <option value="addition">{value}</option>}</FormattedMessage>
                                    <FormattedMessage id="contact-initial_import_washing">{value => <option value="washing">{value}</option>}</FormattedMessage>
                                </select>
                            </label>
                        </div>
                        <div class="element_width_m blank_space_l">
                            <FormattedMessage id="contact-initial_export_btn">{value => <input class="buttons_common btn_wht" type="button" value={value} onClick={handleExport} />}</FormattedMessage>
                        </div>
                        <div class="element_width_ml inline_block blank_space_ml">
                            <a href="javascript:void(0);" class="buttons_common btn_blue" onClick={handleCreate}><FormattedMessage id="contact-initial_addcontact_btn" /></a>
                        </div>
                    </div>
                </div>
            </header>
            <div class="search_result_table_box">
                <table class="search_result_table management_information_table">
                    <tbody>
                        <tr>
                            <th><input class="select_all contact_ckb" type="checkbox" name="all" checked={state.allcheckflag} onClick={handleCheckAllCheckbox}></input></th>
                            <th><span><FormattedMessage id="contact-initial_selectall_chk" /></span></th>
                            <th><span><FormattedMessage id="contact-initial_email_lbl" /></span></th>
                            <th>
                                <div class="inline_block element_width_ml">
                                    <FormattedMessage id="contact-initial_selectdelete_btn">
                                        {value => <input class="buttons_common btn_wht" type="button" value={value} onClick={handleDelete} disabled={state.disabled}></input>}
                                    </FormattedMessage>
                                </div>
                            </th>
                        </tr>
						{
							(() => {
								if (state.search_form !=='') {
									return (
										<tr>
											<td colspan="3">
												<div class="has-error">{state.search_form}</div>
											</td>
											<td />
										</tr>
									);
								}
							})()
						}
                        {
                            state.drawdata.map((data, index) => {
                                if (data.contact_name===undefined) {
                                    return (<tr />);
                                }
                                return (
                                    <tr>
                                        <td><input class="select_all contact_ckb" name="checklist" type="checkbox" checked={data.check} data-index={index} data-contact_list_id={data.contact_id} onClick={handleChangeCheckbox}></input></td>
                                        <td><a href="javascript:void(0);" data-contact_id={data.contact_id} data-email={data.email} data-contact_name={data.contact_name} onClick={handleUpdate}>{data.contact_name}</a></td>
                                        <td>{data.email}</td>
                                        <td>
                                            <div class="element_width_ms inline_block vertical_center">
                                                <a href="javascript:void(0);" class="buttons_common btn_wht" data-email={data.email} data-contact_name={data.contact_name} data-contact_id={data.contact_id} onClick={handleUpdate}>
                                                    <FormattedMessage id="contact-initial_edit_btn" />
                                                </a>
                                            </div>
                                            </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>
            {state.create_flag &&
                <div id="contact_create" >
                    <div>
                        <nav>
                            <div class="in_contents contact-additional" id="create_confirm">
                                <div id="modal_overlay"></div>
                                <div id="modal_container">
                                    <header class="modal_header">
                                        <h2><FormattedMessage id="contact-additional_sentence1_lbl" /></h2>
                                    </header>
                                    <div class="modal_contents blank_above_m">
                                        <div class="inline_block element_width_xl">
                                            <FormattedMessage id="contact-additional_email_txt">
                                            {placeholder => <input id="email" type="text" class={state.form_mail !=='' ? 'has-error' : ''} placeholder={placeholder} maxlength='256' value={state.email} onChange={handleInputChange}></input>}
                                            </FormattedMessage>
                                        </div>
                                        <div class="has-error" style={{ display: state.form_mail !=='' ? '' : 'none' }}>{state.form_mail}</div>
                                        <div class="inline_block element_width_xl blank_above_ms">
                                            <FormattedMessage id="contact-additional_contactname_txt">
                                            {placeholder => <input id="contact_name" class={state.form_name !=='' ? 'has-error' : ''} type="text" placeholder={placeholder} maxlength='36' value={state.contact_name} onChange={handleInputChange} />}
                                            </FormattedMessage>
                                        </div>
                                        <div class="has-error" style={{ display: state.form_name !=='' ? '' : 'none' }}>{state.form_name}</div>
                                        <div class="flex_box right_justified blank_above_m">
                                            <div class="element_width_ms">
                                                <FormattedMessage id="contact-additional_addcontact_btn">
                                                    {value => <input class="buttons_common btn_blue" type="button" value={value} onClick={create_contact}></input>}
                                                </FormattedMessage>
                                            </div>
                                            <div class="element_width_ms blank_space_m">
                                                <FormattedMessage id="contact-additional_cancel_btn">
                                                    {value => <input id="create_cancel_btn" class="buttons_common btn_wht" type="button" value={value} onClick={handleCreateCancel}></input>}
                                                </FormattedMessage>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            }
            {state.update_flag && <div id="contact_update" >
                <div>
                    <nav>
                        <div class="in_contents contact-edit" id="update_confirm">
                            <div id="modal_overlay"></div>
                            <div id="modal_container">
                                    <header class="modal_header">
                                        <h2><FormattedMessage id="contact-edit_sentence1_lbl" /></h2>
                                    </header>
                                    <div class="modal_contents blank_above_m">
                                        <div class="inline_block element_width_xl">
                                            <FormattedMessage id="contact-edit_email_txt">
                                                {placeholder => <input id="email" type="text" class={state.form_mail !=='' ? 'has-error' : ''} placeholder={placeholder} maxlength='256' value={state.email} onChange={handleInputChange}></input>}
                                            </FormattedMessage>
                                        </div>
                                        <div class="has-error" style={{ display: state.form_mail !=='' ? '' : 'none' }}>{state.form_mail}</div>
                                        <div class="inline_block element_width_xl blank_above_ms">
                                            <FormattedMessage id="contact-edit_contactname_txt">
                                                {placeholder => <input id="contact_name" type="text" class={state.form_name !=='' ? 'has-error' : ''} placeholder={placeholder} maxlength='36' value={state.contact_name} onChange={handleInputChange}></input>}
                                            </FormattedMessage>
                                        </div>
                                        <div class="has-error" style={{ display: state.form_name !=='' ? '' : 'none' }}>{state.form_name}</div>
                                        <div class="flex_box right_justified blank_above_m">
                                            <div class="element_width_ms">
                                                <FormattedMessage id="contact-edit_save_btn">
                                                    {value => <input class="buttons_common btn_blue" type="button" value={value} onClick={update_contact}></input>}
                                                </FormattedMessage>
                                            </div>
                                            <div class="element_width_ms blank_space_m">
                                                <FormattedMessage id="contact-edit_cancel_btn">
                                                    {value => <input class="buttons_common btn_wht" type="button" value={value} onClick={handleUpdateCancel}></input>}
                                                </FormattedMessage>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            }
            {state.delete_flag && <div id="contact_delete" >
                <div>
                    <nav>
                        <div class="in_contents contact-edit" id="update_confirm">
                            <div id="modal_overlay"></div>
                            <div id="modal_container">
                                <header class="modal_header">
                                    <h2><FormattedMessage id="contact-delete_sentence1_lbl" /></h2>
                                </header>
                                <div class="modal_contents blank_above_m">
                                    <div class="inline_block element_width_xl"><span><FormattedMessage id="contact-delete_sentence2_lbl" values={{ br: <br /> }} /></span></div>
                                    <div class="flex_box right_justified blank_above_m">
                                        <div class="element_width_ms">
                                            <FormattedMessage id="contact-delete_delete_btn">
                                                {value => <input class="buttons_common btn_blue" type="button" value={value} onClick={delete_contact}></input>}
                                            </FormattedMessage>
                                        </div>
                                        <div class="element_width_ms blank_space_m">
                                            <FormattedMessage id="contact-delete_cancel_btn">
                                                {value => <input id="delete_cancel_btn" class="buttons_common btn_wht" type="button" value={value} onClick={handleDeleteCancel}></input>}
                                            </FormattedMessage>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
            }
            {state.import_flag && <div id="contact_import" >
                <div>
                    <nav>
                        <div class="in_contents contact-edit" id="import_confirm">
                            <div id="modal_overlay"></div>
                            <div id="modal_container">
                                <header class="modal_header">
                                    <h2><FormattedMessage id="contact-csv_washing_title" /></h2>
                                </header>
                                <div class="modal_contents blank_above_m">
                                    <FormattedMessage id="contact-csv_washing_message" values={{ br: <br /> }} />
                                    <div class="flex_box right_justified blank_above_m">
                                        <div class="element_width_ms">
                                            <FormattedMessage id="contact-import_ok_btn">
                                                {value => <input class="buttons_common btn_blue" type="button" value={value} onClick={handleWashing}></input>}
                                            </FormattedMessage>
                                        </div>
                                        <div class="element_width_ms blank_space_m">
                                            <FormattedMessage id="contact-import_cancel_btn">
                                                {value => <input id="Import_cancel_btn" class="buttons_common btn_wht" type="button" value={value} onClick={handleImportCancel}></input>}
                                            </FormattedMessage>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
            }
        </div>
    );
}
