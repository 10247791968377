import * as CONST from '../../../const';

import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import Header from '../../0003_components/0001_header';
import Sidemenu from '../../0003_components/0002_sidemenu';


import { getAccessToken, getRefreshToken, setAccessToken, logout } from '../../../store/slicer/0101_session';
import { getProfile, setProfile, setUserProfile, setUserSettingDefault, setUserSettingUpdate } from '../../../store/slicer/0503_room';
import { setProfileMenu, setSideMenu } from '../../../store/slicer/0501_menu';
import { SetErrorMessage } from '../../../store/slicer/0101_logout_message';

import UserProfileHeader from '../../0003_components/0691_user_profile_header';
import UserMeetingSidemenu from '../../0003_components/0891_user_meeting_sidemenu';
import UserMeetingHeader from '../../0003_components/0892_user_meeting_header';

import UserRecordingBody_01 from '../../0003_components/0901_user_recording_body';

import toast, { Toaster } from 'react-hot-toast';
import { FormattedMessage, useIntl } from "react-intl";



export default function UserRecording(props) {
    const dispatch = useDispatch();
    const loc = useLocation();
    const history = useHistory();


	// プロフィール
	const storeProfile = useSelector(getProfile);
	
	const intl = useIntl();

	var access_token = useSelector(getAccessToken);
	var refresh_token = useSelector(getRefreshToken);


    // 初期化
    const [state, setState] = useState(() => {
		dispatch(setSideMenu(0));
        dispatch(setProfileMenu({top:2, side:4}));
		toast.dismiss();

		// ルーム一覧から、設定ボタン押下で来た場合
		if (loc.state !== undefined && loc.state.data !== undefined) {
			// プロフィールのセット
			dispatch(setProfile(loc.state.data));
			const user_id = loc.state.data.user_id;

			const fetchHttpRefresh = async () => {
				let senddata = {
				};
				let url_tokref = CONST.BASEURL + '/authorize/refresh';
				var httpResponse_tokref = await fetch( url_tokref, {
					method:'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': "Bearer " + refresh_token
					},
					body: JSON.stringify(senddata),
				});
				switch (httpResponse_tokref.status) {
					case 200:
						var httpResponse_tokref_json = await httpResponse_tokref.json();
						access_token = httpResponse_tokref_json.access_token;
						refresh_token = httpResponse_tokref_json.refresh_token;
						// トークンをデコードする
						//const decode_token = jwt(access_token);
						// 問題ない場合は、storeに保存
						dispatch(setAccessToken(access_token));
						//dispatch(setRefreshToken(refresh_token));
						fetchHttp();
						return;					       
					default:
						dispatch(SetErrorMessage('ERROR_0001'));
						dispatch(logout());
						return;
				}
			}


			//fetchHttpRefresh();
			////////////////////////////////
			// ユーザープロファイル取得
			////////////////////////////////
			const fetchHttp = async () => {
				fetch(CONST.BASEURL + '/users/' + user_id , {
					method:'GET',
					headers: {
						Authorization: "Bearer " + access_token
					},
				})
				.then(response => {
					switch (response.status) {
					case 200:
						response.json().then(data => ({
							data: data,
							status: response.status
						})
						).then(res=> {
							// ユーザープロファイルの項目をセット
							dispatch(setUserProfile(res.data));
							return;
						})
						break;
					case 401:
						fetchHttpRefresh();
						return;
					case 409:
						dispatch(SetErrorMessage('ERROR_0039'));  
						dispatch(logout());
						return;
					default:
						response.json().then(data => ({
							data: data,
							status: response.status
						})
						).then(res=> {
							toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

							return;
						})
						break;
					}
				})
				.catch((err) => {
					toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

				});
			}
			fetchHttp();


			const fetchHttpRefresh2 = async () => {
				let senddata = {
				};
				let url_tokref = CONST.BASEURL + '/authorize/refresh';
				var httpResponse_tokref = await fetch( url_tokref, {
					method:'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': "Bearer " + refresh_token
					},
					body: JSON.stringify(senddata),
				});
				switch (httpResponse_tokref.status) {
					case 200:
						var httpResponse_tokref_json = await httpResponse_tokref.json();
						access_token = httpResponse_tokref_json.access_token;
						refresh_token = httpResponse_tokref_json.refresh_token;

						// 問題ない場合は、storeに保存
						dispatch(setAccessToken(access_token));
						fetchHttp2();
						return;					      
					default:
						dispatch(SetErrorMessage('ERROR_0001'));
						dispatch(logout());
						return;
				}
			}

			//fetchHttpRefresh();
			////////////////////////////////
			// ユーザー設定取得
			////////////////////////////////
			const fetchHttp2 = async () => {
				fetch(CONST.BASEURL + '/users/' + user_id + '/settings', {
					method:'GET',
					headers: {
						Authorization: "Bearer " + access_token
					},
				})
				.then(response => {
					switch (response.status) {
					case 200:
						response.json().then(data => ({
							data: data,
							status: response.status
						})
						).then(res=> {
							// ユーザー設定の項目をセット
							dispatch(setUserSettingDefault(res.data));
							dispatch(setUserSettingUpdate(res.data));
							return;
						})
						break;
					case 401:
						fetchHttpRefresh2();
						return
					case 409:
						dispatch(SetErrorMessage('ERROR_0039'));  
						dispatch(logout());
						return;  
					default:
						response.json().then(data => ({
							data: data,
							status: response.status
						})
						).then(res=> {
							toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

						})
						break;
					}
				})
				.catch((err) => {
					toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

				});
			}
			fetchHttp2();

		}
		else {
			// ユーザープロフィール等の画面から来た場合で、ユーザープロフィールの値がセットされていない場合は、不正として、/roomに移行
			if ((loc === undefined || loc.state === undefined || loc.state.data === undefined || loc.state.data.user_id === undefined || loc.state.data.user_id === '') && 
				(storeProfile.user_id === undefined || storeProfile.user_id === '')
			) {
				history.push('/room');
			}
		}

		return 0;
	});


	// サイドエフェクト
	useEffect(() => {
		// ユーザープロフィールが取得できない場合は、不正として、/roomに移行
		if ((loc === undefined || loc.state === undefined || loc.state.data === undefined || loc.state.data.user_id === undefined || loc.state.data.user_id === '') && 
			(storeProfile.user_id === undefined || storeProfile.user_id === '')
		) {
			history.push('/room');
		}
	}, []);


    // 描画
    return (
        <div class="wrapper">
            <Header />
            <div class="container">
                <div class="contents">
                    <Sidemenu />
                    <div class="in_contents settings-recording">
                        <UserProfileHeader />
                        <div class="sticky_container">
                            <UserMeetingSidemenu />
                            <div class="flex_column">
								<UserMeetingHeader />
								<style jsx="true" />
                                <Toaster position="top-center" reverseOrder="false" containerStyle={{ marginTop:'75px'}} />
                                <div class="tab_box">
                                    <div class="meeting_contents">
                                        <div>
                                            <div class="subtitle"><h3><FormattedMessage id="recording_sentence1_lbl" /></h3></div>
                                            <table class="in_tab_box_table">
                                                <UserRecordingBody_01 />
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
