import Header from '../../0003_components/0001_header';
import Sidemenu from '../../0003_components/0002_sidemenu';

import RoomList from '../../0003_components/0501_room_list';
import Pagination from '../../0003_components/0003_pagination';
import { toast, Toaster } from 'react-hot-toast';

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSideMenu } from '../../../store/slicer/0501_menu';

export default function Room(props) {
	const dispatch = useDispatch();

	// 初期化
	const [state, setState] = useState(() => {
		dispatch(setSideMenu(0));
		toast.dismiss()
		return 0;
	});

	// 描画
	return (
		<div class="wrapper">
			<Header />
			<div class="container">
				<div class="contents">
					<Sidemenu />
					<style jsx="true" />
					<Toaster position="top-center" reverseOrder="false" containerStyle={{ marginTop:'75px'}} />
					<div class="in_contents list-initial">
						<RoomList page_size="50" />
						<Pagination />
					</div>
				</div>
			</div>
		</div>
	);
}
