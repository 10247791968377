import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clearPage, setPageNumber, getPage } from '../../store/slicer/0502_page';



// コンポーネント：ページネーション
export default function Pagination(props) {
	const dispatch = useDispatch();
	const storePage = useSelector(getPage);

	// 初期化
	const [state, setState] = useState(() => {
		dispatch(clearPage());
		return {
			prev: false,
			next: false,
		}
	});


	// イベントハンドラ
	const handleOnClick = useCallback(event => {
		event.preventDefault(false);
		const type = event.target.getAttribute('data-type');

		// ページ変更処理
		let num = 1;
		switch (type) {
		case 'first':
			num = 1;
			break;
		case 'prev':
			num = storePage.page_number - 1;
			break;
		case 'next':
			num = storePage.page_number + 1;
			break;
		case 'last':
			num = storePage.page_max;
			break;
		default:
			num = 1;
			break;
		}

		dispatch(setPageNumber(num));
	}, [storePage.page_max, storePage.page_number]);




	// サイドエフェクト
	useEffect(() => {
		// 前ページ・次ページがあるかのチェック
		let paging = {prev: false, next: false};
		paging.prev = (1 < storePage.page_number) ? true: false;
		paging.next = (storePage.page_number < storePage.page_max) ? true : false;
		setState({...state, prev: paging.prev, next: paging.next, });
	}, [storePage.page_max, storePage.page_number]);



	// 描画
	return (
		<div class='pagination'>
			<ul class='pagination-links-ul'>
				{state.prev && <li><a href="javascript:void(0);" class='first-page cursor_a_link'  data-type="first" onClick={handleOnClick} ></a></li>}
				{state.prev && <li><a href="javascript:void(0);" class='prev-page cursor_a_link' data-type="prev" onClick={handleOnClick} ></a></li>}
				<li class='current-page_li'><input tabIndex="-1" class='current-page' type='text' value={storePage.page_number}/></li>
				{state.next && <li><a href="javascript:void(0);" class='next-page cursor_a_link'  data-type="next" onClick={handleOnClick} ></a></li>}
				{state.next && <li><a href="javascript:void(0);" class='last-page cursor_a_link' data-type="last" onClick={handleOnClick} ></a></li>}
			</ul>
		</div>
	);

}
