const LangDE = {
	'EMPTY': "",
	'signin_signin_lbl': "Anmelden",
	'signin_account_txt': "Konto",
	'signin_password_txt': "Password",
	'signin_forget_password_lnk': "Wenn Sie Ihr Password vergessen haben",
	'signin_stay_password_chk': "Angemeldet bleiben",
	'signin_privacy_policy_lnk': "Datenschutzlichtlinie",
	'signin_terms_of_use_lnk': "Nutzungsbedingungen",
	'signin_signin_btn': "Anmelden",
	'send-email-1_sentence1_lbl': "Haben Sie Ihr Password vergessen?",
	'send-email-1_sentence2_lbl': "Machen Sie sich keine Sorgen. Das Zurücksetzen Ihres Passwords ist ganz einfach. Nennen Sie uns einfach das Konto, das Sie bei EZT registriert haben.",
	'send-email-1_account_txt': "Konto",
	'send-email-1_send_btn': "Senden",
	'send-email-2_sentence1_lbl': "Password zurücksetzen",
	'send-email-2_sentence2_lbl': "Eine E-Mail mit dem URL-Link für das Zurücksetzen des Passwords wurde an die im EZT-Dienst registrierte Adresse gesendet. Bitte klicken Sie auf den Link, um ein neues Password festzulegen.",
	'send-email-2_sentence3_lbl': "Bitte prüfen Sie Ihre E-Mail-App, ggf. den Spam-Ordner, wenn Sie unsere E-Mail nicht finden. Ansonsten versuchen ",
	'send-email-2_retry_lnk': "Sie es bitte erneut.",
	'password-reset-1_sentence2_lbl': "{customer_name}, bitte legen Sie Ihr neues Password fest, um sich anzumelden.",
	'password-reset-1_password_txt': "Neues Password",
	'password-reset-1_sentence3_lbl': "Ein Password muss die Regeln unten befolgen;",
	'password-reset-1_sentence4_lbl': "- Mehr als 8 Schriftzeichen",
	'password-reset-1_sentence5_lbl': "- Mindestens ein Großbuchstabe (A-Z), ein Kleinbuchstabe (a-z) und eine Ziffer (0-9)",
	'password-reset-1_passwordchk_txt': "Bestätigung neues Passwords",
	'password-reset-1_save_btn': "Änderungen speichern",
	'password-reset-1_sentence1_lbl': "Password zurücksetzen",
	'password-reset-2_sentence1_lbl': "Das Zurücksetzen des Passwords ist erledigt.",
	'password-reset-2_sentence2_lbl': "{customer_name}, Ihr Password ist erfolgreich geändert.",
	'password-reset-2_intoezt_btn': "Anmeldung auf dem EZT",
	'main_signout_btn': "Abmeldung",
	'main_title1_lbl': "Raum",
	'main_title2_lnk': "Liste",
	'main_title3_lnk': "Aufzeichnung",
	'main_title4_lbl': "Information für Verwaltung",
	'main_title5_lnk': "Standort",
	'main_title6_lnk': "Kontaktinformation",
	'main_title7_lnk': "Gruppe",
	'main_title8_lnk': "Meetingsraum",
	'main_privacy_policy_lnk': "Datenschutzlichtlinie",
	'main_terms_of_use_lnk': "Nutzungsbedingungen",
	'list_location_pll': "Standort auswählen",
	'list_locationuser_txt': "Entweder nach dem Namen des Standorts oder nach dem Namen des Meetingraums suchen",
	'list_locationname_lbl': "Name des Standorts",
	'list_username_lbl': "Name des Meetingraums",
	'list_startdate_lbl': "Startdatum der Nutzung",
	'list_enddate_lbl': "Enddatum der Nutzung",
	'list_profile_btn': "Profil",
	'list_setting_btn': "Einstellung",
	'profile-view_title1_lbl': "Profil",
	'profile-view_title2_lbl': "Meeting",
	'profile-view_title3_lbl': "Aufzeichnung",
	'profile-view_username_lbl': "Name des Meetingraums",
	'profile-view_locationname_lbl': "Name des Standorts",
	'profile-view_usage_period_lbl': "Nutzungszeitraum",
	'profile-view_pmi_lbl': "Persönliche Meeting-ID",
	'profile-view_pmi_password': "Kenncode",
	'profile-view_sentence1_lbl': "Diese ID für ein sofortiges Meeting verwenden",
	'profile-view_language_lbl': "Sprache",
	'profile-view_datetime_lbl': "Datum und Uhrzeit",
	'profile-view_timezone_lbl': "Zeitzone",
	'profile-view_calendar_lbl': "Kalenderintegration",
	'profile-view_signin-password_lbl': "Password zur Anmeldung",
	'profile-view_edit_btn': "Bearbeiten",
	'profile-view_calendar_btn': "Konfiguration des Kalenderservices",
	'profile-edit_sentence3_lbl': "Wir unterstützen das folgende Service:Google calendar, Microsoft Exchanger, Microsoft Office 365",
	'profile-edit_new_password1_txt': "Neues Password",
	'profile-edit_sentence4_lbl': "Neues Password bestätigen",
	'profile-edit_new_password2_txt': "Neues Password",
	'profile-edit_sentence5_lbl': "Ein Password muss die Regeln unten befolgen;{br}- Mehr als 8 Schriftzeichen{br}- Mindestens ein Großbuchstabe (A-Z), ein Kleinbuchstabe (a-z) und eine Ziffer (0-9)",
	'profile-edit_setting_password_txt': "Passcode zum Entsperren",
	'profile-edit_host_key_txt': "Hostschlüssel",
	'profile-edit_host_key_setting_txt': "Bitte geben Sie eine 6-stellige Nummer als Hostschlüssel ein.",
	'profile-edit_save_btn': "Änderungen speichern",
	'profile-edit_cancel_btn': "Stornieren",
	'settings_title1_lbl': "Einstellung",
	'settings_title2_lbl': "Sicherheit",
	'settings_title3_lbl': "Ein Meeting planen",
	'settings_title4_lbl': "Basic",
	'settings_title5_lbl': "Weitere Einstellungen",
	'settings_title6_lbl': "Aufzeichnung",
	'settings_applies_btn': "An allen Räumen verwenden",
	'meeting_security_lbl': "Sicherheit",
	'meeting_security_sentence1_lbl': "Warteraum",
	'meeting_security_sentence2_lbl': "Bitte fragen Sie den Host darum bei der Teilnahme an einem Meeting nach, die Teilnehmer vorerst im Warteraum warten zu lassen und jeden einzelnen Teilnehmer in dem Meeting durch die Genehmigung von dem Host einzulassen.Nach der Aktivierung des Warteraums wird die automatische Einlassung der Teilnehmer ohne die Genehmigung von dem Host deaktiviert.",
	'meeting_security_sentence3_lbl': "Password in den Einladungslink eingeben, um mit einem Klick beizutreten",
	'meeting_security_sentence4_lbl': "Ein Meeting-Password wird verschlüsselt und in einen Einladungslink aufgenommen, damit die Teilnehmer nur mit einem Klick teilnehmen können, ohne den Passcode eingeben zu müssen.",
	'meeting_security_sentence5_lbl': "Kenncode für die persönliche Meeting-ID (PMI)",
	'meeting_security_passcode_txt': "Kenncode",
	'meeting_security_edit_btn': "Bearbeiten",
	'meeting_security_save_btn': "Änderungen speichern",
	'meeting_security_cancel_btn': "Stornieren",
	'meeting_schedule_lbl': "Ein Meeting planen",
	'meeting_schedule_sentence1_lbl': "Hostvideo",
	'meeting_schedule_sentence2_lbl': "Meeting mit dem eingeschalteten Hostvideo starten",
	'meeting_schedule_sentence3_lbl': "Teilnehmervideo",
	'meeting_schedule_sentence4_lbl': "Meeting mit dem eingeschalteten Teilnehmervideo starten. Die Teilnehmer können dies während des Meetings ändern.",
	'meeting_schedule_sentence9_lbl': "Persönliche Meeting-ID aktivieren",
	'meeting_schedule_sentence10_lbl': "Eine persönliche Meeting-ID (PMI) besteht aus einer 9 bis 11-stelligen Nummer und wird jedem einzelnen Konto verteilt. Die PMI-Einstellung kann in Ihrem individuellen Meetingraum geändert werden. ",
	'meeting_schedule_sentence11_lbl': "Meine persönliche Meeting-ID (PMI) beim Planen eines Meetings verwenden",
	'meeting_schedule_sentence12_lbl': "Sie können die Einstellung bezüglich des persönlichen Meetings in Ihrem persönlichen Meetingsraum ändern.",
	'meeting_schedule_sentence13_lbl': "Meine persönliche Meeting-ID (PMI) beim Beginn eines sofortigen Meetings verwenden",
	'meeting_basic_lbl': "Basic",
	'meeting_basic_sentence1_lbl': "Chat",
	'meeting_basic_sentence2_lbl': "Es wird allen Teilnehmern ermöglicht, ihre Messages allen anderen anzuzeigen",
	'meeting_basic_sentence5_lbl': "Automatisches Speichern des Chatverlaufs",
	'meeting_basic_sentence6_lbl': "Der gesamte Chatverlauf wird automatisch gespeichert, sodass der Host Texte des Chats nicht manuell zu speichern braucht.",
	'meeting_basic_sentence7_lbl': "Bei der Teilnahme und beim Verlassen eines Teilnehmers mit dem Ton benachrichtigen",
	'meeting_basic_sentence8_lbl': "Datei senden",
	'meeting_basic_sentence9_lbl': "Der Host und die Teilnehmer können Dateien durch den Chat während eines Meetings einander senden.",
	'meeting_basic_sentence19_lbl': "Bildschirmfreigabe",
	'meeting_basic_sentence20_lbl': "Der Host und die Teilnehmer können ihren Bildschirm sowie Inhalt des Meetings miteinander freigeben.",
	'meeting_basic_sentence21_lbl': "Wer kann freigeben?",
	'meeting_basic_sentence22_rbt': "Nur Host",
	'meeting_basic_sentence23_rbt': "Alle Teilnehmer",
	'meeting_basic_sentence38_lbl': "Fernbedienung",
	'meeting_basic_sentence39_lbl': "Der Teilnehmer, wer seinen Bildschilm freigibt, kann einem anderen Teilnehmer die Kontrolle der freigegebenen Inhalte erlauben.",
	'meeting_basic_sentence43_lbl': "Allen Teilnehmern erlauben, sich umzubennenen",
	'meeting_basic_sentence44_lbl': "Es wird allen Teilnehmern und Diskussionsteilnehmern erlaubt, sich während des Meetings umzubennenen.",
	'meeting_detail_sentence1_lbl': "Weitere Einstellungen",
	'meeting_detail_sentence4_lbl': "Einen ‚‚Von Ihrem Browser teilnehmen“ Link anzeigen",
	'meeting_detail_sentence5_lbl': "Es wird den Teilnehmern ermöglicht, den Download-Prozess der Zoom-Anwendung zu umgehen und einem Meeting direkt über ihren Browser beizutreten. Dies ist ein Workaround für Teilnehmer, die keine Anwendungen herunterladen, installieren oder ausführen können. Beachten Sie, dass die Meeting-Erfahrung über den Browser eingeschränkt ist.",
	'meeting_detail_save_btn': "Änderungen speichern",
	'meeting_detail_cancel_btn': "Stornieren",
	'recording_sentence1_lbl': "Aufzeichnung",
	'recording_sentence2_lbl': "Cloudaufzeichnung",
	'recording_sentence3_lbl': "Dem Host erlauben, dieses Meeting/ Webinar aufzuzeichnen und Dateien in der Cloud zu speichern.",
	'recording_sentence4_chk': "Den aktiven Sprecher auf dem freigegebenen Bildschirm aufzeichnen",
	'recording_sentence5_chk': "Galerieansicht mit dem freigegebenen Bildschirm aufzeichnen",
	'recording_sentence10_chk': "Datei nur mit Audio aufzeichnen",
	'recording_sentence11_chk': "Chatverlauf während eines Meetings/ Webinars speichern",
	'recording_sentence12_lbl': "Erweiterte Einstellungen über Cloudaufzeichnung",
	'recording_sentence13_chk': "Zeitstempel bei einer Aufzeichnung hinzufügen",
	'recording_sentence17_chk': "Audio-Transkpription",
	'recording_save_btn': "Änderungen speichern",
	'recording_cancel_btn': "Stornieren",
	'record-initial_location_pll': "Standort auswählen",
	'record-initial_startdate_cal': "Startdatum",
	'record-initial_enddate_cal': "Enddatum",
	'record-initial_calendar_date': "{year} Jahr  {month} Monat",
	'record-initial_topic_txt': "Nach Themen suchen",
	'record-initial_search_btn': "Suchen",
	'record-initial_selectdelete_btn': "Auswahl löschen",
	'record-delete_sentence2_lbl': "Sie möchten die ausgewählte Zeilen löschen. {br} Sind Sie sicher?",
	'record-initial_selectall_chk': "Thema",
	'record-initial_starttime_lbl': "Startdatum und Zeit",
	'record-initial_filecount_lbl': "Anzahl der Dateien",
	'record-initial_delete_deadline_lbl': "Frist zum Löschen",
	'record-initial_delete_daysafter_lbl': "Nach {after_days} Tage",
	'record-initial_delete_btn': "Löschen",
	'record-initial_cancel_btn': "Stornieren",
	'record-details_share_btn': "Freigeben",
	'record-details_recording': "Aufzeichnung",
	'record-details_sentence1_lbl': "Diese Aufzeichnung wird in {days} Tage automatisch gelöscht.",
	'record-details_playtime_lbl': "Wiedergabezeit {times}",
	'record-details_filisize_lbl': " {count} Datei ({size})",
	'record-details_download_btn': "Herunterladen（ {count} Datei）",
	'record-details_shareablelink_btn': "Den Link zur Freigabe kopieren",
	'record-details_videoaudio_lbl': "Video + Audio",
	'record-details_audio_lbl': "Nur Audio",
	'record-details_selectdelete_btn': "Aufzeichnung löschen",
	'record-details_delete_btn': "Löschen",
	'record-details_cancel_btn': "Stornieren",
	'record-details_clipboard_copy': "Der Link zur Freigabe wurde auf der Zwischenablage kopiert.",
	'record-share_sentence1_lbl': "Diese Cloudaufzeichnung freigeben",
	'record-share_sentence2_lbl': "Diese Aufzeichnung freigeben",
	'record-share_display_btn': "Anzeigen",
	'record-share_display_non_btn': "Ausblenden",
	'record-share_sentence4_lbl': "Thema:",
	'record-share_sentence5_lbl': "Datum:",
	'record-share_sentence6_lbl': "Aufzeichnung dieses Meetings:",
	'record-share_sentence7_lbl': "Passcode ergreifen:",
	'record-share_sentence8_lbl': "Freigegebene Information",
	'record-share_sentence9_lbl': "Es ist keinem anderen erlaubt, auf diese Aufzeichnung zuzugreifen.",
	'record-share_sentence10_lbl': "Passcode schützen",
	'record-share_save_btn': "Änderungen speichern",
	'record-share_cancel_btn': "Stornieren",
	'record-share_clipboard_copy': "Die freigegebene Information wurde auf dem Clipboard kopiert.",
	'record-copysharing_btn': "Freigegebene Information kopieren",
	'record-complete_btn': "Fertig",
	'base-initial_locationuser_txt': "Nach dem Standort suchen",
	'base-initial_addlocation_btn': "Standort hinzufügen",
	'base-initial_locationname_lbl': "Name des Standorts",
	'base-initial_roomcount_lbl': "Anzahl der Meetingsräume",
	'base-initial_selectdelete_btn': "Die ausgewählte Items löschen",
	'base-initial_edit_btn': "Bearbeiten",
	'base-additional_sentence1_lbl': "Standort hinzufügen",
	'base-additional_locationname_txt': "Name des Standorts",
	'base-edit_sentence1_lbl': "Standort bearbeiten",
	'base-edit_locationname_txt': "Name des Standorts",
	'base-edit_save_btn': "Änderungen speichern",
	'base-edit_addlocation_btn': "Hinzufügen",
	'base-edit_cancel_btn': "Stornieren",
	'base-delete_sentence1_lbl': "Standort löschen",
	'base-delete_sentence2_lbl': "Sie möchten die ausgewählte Zeilen löschen. {br} Sind Sie sicher?",
	'base-delete_delete_lbl': "Löschen",
	'base-delete_cancel_lbl': "Stornieren",
	'contact-initial_emailcontactname_txt': "Nach der E-Mail-Adresse und dem vollständigen Namen suchen",
	'contact-initial_import_bll': "Importieren",
	'contact-initial_import_addition': "Hinzufügen",
	'contact-initial_import_washing': "Datei komplett zum Neuen tauschen",
	'contact-initial_export_btn': "Exportieren",
	'contact-initial_addcontact_btn': "Kontakt hinzufügen",
	'contact-initial_selectdelete_btn': "Die ausgewählte Elemente löschen",
	'contact-initial_selectall_chk': "Name",
	'contact-initial_email_lbl': "E-Mail-Adresse",
	'contact-initial_edit_btn': "Bearbeitung",
	'contact-additional_sentence1_lbl': "Kontakt hinzufügen",
	'contact-additional_email_txt': "E-Mail-Adresse",
	'contact-additional_contactname_txt': "Name",
	'contact-additional_addcontact_btn': "Hinzufügen",
	'contact-additional_cancel_btn': "Stornieren",
	'contact-edit_sentence1_lbl': "Kontakt bearbeiten",
	'contact-edit_email_txt': "E-Mail-Adresse",
	'contact-edit_contactname_txt': "Name",
	'contact-edit_save_btn': "Änderungen speichern",
	'contact-edit_cancel_btn': "Stornieren",
	'contact-delete_sentence1_lbl': "Kontakt löschen",
	'contact-delete_sentence2_lbl': "Sie möchten die ausgewählte Zeilen löschen. {br} Sind Sie sicher?",
	'contact-delete_delete_btn': "Löschen",
	'contact-delete_cancel_btn': "Stornieren",
	'contact-csv_name': "Name",
	'contact-csv_sentence': "Kontakte",
	'contact-csv_washing_title': "Message",
	'contact-csv_washing_message': "Durch die Auswechselung zu den neuen Kontaktdaten werden alle alten Kontaktdaten in den existierenden Gruppen komplett gelöscht. {br} Ist es Ihnen in Ordnung?",
	'contact-import_ok_btn': "OK",
	'contact-import_cancel_btn': "Stornieren",
	'group-initial_groupname_txt': "Nach dem Gruppennamen suchen",
	'group-initial_addgroup_btn': "Gruppe hinzufügen",
	'group-initial_selectdelete_btn': "Die ausgewählte Elemente löschen",
	'group-delete_sentence2_lbl': "Sie möchten die ausgewählte Zeilen löschen. {br} Sind Sie sicher?",
	'group-initial_selectall_chk': "Gruppenname",
	'group-initial_membercount_lbl': "Anzahl der Mitglieder",
	'group-initial_edit_btn': "Bearbeiten",
	'group-initial_delete_btn': "Löschen",
	'group-initial_cancel_btn': "Stornieren",
	'group-additional_sentence1_lbl': "Gruppen hinzufügen",
	'group-additional_save_btn': "Änderungen speichern",
	'group-additional_cancel_btn': "Stornieren",
	'group-additional_delete_btn': "Löschen",
	'group-edit_sentence1_lbl': "Gruppe bearbeiten",
	'group-edit_delete_btn': "Löschen",
	'group-edit_emailcontactname_txt': "Nach der E-Mail-Adresse und dem vollständigen Namen suchen",
	'group-delete_sentence1_lbl': "Gruppe löschen",
	'expiration-date_check': "Password zurücksetzen",
	'expiration-date_message': "Gültigkeitsdauer ist abgelaufen.",
	'tab_title_signin': "Anmelden",
	'tab_title_mail_send': "E-Mail senden",
	'tab_title_send_complete': "Erfolgreich gesendet",
	'tab_title_password_reset': "Password zurücksetzen",
	'tab_title_reset_complete': "Das Password zurückgesetzt",
	'tab_title_meeting_list': "Liste des Meetingsraums",
	'tab_title_profile': "Profil",
	'tab_title_meeting_setting': "Meetingseinstellung",
	'tab_title_record_setting': "Auzeichnungseinstellungen",
	'tab_title_record_list': "Aufzeichnungsliste",
	'tab_title_record_details': "Aufzeichnungsdetails",
	'tab_title_location_list': "Standortliste",
	'tab_title_contact_list': "Kontaktliste",
	'tab_title_group_list': "Gruppenliste",
	'tab_title_group_edit': "Registrierung der Gruppe",
	'tab_title_meeting_room': "Meetingsraum",
	'meeting-room_url_txt': "URL vom Meetingsraum",
	'meeting-room_copyUrl_btn': "Diesen URL kopieren",
	'meeting-room_edit_btn': "Bearbeiten",
	'meeting-room_save_btn': "Änderungen speichern",
	'meeting-room_cancel_btn': "Stornieren",
	'meeting-room_password_txt': "Anzeigepasswort",
	'meeting-list-check_title_txt': "Details über Meetingsraum",
	'meeting-list-check_placeholder': "Password",
	'meeting-list-check_password_save_txt': "Passworteingabe ab dem nächsten Mal überspringen",
	'meeting-list-check_show_btn': "Anzeigen",
	'meeting-list_title_txt': "Details über Meetingsraum",
	'meeting-list_locationname_txt': "Name des Standorts",
	'meeting-list_meetingRoom_txt': "Name des Meetingraums",
	'meeting-list_meetingID_txt': "Meeting-ID",
	'meeting-list_passcode_txt': "Kenncode",
	'meeting-list_goto_meeting_btn': "An diesem Meeting teilnehmen",
	'meeting-list_email_copy_btn': "Dieses Einladungslink kopieren",
	'meeting-list_disabled_pmi': "Kein PMI ist verwendet.",
	'meeting-list_location_pll': "Standort auswählen",
	'meeting-list_locationuser_txt': "Entweder nach dem Namen des Standorts oder nach dem Namen des Meetingraums suchen",
	'meeting-list_email_txt': "An diesem Meeting entweder durch EZT oder durch Zoom teilnehmen.",
	'meeting-list_email_id': "Meeting-ID:",
	'meeting-list_email_pwd': "Kenncode:",
	'ERROR_0001': "Systemfehler",
	'ERROR_0002': "Ungültiges Konto",
	'ERROR_0003': "Entweder der Kontoname oder das Password stimmt nicht überein.Bitte versuchen Sie es noch einmal, Information für die Anmeldung einzugeben. Gegebenenfalls fordern Sie eine E-Mail von zoom, welche Ihnen wieder die Zugangsberechtigung erteilt.",
	'ERROR_0004': "Bitte geben Sie die Information Ihres Kontos ein.",
	'ERROR_0005': "Ein Gruppenname muss eingegeben werden.",
	'ERROR_0006': "Das Konto kann nicht gefunden werden.",
	'ERROR_0008': "Dieser Name ist für einen anderen Meetingsraum bereits verwendet.",
	'ERROR_0009': "Dieser Standort ist bereits eingetragen. Bitte wählen Sie einen anderen Standort aus.",
	'ERROR_0010': "Dieser Gruppenname ist bereits verwendet.",
	'ERROR_0012': "Diese persönliche Meeting-ID ist bereits verwendet.",
	'ERROR_0013': "Diese E-Mail-Adresse ist bereits verwendet.",
	'ERROR_0014': "Fehler beim Löschen dieser Datei.",
	'ERROR_0015': "Fehler bei der Eintragung dieser Datei.",
	'ERROR_0016': "Fehler bei der Aktualisierung dieser Datei.",
	'ERROR_0017': "Eine persönliche Meetings-ID muss eingegeben werden und aus einer 10-stelligen Zahl bestehen.",
	'ERROR_0019': "Das neue Password entspricht nicht den Anforderungen der Passwordrichtlinie.",
	'ERROR_0020': "Bitte geben Sie das Password ein.",
	'ERROR_0021': "Ein Hostschlüssel muss eingegeben werden und eine 6-stellige Zahl sein. Er kann nicht in einem einfachen Format wie im folgenden Beispiel sein: 111111, 222222, 123456",
	'ERROR_0022': "Eine E-Mail-Adresse muss eingetragen werden.",
	'ERROR_0023': "Ein Name des Meetingsraums muss eingegeben werden.",
	'ERROR_0024': "Das Password zur Bestätigung stimmt nicht überein.",
	'ERROR_0025': "Ein Standort muss eingetragen werden.",
	'ERROR_0026': "Ihr vollständiger Name muss eingetragen werden.",
	'ERROR_0027': "Ein neues Password muss eingegeben werden.",
	'ERROR_0028': "Ein neues Password muss sich vom alten Password unterscheiden.",
	'ERROR_0029': "Passcode zum Entsperren ist erforderlich.",
	'ERROR_0030': "Bitte wählen Sie eine csv-Datei aus.",
	'ERROR_0031': "Es ist fehlgeschlagen, diese Datei zu exportieren.",
	'ERROR_0032': "Es ist fehlgeschlagen, diese Datei zu importieren.",
	'ERROR_0033': "Es gibt keine Datei zu exportieren.",
	'ERROR_0034': "Diese Datei kann nicht geladen werden.",
	'ERROR_0036': "{success_cnt} von den ausgewählten Dateien wurden zwar erfolgreich gelöscht. Jedoch {error_cnt} von den ausgewählten Dateien wurden nicht gelöscht. Bitte versuchen Sie es noch einmal.",
	'ERROR_0037': "Bitte geben Sie die richtige E-Mail-Adresse ein.",
	'ERROR_0038': "Sie müssen mindestens eine Datei zum Speichern in der Cloud auswählen, wenn die Cloudaufzeichnung aktiv ist.",
	'ERROR_0039': "Ein unbefugter Zugriff wurde erkannt.",
	'ERROR_0040': "Sie können Ihren Standort nicht ändern, sonst wird die Anzahl des Meetingraums 0.",
	'ERROR_0041': "Dieses Password ist falsch. Bitte geben Sie es erneut ein.",
	'SUCCESS_0001': "Die Einstellung wurde aktualisiert.",
	'SUCCESS_0002': "Die Nutzerinformation wurde erfolgreich gespeichert.",
	'SUCCESS_0003': "Die Datei wurde erfolgreich gelöscht.",
	'SUCCESS_0004': "Die Datei wurde erfolgreich eingetragen.",
	'SUCCESS_0005': "Die Datei wurde erfolgreich aktualisiert.",
	'SUCCESS_0006': "Die Datei wurde erfolgreich exportiert.",
	'SUCCESS_0007': "Die Datei wurde erfolgreich importiert.",
	'SUCCESS_0008': "Dieser URL wurde kopiert.",
	'SUCCESS_0009': "Dieser Einladungstext wurde kopiert.",
	'TIMEZONE_0001': "(GMT-11:00) Midway Island, Samoa",
	'TIMEZONE_0002': "(GMT-11:00) Pago Pago",
	'TIMEZONE_0003': "(GMT-10:00) Hawaii",
	'TIMEZONE_0004': "(GMT-8:00) Alaska",
	'TIMEZONE_0005': "(GMT-8:00) Juneau",
	'TIMEZONE_0006': "(GMT-7:00) Vancouver",
	'TIMEZONE_0007': "(GMT-7:00) Pacific Time (USA und Kanada)",
	'TIMEZONE_0008': "(GMT-7:00) Tijuana",
	'TIMEZONE_0009': "(GMT-7:00) Arizona",
	'TIMEZONE_0010': "(GMT-6:00) Edmonton",
	'TIMEZONE_0011': "(GMT-6:00) Mountain Time (USA und Kanada)",
	'TIMEZONE_0012': "(GMT-6:00) Mazatlán",
	'TIMEZONE_0013': "(GMT-6:00) Saskatchewan",
	'TIMEZONE_0014': "(GMT-6:00) Guatemala",
	'TIMEZONE_0015': "(GMT-6:00) El Salvador",
	'TIMEZONE_0016': "(GMT-6:00) Managua",
	'TIMEZONE_0017': "(GMT-6:00) Costa Rica",
	'TIMEZONE_0018': "(GMT-6:00) Tegucigalpa",
	'TIMEZONE_0019': "(GMT-6:00) Chihuahua",
	'TIMEZONE_0020': "(GMT-5:00) Winnipeg",
	'TIMEZONE_0021': "(GMT-5:00) Central Time (USA und Kanada)",
	'TIMEZONE_0022': "(GMT-5:00) Mexiko-Stadt",
	'TIMEZONE_0023': "(GMT-5:00) Panama",
	'TIMEZONE_0024': "(GMT-5:00) Bogota",
	'TIMEZONE_0025': "(GMT-5:00) Lima",
	'TIMEZONE_0026': "(GMT-5:00) Monterrey",
	'TIMEZONE_0027': "(GMT-4:00) Montreal",
	'TIMEZONE_0028': "(GMT-4:00) Eastern Time (USA und Kanada)",
	'TIMEZONE_0029': "(GMT-4:00) Indiana (Osten)",
	'TIMEZONE_0030': "(GMT-4:00) Puerto Rico",
	'TIMEZONE_0031': "(GMT-4:00) Caracas",
	'TIMEZONE_0032': "(GMT-4:00) Santiago",
	'TIMEZONE_0033': "(GMT-4:00) La Paz",
	'TIMEZONE_0034': "(GMT-4:00) Guyana",
	'TIMEZONE_0035': "(GMT-3:00) Halifax",
	'TIMEZONE_0036': "(GMT-3:00) Montevideo",
	'TIMEZONE_0037': "(GMT-3:00) Recife",
	'TIMEZONE_0038': "(GMT-3:00) Buenos Aires, Georgetown",
	'TIMEZONE_0039': "(GMT-3:00) Sao Paulo",
	'TIMEZONE_0040': "(GMT-3:00) Atlantic Time (Kanada)",
	'TIMEZONE_0041': "(GMT-2:30) Neufundland und Labrador",
	'TIMEZONE_0042': "(GMT-2:00) Grönland",
	'TIMEZONE_0043': "(GMT-1:00) Kapverdische Inseln",
	'TIMEZONE_0044': "(GMT+0:00) Azoren",
	'TIMEZONE_0045': "(GMT+0:00) Universal Time UTC",
	'TIMEZONE_0046': "(GMT+0:00) Greenwich-Zeit",
	'TIMEZONE_0047': "(GMT+0:00) Reykjavik",
	'TIMEZONE_0048': "(GMT+0:00) Nouakchott",
	'TIMEZONE_0049': "(GMT+1:00) Casablanca",
	'TIMEZONE_0050': "(GMT+1:00) Dublin",
	'TIMEZONE_0051': "(GMT+1:00) London",
	'TIMEZONE_0052': "(GMT+1:00) Lissabon",
	'TIMEZONE_0053': "(GMT+1:00) West-Zentral-Afrika",
	'TIMEZONE_0054': "(GMT+1:00) Algier",
	'TIMEZONE_0055': "(GMT+1:00) Tunis",
	'TIMEZONE_0056': "(GMT+2:00) Belgrad, Bratislava, Ljubljana",
	'TIMEZONE_0057': "(GMT+2:00) Sarajevo, Skopje, Zagreb",
	'TIMEZONE_0058': "(GMT+2:00) Oslo",
	'TIMEZONE_0059': "(GMT+2:00) Kopenhagen",
	'TIMEZONE_0060': "(GMT+2:00) Brüssel",
	'TIMEZONE_0061': "(GMT+2:00) Amsterdam, Berlin, Rom, Stockholm, Wien",
	'TIMEZONE_0062': "(GMT+2:00) Amsterdam",
	'TIMEZONE_0063': "(GMT+2:00) Rom",
	'TIMEZONE_0064': "(GMT+2:00) Stockholm",
	'TIMEZONE_0065': "(GMT+2:00) Wien",
	'TIMEZONE_0066': "(GMT+2:00) Luxemburg",
	'TIMEZONE_0067': "(GMT+2:00) Paris",
	'TIMEZONE_0068': "(GMT+2:00) Zürich",
	'TIMEZONE_0069': "(GMT+2:00) Madrid",
	'TIMEZONE_0070': "(GMT+2:00) Harare, Pretoria",
	'TIMEZONE_0071': "(GMT+2:00) Warschau",
	'TIMEZONE_0072': "(GMT+2:00) Prag Bratislava",
	'TIMEZONE_0073': "(GMT+2:00) Budapest",
	'TIMEZONE_0074': "(GMT+2:00) Tripolis",
	'TIMEZONE_0075': "(GMT+2:00) Kairo",
	'TIMEZONE_0076': "(GMT+2:00) Johannesburg",
	'TIMEZONE_0077': "(GMT+2:00) Khartum",
	'TIMEZONE_0078': "(GMT+3:00) Helsinki",
	'TIMEZONE_0079': "(GMT+3:00) Nairobi",
	'TIMEZONE_0080': "(GMT+3:00) Sofia",
	'TIMEZONE_0081': "(GMT+3:00) Istanbul",
	'TIMEZONE_0082': "(GMT+3:00) Athen",
	'TIMEZONE_0083': "(GMT+3:00) Bukarest",
	'TIMEZONE_0084': "(GMT+3:00) Nicosia",
	'TIMEZONE_0085': "(GMT+3:00) Beirut",
	'TIMEZONE_0086': "(GMT+3:00) Damaskus",
	'TIMEZONE_0087': "(GMT+3:00) Jerusalem",
	'TIMEZONE_0088': "(GMT+3:00) Amman",
	'TIMEZONE_0089': "(GMT+3:00) Moskau",
	'TIMEZONE_0090': "(GMT+3:00) Bagdad",
	'TIMEZONE_0091': "(GMT+3:00) Kuwait",
	'TIMEZONE_0092': "(GMT+3:00) Riad",
	'TIMEZONE_0093': "(GMT+3:00) Bahrain",
	'TIMEZONE_0094': "(GMT+3:00) Katar",
	'TIMEZONE_0095': "(GMT+3:00) Aden",
	'TIMEZONE_0096': "(GMT+3:00) Dschibuti",
	'TIMEZONE_0097': "(GMT+3:00) Mogadischu",
	'TIMEZONE_0098': "(GMT+3:00) Kiew",
	'TIMEZONE_0099': "(GMT+3:00) Minsk",
	'TIMEZONE_0100': "(GMT+4:00) Dubai",
	'TIMEZONE_0101': "(GMT+4:00) Maskat",
	'TIMEZONE_0102': "(GMT+4:00) Baku, Tiflis, Jerevan",
	'TIMEZONE_0103': "(GMT+4:30) Teheran",
	'TIMEZONE_0104': "(GMT+4:30) Kabul",
	'TIMEZONE_0105': "(GMT+5:00) Jekaterinburg",
	'TIMEZONE_0106': "(GMT+5:00) Islamabad, Karatschi, Taschkent",
	'TIMEZONE_0107': "(GMT+5:30) Indien",
	'TIMEZONE_0108': "(GMT+5:30) Mumbai, Kalkutta, Neu Delhi",
	'TIMEZONE_0109': "(GMT+5:30) Asia/Colombo",
	'TIMEZONE_0110': "(GMT+5:45) Katmandu",
	'TIMEZONE_0111': "(GMT+6:00) Almaty",
	'TIMEZONE_0112': "(GMT+6:00) Dhaka",
	'TIMEZONE_0113': "(GMT+6:00) Astana, Dhaka",
	'TIMEZONE_0114': "(GMT+6:30) Rangun",
	'TIMEZONE_0115': "(GMT+7:00) Novosibirsk",
	'TIMEZONE_0116': "(GMT+7:00) Krasnojarsk",
	'TIMEZONE_0117': "(GMT+7:00) Bangkok",
	'TIMEZONE_0118': "(GMT+7:00) Vietnam",
	'TIMEZONE_0119': "(GMT+7:00) Jakarta",
	'TIMEZONE_0120': "(GMT+8:00) Irkutsk, Ulaanbaatar",
	'TIMEZONE_0121': "(GMT+8:00) Beijing, Shanghai",
	'TIMEZONE_0122': "(GMT+8:00) Hongkong SAR",
	'TIMEZONE_0123': "(GMT+8:00) Taipeh",
	'TIMEZONE_0124': "(GMT+8:00) Kuala Lumpur",
	'TIMEZONE_0125': "(GMT+8:00) Singapur",
	'TIMEZONE_0126': "(GMT+8:00) Perth",
	'TIMEZONE_0127': "(GMT+9:00) Jakutsk",
	'TIMEZONE_0128': "(GMT+9:00) Seoul",
	'TIMEZONE_0129': "(GMT+9:00) Osaka, Sapporo, Tokio",
	'TIMEZONE_0130': "(GMT+9:30) Darwin",
	'TIMEZONE_0131': "(GMT+9:30) Adelaide",
	'TIMEZONE_0132': "(GMT+10:00) Wladiwostok",
	'TIMEZONE_0133': "(GMT+10:00) Guam, Port Moresby",
	'TIMEZONE_0134': "(GMT+10:00) Brisbane",
	'TIMEZONE_0135': "(GMT+10:00) Canberra, Melbourne, Sydney",
	'TIMEZONE_0136': "(GMT+10:00) Hobart",
	'TIMEZONE_0137': "(GMT+11:00) Magadan",
	'TIMEZONE_0138': "(GMT+11:00) Salomonen",
	'TIMEZONE_0139': "(GMT+11:00) Neukaledonien",
	'TIMEZONE_0140': "(GMT+12:00) Kamtschatka",
	'TIMEZONE_0141': "(GMT+12:00) Fidschiinseln, Marschallinseln",
	'TIMEZONE_0142': "(GMT+12:00) Auckland, Wellington",
	'TIMEZONE_0143': "(GMT+13:00) Unabhängiger Staat Samoa",
};
export default LangDE;
