import React, { useState }  from 'react';
import { useSelector, useDispatch } from 'react-redux';


import { getVisible } from '../../../store/slicer/0102_messagebox';
import { setSideMenu } from '../../../store/slicer/0501_menu';
import { clearRecord } from '../../../store/slicer/1001_record';

import Header from '../../0003_components/0001_header';
import Sidemenu from '../../0003_components/0002_sidemenu';
import MessageBox from '../../0003_components/0004_message_ok_only';
import toast, { Toaster } from 'react-hot-toast';

import RecordDetails from '../../0003_components/1101_record_details';

export default function Record(props) {
	const dispatch = useDispatch();
	// メッセージボックス
	const storeVisible = useSelector(getVisible);

	// 初期化
	const [state, setState] = useState(() => {
		dispatch(setSideMenu(1));
		dispatch(clearRecord());
		toast.dismiss();
		return 0;
	});

	return(
		<div class="wrapper">
			<Header />
			<div class="container">
				<div class="contents">
					{storeVisible && <div id="modal_overlay"></div>}
					<Sidemenu />
					{storeVisible && <MessageBox />}
					{!storeVisible && 
						<div class="in_contents record-details">
						<style jsx="true" />
							<Toaster position="top-center" reverseOrder="false" containerStyle={{ marginTop:'75px'}} />
							<RecordDetails />
						</div>
					}
				</div>
			</div>
		</div>
	);
}
