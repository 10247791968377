import * as CONST from '../../../const';

import { useState, useEffect }  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory  } from 'react-router-dom';
import { getAccessToken, getRefreshToken, setAccessToken, logout } from '../../../store/slicer/0101_session';
import { getProfile, setProfile, setUserProfile, setUserSettingDefault, setUserSettingUpdate, getUserSettingUpdate } from '../../../store/slicer/0503_room';
import { setProfileMenu, setSideMenu } from '../../../store/slicer/0501_menu';
import { SetErrorMessage } from '../../../store/slicer/0101_logout_message';
import { useIntl } from "react-intl";



import Header from '../../0003_components/0001_header';
import Sidemenu from '../../0003_components/0002_sidemenu';
import UserProfileHeader from '../../0003_components/0691_user_profile_header';

import UserProfileBody_01 from '../../0003_components/0601_user_profile_body';
import UserProfileBody_02 from '../../0003_components/0602_user_profile_body';
import UserProfileBody_04 from '../../0003_components/0604_user_profile_body';
import UserProfileBody_05 from '../../0003_components/0605_user_profile_body';
// カレンダー連携は非表示とする
// import UserProfileBody_06 from '../../0003_components/0606_user_profile_body';
import UserProfileBody_07 from '../../0003_components/0607_user_profile_body';
import UserProfileBody_08 from '../../0003_components/0608_user_profile_body';
// ホストキーは非表示とする
// import UserProfileBody_09 from '../../0003_components/0609_user_profile_body';

import toast, { Toaster } from 'react-hot-toast';

export default function UserProfile(props) {
	const dispatch = useDispatch();
	const loc = useLocation();
	const history = useHistory();

	//ミーティング
	const storeSetting = useSelector(getUserSettingUpdate);

	// プロフィール
	const storeProfile = useSelector(getProfile);
	
	const intl = useIntl();

	var access_token = useSelector(getAccessToken);
	var refresh_token = useSelector(getRefreshToken);

	// 初期化
	const [state, setState] = useState(() => {
		dispatch(setSideMenu(0));
		dispatch(setProfileMenu({top:0, side:0}));
		toast.dismiss();
		
		if (loc.state !== undefined && loc.state.data !== undefined) {
			// プロフィールのセット
			dispatch(setProfile(loc.state.data));
			const user_id = loc.state.data.user_id;

			const fetchHttpRefresh = async () => {
				let senddata = {};
				let url_tokref = CONST.BASEURL + '/authorize/refresh';
				var httpResponse_tokref = await fetch( url_tokref, {
					method:'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': "Bearer " + refresh_token
					},
					body: JSON.stringify(senddata),
				});
				switch (httpResponse_tokref.status) {
					case 200:
						var httpResponse_tokref_json = await httpResponse_tokref.json();
						access_token = httpResponse_tokref_json.access_token;
						refresh_token = httpResponse_tokref_json.refresh_token;
						// トークンをデコードする
						//const decode_token = jwt(access_token);
						// 問題ない場合は、storeに保存
						dispatch(setAccessToken(access_token));
						//dispatch(setRefreshToken(refresh_token));
						fetchHttp();
						return;
					default:
						dispatch(SetErrorMessage('ERROR_0001'));
						dispatch(logout());
						return;
				}
			}


			//fetchHttpRefresh();
			////////////////////////////////
			// ユーザープロファイル取得
			////////////////////////////////
			const fetchHttp = async () => {
				fetch(CONST.BASEURL + '/users/' + user_id , {
					method:'GET',
					headers: {
						Authorization: "Bearer " + access_token
					},
				})
				.then(response => {
					switch (response.status) {
					case 200:
						response.json().then(data => ({
							data: data,
							status: response.status
						})
						).then(res=> {
							// ユーザープロファイルの項目をセット
							dispatch(setUserProfile(res.data));
							return;
						})
						break;

					case 401:
						fetchHttpRefresh();
						return;
					case 409:
						dispatch(SetErrorMessage('ERROR_0039'));  						
						dispatch(logout());
						return;
					default:
						response.json().then(data => ({
							data: data,
							status: response.status
						})
						).then(res=> {
							toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});
							return;
						})
						break;
					}
				})
				.catch((err) => {
					toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});
				});
			}
			fetchHttp();


			const fetchHttpRefresh2 = async () => {
				let senddata = {};
				let url_tokref = CONST.BASEURL + '/authorize/refresh';
				var httpResponse_tokref = await fetch( url_tokref, {
					method:'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': "Bearer " + refresh_token
					},
					body: JSON.stringify(senddata),
				});
				switch (httpResponse_tokref.status) {
					case 200:
						var httpResponse_tokref_json = await httpResponse_tokref.json();
						access_token = httpResponse_tokref_json.access_token;
						refresh_token = httpResponse_tokref_json.refresh_token;

						// 問題ない場合は、storeに保存
						dispatch(setAccessToken(access_token));
						fetchHttp2();
						return;					                       
					default:
						dispatch(SetErrorMessage('ERROR_0001'));
						dispatch(logout());
						return;
				}
			}

			//fetchHttpRefresh();
			////////////////////////////////
			// ユーザー設定取得
			////////////////////////////////
			const fetchHttp2 = async () => {
				fetch(CONST.BASEURL + '/users/' + user_id + '/settings', {
					method:'GET',
					headers: {
						Authorization: "Bearer " + access_token
					},
				})
				.then(response => {
					switch (response.status) {
					case 200:
						response.json().then(data => ({
							data: data,
							status: response.status
						})
						).then(res=> {
							// ユーザー設定の項目をセット
							dispatch(setUserSettingDefault(res.data));
							dispatch(setUserSettingUpdate(res.data));
							return;
						})
						break;
					case 401:
						fetchHttpRefresh2();
						return;
					case 409:
						dispatch(SetErrorMessage('ERROR_0039'));  
						dispatch(logout());
						return;
					default:
						response.json().then(data => ({
							data: data,
							status: response.status
						})
						).then(res=> {
							toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});
						})
						break;
					}
				})
				.catch((err) => {
					toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});
				});
			}
			fetchHttp2();

		}

		return 0;
	});


	// サイドエフェクト
	useEffect(() => {
		// ユーザープロフィールが取得できない場合は、不正として、/roomに移行
		if ((loc === undefined || loc.state === undefined || loc.state.data === undefined || loc.state.data.user_id === undefined || loc.state.data.user_id === '') && 
			(storeProfile.user_id === undefined || storeProfile.user_id === '')
		) {
			history.push('/room');
		}
	}, []);


	// 描画
	return (
		<div class="wrapper">
			<Header />
			<div class="container">
				<div class="contents">
					<Sidemenu />
					<div class="in_contents profile-view">
						<UserProfileHeader />
						<style jsx="true" />
						<Toaster position="top-center" reverseOrder="false" containerStyle={{ marginTop:'75px'}} />
						<div class="tab_box">
							<table class="in_tab_box_table">
								<UserProfileBody_01 />
								{storeSetting.schedule_meeting.personal_meeting &&
									<>
									<UserProfileBody_02 />
									</>
								}

								<UserProfileBody_04 />
								<UserProfileBody_05 />
								<UserProfileBody_07 />
								<UserProfileBody_08 />
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

/* カレンダー連携とホストキーは非表示とする
								<UserProfileBody_04 />
								<UserProfileBody_05 />
								<UserProfileBody_06 />
								<UserProfileBody_07 />
								<UserProfileBody_08 />
								<UserProfileBody_09 />
*/
