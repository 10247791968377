import React from 'react';
import toast from 'react-hot-toast';

export default class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
			hasError: false,
			error: null,
			errorInfo: null,
		};
		toast.dismiss();
	}

	// 
	static getDerivedStateFromError(error) {
		return { hasError: true };
	}

	// エラーキャッチ用
	componentDidCatch(error, errorInfo) {
		//logErrorToMyService(error, errorInfo);
		this.setState({ error, errorInfo })
	}

	render() {
		if (this.state.hasError) {
			return (
				<div>
					<h1>エラー</h1>
					<pre>{this.state.error?.message}</pre>
					<pre>{this.state.errorInfo?.componentStack}</pre>
				</div>
			);
		}
		return this.props.children;
	}
}
