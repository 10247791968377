import { createSlice } from '@reduxjs/toolkit';


// Redux Toolkitを使用すると、reducersで「変更」ロジックを記述できます。 
// 「draft state」への変更を検出しそれらの変更に基づいて
// まったく新しい不変の状態を生成するImmerライブラリを使用するため
// 実際には状態を変更しません。
export const recordDetailsSlice = createSlice({
	name: 'record_details',
	initialState: {
		records: {
			meeting_id: '',
			meeting_uuid: '',
			auto_delete_cmr_days: '',
        },
	},
	reducers: {
		setRecords: (state, action) => {
			state.records.meeting_id = action.payload.meeting_id;
			state.records.meeting_uuid = action.payload.meeting_uuid;
			state.records.auto_delete_cmr_days = action.payload.auto_delete_cmr_days;
		},
	},
});

export const { setRecords } = recordDetailsSlice.actions;


// サンク：非同期ロジックの実行
// dispatch(incrementByAmount(amount))
// 最初の引数として dispatch関数を使用してサンクが呼び出されます。
// その後、非同期コードを実行し、他のアクションをディスパッチできます
/*
export const incrementAsync = amount => dispatch => {
  setTimeout(() => {
    dispatch(incrementByAmount(amount));
  }, 1000);
};
*/

// セレクター：state から値を取得する
// useSelector((state) => state.counter.value) のようにインラインで定義もできる

export const getRecords = state => state.record_details.records;


// レデューサーをエクスポートする
export default recordDetailsSlice.reducer;

