import React, { useCallback } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { setResult, getMessagebox, getVisible } from '../../store/slicer/0102_messagebox';
import { FormattedMessage } from 'react-intl';


export default function MessageBoxOkOnlyMultilingual(props) {
	const dispatch = useDispatch();
	const storeMessagebox = useSelector(getMessagebox);
	const storeVisible = useSelector(getVisible);

	// onSubmitイベントハンドラ
	const handleOnSubmit = useCallback(event => {
		dispatch(setResult('ok'));
	}, []);

	// レンダリング
	return (
		<div>
			{storeVisible && 
				<div class="in_contents record-share">
					<div id="modal_container">
						<header class="modal_header">
							<h2><FormattedMessage id={storeMessagebox.title} /></h2>
						</header>
						<div class="modal_contents blank_above_m">
							<div class="inline_block element_width_xl"><FormattedMessage id={storeMessagebox.message} /></div> 
							<div class="inline_block element_width_xl blank_above_ms"></div>
						<div class="flex_box right_justified blank_above_m">
							<div class="element_width_ms"><input class="buttons_common btn_blue" type="button" onClick={handleOnSubmit} value="OK" autofocus="true" /></div>
							</div>
						</div>
					</div>
				</div>
			}
		</div>
	);
}
