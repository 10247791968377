import * as CONST from '../../const';
import { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserProfile, getUserSettingUpdate, setUserSettingUpdate } from '../../store/slicer/0503_room';
import { getAccessToken, getRefreshToken, setAccessToken, logout } from '../../store/slicer/0101_session';
import { SetErrorMessage } from '../../store/slicer/0101_logout_message';
import toast from 'react-hot-toast';
import { FormattedMessage, useIntl } from "react-intl";


export default function UserMeetingBody(props) {
    const dispatch = useDispatch();

    // プロフィール
    const storeUserProfile = useSelector(getUserProfile);
    const storeUserSetting = useSelector(getUserSettingUpdate);

	var access_token = useSelector(getAccessToken);
	var refresh_token = useSelector(getRefreshToken);
    const [state, setState] = useState(() => {
        return {
            check: storeUserSetting.in_meeting.waiting_room,
        }
	});

	const intl = useIntl();

    // onChangeイベントハンドラ
    const handleOnChange = event => {
        const now_check = state.check;
        const check = state.check ? false : true;
        setState({ ...state, check: check });

		const fetchHttpRefresh = async () => {
			let senddata = {
			};
			let url_tokref = CONST.BASEURL + '/authorize/refresh';
			var httpResponse_tokref = await fetch( url_tokref, {
				method:'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': "Bearer " + refresh_token
				},
				body: JSON.stringify(senddata),
			});
			switch (httpResponse_tokref.status) {
				case 200:
					var httpResponse_tokref_json = await httpResponse_tokref.json();
					access_token = httpResponse_tokref_json.access_token;
					refresh_token = httpResponse_tokref_json.refresh_token;
					// トークンをデコードする
					//const decode_token = jwt(access_token);
					// 問題ない場合は、storeに保存
					dispatch(setAccessToken(access_token));
					//dispatch(setRefreshToken(refresh_token));
					fetchHttp();
					return;				       
				default:
					toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

					dispatch(logout());
					return;
			}
		}

		//fetchHttpRefresh();
		// ユーザー設定更新
        const senddata = { ...storeUserSetting, in_meeting: { ...storeUserSetting.in_meeting, waiting_room: check } };
		const zoom_senddata = { in_meeting: { waiting_room: check } };

		const fetchHttp = async () => {
	        fetch(CONST.BASEURL + '/users/' + storeUserProfile.user_id + '/settings', {
	            method: 'PATCH',
	            headers: {
	                'Content-Type': 'application/json',
					Authorization: "Bearer " + access_token
	            },
				body: JSON.stringify(zoom_senddata),
	        })
	        .then(response => {
	            switch (response.status) {
	                case 204:
	                    // 正常終了
	                    dispatch(setUserSettingUpdate(senddata));

	                    //成功通知
	                    toast(intl.formatMessage({ id: 'SUCCESS_0001' }),{style:{background:  CONST.HOT_TOAST_SUCCESS}});
	                    return;
					case 401:
						fetchHttpRefresh();
						return;
					case 409:
						dispatch(SetErrorMessage('ERROR_0039'));  
						dispatch(logout());
						return; 
	                default:
	                    setState({ ...state, check: now_check });
	                    response.json().then(data => ({
	                        data: data,
	                        status: response.status
	                    })
	                    ).then(res => {
	                        switch (res.status) {
	                            case 400:
	                                //Error出力
									toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

	                                break;

	                            default:
	                                //Error出力
									toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

	                                break;
	                        }
						}).catch((err) => {
							//Error出力
							toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

						});
	            }
	        })
	        .catch((err) => {
	            setState({ ...state, check: now_check });

	            //Error出力
				toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

	        });
		}
		fetchHttp();
		
    }


	// サイドエフェクト
	useEffect(() => {
		if (state.check !==storeUserSetting.in_meeting.waiting_room) {
			setState({ ...state, check: storeUserSetting.in_meeting.waiting_room });
		}
	}, [state.check, storeUserSetting.in_meeting.waiting_room]);

	// チェック押下
	const handleChecktClick = event => {
		document.getElementById("toggle1").click();
	}

    // 描画
    return (
        <tbody class="lump">
            <tr>
                <td>
                    <div class="flex_box">
						<p class="save_itme_name"><FormattedMessage id="meeting_security_sentence1_lbl" /></p>
                        <div class="toggle-switch">
							<input tabindex="-1" id="toggle1" class="toggle-input" type='checkbox' checked={state.check} onChange={handleOnChange} />
							<a tabindex="0" onClick={handleChecktClick} href="javascript:void(0);" class="toggle-label" />
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
				<td><p><FormattedMessage id="meeting_security_sentence2_lbl" values={{ br: <br /> }}/></p>
                </td>
            </tr>
        </tbody>
    );
}
