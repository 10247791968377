import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom'

import { connect } from 'react-redux';
import { setLanguage } from './store/slicer/0103_language';
import { IntlProvider } from 'react-intl';
import ChangeLanguage from './lang//ChangeLanguage'

import GuestRoute from './components/0001_sections/0001_GuestRoute';
import PrivateRoute from './components/0001_sections/0002_PrivateRoute';
import ErrorBoundary from './components/0002_pages/0401_error';

import Home from './components/0002_pages/0001_home';
import Login from './components/0002_pages/0101_login';
import Forget from './components/0002_pages/0201_forget';
import PassReset from './components/0002_pages/0301_password_reset';

import Room from './components/0002_pages/0002_PrivateRoute/0501_room';
import UserProfile from './components/0002_pages/0002_PrivateRoute/0601_user_profile';
import UserMeeting from './components/0002_pages/0002_PrivateRoute/0801_user_meeting';
import UserRecording from './components/0002_pages/0002_PrivateRoute/0901_user_recording';

import Record from './components/0002_pages/0002_PrivateRoute/1001_recording';
import RecordDetails from './components/0002_pages/0002_PrivateRoute/1101_recording_details';

import Location from './components/0002_pages/0002_PrivateRoute/1301_location';
import Contact from './components/0002_pages/0002_PrivateRoute/1601_contact';

import Group from './components/0002_pages/0002_PrivateRoute/1901_group';
import GroupAdd from './components/0002_pages/0002_PrivateRoute/2001_group_edit';
import GroupEdit from './components/0002_pages/0002_PrivateRoute/2001_group_edit';
import MeettingRoom from './components/0002_pages/0002_PrivateRoute/2101_meetting_room';
import MeettingListCheck from './components/0002_pages/0002_PrivateRoute/2201_meeting_list_check';
import MeettingList from './components/0002_pages/0002_PrivateRoute/2301_meeting_list';



class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			lang: props.lang === undefined || props.lang === '' ? 'ja': props.lang,
		};
	}

	// DOMツリーに追加される直前に一度のみ呼ばれる
	componentWillMount() {
	}

	// 最初のレンダー直後に呼ばれる
	componentDidMount(props) {
		this.props.setLanguage(this.state.lang);
	};


	// Propsの値を受け取った際に呼ばれる
	componentWillReceiveProps(nextProps) {
		this.setState({lang: nextProps.lang});

		// ここで Storeに書き込む場合は以下の記述
		//this.props.setLanguage(nextProps.lang);
	};

	render() {
		return (
			<ErrorBoundary>
				<IntlProvider locale={this.state.lang} messages={ChangeLanguage[this.state.lang]}>
					<div className="App">
						<Router>
							<div>
								<Switch>
									{/* 誰でもアクセス可能 */}
									<Route exact path={'/'} children={<Home />}/>

									{/* ログインしていないユーザーのみアクセス可能 */}
									<GuestRoute path={'/login'} children={<Login />}/>
									<GuestRoute path={'/forget'} children={<Forget />}/>
									<GuestRoute path={'/reset'} children={<PassReset />}/>
									

									{/* ログインしているユーザーのみアクセス可能 */}
									<PrivateRoute path='/room' children={<Room />}/>
									<PrivateRoute path='/user_profile' children={<UserProfile />}/>
									<PrivateRoute path='/user_meeting' children={<UserMeeting />}/>
									<PrivateRoute path='/user_recording' children={<UserRecording />}/>
									<PrivateRoute path='/recording' children={<Record />}/>
									<PrivateRoute path='/recording_details' children={<RecordDetails />}/>
									<PrivateRoute path='/contact' children={<Contact />}/>
									<PrivateRoute path='/group' children={<Group />}/>
									<PrivateRoute path='/group_add' children={<GroupAdd />}/>
									<PrivateRoute path='/group_edit' children={<GroupEdit />}/>
									<PrivateRoute path='/location' children={<Location />} />
									<PrivateRoute path='/meetting_room' children={<MeettingRoom />} />

									{/* 会議室情報ページ用 */}
									<Route path={'/meeting-info/:id'} children={<MeettingListCheck />} />
									<Route path='/meeting_list/:id' children={<MeettingList />} />

								</Switch>
							</div>
						</Router>
					</div>
				</IntlProvider>
			</ErrorBoundary>
		);
	}
}

const mapStateToProps = (state) => {
	return {lang: state.language.lang}
};

const mapDispatchToProps = { setLanguage };
export default connect(mapStateToProps, mapDispatchToProps)(App);
