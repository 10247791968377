import * as CONST from '../../../const';
import { useState, useEffect, useCallback }  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { SetErrorMessage } from '../../../store/slicer/0101_logout_message';
import { setSideMenu, setProfileMenu, getProfileMenu } from '../../../store/slicer/0501_menu';
import { getProfile, setProfile, setUserProfile, setUserSettingDefault, setUserSettingUpdate, getUserSettingUpdate } from '../../../store/slicer/0503_room';

import { getAccessToken, getRefreshToken, setAccessToken, logout } from '../../../store/slicer/0101_session';

import Header from '../../0003_components/0001_header';
import Sidemenu from '../../0003_components/0002_sidemenu';
import UserProfileHeader from '../../0003_components/0691_user_profile_header';
import UserMeetingSidemenu from '../../0003_components/0891_user_meeting_sidemenu';
import UserMeetingHeader from '../../0003_components/0892_user_meeting_header';

import UserMeetingBody_01 from '../../0003_components/0801_user_meeting_body';
import UserMeetingBody_02 from '../../0003_components/0802_user_meeting_body';
import UserMeetingBody_03 from '../../0003_components/0803_user_meeting_body';
import UserMeetingBody_04 from '../../0003_components/0804_user_meeting_body';
import UserMeetingBody_06 from '../../0003_components/0806_user_meeting_body';
import UserMeetingBody_07 from '../../0003_components/0807_user_meeting_body';
import UserMeetingBody_08 from '../../0003_components/0808_user_meeting_body';
import UserMeetingBody_10 from '../../0003_components/0810_user_meeting_body';
import UserMeetingBody_12 from '../../0003_components/0812_user_meeting_body';
import UserMeetingBody_13 from '../../0003_components/0813_user_meeting_body';
import UserMeetingBody_14 from '../../0003_components/0814_user_meeting_body';
import UserMeetingBody_17 from '../../0003_components/0817_user_meeting_body';
import UserMeetingBody_19 from '../../0003_components/0819_user_meeting_body';
import UserMeetingBody_20 from '../../0003_components/0820_user_meeting_body';
import UserMeetingBody_22 from '../../0003_components/0822_user_meeting_body';
import UserMeetingBody_23 from '../../0003_components/0823_user_meeting_body';

import toast, { Toaster } from 'react-hot-toast';
import { FormattedMessage, useIntl } from "react-intl";

export default function UserMeeting(props) {
	const dispatch = useDispatch();
    const loc = useLocation();


	const storeProfileMenu = useSelector(getProfileMenu);
	const storeProfile = useSelector(getProfile);

    //ミーティング
    const storeSetting = useSelector(getUserSettingUpdate);

	const history = useHistory();

	const intl = useIntl();

	var access_token = useSelector(getAccessToken);
	var refresh_token = useSelector(getRefreshToken);

	// 初期化
	const [state, setState] = useState(() => {
		dispatch(setSideMenu(0));
		toast.dismiss();

		// ルーム一覧から、設定ボタン押下で来た場合
		if (loc.state !== undefined && loc.state.data !== undefined) {
			// タブ、サイドメニューのセット
			dispatch(setProfileMenu({ top: 1, side: 0 }));
			// プロフィールのセット
			dispatch(setProfile(loc.state.data));
			const user_id = loc.state.data.user_id;

			const fetchHttpRefresh = async () => {
				let senddata = {
				};
				let url_tokref = CONST.BASEURL + '/authorize/refresh';
				var httpResponse_tokref = await fetch( url_tokref, {
					method:'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': "Bearer " + refresh_token
					},
					body: JSON.stringify(senddata),
				});
				switch (httpResponse_tokref.status) {
					case 200:
						var httpResponse_tokref_json = await httpResponse_tokref.json();
						access_token = httpResponse_tokref_json.access_token;
						refresh_token = httpResponse_tokref_json.refresh_token;
						// トークンをデコードする
						//const decode_token = jwt(access_token);
						// 問題ない場合は、storeに保存
						dispatch(setAccessToken(access_token));
						//dispatch(setRefreshToken(refresh_token));
						fetchHttp();
						return;					                     
					default:
						dispatch(SetErrorMessage('ERROR_0001'));
						dispatch(logout());
						return;
				}
			}


			//fetchHttpRefresh();
			////////////////////////////////
			// ユーザープロファイル取得
			////////////////////////////////
			const fetchHttp = async () => {
				fetch(CONST.BASEURL + '/users/' + user_id , {
					method:'GET',
					headers: {
						Authorization: "Bearer " + access_token
					},
				})
				.then(response => {
					switch (response.status) {
					case 200:
						response.json().then(data => ({
							data: data,
							status: response.status
						})
						).then(res=> {
							// ユーザープロファイルの項目をセット
							dispatch(setUserProfile(res.data));
							return;
						})
						break;
					case 401:
						fetchHttpRefresh();
						return;
					case 409:
						dispatch(SetErrorMessage('ERROR_0039'));  
						dispatch(logout());
						return;   
					default:
						response.json().then(data => ({
							data: data,
							status: response.status
						})
						).then(res=> {
							toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});
							return;
						})
						break;
					}
				})
				.catch((err) => {
					toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});
				});
			}
			fetchHttp();


			const fetchHttpRefresh2 = async () => {
				let senddata = {
				};
				let url_tokref = CONST.BASEURL + '/authorize/refresh';
				var httpResponse_tokref = await fetch( url_tokref, {
					method:'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': "Bearer " + refresh_token
					},
					body: JSON.stringify(senddata),
				});
				switch (httpResponse_tokref.status) {
					case 200:
						var httpResponse_tokref_json = await httpResponse_tokref.json();
						access_token = httpResponse_tokref_json.access_token;
						refresh_token = httpResponse_tokref_json.refresh_token;

						// 問題ない場合は、storeに保存
						dispatch(setAccessToken(access_token));
						fetchHttp2();
						return;					                      
					default:
						dispatch(SetErrorMessage('ERROR_0001'));
						dispatch(logout());
						return;
				}
			}

			//fetchHttpRefresh();
			////////////////////////////////
			// ユーザー設定取得
			////////////////////////////////
			const fetchHttp2 = async () => {
				fetch(CONST.BASEURL + '/users/' + user_id + '/settings', {
					method:'GET',
					headers: {
						Authorization: "Bearer " + access_token
					},
				})
				.then(response => {
					switch (response.status) {
					case 200:
						response.json().then(data => ({
							data: data,
							status: response.status
						})
						).then(res=> {
							// ユーザー設定の項目をセット
							dispatch(setUserSettingDefault(res.data));
							dispatch(setUserSettingUpdate(res.data));
							return;
						})
						break;
					case 401:
						fetchHttpRefresh2();
						return
					case 409:
						dispatch(SetErrorMessage('ERROR_0039'));  
						dispatch(logout());
						return; 		
					default:
						response.json().then(data => ({
							data: data,
							status: response.status
						})
						).then(res=> {
							toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});
						})
						break;
					}
				})
				.catch((err) => {
					toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});
				});
			}
			fetchHttp2();

		}
		else {
			// ユーザープロフィール等の画面から来た場合で、ユーザープロフィールの値がセットされていない場合は、不正として、/roomに移行
			if ((loc === undefined || loc.state === undefined || loc.state.data === undefined || loc.state.data.user_id === undefined || loc.state.data.user_id === '') && 
				(storeProfile.user_id === undefined || storeProfile.user_id === '')
			) {
				history.push('/room');
			}
		}

		return 0;
	});


	// リスナに登録する関数
	const isScrollToggle = useCallback(() => {
		const TOP_001 = document.querySelector("#schedule_anchor").offsetTop;
		const TOP_002 = document.querySelector("#basic_anchor").offsetTop;
		const TOP_003 = document.querySelector("#details_anchor").offsetTop - 400;
		const TOP_004 = document.querySelector("#details_anchor").offsetTop - 1000;

		const scrollTop = document.querySelector(".in_contents").scrollTop + CONST.SCROLL_MARGIN;

		switch (true) {
			case scrollTop < TOP_001:
				dispatch(setProfileMenu({top: 1, side: 0}));
				break;
			case TOP_001 <= scrollTop && scrollTop < TOP_002:
				if (storeProfileMenu.side !== 1) {
					dispatch(setProfileMenu({top: 1, side: 1}));
				}
				break;
			case TOP_002 <=scrollTop && scrollTop < TOP_003:
				if (storeProfileMenu.side !== 2) {
					dispatch(setProfileMenu({top: 1, side: 2}));
				}
				break;
			case TOP_004 <=scrollTop:
				if (storeProfileMenu.side !== 3) {
					dispatch(setProfileMenu({top: 1, side: 3}));
				}
				break;
			default:
				dispatch(setProfileMenu({top: 1, side: 0}));
				break;
		}
	}, []);


	// サイドエフェクト
	useEffect(() => {
		// ユーザープロフィールが取得できない場合は、不正として、/roomに移行
		if ((loc === undefined || loc.state === undefined || loc.state.data === undefined || loc.state.data.user_id === undefined || loc.state.data.user_id === '') && 
			(storeProfile.user_id === undefined || storeProfile.user_id === '')
		) {
			history.push('/room');
		}

		document.querySelector(".in_contents").addEventListener('scroll', isScrollToggle, { passive: true, capture: true });
		return () => {
			if (document.querySelector(".in_contents") !== null) {
				document.querySelector(".in_contents").removeEventListener('scroll', isScrollToggle, { passive: true, capture: true });
			}
		}
	}, []);


	// 描画
	return(
		<div class="wrapper">
			<Header />
			<div class="container">
				<div class="contents">
					<Sidemenu />
					<div class="in_contents settings-meeting">
						<UserProfileHeader />
						<div class="sticky_container">
							<UserMeetingSidemenu />
							<div class="flex_column">
								<UserMeetingHeader />
								<style jsx="true" />
								<Toaster position="top-center" reverseOrder="false" containerStyle={{ marginTop:'75px'}} />

								<div class="tab_box">
									<div class="meeting_contents">
										<div>
											<a id="security_anchor"/>
											<div class="subtitle" id="security"><h3><FormattedMessage id="meeting_security_lbl" /></h3></div>
											<table class="in_tab_box_table">
												<UserMeetingBody_01 />
												<UserMeetingBody_02 />
												{storeSetting.schedule_meeting.personal_meeting &&
													<UserMeetingBody_23 />
												}
											</table>
											<a id="schedule_anchor"/>
											<div class="subtitle" id="schedule"><h3><FormattedMessage id="meeting_schedule_lbl" /></h3></div>
											<table class="in_tab_box_table">
												<UserMeetingBody_03 />
												<UserMeetingBody_04 />
												<UserMeetingBody_06 />
												{ storeSetting.schedule_meeting.personal_meeting && 
													<>
														<UserMeetingBody_07 />
														<UserMeetingBody_08 />
													</>
												}
											</table>
											<a id="basic_anchor"/>
											<div class="subtitle" id="basic"><h3><FormattedMessage id="meeting_basic_lbl" /></h3></div>
											<table class="in_tab_box_table">
												<UserMeetingBody_10 />
												{ storeSetting.in_meeting.chat && 
													<>
														<UserMeetingBody_12 />
													</>
												}
												<UserMeetingBody_13 />
												<UserMeetingBody_14 />
												
												<UserMeetingBody_17 />
												
												{storeSetting.in_meeting.screen_sharing &&
													<>
														<UserMeetingBody_19 />
													</>
												}
												<UserMeetingBody_20 />
											</table>
											<a id="details_anchor" />
											<div class="subtitle" id="details"><h3><FormattedMessage id="meeting_detail_sentence1_lbl" /></h3></div>
											<table class="in_tab_box_table">
												<UserMeetingBody_22 />
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
