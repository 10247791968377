import * as CONST from '../../../const';
import { useIntl } from 'react-intl';
import { useState }  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import Header from '../../0003_components/0001_header';
import MeetingRoomList from '../../0003_components/2301_meeting_list_body';
import Pagination from '../../0003_components/0003_pagination';
import { Redirect, useRouteMatch } from 'react-router';
import { SetErrorMessage } from '../../../store/slicer/0101_logout_message';
import { getAccessTokenForMeetingRoom,checkLoginOfMeetingRoom, logoutMeetingRoom } from "../../../store/slicer/2201_meetingroom_session";


export default function MeetingRoom(props){
	const dispatch = useDispatch();
	const match = useRouteMatch();
	const isLogin = useSelector(checkLoginOfMeetingRoom);
	const intl = useIntl();
	var access_token = useSelector(getAccessTokenForMeetingRoom);
	
    // 初期化
	const [state, setState] = useState(() => {
		toast.dismiss();
		//会社名をチェックする
		const fetchHttp = async () => {
			let url = CONST.BASEURL + '/customers/' + match.params.id + '/customer_name'
			fetch(url, {
				method: 'GET',
				headers: {
					Authorization: "Bearer " + access_token
				}
			})
			.then(response =>
				response.json().then(data => ({
					data: data,
					status: response.status
				})
				).then(res => {
					switch (res.status) {
						case 200:
							return;
						
						case 404:
							dispatch(SetErrorMessage('ERROR_0039'));  
							dispatch(logoutMeetingRoom());
							return;   

						default:
							//Error出力
							toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

							break;
					}

				}).catch((err) => {
					//Error出力
					toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

				})
			)
			.catch((err) => {
				//Error出力
				toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

			});
			
		}
		fetchHttp();
	});

    return(
        <div class="wrapper">
			<Header partsOnShow="meeting_room"/>
			<div class="container">
				<div class="list-initial">
					<style jsx="true" />
					{ !isLogin && <Redirect to={'/meeting-info/' + match.params.id}></Redirect>}
					<Toaster position="top-center" reverseOrder="false" containerStyle={{ marginTop:'75px'}} />
					{ isLogin && <MeetingRoomList page_size="50"/> }
					<Pagination />

				</div>
			</div>
		</div>
    );
}