import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserData, getAccessToken, getRefreshToken, setAccessToken, logout } from '../../store/slicer/0101_session';
import * as CONST from '../../const';
import { setPageNumber, setPageMax, getPage } from '../../store/slicer/0502_page';
import { FormattedMessage, useIntl } from "react-intl";
import toast from 'react-hot-toast';
import { SetErrorMessage } from '../../store/slicer/0101_logout_message';

export default function LocationSearch() {
	const dispatch = useDispatch();
	const userdata = useSelector(getUserData);
	const storePage = useSelector(getPage);

	const intl = useIntl();


	var access_token = useSelector(getAccessToken);
	var refresh_token = useSelector(getRefreshToken);
	// 初期化
	const [state, setState] = useState(() => {
		return {
			search_keyword: '',
			input_keyword: '',
			location_id: '',
			location_name: '',
			page_size: "50",
			page_number: 1,
			drawdata: [{}],
			create_flag: false,
			delete_flag: false,
			update_flag: false,
			disabled: true,
			all_checked: false,
			crud_flag: false,
			form: [
				{ message: 'EMPTY' },
				{ message: 'EMPTY' },
				{ message: 'EMPTY' },
			],
			search_form: '',
		}
	});

	// 表示リスト作成
	useEffect(() => {
		let now_page;
		now_page = storePage.page_number;
		if (now_page < 1) {
			now_page = 1;
		}
		const fetchHttpRefresh = async () => {
			let senddata = {};
			let url_tokref = CONST.BASEURL + '/authorize/refresh';
			var httpResponse_tokref = await fetch( url_tokref, {
				method:'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': "Bearer " + refresh_token
				},
				body: JSON.stringify(senddata),
			});
			switch (httpResponse_tokref.status) {
				case 200:
					var httpResponse_tokref_json = await httpResponse_tokref.json();
					access_token = httpResponse_tokref_json.access_token;
					refresh_token = httpResponse_tokref_json.refresh_token;
					// トークンをデコードする
					//const decode_token = jwt(access_token);
					// 問題ない場合は、storeに保存
					dispatch(setAccessToken(access_token));
					//dispatch(setRefreshToken(refresh_token));
					fetchHttp();
					return;				        
				default:
					dispatch(SetErrorMessage('ERROR_0001'));
					dispatch(logout());
					return;
			}
		}
		//fetchHttpRefresh();
		// 拠点一覧取得
		const fetchHttp = async () => {
			fetch(CONST.BASEURL + '/customers/' + userdata.id + '/locations?page_size=' + state.page_size + '&page_number=' + now_page + '&search=' + state.search_keyword, {
				method: 'GET',
				headers: {
					Authorization: "Bearer " + access_token
				}
			})
			.then(response =>
				response.json().then(data => ({
					data: data,
					status: response.status
				})
				).then(res => {
					switch (res.status) {
						case 200:
							let drawdata = []
							let error = [...state.form];
							error[0]['message'] = 'EMPTY';
							//検索結果の表示用リストデータを設定する
							drawdata = res.data.locations;

							for (let i = 0; i < drawdata.length; i++) {
								drawdata[i].check = false;
							}

							// ページ数を算出する
							const page_max = Math.ceil(res.data.locations_count / state.page_size);

							// 最大ページ数を変更
							dispatch(setPageMax(page_max));

							if (drawdata.length === 0) {
								error[0]['message'] = 'ERROR_0006';
								document.getElementById('sbox2').focus();
							}
							setState({ ...state, drawdata: drawdata, crud_flag: false, disabled: true, form: error, all_checked: false });
							return;
						case 401:
							fetchHttpRefresh();
							return;
						case 409:
							dispatch(SetErrorMessage('ERROR_0039'));  
							dispatch(logout());
							return; 
						default:
							toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

							return;
					}

				}).catch((err) => {
					//Error出力
					toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

				})
			)
			.catch((err) => {
				toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

			});
		}
		fetchHttp();
	}, [state.search_keyword, state.crud_flag, storePage.page_number]);

	// 入力のイベント
	const handleInputSearch = event => {
		setState({ ...state, input_keyword: event.target.value });
	};

    //追加、編集テキストボックス入力処理
    const handleInputChange = event => {
        setState({ ...state, [event.target.id]: event.target.value });
    };

	// サブミット
	const handleSubmit = event => {
		event.preventDefault(false);
		setState({ ...state, search_keyword: state.input_keyword, crud_flag: true});
		dispatch(setPageNumber(1));
	};

	// 追加　拠点追加ダイアログ表示
	const handleCreateDialog = event => {
		setState({ ...state, create_flag: true});
	};

	//追加 キャンセルボタン押下
	const handleCreateCancel = event => {
		let error = [...state.form];
		error[1]['message'] = 'EMPTY';
		setState({ ...state, create_flag: false, crud_flag: true, location_name: "", form: error});
	};

	//追加　拠点追加ボタン押下
	function handleCreate() {

		let error = [...state.form];
		error[1]['message'] = 'EMPTY';
		if (state.location_name.trim()==="") {
			error[1]['message'] = 'ERROR_0025';
			document.getElementById('location_name').focus();
			setState({ ...state, form: error, });
			return;
		}
		const fetchHttpRefresh = async () => {
			let senddata = {
			};
			let url_tokref = CONST.BASEURL + '/authorize/refresh';
			var httpResponse_tokref = await fetch( url_tokref, {
				method:'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': "Bearer " + refresh_token
				},
				body: JSON.stringify(senddata),
			});
			switch (httpResponse_tokref.status) {
				case 200:
					var httpResponse_tokref_json = await httpResponse_tokref.json();
					access_token = httpResponse_tokref_json.access_token;
					refresh_token = httpResponse_tokref_json.refresh_token;
					// トークンをデコードする
					//const decode_token = jwt(access_token);
					// 問題ない場合は、storeに保存
					dispatch(setAccessToken(access_token));
					//dispatch(setRefreshToken(refresh_token));
					fetchHttp();
					return;				        
				default:
					dispatch(SetErrorMessage('ERROR_0001'));
					dispatch(logout());
					return;
			}
		}
		//fetchHttpRefresh();
		const fetchHttp = async () => {
			fetch(CONST.BASEURL + '/customers/' + userdata.id + '/locations', {
				method: 'POST',
				headers: {
					'Content-type': 'application/json',
					Authorization: "Bearer " + access_token
				},
				body: JSON.stringify({
					location_name: state.location_name
				})
			})
			.then(response => {
				switch (response.status) {
					case 201:
						toast(intl.formatMessage({ id: 'SUCCESS_0004' }),{style:{background:  CONST.HOT_TOAST_SUCCESS}});
						document.getElementById('location_name').focus();
						// 問題ない場合は、リロード                        
						error[1]['message'] = 'EMPTY';
						setState({ ...state, location_name: "", form: error });
						dispatch(setPageNumber(1));
						break;
					case 400:
						response.json().then(data => ({
							data: data,
							status: response.status
						})
						).then(res => {
							switch (res.data.errors[0]["code"]) {
								case 3030:
									error[1]['message'] = 'ERROR_0009';
									document.getElementById('location_name').focus();
									setState({ ...state, form: error });
									break;
								default:
									//Error出力
									toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

									break;
							}
						})
						.catch((err) =>
							toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}})
						)
						break;
					case 401:
						fetchHttpRefresh();
						return;
					case 409:
						dispatch(SetErrorMessage('ERROR_0039'));  
						dispatch(logout());
						return;
					default:
						//Error出力
						toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

						break;
				}
				
			})
			.catch((err) =>
				toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}})
			)
		}
		fetchHttp();
	}

	// 編集　拠点編集ダイアログ表示
	const handleUpdateDialog = event => {
		const location_id = event.target.getAttribute('data-location_id');
		const location_name = event.target.getAttribute('data-location_name');
		setState({ ...state, location_id: location_id, update_flag: true, location_name: location_name });
	};

	//　編集ダイアログ　追加
	function handleUpdate() {
		let error = [...state.form];
		error[2]['message'] = 'EMPTY';

		if (state.location_name.trim()==="") {
			error[2]['message'] = 'ERROR_0025';
			document.getElementById('location_name').focus();
			setState({ ...state, form: error, });
			return;
		}
		const fetchHttpRefresh = async () => {
			let senddata = {
			};
			let url_tokref = CONST.BASEURL + '/authorize/refresh';
			var httpResponse_tokref = await fetch( url_tokref, {
				method:'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': "Bearer " + refresh_token
				},
				body: JSON.stringify(senddata),
			});
			switch (httpResponse_tokref.status) {
				case 200:
					var httpResponse_tokref_json = await httpResponse_tokref.json();
					access_token = httpResponse_tokref_json.access_token;
					refresh_token = httpResponse_tokref_json.refresh_token;
					// トークンをデコードする
					//const decode_token = jwt(access_token);
					// 問題ない場合は、storeに保存
					dispatch(setAccessToken(access_token));
					//dispatch(setRefreshToken(refresh_token));
					fetchHttp();
					return;				       
				default:
					dispatch(SetErrorMessage('ERROR_0001'));
					dispatch(logout());
					return;
			}
		}
		//fetchHttpRefresh();
		const fetchHttp = async () => {
			fetch(CONST.BASEURL + '/customers/' + userdata.id + '/locations/' + state.location_id, {
				method: 'PATCH',
				headers: {
					'Content-type': 'application/json',
					Authorization: "Bearer " + access_token
				},
				body: JSON.stringify({
					location_name: state.location_name
				})
			})
			.then(response => {
				let error = [...state.form];
				switch (response.status) {
					case 204:
						toast(intl.formatMessage({ id: 'SUCCESS_0005' }),{style:{background:  CONST.HOT_TOAST_SUCCESS}});
						// 問題ない場合は、リロード
						error[2]['message'] = 'EMPTY';
						setState({ ...state, update_flag: false, crud_flag: true, location_name: "", form: error });
						dispatch(setPageNumber(1));
						document.getElementById('create_btn').focus();
						break;
					case 400:
						response.json().then(data => ({
							data: data,
							status: response.status
						})
						).then(res => {
							switch (res.data.errors[0]["code"]) {
								case 3030:
									error[2]['message'] = 'ERROR_0009';
									document.getElementById('location_name').focus();
									setState({ ...state, form: error });
									break;
								default:
									//Error出力
									toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

									break;
							}
						})
						.catch((err) =>
							toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}})
						)
						break;
					case 401:
						fetchHttpRefresh();
						return;
					case 409:
						dispatch(SetErrorMessage('ERROR_0039'));  
						dispatch(logout());
						return;
					default:
						//Error出力
						toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}})
						break;
				}
			})
			.catch((err) =>
				toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}})
			)
		}
		fetchHttp();
		return {
			location_id: '',
		}
	}

	//編集 キャンセルボタン押下
	const handleUpdateCancel = event => {
		let error = [...state.form];
		error[2]['message'] = 'EMPTY';
		setState({ ...state, update_flag: false, location_name: "", form: error});
	};

	//チェックボックス全選択
	const handleAllChangeCheckbox = event => {
		let drawdate_checkall = [{}];
		let checkdrawdata = [{}];
		let allcheck = false;

		checkdrawdata = state.drawdata;

		drawdate_checkall = state.drawdata;
		if (state.all_checked === false) {
			for (let i = 0; i < drawdate_checkall.length; i++) {
				drawdate_checkall[i].check = true;
			}
			allcheck = true;
		} else {
			for (let i = 0; i < drawdate_checkall.length; i++) {
				drawdate_checkall[i].check = false;
			}
			allcheck = false;
		}


		//チェックボックスにチェックが入っていないと削除ボタンを押せなくする
		let delete_check = true;
		for (let i = 0; i < checkdrawdata.length; i++) {
			if (checkdrawdata[i].check === true) {
				delete_check = false;
				break;
			}
		}

		setState({ ...state, drawdata: drawdate_checkall, all_checked: allcheck, disabled: delete_check });
	}

	//チェックボックスの値が変化したら、配列に追加または削除をする
	const handleChangeCheckbox = event => {
		
		const index = event.target.getAttribute('data-index');

		let checkdrawdata = [{}];
		let flag = false;//リストのチェックボックスの値
		let AllorNotAll = false;//全チェックのチェックボックスの値

		flag = state.drawdata[index].check ? false : true;

		checkdrawdata = state.drawdata;
		checkdrawdata[index].check = flag;

		//すべてにチェックが入っていなかったらallからチェックを外す。すべてにチェックが入っていたらallにチェックを入れる
		for (let i = 0; i < checkdrawdata.length; i++) {
			if (checkdrawdata[i].check === false) {
				AllorNotAll = true;
				break;
			}
		}
		if (AllorNotAll === true) {
			AllorNotAll = false;
		} else {
			AllorNotAll = true;
		}

		//チェックボックスにチェックが入っていないと削除ボタンを押せなくする
		let delete_check = true;
		for (let i = 0; i < checkdrawdata.length; i++) {
			if (checkdrawdata[i].check === true) {
				delete_check = false;
				break;
			}
		}

		setState({ ...state, drawdata: checkdrawdata, all_checked: AllorNotAll, disabled: delete_check });
	}

	// 削除　拠点削除ダイアログ表示
	const handleDeleteDialog = event => {
		setState({ ...state, delete_flag: true });
	};

	//　削除ダイアログ　削除
	function handleDelete() {
		let delete_location_id_data = [{}];
		let drawdate_refresh = [{}];
		let x = 0;
		for (let i = 0; i < state.drawdata.length; i++) {
			if (state.drawdata[i].check) {
				delete_location_id_data[x] = { "location_id": state.drawdata[i].location_id };
				x++;
			}
		}
		const fetchHttpRefresh = async () => {
			let senddata = {
			};
			let url_tokref = CONST.BASEURL + '/authorize/refresh';
			var httpResponse_tokref = await fetch( url_tokref, {
				method:'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': "Bearer " + refresh_token
				},
				body: JSON.stringify(senddata),
			});
			switch (httpResponse_tokref.status) {
				case 200:
					var httpResponse_tokref_json = await httpResponse_tokref.json();
					access_token = httpResponse_tokref_json.access_token;
					refresh_token = httpResponse_tokref_json.refresh_token;
					// トークンをデコードする
					//const decode_token = jwt(access_token);
					// 問題ない場合は、storeに保存
					dispatch(setAccessToken(access_token));
					//dispatch(setRefreshToken(refresh_token));
					fetchHttp();
					return;				        
				default:
					dispatch(SetErrorMessage('ERROR_0001'));
					dispatch(logout());
					return;
			}
		}
		//fetchHttpRefresh();
		const fetchHttp = async () => {
			fetch(CONST.BASEURL + '/customers/' + userdata.id + '/locations', {
				method: 'DELETE',
				headers: {
					'Content-type': 'application/json',
					Authorization: "Bearer " + access_token
				},
				body: JSON.stringify({
					"locations": delete_location_id_data
				})
			})
			.then(res => {
				switch (res.status) {
					case 204:
						//削除に成功したので配列を空にする
						delete_location_id_data = 0;

						//削除に成功したのですべてのチェックを外す
						drawdate_refresh = state.drawdata;
						for (let i = 0; i < drawdate_refresh.length; i++) {
							drawdate_refresh[i].check = false;
						}
						toast(intl.formatMessage({ id: 'SUCCESS_0003' }),{style:{background:  CONST.HOT_TOAST_SUCCESS}});
						// 問題ない場合は、リロード                    
						setState({ ...state, drawdata: drawdate_refresh, delete_flag: false, crud_flag: true, all_checked: false });
						dispatch(setPageNumber(1));
						break;
					case 401:
						fetchHttpRefresh();
						return;
					case 409:
						dispatch(SetErrorMessage('ERROR_0039'));  
						dispatch(logout());
						return; 
					default:
						//Error出力
						toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

						break;
				}
			})

			.catch((err) =>
				toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}})
			)
		}
		fetchHttp();
	}

	// 削除　拠点キャンセルボタン押下
	const handleDeleteCancel = event => {
		setState({ ...state, delete_flag: false });
	};

	// ダイアログ初期カーソル
	useLayoutEffect(() => {
		if (state.create_flag == true) {
			document.getElementById('location_name').focus();
		} else if (state.update_flag == true) {
			document.getElementById('location_name').focus();
		} else if (state.delete_flag == true) {
			document.getElementById('delete_cancel_btn').focus();
		}
	}, [state.create_flag, state.update_flag, state.delete_flag])

	// 描画
	return (
		<div class="scroll_range">
			<title><FormattedMessage id="tab_title_location_list" /></title>
			<header class="in_contents_header">
				<div class="flex_box left_justified">
					<div class="element_width_xl">
						<div class="search_box">
							<button tabIndex="-1" class="" type="button" onClick={handleSubmit} id="sbtn2"><img src={`${process.env.PUBLIC_URL}/img/EZTP_search.svg`} alt=""/></button>
							<FormattedMessage id="base-initial_locationuser_txt">{placeholder => <input id="sbox2" name="s" type="text" maxlength='64' placeholder={placeholder} value={state.input_keyword} onChange={handleInputSearch} />}</FormattedMessage>
						</div>
					</div>
					<div class="element_width_mm inline_block blank_space_m">
						<button href="javascript:void(0);" id="create_pop_btn" class="buttons_common btn_blue" onClick={handleCreateDialog}><FormattedMessage id="base-initial_addlocation_btn" /></button>
					</div>
				</div>
			</header>
			<div class="search_result_table_box">
				<table class="search_result_table management_information_table" id="table_row">
					<tbody>
						<tr>
							<th><input class="select_all base_ckb" type="checkbox" onClick={handleAllChangeCheckbox} checked={state.all_checked}></input></th>
							<th><span><FormattedMessage id="base-initial_locationname_lbl" /></span></th>
							<th><span><FormattedMessage id="base-initial_roomcount_lbl" /></span></th>
							<th><div class="element_width_ml inline_block"><FormattedMessage id="base-initial_selectdelete_btn">{value => <input onClick={handleDeleteDialog} class="buttons_common btn_wht" type="button" value={value} disabled={state.disabled}></input>}</FormattedMessage></div></th>
						</tr>
						{
							(() => {
								if (state.form[0].message !=='EMPTY') {
									return (
										<tr>
											<td colspan="3">
												<div class="has-error"><FormattedMessage id={state.form[0].message} /></div>
											</td>
											<td />
										</tr>
									);
								}
							})()
						}
						{
							state.drawdata.map((data, index) => {
								if (data.location_name===undefined) {
									return (<tr />);
								}
								return (
									<tr>
										<td><input id="chk" class="base_ckb" type="checkbox" onClick={handleChangeCheckbox} data-index={index} data-delete_location_id={data.location_id} checked={data.check}></input></td>
										<td><a data-location_id={data.location_id} data-location_name={data.location_name} onClick={handleUpdateDialog} href="javascript:void(0);">{data.location_name}</a></td>
										<td>{data.members_count}</td>
										<td>
											<div class="element_width_m inline_block">
												<a id="create_btn" data-location_name={data.location_name} data-location_id={data.location_id} onClick={handleUpdateDialog} class="buttons_common btn_wht" href="javascript:void(0);">
													<FormattedMessage id="base-initial_edit_btn" />
												</a>
											</div>
										</td>
									</tr>
								);
							})
						}
					</tbody>
				</table>
			</div>
			{ state.create_flag && 
				<div id="location_create" >
					<div>
						<nav>
							<div class="in_contents base-additional">
								<div id="modal_overlay"></div>
								<div id="modal_container">
									<header class="modal_header">
										<h2><FormattedMessage id="base-additional_sentence1_lbl" /></h2>
									</header>
									<div class="modal_contents blank_above_m">
										<div class="inline_block element_width_xl">
											<FormattedMessage id="base-additional_locationname_txt">{value => <input type="text" class={state.form[1].message !== 'EMPTY' ? 'has-error' : ''} maxlength='64' id="location_name" onChange={handleInputChange} value={state.location_name} placeholder={value}></input>}</FormattedMessage>
											<div class="has-error" style={{ display: state.form[1].message !== 'EMPTY' ? '' : 'none' }}><FormattedMessage id={state.form[1].message} /></div>
										</div>
										<div class="flex_box right_justified blank_above_l">
											<div class="element_width_ms"><FormattedMessage id="base-edit_addlocation_btn">{value => <input onClick={handleCreate} id="create_location" class="buttons_common btn_blue" type="button" value={value}></input>}</FormattedMessage></div>
											<div class="element_width_ms blank_space_m"><FormattedMessage id="base-edit_cancel_btn">{value => <input onClick={handleCreateCancel} id="create_cancel_btn" class="buttons_common btn_wht" type="button" value={value}></input>}</FormattedMessage></div>
										</div>
									</div>
								</div>
							</div>
						</nav>
					</div>
				</div>
			}
			{ state.update_flag &&
				<div id="location_update" >
					<div>
						<nav>
							<div class="in_contents base-edit">
								<div id="modal_overlay"></div>
								<div id="modal_container">
									<header class="modal_header">
										<h2><FormattedMessage id="base-edit_sentence1_lbl" /></h2>
									</header>
									<div class="modal_contents blank_above_m">
									<div class="inline_block element_width_xl">
										<FormattedMessage id="base-edit_locationname_txt">{value => <input type="text" class={state.form[2].message !== 'EMPTY' ? 'has-error' : ''} id="location_name" maxlength='64' value={state.location_name} onChange={handleInputChange} placeholder={value} ></input>}</FormattedMessage>
										<div class="has-error" style={{ display: state.form[2].message !== 'EMPTY' ? '' : 'none' }}><FormattedMessage id={state.form[2].message} /></div>
									</div>
										<div class="flex_box right_justified blank_above_l">
											<div class="element_width_ms"><FormattedMessage id="base-edit_save_btn">{value => <input onClick={handleUpdate} id="create_location" class="buttons_common btn_blue" type="button" value={value}></input>}</FormattedMessage></div>
											<div class="element_width_ms blank_space_m"><FormattedMessage id="base-edit_cancel_btn">{value => <input onClick={handleUpdateCancel} id="create_cancel_btn" class="buttons_common btn_wht" type="button" value={value}></input>}</FormattedMessage></div>
										</div>
									</div>
								</div>
							</div>
						</nav>
					</div>
				</div>
			}
			{ state.delete_flag && 
				<div id="location_delete" >
					<div>
						<nav>
							<div class="in_contents base-edit">
								<div id="modal_overlay"></div>
								<div id="modal_container">
									<header class="modal_header">
										<h2><FormattedMessage id="base-delete_sentence1_lbl" /></h2>
									</header>
									<div class="modal_contents blank_above_m">
									<div class="inline_block element_width_xl"><span><FormattedMessage id="base-delete_sentence2_lbl" values={{ br: <br /> }} /></span></div>
										<div class="flex_box right_justified blank_above_l">
											<div class="element_width_ms"><FormattedMessage id="base-delete_delete_lbl">{value => <input onClick={handleDelete} id="create_location" class="buttons_common btn_blue" type="button" value={value}></input>}</FormattedMessage></div>
											<div class="element_width_ms blank_space_m"><FormattedMessage id="base-delete_cancel_lbl">{value => <input onClick={handleDeleteCancel} id="delete_cancel_btn" class="buttons_common btn_wht" type="button" value={value}></input>}</FormattedMessage></div>
										</div>
									</div>
								</div>
							</div>
						</nav>
					</div>
				</div>
			}
		</div>
	);

}
