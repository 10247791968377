import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import reduxCookiesMiddleware, { getStateFromCookies } from 'redux-cookies-middleware';
import jsCookie from 'js-cookie';

import sessionReducer from './slicer/0101_session';
import messageboxReducer from './slicer/0102_messagebox';
import languageReducer from './slicer/0103_language';

import menuReducer from './slicer/0501_menu';
import pageReducer from './slicer/0502_page';
import roomReducer from './slicer/0503_room';
import recordReducer from './slicer/1001_record';
import logout_messageReducer from './slicer/0101_logout_message';
import recordDetailsReducer from './slicer/1002_record_details';
import groupReducer from './slicer/1901_group';
import meetingRoomSessionReducer from './slicer/2201_meetingroom_session';



// レデューサー登録
const reducer = combineReducers({
	session: sessionReducer,
	messagebox: messageboxReducer,
	language: languageReducer,
	menu: menuReducer,
	page: pageReducer,
	room: roomReducer,
	record: recordReducer,
	logout_message: logout_messageReducer,
	record_details: recordDetailsReducer,
	group: groupReducer,
	meetingRoomSession: meetingRoomSessionReducer
});



//  Cookieに保持する状態
const pathData = {
	'session': {name: 'session'},
	'messagebox': {name: 'messagebox'},
	'language': {name: 'language'},
	'menu': {name: 'menu'},
	'page': {name: 'page'},
	'room': {name: 'room'},
	'record': { name: 'record' },
	'logout_message': { name: 'logout_message' },
	'record_details': { name: 'record_details' },
	'group': { name: 'group' },
	'meetingRoomSession': { name: 'meetingRoomSession' }
};


// Cookieから初回に読み込む情報
const loadPathData = {
	'session': {name: 'session'},
	'messagebox': {name: 'messagebox'},
	'language': {name: 'language'},
	'menu': {name: 'menu'},
	'page': {name: 'page'},
	'room': {name: 'room'},
	'record': { name: 'record' },
	'logout_message': { name: 'logout_message' },
	'record_details': { name: 'record_details' },
	'group': { name: 'group' },
	'meetingRoomSession': { name: 'meetingRoomSession' }
};


// Cookie保持オプション
const customOptions ={
	setCookie: (name, value) => {
		let day = "";

		switch (name) {
			case 'language':
				day = 365;
				break;

			case 'session':
				const obj = JSON.parse(value);
				if (obj.signinForEver === true) {
					day = 365;
				}
				break;
			
			case 'meetingRoomSession':
				const object = JSON.parse(value);
				if (object.signinForEver === true) {
					day = 365;
				}
				break;
			default:
			break;
		}
		jsCookie.set(name, value, { expires: day });
	},
};



export default configureStore({
	reducer, 
	preloadedState: getStateFromCookies([], loadPathData), 
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([reduxCookiesMiddleware(pathData, customOptions)]), 
});
