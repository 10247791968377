import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { HashLink } from 'react-router-hash-link';
import { FormattedMessage } from 'react-intl';

import { setProfileMenu, getProfileMenu } from '../../store/slicer/0501_menu';


export default function UserMeetingSidemenu(props) {
	const dispatch = useDispatch();
	const storeProfileMenu = useSelector(getProfileMenu);

	// onClickイベントハンドラ
	const handleOnClick = useCallback(event => {
		const num = Number(event.target.getAttribute('data-pos'));
		switch (num) {
			case 0:
			case 1:
			case 2:
			case 3:
				dispatch(setProfileMenu({top: 1, side: num}));
				break;
			case 4:
				dispatch(setProfileMenu({top: 2, side: 4}));
				break;
			default:
				dispatch(setProfileMenu({top: 0, side: 0}));
				break;
		}
	}, []);


	// 描画
	switch (storeProfileMenu.side) {
	case 0:
		return (
			<nav class="meeting_navigation">
				<ul>
					<li><p><FormattedMessage id="settings_title1_lbl" /></p></li>
					<li class="meeting_navigation_current"><HashLink to="/user_meeting#security_anchor" data-pos="0" onClick={handleOnClick}><FormattedMessage id="settings_title2_lbl" /></HashLink></li>
					<li><HashLink to="/user_meeting#schedule_anchor" data-pos="1" onClick={handleOnClick}><FormattedMessage id="settings_title3_lbl" /></HashLink></li>
					<li><HashLink to="/user_meeting#basic_anchor" data-pos="2" onClick={handleOnClick}><FormattedMessage id="settings_title4_lbl" /></HashLink></li>
					<li><HashLink to="/user_meeting#details_anchor" data-pos="3" onClick={handleOnClick}><FormattedMessage id="settings_title5_lbl" /></HashLink></li>
					<li><HashLink to="/user_recording"><FormattedMessage id="settings_title6_lbl" /></HashLink></li>
				</ul>
			</nav>
		);
	case 1:
		return (
			<nav class="meeting_navigation">
				<ul>
					<li><p><FormattedMessage id="settings_title1_lbl" /></p></li>
					<li><HashLink to="/user_meeting#security_anchor" data-pos="0" onClick={handleOnClick}><FormattedMessage id="settings_title2_lbl" /></HashLink></li>
					<li class="meeting_navigation_current"><HashLink to="/user_meeting#schedule_anchor" data-pos="1" onClick={handleOnClick}><FormattedMessage id="settings_title3_lbl" /></HashLink></li>
					<li><HashLink to="/user_meeting#basic_anchor" data-pos="2" onClick={handleOnClick}><FormattedMessage id="settings_title4_lbl" /></HashLink></li>
					<li><HashLink to="/user_meeting#details_anchor" data-pos="3" onClick={handleOnClick}><FormattedMessage id="settings_title5_lbl" /></HashLink></li>
					<li><HashLink to="/user_recording" data-pos="4" onClick={handleOnClick}><FormattedMessage id="settings_title6_lbl" /></HashLink></li>
				</ul>
			</nav>
		);
		
	case 2:
		return (
			<nav class="meeting_navigation">
				<ul>
					<li><p><FormattedMessage id="settings_title1_lbl" /></p></li>
					<li><HashLink to="/user_meeting#security_anchor" data-pos="0" onClick={handleOnClick}><FormattedMessage id="settings_title2_lbl" /></HashLink></li>
					<li><HashLink to="/user_meeting#schedule_anchor" data-pos="1" onClick={handleOnClick}><FormattedMessage id="settings_title3_lbl" /></HashLink></li>
					<li class="meeting_navigation_current"><HashLink to="/user_meeting#basic_anchor"  data-pos="2" onClick={handleOnClick}><FormattedMessage id="settings_title4_lbl" /></HashLink></li>
					<li><HashLink to="/user_meeting#details_anchor" data-pos="3" onClick={handleOnClick}><FormattedMessage id="settings_title5_lbl" /></HashLink></li>
					<li><HashLink to="/user_recording" data-pos="4" onClick={handleOnClick}><FormattedMessage id="settings_title6_lbl" /></HashLink></li>
				</ul>
			</nav>
		);
		
	case 3:
		return (
			<nav class="meeting_navigation">
				<ul>
					<li><p><FormattedMessage id="settings_title1_lbl" /></p></li>
					<li><HashLink to="/user_meeting#security_anchor" data-pos="0" onClick={handleOnClick}><FormattedMessage id="settings_title2_lbl" /></HashLink></li>
					<li><HashLink to="/user_meeting#schedule_anchor" data-pos="1" onClick={handleOnClick}><FormattedMessage id="settings_title3_lbl" /></HashLink></li>
					<li><HashLink to="/user_meeting#basic_anchor" data-pos="2" onClick={handleOnClick}><FormattedMessage id="settings_title4_lbl" /></HashLink></li>
					<li class="meeting_navigation_current"><HashLink to="/user_meeting#details_anchor" data-pos="3" onClick={handleOnClick}><FormattedMessage id="settings_title5_lbl" /></HashLink></li>
					<li><HashLink to="/user_recording" data-pos="4" onClick={handleOnClick}><FormattedMessage id="settings_title6_lbl" /></HashLink></li>
				</ul>
			</nav>
		);
		
	case 4:
		return (
			<nav class="meeting_navigation">
				<ul>
					<li><p><FormattedMessage id="settings_title1_lbl" /></p></li>
					<li><HashLink to="/user_meeting#security_anchor" data-pos="0" onClick={handleOnClick}><FormattedMessage id="settings_title2_lbl" /></HashLink></li>
					<li><HashLink to="/user_meeting#schedule_anchor" data-pos="1" onClick={handleOnClick}><FormattedMessage id="settings_title3_lbl" /></HashLink></li>
					<li><HashLink to="/user_meeting#basic_anchor" data-pos="2" onClick={handleOnClick}><FormattedMessage id="settings_title4_lbl" /></HashLink></li>
					<li><HashLink to="/user_meeting#details_anchor" data-pos="3" onClick={handleOnClick}><FormattedMessage id="settings_title5_lbl" /></HashLink></li>
					<li class="meeting_navigation_current"><HashLink to="/user_recording" data-pos="4" onClick={handleOnClick}><FormattedMessage id="settings_title6_lbl" /></HashLink></li>

				</ul>
			</nav>
		);
		
	default:
		break;
	}
	return (<nav></nav>);

/*
*/



}
