import { createSlice } from '@reduxjs/toolkit';


// Redux Toolkitを使用すると、reducersで「変更」ロジックを記述できます。 
// 「draft state」への変更を検出しそれらの変更に基づいて
// まったく新しい不変の状態を生成するImmerライブラリを使用するため
// 実際には状態を変更しません。
export const recordSlice = createSlice({
	name: 'record',
	initialState: {
		locationList: '',
		roomList: '',
		fromDate: '',
		toDate: '',
		topic: '',
		record_flag:true,
		meeting: {
			meeting_id: '',
			topic: '',
			start_time: '',
			timezone: '',
			recording_count: '',
		}
	},
	reducers: {
		clearRecord: (state) => {
			state.locationList = '';
			state.roomList = '';
			state.fromDate = '';
			state.toDate = '';
			state.topic = '';
			state.meeting.meeting_id = '';
			state.meeting.topic = '';
			state.meeting.start_time = '';
			state.meeting.timezone = '';
			state.meeting.recording_count = '';
		},
		clearMeeting: (state) => {
			state.meeting.meeting_id = '';
			state.meeting.topic = '';
			state.meeting.start_time = '';
			state.meeting.timezone = '';
			state.meeting.recording_count = '';
		},
		setSearch: (state, action) => {
			state.locationList = action.payload.locationList;
			state.roomList = action.payload.roomList;
			state.fromDate = action.payload.fromDate;
			state.toDate = action.payload.toDate;
			state.topic = action.payload.topic;
			state.record_flag = action.payload.record_flag;
		},
		setMeeting: (state, action) => {
			state.meeting.meeting_id  = action.payload.meeting_id;
			state.meeting.topic = action.payload.topic;
			state.meeting.start_time  = action.payload.start_time;
			state.meeting.timezone  = action.payload.timezone;
			state.meeting.recording_count  = action.payload.recording_count;
		},
	},
});

export const { clearRecord, clearMeeting, setSearch, setMeeting } = recordSlice.actions;


// サンク：非同期ロジックの実行
// dispatch(incrementByAmount(amount))
// 最初の引数として dispatch関数を使用してサンクが呼び出されます。
// その後、非同期コードを実行し、他のアクションをディスパッチできます
/*
export const incrementAsync = amount => dispatch => {
  setTimeout(() => {
    dispatch(incrementByAmount(amount));
  }, 1000);
};
*/

// セレクター：state から値を取得する
// useSelector((state) => state.counter.value) のようにインラインで定義もできる

export const getRecord = state => state.record;
export const getMeeting = state => state.record.meeting;


// レデューサーをエクスポートする
export default recordSlice.reducer;

