import * as CONST from '../../const';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { login, getSignCheck } from '../../store/slicer/0101_session';
import { setLanguage, getLanguage } from '../../store/slicer/0103_language';

import { FormattedMessage, useIntl } from "react-intl";

import Header from '../0003_components/0001_header';

import jwt from 'jwt-decode';

import { setSideMenu } from '../../store/slicer/0501_menu';
import { GetErrorMessage, SetErrorMessage } from '../../store/slicer/0101_logout_message';
import toast, { Toaster } from 'react-hot-toast';


export default function Login() {
	const dispatch = useDispatch();
	const history = useHistory();
	const storeSignCheck = useSelector(getSignCheck);
	const storeLanguage = useSelector(getLanguage);
	const storelogout_message = useSelector(GetErrorMessage);
	const intl = useIntl();


	const [state, setState] = useState(() => {		
		
		return {		
			account: '',
			password: '',
			type: 'password',
			chk1: storeSignCheck !== undefined ? storeSignCheck: false,
			form: [
				{message: 'EMPTY'},
				{message: 'EMPTY'},
			],
		}
	});

	// 入力のイベント
	const handleInputChange = event => {
		setState({...state, [event.target.id]: event.target.value,});
	};
	const handleCheckboxChange = event => {
		setState({...state, [event.target.id]: event.target.checked,});
	};

	//password表示・非表示
	const Masking_Password = event => {
		if (state.type === 'password') {			
			event.target.setAttribute('class', 'masking_btn visible');
			setState({ ...state, type: 'text' });
		} else {
			event.target.setAttribute('class', 'masking_btn invisible');
			setState({ ...state, type: 'password' });
		}
	}

	// サブミット
	const handleSubmit = event => {	
		let error = [...state.form];

		error[0]['message'] = 'EMPTY';
		error[1]['message'] = 'EMPTY';

		if (state.account === '') {
			error[0]['message'] = 'ERROR_0004';
			document.getElementById('account').focus();
		}
		if (state.password === '') {
			error[1]['message'] = 'ERROR_0020';
			if (state.account === '') {
				document.getElementById('account').focus();
			} else {
				document.getElementById('password').focus();
            }
		}
		if (state.account === '' || state.password === '') {
			setState({...state, form: error, });
			return;
		}



		// ログイン処理
		fetch(CONST.BASEURL + '/authorize', {
			method:'POST',
			headers: {
				'Content-type':'application/json'
			},
			body: JSON.stringify({
				account: state.account,
				password: state.password,
				role: 'customer'
			})
		})
		.then(response => 
			response.json().then(data => ({
				data: data,
				status: response.status
			})
			).then(res => {
				switch (res.status) {
					case 200:
						const access_token = res.data.access_token;
						const refresh_token = res.data.refresh_token;
						// トークンをデコードする
						const decode_token = jwt(access_token);

						//const lang = decode_token.user_claims.account;

						//if (lang === "en") {
						//	dispatch(setLanguage("en"));
						//} else if (lang === "de") {
						//	dispatch(setLanguage("de"));
						//} else if (lang === "fr") {
						//	dispatch(setLanguage("fr"));
						//} else {
						//	dispatch(setLanguage("ja"));
						//}

						// 問題ない場合は、storeに保存してログイン後のページに偏移
						dispatch(login({
							access_token: access_token,
							refresh_token: refresh_token,
							signinForEver: state.chk1,
							id: decode_token.user_claims.customer_id,
							name: decode_token.user_claims.customer_name,
							role: decode_token.user_claims.role,
							account: decode_token.user_claims.account,
							email: decode_token.user_claims.email,
						}));



						dispatch(setSideMenu(0));
						setState({...state, form: error, });
						history.push('/room');
						break;

					case 401:
						error[0]['message'] = 'ERROR_0003';
						document.getElementById('account').focus();
						setState({...state, form: error, });
						break;

					default:
						toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

						error[0]['message'] = 'EMPTY';
						setState({ ...state, form: error, });
						break;
				}

			}).catch((err) => {
				//Error出力
				toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

			})
		)
		.catch((err) => {
			toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

			error[0]['message'] = 'EMPTY';
			setState({ ...state, form: error, });
		});

	};


	//言語切り替え用のダミーボタン
	const handle_dammy = event => {
		dispatch(setLanguage(event.target.value));
	}

	//不正アクセスエラーでログイン画面に戻された場合Toasts表示
	useEffect(() => {
		if (storelogout_message !== '') {
			toast(intl.formatMessage({ id: storelogout_message }),{style:{background:  CONST.HOT_TOAST_ERROR}});
			dispatch(SetErrorMessage(''));
        }	
	}, [storelogout_message]);

	// ENTERイベント
	const keydownEnter = event => {
		if (window.event.keyCode === 13) {
			// ログイン
			handleSubmit()
		}
	}

	// 描画
	return (
		<div class="before_login">
			<title><FormattedMessage id="tab_title_signin" /></title>
			<div class="wrapper">
				<Header />
				<div class="container">
					<div class="contents">
						<Toaster position="top-center" reverseOrder="false" containerStyle={{ marginTop:'75px'}} />
						<div class="before_login_box">
							<div class="operation_box">
								<h1><FormattedMessage id="signin_signin_lbl" /></h1>
								<dl>
									<dd class="blank_above_l">
										<FormattedMessage id="signin_account_txt">{placeholder => <input onKeyDown={keydownEnter} id="account" class={state.form[0].message !== 'EMPTY' ? 'has-error' : ''} type="text" name="account" placeholder={placeholder} value={state.account} onChange={handleInputChange} autoFocus />}</FormattedMessage>
										<div class="has-error" style={{display: state.form[0].message !== 'EMPTY' ? '' : 'none' }}><FormattedMessage id={state.form[0].message} values={{ br: <br /> }} /></div>
									</dd>
									<dd class="blank_above_l">
										<div class="search_box">
											<button tabIndex="-1" type="button" id="sbtn2">
												<button tabIndex="-1" type="button" class="masking_btn invisible" onClick={Masking_Password} style={{ marginLeft: '440px', marginTop: '5px' }} />
											</button>
											<FormattedMessage id="signin_password_txt">{placeholder => <input onKeyDown={keydownEnter} id="password" class={state.form[1].message !== 'EMPTY' ? 'has-error' : ''} type={state.type} name="pass" placeholder={placeholder} value={state.password} onChange={handleInputChange} />}</FormattedMessage>
										</div>
										<div class="has-error" style={{ display: state.form[1].message !== 'EMPTY' ? '' : 'none' }}><FormattedMessage id={state.form[1].message} values={{ br: <br /> }} /></div>
									</dd>									
									<dd class="blank_above_s forget_password"><Link to="/forget"><FormattedMessage id="signin_forget_password_lnk" /></Link></dd>
									<dd class="blank_above_m">
										<label class="ckbox_label">
											<input id="chk1" class="vertical_center" type="checkbox" name="" checked={state.chk1} onChange={handleCheckboxChange} /><FormattedMessage id="signin_stay_password_chk" />
										</label>
									</dd>
									<dd class="blank_above_l">
										<FormattedMessage id="signin_signin_btn">{value => <input class="buttons_sign_in btn_blue" type="button" value={value} onClick={handleSubmit} id="login_btn" />}</FormattedMessage>
									</dd>
								</dl>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="policy_terms_link">
				<div>
					<p class="policy_terms_link_p">
					<a class="link_policy" href={CONST.PRIVACY_POLICY_URL} target="_blank"><FormattedMessage id="signin_privacy_policy_lnk" /></a>
					</p>
					<p class="policy_terms_link_p">
					<a class="link_terms" href={CONST.TERMS_OF_USE_URL} target="_blank"><FormattedMessage id="signin_terms_of_use_lnk" /></a>
					</p>
					<p class="policy_terms_link_p">
							<select class="lang_selection_list" name="lang" onChange={handle_dammy} value={storeLanguage} >
								<option value="ja">日本語</option>
								<option value="en">English</option>
								<option value="de">Deutsche</option>
								<option value="fr">Français</option>
							</select>
					</p>
				</div>
			</div>
		</div>

	);
}


