import * as CONST from '../../const';
import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUserData, getAccessToken, getRefreshToken, setAccessToken, logout } from '../../store/slicer/0101_session';
import { setPageNumber, setPageMax, getPage } from '../../store/slicer/0502_page';
import { SetErrorMessage } from '../../store/slicer/0101_logout_message';
import { FormattedMessage, useIntl } from 'react-intl';
import toast from 'react-hot-toast';

export default function RoomList(props) {
	const dispatch = useDispatch();
	const userdata = useSelector(getUserData);
	const storePage = useSelector(getPage);
	let drawdata = [];

	var access_token = useSelector(getAccessToken);
	var refresh_token = useSelector(getRefreshToken);

	const intl = useIntl();
	// 初期化
	const [state, setState] = useState(() => {

		// 初期化
		let options = [
			{ value: '', name: '', text: intl.formatMessage({ id: 'list_location_pll' }) },
		];

		const fetchHttpRefresh = async () => {
			let senddata = {};
			let url_tokref = CONST.BASEURL + '/authorize/refresh';
			var httpResponse_tokref = await fetch(url_tokref, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': "Bearer " + refresh_token
				},
				body: JSON.stringify(senddata),
			});
			switch (httpResponse_tokref.status) {
				case 200:
					var httpResponse_tokref_json = await httpResponse_tokref.json();
					access_token = httpResponse_tokref_json.access_token;
					refresh_token = httpResponse_tokref_json.refresh_token;

					// 問題ない場合は、storeに保存
					dispatch(setAccessToken(access_token));
					fetchHttp();
					return;				       
				default:
					dispatch(SetErrorMessage('ERROR_0001'));
					dispatch(logout());
					return;
			}
		}

		// 拠点一覧取得
		const fetchHttp = async () => {
			fetch(CONST.BASEURL + '/customers/' + userdata.id + '/locations?page_size=99999&page_number=1', {
				method: 'GET',
				headers: {
					Authorization: "Bearer " + access_token
				}
			})
			.then(response =>
				response.json().then(data => ({
					data: data,
					status: response.status
				})
				).then(res => {
					switch (res.status) {
						case 200:
							// データ取得
							for (let i = 0; i < res.data.locations.length; i++) {
								options[i + 1] = {
									value: res.data.locations[i].location_id.trim(),
									name: "",
									text: res.data.locations[i].location_name.trim(),
								};
							}
							// 初期化
							setState({ ...state, drawdata: drawdata, options: options, list: '', text: '', search_text: '' });
							return;

						case 401:
							fetchHttpRefresh();
							return;
						case 409:
							dispatch(SetErrorMessage('ERROR_0039'));
							dispatch(logout());
							return; 
						default:
                            //Error出力
                            toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                            break;
					}

				}).catch((err) => {
					//Error出力
					toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

				})
			)
			.catch((err) => {
                //Error出力
                toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

			});
		}
		fetchHttp();
		return {
			page_size: "50",
			page_number: 1,
			page_max: 1,
			drawdata: [{}],
			list: '',
			text: '',
			search_text: '',
			options: options,
			search_form: '',
		}
	});

	// 表示リスト作成
	useEffect(() => {
		let now_page;
		now_page = storePage.page_number;
		if (now_page < 1) {
			now_page = 1;
		}

		// ユーザー一覧取得
		let url_data = CONST.BASEURL + '/customers/' + userdata.id + '/users?page_size=' + state.page_size + '&page_number=' + now_page;

		if (state.list !==undefined && state.list !=='') {
			url_data += '&location_id=' + state.list;
		}
		if (state.search_text !==undefined && state.search_text !=='') {
			url_data += '&search=' + state.search_text;
		}

		const fetchHttpRefresh = async () => {
			let senddata = {};
			let url_tokref = CONST.BASEURL + '/authorize/refresh';
			var httpResponse_tokref = await fetch(url_tokref, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': "Bearer " + refresh_token
				},
				body: JSON.stringify(senddata),
			});
			switch (httpResponse_tokref.status) {
				case 200:
					var httpResponse_tokref_json = await httpResponse_tokref.json();
					access_token = httpResponse_tokref_json.access_token;
					refresh_token = httpResponse_tokref_json.refresh_token;

					// 問題ない場合は、storeに保存
					dispatch(setAccessToken(access_token));
					fetchHttp();
					return;				     
				default:
					dispatch(SetErrorMessage('ERROR_0001'));
					dispatch(logout());
					return;
			}
		}

		// 一覧取得
		const fetchHttp = async () => {
			fetch(url_data, {
				method: 'GET',
				headers: {
					Authorization: "Bearer " + access_token
				}
			})
			.then(response =>
				response.json().then(data => ({
					data: data,
					status: response.status
				})
				).then(res => {
					switch (res.status) {
						case 200:
							let error = state.search_form;
							error = ""

							drawdata = [];

							//検索結果の表示用リストデータを設定する
							drawdata = res.data.users;
							for (let i = 0; i < drawdata.length; i++) {
								drawdata[i].check = false;
								drawdata[i].use_start_date = drawdata[i].use_start_date.split('-').join('/');
								drawdata[i].use_end_date = drawdata[i].use_end_date.split('-').join('/');
							}

							// ページ数を算出する
							const page_max = Math.ceil(res.data.user_count / state.page_size);

							// 最大ページ数を変更
							dispatch(setPageMax(page_max));

							if (drawdata.length === 0) {
								error = intl.formatMessage({ id: 'ERROR_0006' });
								document.getElementById('sbox2').focus();
							}

							setState({ ...state, drawdata: drawdata, search_form: error });
							return;
						case 401:
							fetchHttpRefresh();
							return;
						case 409:
							dispatch(SetErrorMessage('ERROR_0039'));  
							dispatch(logout());							
							return;   
						default:
                            //Error出力
                            toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                            break;
					}

				}).catch((err) => {
					//Error出力
					toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

				})
			)
			.catch((err) => {
                //Error出力
                toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

			});
		}
		fetchHttp();
	}, [state.list, state.search_text, storePage.page_number]);

	// セレクトボックス変更ハンドラ
	const handleSelectChange = useCallback(event => {
		event.preventDefault(true);
		setState({ ...state, list: event.target.value, search_text: '', text: '' });
		dispatch(setPageNumber(1));
	}, [state.list]);

	// テキストボックス入力ハンドラ
	const handleInputChange = event => {
		setState({ ...state, text: event.target.value });
	};

	// サブミットボタンハンドラ
	const handleSubmit = event => {
		event.preventDefault(false);
		setState({ ...state, list: state.list, search_text: state.text });
		dispatch(setPageNumber(1));
	};

	// 描画
	return (
		<div class="scroll_range">
			<title><FormattedMessage id="tab_title_meeting_list" /></title>
			<header class="in_contents_header">

					<div class="flex_box left_justified">
						<div class="selecter_down_arrow select_option element_width_xl">
							<label>
								<img class="svg_img-Pulldown" src="img/EZTP_Pulldown.svg" alt="▽" />
								<select value={state.list} id="location_select" onChange={handleSelectChange}>
									{
										state.options.map((data, index) => {
											return (
												<option value={data.value} >{data.text}</option>
											);
										})
									}
								</select>
							</label>
						</div>
						<div class="element_width_xl blank_space_l">
							<div class="search_box">
								<button tabIndex="-1" class="" type="button" onClick={handleSubmit} id="sbtn2"><img src={`${process.env.PUBLIC_URL}/img/EZTP_search.svg`} alt="" /></button>
                                <FormattedMessage id="list_locationuser_txt">{placeholder => <input id="sbox2" name="s" type="text" placeholder={placeholder} maxlength='64' value={state.text} onChange={handleInputChange} />}</FormattedMessage>
							</div>
						</div>
					</div>

			</header>
			<div class="search_result_table_box">
				<table class="search_result_table list_initial_table">
					<tbody>
						<tr>
							<th><FormattedMessage id="list_locationname_lbl" /></th>
							<th><FormattedMessage id="list_username_lbl" /></th>
							<th><FormattedMessage id="list_startdate_lbl" /></th>
							<th><FormattedMessage id="list_enddate_lbl" /></th>
							<th></th>
						</tr>
						{
							(() => {
								if (state.search_form !=='') {
									return (
										<tr>
											<td colspan="4">
												<div class="has-error">{state.search_form}</div>
											</td>
											<td />
										</tr>
									);
								}
							})()
						}
						{
							state.drawdata.map((data, index) => {
								if (data.location_name === undefined) {
									return (<tr />);
								}
								return (
									<tr>
										<td>{data.location_name}</td>
										<td><Link to={{ pathname: "/user_profile", state: { data: data } }}>{data.user_name}</Link></td>
										<td>{data.use_start_date}</td>
										<td>{data.use_end_date}</td>
										<td>
											<div class="element_width_m inline_block">
												<Link to={{ pathname: "/user_profile", state: { data: data } }} class="buttons_common btn_wht">
													<FormattedMessage id="list_profile_btn" />
												</Link>
											</div>
											<div class="element_width_m inline_block blank_space_m">
												<Link to={{ pathname: "/user_meeting", state: { data: data } }} class="buttons_common btn_wht">
													<FormattedMessage id="list_setting_btn" />
												</Link>
	                                        </div>
										</td>
									</tr>
								);
							})
						}
					</tbody>
				</table>
			</div>
		</div>
	);

}
