const LangJA = {
	'EMPTY': "",
	'signin_signin_lbl': "サインイン",
	'signin_account_txt': "アカウント",
	'signin_password_txt': "パスワード",
	'signin_forget_password_lnk': "パスワードを忘れた場合",
	'signin_stay_password_chk': "サインインしたままにする",
	'signin_privacy_policy_lnk': "プライバシーポリシー",
	'signin_terms_of_use_lnk': "利用規約",
	'signin_signin_btn': "サインイン",
	'send-email-1_sentence1_lbl': "パスワードをお忘れですか？",
	'send-email-1_sentence2_lbl': "ご安心ください。パスワードのリセットは簡単です。{br}EZTサービスにご登録のアカウントをお伝えいただくだけです。",
	'send-email-1_account_txt': "アカウント",
	'send-email-1_send_btn': "送信",
	'send-email-2_sentence1_lbl': "パスワードをリセットする",
	'send-email-2_sentence2_lbl': "EZTサービスに登録されているメールアドレス宛てに{br}リセットパスワードが記載された電子メールを送信しました。{br}新しいパスワードを設定するには、{br}リセットされたパスワードのリンクをクリックしてください。",
	'send-email-2_sentence3_lbl': "ご使用のメールアプリをご確認ください。{br}もしメールが見当たらなければ、{br}迷惑メールフォルダをご確認いただくか、{br}誠にお手数ですが",
	'send-email-2_retry_lnk': "再度お試しください。",
	'password-reset-1_sentence2_lbl': "{customer_name}様、新しいログイン用パスワードを設定してください。",
	'password-reset-1_password_txt': "新しいパスワード",
	'password-reset-1_sentence3_lbl': "パスワードは次の通りに設定してください。",
	'password-reset-1_sentence4_lbl': "・8文字以上",
	'password-reset-1_sentence5_lbl': "・アルファベット小文字/大文字(a～z/A～Z)、数字(0～9)を各１文字以上使うこと。",
	'password-reset-1_passwordchk_txt': "新しいパスワードの確認",
	'password-reset-1_save_btn': "保存",
	'password-reset-1_sentence1_lbl': "パスワードをリセットする",
	'password-reset-2_sentence1_lbl': "パスワードのリセット完了",
	'password-reset-2_sentence2_lbl': "{customer_name}様、パスワードは問題なく変更されました。",
	'password-reset-2_intoezt_btn': "EZTにサインイン",
	'main_signout_btn': "サインアウト",
	'main_title1_lbl': "ルーム",
	'main_title2_lnk': "一覧",
	'main_title3_lnk': "記録",
	'main_title4_lbl': "管理情報",
	'main_title5_lnk': "拠点",
	'main_title6_lnk': "連絡先",
	'main_title7_lnk': "グループ",
	'main_title8_lnk': "会議室情報ページ",
	'main_privacy_policy_lnk': "プライバシーポリシー",
	'main_terms_of_use_lnk': "利用規約",
	'list_location_pll': "拠点名を選択",
	'list_locationuser_txt': "拠点名、会議室名で検索",
	'list_locationname_lbl': "拠点名",
	'list_username_lbl': "会議室名",
	'list_startdate_lbl': "利用開始日",
	'list_enddate_lbl': "利用終了日",
	'list_profile_btn': "プロフィール",
	'list_setting_btn': "設定",
	'profile-view_title1_lbl': "プロフィール",
	'profile-view_title2_lbl': "ミーティング",
	'profile-view_title3_lbl': "記録",
	'profile-view_username_lbl': "会議室名",
	'profile-view_locationname_lbl': "拠点名",
	'profile-view_usage_period_lbl': "利用期間",
	'profile-view_pmi_lbl': "パーソナルミーティングID",
	'profile-view_pmi_password': "パスコード",
	'profile-view_sentence1_lbl': "インスタントミーティングにこのIDを使用する",
	'profile-view_language_lbl': "言語",
	'profile-view_datetime_lbl': "日時",
	'profile-view_timezone_lbl': "タイムゾーン",
	'profile-view_calendar_lbl': "カレンダーとの統合",
	'profile-view_signin-password_lbl': "サインインパスワード",
	'profile-view_edit_btn': "編集",
	'profile-view_calendar_btn': "カレンダーのサービスを構成",
	'profile-edit_sentence3_lbl': "以下のサービスをサポート：Googleカレンダー、Microsoft Exchanger、Microsoft Office 365",
	'profile-edit_new_password1_txt': "新パスワード",
	'profile-edit_sentence4_lbl': "新パスワードの確認",
	'profile-edit_new_password2_txt': "新パスワード",
	'profile-edit_sentence5_lbl': "パスワードは次の通りに設定してください。{br}・8文字以上{br}・アルファベット小文字(a～z)、アルファベット大文字(A～Z)、数字(0～9)を各１文字以上使うこと。",
	'profile-edit_setting_password_txt': "設定ロック解除用パスコード",
	'profile-edit_host_key_txt': "ホストキー",
	'profile-edit_host_key_setting_txt': "６桁の数字を設定してください。",
	'profile-edit_save_btn': "保存",
	'profile-edit_cancel_btn': "キャンセル",
	'settings_title1_lbl': "設定",
	'settings_title2_lbl': "セキュリティ",
	'settings_title3_lbl': "スケジュールする",
	'settings_title4_lbl': "基本",
	'settings_title5_lbl': "詳細",
	'settings_title6_lbl': "記録",
	'settings_applies_btn': "全ルームに適用",
	'meeting_security_lbl': "セキュリティ",
	'meeting_security_sentence1_lbl': "待機室",
	'meeting_security_sentence2_lbl': "参加者がミーティングに参加する際、待機室に参加者を配置し、{br}参加者の入室を個別に許可させるようにホストに求めてください。{br}待機室を有効にすると、参加者がホストの前に参加できる設定が自動的に無効になります。",
	'meeting_security_sentence3_lbl': "ワンクリックで参加できるように、招待リンクにパスワードを埋め込みます",
	'meeting_security_sentence4_lbl': "ミーティングパスワードは暗号化され、招待リンクに含まれます。{br}これにより、パスコードを入力せずに、ワンクリックで参加者が参加できます。",
	'meeting_security_sentence5_lbl': "PMI（個人ミーティングID）に対するパスコード",
	'meeting_security_passcode_txt': "パスコード",
	'meeting_security_edit_btn': "編集",
	'meeting_security_save_btn': "保存",
	'meeting_security_cancel_btn': "キャンセル",
	'meeting_schedule_lbl': "スケジュール",
	'meeting_schedule_sentence1_lbl': "ホストビデオ",
	'meeting_schedule_sentence2_lbl': "ホストビデオオンでミーティングを開始します。",
	'meeting_schedule_sentence3_lbl': "参加者ビデオ",
	'meeting_schedule_sentence4_lbl': "参加者ビデオオンでミーティングを開始します。参加者はミーティング中にこの変更ができます。",
	'meeting_schedule_sentence9_lbl': "個人ミーティングIDを有効化",
	'meeting_schedule_sentence10_lbl': "個人ミーティングID（PMI）は9～11桁の数字で、アカウントに割り当てられます。{br}個人ミーティングルームにアクセスし、個人ミーティング設定を変更できます。",
	'meeting_schedule_sentence11_lbl': "スケジューリング時に個人ミーティングID (PMI)を使用",
	'meeting_schedule_sentence12_lbl': "個人ミーティングルームにアクセスし、個人ミーティング設定を変更できます。",
	'meeting_schedule_sentence13_lbl': "インスタントミーティング開始時に個人ミーティングID (PMI)を使用",
	'meeting_basic_lbl': "基本",
	'meeting_basic_sentence1_lbl': "チャット",
	'meeting_basic_sentence2_lbl': "ミーティング参加者が参加者全員に見える形でメッセージを送信できるようになります。",
	'meeting_basic_sentence5_lbl': "チャット自動保存",
	'meeting_basic_sentence6_lbl': "ミーティング中のチャットをすべて自動的に保存するため、ホストはミーティング開始後に{br}チャットのテキストを手動で保存する必要がありません。",
	'meeting_basic_sentence7_lbl': "誰かが参加するときまたは退出するときに音声で通知",
	'meeting_basic_sentence8_lbl': "ファイル送信",
	'meeting_basic_sentence9_lbl': "ホストと参加者はミーティング内チャットを通じてファイルを送信できます。",
	'meeting_basic_sentence19_lbl': "画面共有",
	'meeting_basic_sentence20_lbl': "ホストと参加者が自身の画面やミーティング中の内容を共有することができます。",
	'meeting_basic_sentence21_lbl': "共有できるのは誰ですか？",
	'meeting_basic_sentence22_rbt': "ホストのみ",
	'meeting_basic_sentence23_rbt': "全参加者",
	'meeting_basic_sentence38_lbl': "遠隔操作",
	'meeting_basic_sentence39_lbl': "画面の共有中に、共有する人が他の人に共有する内容の管理を許可することができます。",
	'meeting_basic_sentence43_lbl': "参加者が自分の名前を変更することを許可",
	'meeting_basic_sentence44_lbl': "ミーティングの参加者とウェビナーのパネリストが自分達の名前を変更することを許可します。",
	'meeting_detail_sentence1_lbl': "詳細",
	'meeting_detail_sentence4_lbl': "「ブラウザから参加する」リンクを表示します",
	'meeting_detail_sentence5_lbl': "参加者はZoomアプリケーションダウンロードプロセスを回避し、各自のブラウザから{br}直接ミーティングに参加することができます。{br}これは、ダウンロード、インストール、アプリケーションの実行ができない参加者向けの方法です。{br}ブラウザからのミーティングエクスペリエンスは制限付きであることに注意してください。",
	'meeting_detail_save_btn': "保存",
	'meeting_detail_cancel_btn': "キャンセル",
	'recording_sentence1_lbl': "記録",
	'recording_sentence2_lbl': "クラウド記録",
	'recording_sentence3_lbl': "ホストがミーティング / ウェビナーをクラウドに記録して保存することを許可",
	'recording_sentence4_chk': "共有画面でアクティブなスピーカーを録画",
	'recording_sentence5_chk': "共有画面でのギャラリービューの録画",
	'recording_sentence10_chk': "音声のみのファイルを記録",
	'recording_sentence11_chk': "ミーティング / ウェビナーからのチャットメッセージを保存",
	'recording_sentence12_lbl': "クラウド記録の詳細設定",
	'recording_sentence13_chk': "録画にタイムスタンプを追加する",
	'recording_sentence17_chk': "音声トランスクリプト",
	'recording_save_btn': "保存",
	'recording_cancel_btn': "キャンセル",
	'record-initial_location_pll': "拠点名を選択",
	'record-initial_startdate_cal': "開始日",
	'record-initial_enddate_cal': "終了日",
	'record-initial_calendar_date': " {year} 年  {month} 月",
	'record-initial_topic_txt': "トピックで検索",
	'record-initial_search_btn': "検索",
	'record-initial_selectdelete_btn': "選択したものを削除",
	'record-delete_sentence2_lbl': "選択した行を削除します。{br}よろしいですか？",
	'record-initial_selectall_chk': "トピック",
	'record-initial_starttime_lbl': "開始日時",
	'record-initial_filecount_lbl': "ファイル数",
	'record-initial_delete_deadline_lbl': "削除期限",
	'record-initial_delete_daysafter_lbl': "{after_days} 日後",
	'record-initial_delete_btn': "削除",
	'record-initial_cancel_btn': "キャンセル",
	'record-details_share_btn': "共有",
	'record-details_recording': "記録",
	'record-details_sentence1_lbl': "この録画は{days}日間で自動的に削除されます。",
	'record-details_playtime_lbl': "再生時間 { times }",
	'record-details_filisize_lbl': " {count} ファイル ({size})",
	'record-details_download_btn': "ダウンロード（ {count} ファイル）",
	'record-details_shareablelink_btn': "共有可能リンクをコピーする",
	'record-details_videoaudio_lbl': "映像+音声",
	'record-details_audio_lbl': "音声のみ",
	'record-details_selectdelete_btn': "レコーディングを削除する",
	'record-details_delete_btn': "削除",
	'record-details_cancel_btn': "キャンセル",
	'record-details_clipboard_copy': "共有可能リンクをクリップボードにコピーしました。",
	'record-share_sentence1_lbl': "このクラウドレコーディングを共有します",
	'record-share_sentence2_lbl': "この記録を共有する",
	'record-share_display_btn': "表示",
	'record-share_display_non_btn': "非表示",
	'record-share_sentence4_lbl': "トピック: ",
	'record-share_sentence5_lbl': "日付: ",
	'record-share_sentence6_lbl': "ミーティングの記録: ",
	'record-share_sentence7_lbl': "パスコードへアクセス: ",
	'record-share_sentence8_lbl': "共有情報",
	'record-share_sentence9_lbl': "この記録には他の誰もアクセスできません",
	'record-share_sentence10_lbl': "パスコードの保護",
	'record-share_save_btn': "保存",
	'record-share_cancel_btn': "キャンセル",
	'record-share_clipboard_copy': "共有情報をクリップボードにコピーしました。",
	'record-copysharing_btn': "共有情報のコピー",
	'record-complete_btn': "完了",
	'base-initial_locationuser_txt': "拠点名で検索",
	'base-initial_addlocation_btn': "拠点を追加",
	'base-initial_locationname_lbl': "拠点名",
	'base-initial_roomcount_lbl': "部屋数",
	'base-initial_selectdelete_btn': "選択したものを削除",
	'base-initial_edit_btn': "編集",
	'base-additional_sentence1_lbl': "拠点を追加する",
	'base-additional_locationname_txt': "拠点名",
	'base-edit_sentence1_lbl': "拠点を編集する",
	'base-edit_locationname_txt': "拠点名",
	'base-edit_save_btn': "保存",
	'base-edit_addlocation_btn': "追加",
	'base-edit_cancel_btn': "キャンセル",
	'base-delete_sentence1_lbl': "拠点を削除する",
	'base-delete_sentence2_lbl': "選択した行を削除します。{br}よろしいですか？",
	'base-delete_delete_lbl': "削除",
	'base-delete_cancel_lbl': "キャンセル",
	'contact-initial_emailcontactname_txt': "メールアドレス、氏名で検索",
	'contact-initial_import_bll': "インポート",
	'contact-initial_import_addition': "追加",
	'contact-initial_import_washing': "入れ替え",
	'contact-initial_export_btn': "エクスポート",
	'contact-initial_addcontact_btn': "連絡先を追加",
	'contact-initial_selectdelete_btn': "選択したものを削除",
	'contact-initial_selectall_chk': "氏名",
	'contact-initial_email_lbl': "メールアドレス",
	'contact-initial_edit_btn': "編集",
	'contact-additional_sentence1_lbl': "連絡先を追加する",
	'contact-additional_email_txt': "メールアドレス",
	'contact-additional_contactname_txt': "氏名",
	'contact-additional_addcontact_btn': "追加",
	'contact-additional_cancel_btn': "キャンセル",
	'contact-edit_sentence1_lbl': "連絡先を編集する",
	'contact-edit_email_txt': "メールアドレス",
	'contact-edit_contactname_txt': "氏名",
	'contact-edit_save_btn': "保存",
	'contact-edit_cancel_btn': "キャンセル",
	'contact-delete_sentence1_lbl': "連絡先を削除する",
	'contact-delete_sentence2_lbl': "選択した行を削除します。{br}よろしいですか？",
	'contact-delete_delete_btn': "削除",
	'contact-delete_cancel_btn': "キャンセル",
	'contact-csv_name': "氏名",
	'contact-csv_sentence': "連絡先",
	'contact-csv_washing_title': "メッセージ",
	'contact-csv_washing_message': "連絡先の入れ替えは、連絡先をすべて再登録するため、{br}グループに登録されていた連絡先もすべてクリアされます。{br}よろしいですか？",
	'contact-import_ok_btn': "OK",
	'contact-import_cancel_btn': "キャンセル",
	'group-initial_groupname_txt': "グループ名で検索",
	'group-initial_addgroup_btn': "グループを追加",
	'group-initial_selectdelete_btn': "選択したものを削除",
	'group-delete_sentence2_lbl': "選択した行を削除します。{br}よろしいですか？",
	'group-initial_selectall_chk': "グループ名",
	'group-initial_membercount_lbl': "メンバー数",
	'group-initial_edit_btn': "編集",
	'group-initial_delete_btn': "削除",
	'group-initial_cancel_btn': "キャンセル",
	'group-additional_sentence1_lbl': "グループを追加する",
	'group-additional_save_btn': "保存",
	'group-additional_cancel_btn': "キャンセル",
	'group-additional_delete_btn': "削除",
	'group-edit_sentence1_lbl': "グループを編集する",
	'group-edit_delete_btn': "削除",
	'group-edit_emailcontactname_txt': "メールアドレス、氏名で検索",
	'group-delete_sentence1_lbl': "グループを削除する",
	'expiration-date_check': "パスワードをリセットする",
	'expiration-date_message': "有効期限が切れています。",
	'tab_title_signin': "サインイン",
	'tab_title_mail_send': "メール送信",
	'tab_title_send_complete': "送信完了",
	'tab_title_password_reset': "パスワードリセット",
	'tab_title_reset_complete': "リセット完了",
	'tab_title_meeting_list': "会議室一覧",
	'tab_title_profile': "プロフィール",
	'tab_title_meeting_setting': "ミーティング設定",
	'tab_title_record_setting': "記録設定",
	'tab_title_record_list': "記録一覧",
	'tab_title_record_details': "記録詳細",
	'tab_title_location_list': "拠点一覧",
	'tab_title_contact_list': "連絡先一覧",
	'tab_title_group_list': "グループ一覧",
	'tab_title_group_edit': "グループ登録",
	'tab_title_meeting_room': "会議室情報ページ",
	'meeting-room_url_txt': "会議室情報ページURL",
	'meeting-room_copyUrl_btn': "URLコピー",
	'meeting-room_edit_btn': "編集",
	'meeting-room_save_btn': "保存",
	'meeting-room_cancel_btn': "キャンセル",
	'meeting-room_password_txt': "表示用パスワード",
	'meeting-list-check_title_txt': "会議室情報",
	'meeting-list-check_placeholder': "パスワード",
	'meeting-list-check_password_save_txt': "次回からパスワードの入力を省略する",
	'meeting-list-check_show_btn': "表示",
	'meeting-list_title_txt': "会議室情報",
	'meeting-list_locationname_txt': "拠点名",
	'meeting-list_meetingRoom_txt': "会議室名",
	'meeting-list_meetingID_txt': "ミーティングID",
	'meeting-list_passcode_txt': "パスコード",
	'meeting-list_goto_meeting_btn': "会議へ参加",
	'meeting-list_email_copy_btn': "招待コピー",
	'meeting-list_disabled_pmi': "PMIを利用していません。",
	'meeting-list_location_pll': "拠点名を選択",
	'meeting-list_locationuser_txt': "拠点名、会議室名で検索",
	'meeting-list_email_txt': "EZT、および、Zoomからミーティングに参加する",
	'meeting-list_email_id': "ミーティングID:",
	'meeting-list_email_pwd': "パスコード:",
	'ERROR_0001': "システムエラー",
	'ERROR_0002': "アカウントが不正です",
	'ERROR_0003': "アカウントまたはパスワードが不正です{br}もう一度サインイン情報を入力するか、アカウントへのアクセス権を依頼するメールをリクエストしてください。",
	'ERROR_0004': "アカウントを入力してください",
	'ERROR_0005': "グループ名は必須です",
	'ERROR_0006': "検索に一致する結果が見つかりません",
	'ERROR_0008': "この会議室名はすでに使用されています",
	'ERROR_0009': "既に登録されている拠点名です。別の拠点名を指定してください。",
	'ERROR_0010': "このグループ名はすでに使用されています",
	'ERROR_0012': "このパーソナルミーティングIDはすでに使用されています",
	'ERROR_0013': "このメールアドレスはすでに使用されています",
	'ERROR_0014': "削除に失敗しました",
	'ERROR_0015': "登録に失敗しました",
	'ERROR_0016': "更新に失敗しました",
	'ERROR_0017': "パーソナルミーティングIDは必須であり、10桁の数字でなければなりません",
	'ERROR_0019': "パスワードポリシーを満たしていません",
	'ERROR_0020': "パスワードを入力してください",
	'ERROR_0021': "ホストキーは必須であり、6桁の数字で、次の例のような簡潔な形式にすることはできません: 111111または222222または123456",
	'ERROR_0022': "メールアドレスは必須です",
	'ERROR_0023': "会議室名は必須です",
	'ERROR_0024': "確認用パスワードが一致しません",
	'ERROR_0025': "拠点名は必須です",
	'ERROR_0026': "氏名は必須です",
	'ERROR_0027': "新しいパスワードは必須です",
	'ERROR_0028': "新しいパスワードは古いパスワードと異なるものでなければなりません",
	'ERROR_0029': "設定ロック解除用パスコードは必須です",
	'ERROR_0030': "csvファイルを選択して下さい。",
	'ERROR_0031': "エクスポートに失敗しました。",
	'ERROR_0032': "インポートに失敗しました。",
	'ERROR_0033': "出力するデータがありません。",
	'ERROR_0034': "このファイルは読込できません。",
	'ERROR_0036': "{success_cnt}件は削除しましたが、{error_cnt}件は削除できませんでした。もう一度操作をお願いします。",
	'ERROR_0037': "正しいメールアドレスを入力してください。",
	'ERROR_0038': "クラウド記録が有効になっている場合は、クラウドに記録するファイルを1つ以上選択する必要があります。",
	'ERROR_0039': "不正なアクセスが検知されました。",
	'ERROR_0040': "会議室一覧が０件になるため、拠点を変更できません。",
	'ERROR_0041': "パスワードが間違っています。再度入力してください。",
	'SUCCESS_0001': "設定が更新されました。",
	'SUCCESS_0002': "ユーザー情報の保存に成功しました。",
	'SUCCESS_0003': "削除に成功しました。",
	'SUCCESS_0004': "登録に成功しました。",
	'SUCCESS_0005': "更新に成功しました。",
	'SUCCESS_0006': "エクスポートに成功しました。",
	'SUCCESS_0007': "インポートに成功しました。",
	'SUCCESS_0008': "URLをコピーしました。",
	'SUCCESS_0009': "招待文をコピーしました。",
	'TIMEZONE_0001': "(GMT-11:00) ミッドウェー島、サモア",
	'TIMEZONE_0002': "(GMT-11:00) パゴパゴ",
	'TIMEZONE_0003': "(GMT-10:00) ハワイ",
	'TIMEZONE_0004': "(GMT-8:00) アラスカ州",
	'TIMEZONE_0005': "(GMT-8:00) ジュノー",
	'TIMEZONE_0006': "(GMT-7:00) バンクーバー",
	'TIMEZONE_0007': "(GMT-7:00) 太平洋標準時（米国およびカナダ）",
	'TIMEZONE_0008': "(GMT-7:00) ティファナ",
	'TIMEZONE_0009': "(GMT-7:00) アリゾナ州",
	'TIMEZONE_0010': "(GMT-6:00) エドモントン",
	'TIMEZONE_0011': "(GMT-6:00) 山地標準時（米国およびカナダ）",
	'TIMEZONE_0012': "(GMT-6:00) マサトラン",
	'TIMEZONE_0013': "(GMT-6:00) サスカチュワン州",
	'TIMEZONE_0014': "(GMT-6:00) グアテマラ",
	'TIMEZONE_0015': "(GMT-6:00) エルサルバドル",
	'TIMEZONE_0016': "(GMT-6:00) マナグア",
	'TIMEZONE_0017': "(GMT-6:00) コスタリカ",
	'TIMEZONE_0018': "(GMT-6:00) テグシガルパ",
	'TIMEZONE_0019': "(GMT-6:00) チワワ",
	'TIMEZONE_0020': "(GMT-5:00) ウィニペグ",
	'TIMEZONE_0021': "(GMT-5:00) 中部標準時（米国およびカナダ）",
	'TIMEZONE_0022': "(GMT-5:00) メキシコシティ",
	'TIMEZONE_0023': "(GMT-5:00) パナマ",
	'TIMEZONE_0024': "(GMT-5:00) ボゴタ",
	'TIMEZONE_0025': "(GMT-5:00) リマ",
	'TIMEZONE_0026': "(GMT-5:00) モンテレー",
	'TIMEZONE_0027': "(GMT-4:00) モントリオール",
	'TIMEZONE_0028': "(GMT-4:00) 東部標準時（米国およびカナダ）",
	'TIMEZONE_0029': "(GMT-4:00) インディアナ（東部）",
	'TIMEZONE_0030': "(GMT-4:00) プエルトリコ",
	'TIMEZONE_0031': "(GMT-4:00) カラカス",
	'TIMEZONE_0032': "(GMT-4:00) サンティアゴ",
	'TIMEZONE_0033': "(GMT-4:00) ラパス",
	'TIMEZONE_0034': "(GMT-4:00) ガイアナ",
	'TIMEZONE_0035': "(GMT-3:00) ハリファックス",
	'TIMEZONE_0036': "(GMT-3:00) モンテビデオ",
	'TIMEZONE_0037': "(GMT-3:00) レシフェ",
	'TIMEZONE_0038': "(GMT-3:00) ブエノスアイレス、ジョージタウン",
	'TIMEZONE_0039': "(GMT-3:00) サンパウロ",
	'TIMEZONE_0040': "(GMT-3:00) 大西洋標準時（カナダ）",
	'TIMEZONE_0041': "(GMT-2:30) ニューファンドランド・ラブラドール州",
	'TIMEZONE_0042': "(GMT-2:00) グリーンランド",
	'TIMEZONE_0043': "(GMT-1:00) カーボベルデ諸島",
	'TIMEZONE_0044': "(GMT+0:00) アゾレス諸島",
	'TIMEZONE_0045': "(GMT+0:00) 標準時UTC",
	'TIMEZONE_0046': "(GMT+0:00) グリニッジ標準時",
	'TIMEZONE_0047': "(GMT+0:00) レイキャビク",
	'TIMEZONE_0048': "(GMT+0:00) ヌアクショット",
	'TIMEZONE_0049': "(GMT+1:00) カサブランカ",
	'TIMEZONE_0050': "(GMT+1:00) ダブリン",
	'TIMEZONE_0051': "(GMT+1:00) ロンドン",
	'TIMEZONE_0052': "(GMT+1:00) リスボン",
	'TIMEZONE_0053': "(GMT+1:00) 西中央アフリカ",
	'TIMEZONE_0054': "(GMT+1:00) アルジェ",
	'TIMEZONE_0055': "(GMT+1:00) チュニス",
	'TIMEZONE_0056': "(GMT+2:00) ベオグラード、ブラチスラバ、リュブリャナ",
	'TIMEZONE_0057': "(GMT+2:00) サラエボ、スコピエ、ザグレブ",
	'TIMEZONE_0058': "(GMT+2:00) オスロ",
	'TIMEZONE_0059': "(GMT+2:00) コペンハーゲン",
	'TIMEZONE_0060': "(GMT+2:00) ブリュッセル",
	'TIMEZONE_0061': "(GMT+2:00) アムステルダム、ベルリン、ローマ、ストックホルム、ウィーン",
	'TIMEZONE_0062': "(GMT+2:00) アムステルダム",
	'TIMEZONE_0063': "(GMT+2:00) ローマ",
	'TIMEZONE_0064': "(GMT+2:00) ストックホルム",
	'TIMEZONE_0065': "(GMT+2:00) ウィーン",
	'TIMEZONE_0066': "(GMT+2:00) ルクセンブルク",
	'TIMEZONE_0067': "(GMT+2:00) パリ",
	'TIMEZONE_0068': "(GMT+2:00) チューリッヒ",
	'TIMEZONE_0069': "(GMT+2:00) マドリード",
	'TIMEZONE_0070': "(GMT+2:00) ハラレ、プレトリア",
	'TIMEZONE_0071': "(GMT+2:00) ワルシャワ",
	'TIMEZONE_0072': "(GMT+2:00) プラハ ブラチスラヴァ",
	'TIMEZONE_0073': "(GMT+2:00) ブダペスト",
	'TIMEZONE_0074': "(GMT+2:00) トリポリ",
	'TIMEZONE_0075': "(GMT+2:00) カイロ",
	'TIMEZONE_0076': "(GMT+2:00) ヨハネスブルグ",
	'TIMEZONE_0077': "(GMT+2:00) ハルツーム",
	'TIMEZONE_0078': "(GMT+3:00) ヘルシンキ",
	'TIMEZONE_0079': "(GMT+3:00) ナイロビ",
	'TIMEZONE_0080': "(GMT+3:00) ソフィア",
	'TIMEZONE_0081': "(GMT+3:00) イスタンブール",
	'TIMEZONE_0082': "(GMT+3:00) アテネ",
	'TIMEZONE_0083': "(GMT+3:00) ブカレスト",
	'TIMEZONE_0084': "(GMT+3:00) ニコシア",
	'TIMEZONE_0085': "(GMT+3:00) ベイルート",
	'TIMEZONE_0086': "(GMT+3:00) ダマスカス",
	'TIMEZONE_0087': "(GMT+3:00) エルサレム",
	'TIMEZONE_0088': "(GMT+3:00) アンマン",
	'TIMEZONE_0089': "(GMT+3:00) モスクワ",
	'TIMEZONE_0090': "(GMT+3:00) バグダッド",
	'TIMEZONE_0091': "(GMT+3:00) クウェート",
	'TIMEZONE_0092': "(GMT+3:00) リヤド",
	'TIMEZONE_0093': "(GMT+3:00) バーレーン",
	'TIMEZONE_0094': "(GMT+3:00) カタール",
	'TIMEZONE_0095': "(GMT+3:00) アデン",
	'TIMEZONE_0096': "(GMT+3:00) ジブチ",
	'TIMEZONE_0097': "(GMT+3:00) モガディシュ",
	'TIMEZONE_0098': "(GMT+3:00) キエフ",
	'TIMEZONE_0099': "(GMT+3:00) ミンスク",
	'TIMEZONE_0100': "(GMT+4:00) ドバイ",
	'TIMEZONE_0101': "(GMT+4:00) マスカット",
	'TIMEZONE_0102': "(GMT+4:00) バクー、トビリシ、エレバン",
	'TIMEZONE_0103': "(GMT+4:30) テヘラン",
	'TIMEZONE_0104': "(GMT+4:30) カブール",
	'TIMEZONE_0105': "(GMT+5:00) エカテリンブルク",
	'TIMEZONE_0106': "(GMT+5:00) イスラマバード、カラチ、タシケント",
	'TIMEZONE_0107': "(GMT+5:30) インド",
	'TIMEZONE_0108': "(GMT+5:30) ムンバイ、コルカタ、ニューデリー",
	'TIMEZONE_0109': "(GMT+5:30) Asia/Colombo",
	'TIMEZONE_0110': "(GMT+5:45) カトマンズ",
	'TIMEZONE_0111': "(GMT+6:00) アルマトイ",
	'TIMEZONE_0112': "(GMT+6:00) ダッカ",
	'TIMEZONE_0113': "(GMT+6:00) アスタナ、ダッカ",
	'TIMEZONE_0114': "(GMT+6:30) ラングーン",
	'TIMEZONE_0115': "(GMT+7:00) ノボシビルスク",
	'TIMEZONE_0116': "(GMT+7:00) クラスノヤルスク",
	'TIMEZONE_0117': "(GMT+7:00) バンコク",
	'TIMEZONE_0118': "(GMT+7:00) ベトナム",
	'TIMEZONE_0119': "(GMT+7:00) ジャカルタ",
	'TIMEZONE_0120': "(GMT+8:00) イルクーツク、ウランバートル",
	'TIMEZONE_0121': "(GMT+8:00) 北京、上海",
	'TIMEZONE_0122': "(GMT+8:00) 香港特別行政区",
	'TIMEZONE_0123': "(GMT+8:00) 台北",
	'TIMEZONE_0124': "(GMT+8:00) クアラルンプール",
	'TIMEZONE_0125': "(GMT+8:00) シンガポール",
	'TIMEZONE_0126': "(GMT+8:00) パース",
	'TIMEZONE_0127': "(GMT+9:00) ヤクーツク",
	'TIMEZONE_0128': "(GMT+9:00) ソウル",
	'TIMEZONE_0129': "(GMT+9:00) 大阪、札幌、東京",
	'TIMEZONE_0130': "(GMT+9:30) ダーウィン",
	'TIMEZONE_0131': "(GMT+9:30) アデレード",
	'TIMEZONE_0132': "(GMT+10:00) ウラジオストク",
	'TIMEZONE_0133': "(GMT+10:00) グアム、ポートモレスビー",
	'TIMEZONE_0134': "(GMT+10:00) ブリスベン",
	'TIMEZONE_0135': "(GMT+10:00) キャンベラ、メルボルン、シドニー",
	'TIMEZONE_0136': "(GMT+10:00) ホバート",
	'TIMEZONE_0137': "(GMT+11:00) マガダン",
	'TIMEZONE_0138': "(GMT+11:00) ソロモン諸島",
	'TIMEZONE_0139': "(GMT+11:00) ニューカレドニア",
	'TIMEZONE_0140': "(GMT+12:00) カムチャッカ半島",
	'TIMEZONE_0141': "(GMT+12:00) フィジー諸島、マーシャル諸島",
	'TIMEZONE_0142': "(GMT+12:00) オークランド、ウェリントン",
	'TIMEZONE_0143': "(GMT+13:00) サモア独立国",
};
export default LangJA;
