import { createSlice } from '@reduxjs/toolkit';


// Redux Toolkitを使用すると、reducersで「変更」ロジックを記述できます。 
// 「draft state」への変更を検出しそれらの変更に基づいて
// まったく新しい不変の状態を生成するImmerライブラリを使用するため
// 実際には状態を変更しません。
export const pageSlice = createSlice({
	name: 'page',
	initialState: {
		page_max: '',
		page_number: '',
	},
	reducers: {
		clearPage: (state) => {
			state.page_max = 1;
			state.page_number = 1;
		},
		setPageNumber: (state, action) => {
			state.page_number = Number(action.payload);
		},
		setPageMax: (state, action) => {
			state.page_max = Number(action.payload);
		},
		setPage: (state, action) => {
			state.page_max =  Number(action.payload.page_max);
			state.page_number =  Number(action.payload.page_number);
		},
	},
});

export const { clearPage, setPageNumber, setPageMax, setPage } = pageSlice.actions;


// サンク：非同期ロジックの実行
// dispatch(incrementByAmount(amount))
// 最初の引数として dispatch関数を使用してサンクが呼び出されます。
// その後、非同期コードを実行し、他のアクションをディスパッチできます
/*
export const incrementAsync = amount => dispatch => {
  setTimeout(() => {
    dispatch(incrementByAmount(amount));
  }, 1000);
};
*/

// セレクター：state から値を取得する
// useSelector((state) => state.counter.value) のようにインラインで定義もできる

export const getPage = state => state.page;


// レデューサーをエクスポートする
export default pageSlice.reducer;

