import React, { useState, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProfile, getUserProfile } from '../../store/slicer/0503_room';
import * as CONST from '../../const';
import { getAccessToken, getRefreshToken, setAccessToken, logout } from '../../store/slicer/0101_session';
import { SetErrorMessage } from '../../store/slicer/0101_logout_message';
import toast from 'react-hot-toast';
import { FormattedMessage, useIntl } from "react-intl";

export default function UserProfileBody() {
    const dispatch = useDispatch();
    


    // プロフィール
    const storeProfile = useSelector(getProfile);
    const storeUserProfile = useSelector(getUserProfile);

    const intl = useIntl();

    var access_token = useSelector(getAccessToken);
    var refresh_token = useSelector(getRefreshToken);
    // 初期化
    const [state, setState] = useState(() => {
        let temp = {};

        temp.edit = false;
        temp.old_password = storeUserProfile.setting_password;
        temp.new_password = "";

        //masking
        temp.pass_class = true;
        temp.form = [
            { message: 'EMPTY' },
        ];
        temp.focus_initial_escape = false;
        return temp;
    });


    // テキストボックス入力処理
    const handleInputPassword = event => {
        setState({ ...state, new_password: event.target.value });      
    };


    // onClickイベントハンドラ
    const handleOnClick = event => {
        event.preventDefault(false);
        const type = event.target.getAttribute('data-type');
        const editing = state.edit ? false : true;
        let error = [...state.form];
      
        switch (type) {
            case "edit":

                setState({ ...state, edit: editing, focus_initial_escape: true });

                break;

            case "cancel":
                error[0]['message'] = 'EMPTY'; 
                let text_reset = "";
                setState({ ...state, edit: editing, new_password: text_reset, form: error, });

                break;

            case "submit":
                //入力チェック　
                if (state.new_password.length === 0) {
                    error[0]['message'] = 'ERROR_0029';
                    document.getElementById('passcode_for_unlocking').focus();
                    setState({ ...state, form: error, });
                    return;
                }
                const fetchHttpRefresh = async () => {
                    let senddata = {
                    };
                    let url_tokref = CONST.BASEURL + '/authorize/refresh';
                    var httpResponse_tokref = await fetch(url_tokref, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + refresh_token
                        },
                        body: JSON.stringify(senddata),
                    });
                    switch (httpResponse_tokref.status) {
                        case 200:
                            var httpResponse_tokref_json = await httpResponse_tokref.json();
                            access_token = httpResponse_tokref_json.access_token;
                            refresh_token = httpResponse_tokref_json.refresh_token;
                            // トークンをデコードする
                            //const decode_token = jwt(access_token);
                            // 問題ない場合は、storeに保存
                            dispatch(setAccessToken(access_token));
                            //dispatch(setRefreshToken(refresh_token));
                            fetchHttp();
                            return;                              
                        default:
                            dispatch(SetErrorMessage('ERROR_0001'));
                            dispatch(logout());
                            return;
                    }
                }
                //fetchHttpRefresh();
                //更新処理
                const fetchHttp = async () => {
                    fetch(CONST.BASEURL + '/users/' + storeProfile.user_id, {
                        method: 'PATCH',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: "Bearer " + access_token
                        },
                        body: JSON.stringify({ "setting_password": state.new_password })
                    })
                        .then(response => {
                            switch (response.status) {
                                case 204:
                                    //成功通知
                                    error[0]['message'] = 'EMPTY';
                                    toast(intl.formatMessage({ id: 'SUCCESS_0002' }),{style:{background:  CONST.HOT_TOAST_SUCCESS}});

                                    let text_reset = "";
                                    setState({ ...state, edit: false, old_password: state.new_password, new_password: text_reset, form: error, });

                                    break;
                                case 401:
                                    fetchHttpRefresh();
                                    return;
                                case 409:
                                    dispatch(SetErrorMessage('ERROR_0039'));  
                                    dispatch(logout());
                                    return;  
                                default:
                                    response.json().then(data => ({
                                        data: data,
                                        status: response.status
                                    })
                                    ).then(res => {
                                        //Error出力
                                        toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                                    }).catch((err) => {
                                        //Error出力
                                        toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                                    });
                            }
                        })
                        .catch((err) => {
                            //Error出力
                            toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                        });
                }
                fetchHttp();
                break;
            default:
                break;
        }
    }

    // ダイアログ初期カーソル
    useLayoutEffect(() => {
        if (state.edit == true) {
            document.getElementById('passcode_for_unlocking').focus();
        } else if (state.edit == false && state.focus_initial_escape == true) {
            document.getElementById('edit_btn8').focus();
            setState({ ...state, focus_initial_escape: false });
        }
    }, [state.edit])

    // レンダリング
    switch (state.edit) {
        // 表示画面
        case false:
            return (
                <tbody class="lump">
                    <tr>
                        <td><p><FormattedMessage id="profile-edit_setting_password_txt" /></p></td>
                        <td><input class="masking" id="masking4" disabled="disabled" type="text" value="************" /></td>
                        <td class="edit_button_cell">
                            <div class="element_width_ms">
                                <a id="edit_btn8" href="javascript:void(0);" class="buttons_common btn_wht" data-type="edit" onClick={handleOnClick}><FormattedMessage id="profile-view_edit_btn" /></a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            );

        // 編集画面
        case true:
            return (
                <tbody class="lump" id="passcode_for_unlocking_settings">
                    <tr>
                        <td><label for="passcode_for_unlocking"><FormattedMessage id="profile-edit_setting_password_txt" /></label></td>                  
                        <td>
                            <div class="element_width_xxl">
                                <input id="passcode_for_unlocking" class={state.form[0].message !=='EMPTY' ? 'has-error' : ''} type="text" type="text" value="" maxlength='32' value={state.new_password} onChange={handleInputPassword}></input>
                            </div>
                        </td>
                        <td class="save_and_cancel">
                            <div class="delete_invisible_space">
                                    <div class="inline_block element_width_ms"><FormattedMessage id="profile-edit_save_btn">{value => <input class="buttons_common btn_blue" type="submit" value={value} data-type="submit" onClick={handleOnClick} />}</FormattedMessage></div>
                                    <div class="inline_block element_width_ms blank_space_m"><button class="buttons_common btn_wht" data-type="cancel" onClick={handleOnClick}><FormattedMessage id="profile-edit_cancel_btn" /></button></div>
                                </div>
                        </td>
                    </tr>
                    <br class="has-error" style={{ display: state.form[0].message==='EMPTY' ? '' : 'none' }} />
                    <tr class="has-error" style={{ display: state.form[0].message !=='EMPTY' ? '' : 'none' }}>
                        <td />
                        <div class="has-error" style={{ display: state.form[0].message !=='EMPTY' ? '' : 'none' }}><FormattedMessage id={state.form[0].message} /></div>
                    </tr>
			    </tbody>
            );
            

        default:
            break;
    }

    return (<tbody class="lump"></tbody>);
}
