import * as CONST from '../../const';
import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { clearMessagebox, setTitleMessage, setValue, setVisible, getVisible, getResult, getValue } from '../../store/slicer/0102_messagebox';
import Header from '../0003_components/0001_header';
import MessageBox from '../0003_components/0006_message_ok_only_multilingual';
import { FormattedMessage, useIntl } from 'react-intl';
import toast, { Toaster } from 'react-hot-toast';
import { setLanguage, getLanguage } from '../../store/slicer/0103_language';

export default function PasswordReset(props) {
	const dispatch = useDispatch();
	const loc = useLocation();
	const history = useHistory();

	// メッセージボックス
	const storeVisible = useSelector(getVisible);
	const storeResult = useSelector(getResult);
	const storeValue = useSelector(getValue);
	const storeLanguage = useSelector(getLanguage);
	const intl = useIntl();
    const message_title = "expiration-date_check";
    const message_content = "expiration-date_message";

	let url_query = {};
	(() => {
		loc.search.substr(1).split("&").forEach(function(query) {
			const data = query.split('=');
			url_query[data[0]] = data[1];
		});

		// 言語の設定
		switch (url_query['lang']) {
			case 'ja':
			case 'de':
			case 'en':
			case 'fr':
				break;
			case 'jp':
			default:
				url_query['lang'] = 'ja';
				break;
		}
		dispatch(setLanguage(url_query['lang']));
	})();

	// 初期化
	const [state, setState] = useState(() => {
		toast.dismiss();
		// メッセージボックスの作成
		dispatch(clearMessagebox());
		dispatch(setTitleMessage({
			title: message_title, 
			message: message_content, 
		}));
		dispatch(setValue({message_type: 'expires'}));

		// URLトークンチェック
		fetch(CONST.BASEURL + '/customers/' + url_query.url_token + '/password/auth', {
			method:'POST',
			headers: {
			}
		})
		.then(response => 
			response.json().then(data => ({
				data: data,
				status: response.status
			})
			).then(res => {
				switch (res.status) {
					case 200:
						setState({...state, customer_id:  res.data.customer_id, customer_name: res.data.customer_name});
						break;
					case 401:
						dispatch(setTitleMessage({
							title: message_title,
							message: message_content,
						}));
						dispatch(setVisible(true));
						break;
					default:
						dispatch(setTitleMessage({
							title: message_title, 
							message: message_content, 
						}));
						dispatch(setVisible(true));
						break;
				}
				return;
			}).catch((err) => {
				//Error出力
				toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

			})
		)
		.catch((err) => {
			dispatch(setTitleMessage({
				title: message_title, 
				message: err.message, 
			}));
			dispatch(setVisible(true));
			return;
		});

		return {
			customer_id: '',
			customer_name: '',
			new_password: '',
			confirm_password: '',
			saved: false,
			form: [
				{ message: 'EMPTY' },
				{ message: 'EMPTY' },
			],
		}
	});


	// 入力のイベント
	const handleInputChange = event => {
		event.preventDefault(true);

		switch (event.target.name) {
		case 'new_password':
			setState({...state, new_password: event.target.value});
			break;

		case 'confirmation_password':
			setState({...state, confirm_password: event.target.value});
			break;

		default:
			break;
		}
	};



	// サブミット
	const handleSubmit = event => {
		event.preventDefault(false);
		let error = [...state.form];

		error[0]['message'] = 'EMPTY';
		error[1]['message'] = 'EMPTY';

		dispatch(setValue({message_type: 'error'}));
		switch (state.saved) {
		case false:
			// エラーチェック
			if (state.new_password.length === 0) {
				error[0]['message'] = 'ERROR_0027';
				document.getElementById('password').focus();
				setState({ ...state, form: error, });
				return;
            }

			if (state.new_password !==state.confirm_password) {
				error[1]['message'] = 'ERROR_0024';
				document.getElementById('password_conf').focus();
				setState({ ...state, form: error, });
				return;
            }

			let error_flag = false;
			const reg_result = state.new_password.match(/^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,32}$/);
			error_flag = (state.new_password !==state.confirm_password) ? true: error_flag;
			error_flag = (reg_result===null) ? true: error_flag;

			if (error_flag) {
				error[0]['message'] = 'ERROR_0019';
				document.getElementById('password').focus();
				setState({ ...state, form: error, });
				return;
			}

			// パスワード更新
			fetch(CONST.BASEURL + '/customers/' + state.customer_id + '/password', {
				method:'PUT',
				headers: {
					'Content-type':'application/json'
				},
				body: JSON.stringify({
					'new_password': state.new_password,
				})
			})
				.then(response => {
				switch (response.status) {
					case 204:
						setState({...state, saved: true, customer_id: '', new_password: '', confirm_password: ''});
						break;
					case 401:
						toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

						break;
					default:
						response.json().then(data => ({
							data: data,
							status: response.status
						})
						).then(res=> {
							toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

						})
						break;
				}
			})
			.catch((err) => {
				toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

			});
			break;

		case true:
			setState({...state, saved: false, message: ''});
			history.push('/login');
			break;

		default:
			break;
		}
	};




	// 副作用処理
	useEffect(() => {
		switch (storeResult) {
		case 'ok':
			switch (storeValue.message_type) {
			case 'expires':
				setState({...state, saved: false, customer_id:  '', customer_name: '', new_password: '', confirm_password: ''});
				history.push('/forget');
				break;

			case 'error':
				break;

			default:
				break;
			}
			break;
		}
	}, [storeResult])



	// 描画
	switch (state.saved) {
	// 送信前
	case false:
		return (
			<div>
				{storeVisible && <div id="modal_overlay"></div>}
				<Header />
				<title><FormattedMessage id="tab_title_password_reset" /></title>
				<div class="container">
					<div class="contents">
						{storeVisible && <MessageBox />}
						<style jsx="true" />
						<Toaster position="top-center" reverseOrder="false" containerStyle={{ marginTop:'75px'}} />
						{!storeVisible && 
							<div class="before_login_box">
								<div class="operation_box">
								<h1><FormattedMessage id="password-reset-1_sentence1_lbl" /></h1>
									<dl>
										<dt class="blank_above_m"><p><FormattedMessage id="password-reset-1_sentence2_lbl" values={{ customer_name: state.customer_name }} /></p></dt>
										<dd class="blank_above_s">
											<FormattedMessage id="password-reset-1_password_txt">{placeholder => <input class={state.form[0].message !== 'EMPTY' ? 'has-error' : ''} id="password" type="password" name="new_password" placeholder={placeholder} value={state.new_password} onChange={handleInputChange} maxlength='32' autoFocus/>}</FormattedMessage>
											<div class="has-error" style={{ display: state.form[0].message !=='EMPTY' ? '' : 'none' }}><FormattedMessage id={state.form[0].message} /></div>
										</dd>
										<dt class="blank_above_m">
											<div id="caution">
												<ul>
													<li><FormattedMessage id="password-reset-1_sentence3_lbl" /></li>
													<li><FormattedMessage id="password-reset-1_sentence4_lbl" /></li>
													<li><FormattedMessage id="password-reset-1_sentence5_lbl" values={{ br: <br /> }} /></li>
												</ul>
											</div>
										</dt>
										<dd class="blank_above_l">
											<FormattedMessage id="password-reset-1_passwordchk_txt">{placeholder => <input class={state.form[1].message !== 'EMPTY' ? 'has-error' : ''} id="password_conf" type="password" name="confirmation_password" placeholder={placeholder} value={state.confirm} onChange={handleInputChange} maxlength='32' />}</FormattedMessage>											
											<div class="has-error" style={{ display: state.form[1].message !=='EMPTY' ? '' : 'none' }}><FormattedMessage id={state.form[1].message} /></div>
										</dd>
										<dd class="blank_above_l"><div class="element_width_ms inline_block"><FormattedMessage id="password-reset-1_save_btn">{placeholder => <input onClick={handleSubmit} class="before_login_buttons btn_blue" type="button" value={placeholder} />}</FormattedMessage></div></dd>										
									</dl>
								</div>
							</div>
						}
					</div>

				</div>
			</div>
		);
		

	// 送信後
	case true:
		return (
			<div class="container">
				<title><FormattedMessage id="tab_title_reset_complete" /></title>
				<Header />
				<div class="contents">
					<style jsx="true" />
					<Toaster position="top-center" reverseOrder="false" containerStyle={{ marginTop:'75px'}} />
					<div class="before_login_box">
						<div class="operation_box">
							<h1><FormattedMessage id="password-reset-2_sentence1_lbl" /></h1>
							<dl>
								<dd class="blank_above_m"><p><FormattedMessage id="password-reset-2_sentence2_lbl" values={{customer_name: state.customer_name }} /></p></dd>
								<dd class="blank_above_m"><div class="sign_in_to_EZT inline_block"><FormattedMessage id="password-reset-2_intoezt_btn">{placeholder => <input onClick={handleSubmit} class="before_login_buttons btn_wht" type="button" value={placeholder} />}</FormattedMessage></div></dd>									
							</dl>
						</div>
					</div>
				</div>

			</div>
		);
		

	default:
		break;
	}

	return (
		<div />
	);


}
