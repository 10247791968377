import { Route, Redirect} from "react-router-dom";
import { useSelector } from "react-redux";
import { checkLogin } from "../../store/slicer/0101_session";


export default function GuestRoute(props) {
	const loggedin = useSelector(checkLogin);
	return loggedin ? <Redirect to="/room" />: <Route {...props} />;
}

