import { createSlice } from '@reduxjs/toolkit';


// Redux Toolkitを使用すると、reducersで「変更」ロジックを記述できます。 
// 「draft state」への変更を検出しそれらの変更に基づいて
// まったく新しい不変の状態を生成するImmerライブラリを使用するため
// 実際には状態を変更しません。
export const groupSlice = createSlice({
	name: 'group',
	initialState: {
		groups: {
			group_id: '',
			group_name: '',
		}
	},
	reducers: {
		setGroup: (state, action) => {
			state.groups.group_id = action.payload.group_id;
			state.groups.group_name = action.payload.group_name;
		},
	},
});

export const { setGroup } = groupSlice.actions;

// サンク：非同期ロジックの実行
// dispatch(incrementByAmount(amount))
// 最初の引数として dispatch関数を使用してサンクが呼び出されます。
// その後、非同期コードを実行し、他のアクションをディスパッチできます
/*
export const incrementAsync = amount => dispatch => {
  setTimeout(() => {
	dispatch(incrementByAmount(amount));
  }, 1000);
};
*/

// サンク：非同期ロジックの実行
// dispatch(incrementByAmount(amount))
// 最初の引数として dispatch関数を使用してサンクが呼び出されます。
// その後、非同期コードを実行し、他のアクションをディスパッチできます
/*
export const incrementAsync = amount => dispatch => {
  setTimeout(() => {
	dispatch(incrementByAmount(amount));
  }, 1000);
};
*/

// セレクター：state から値を取得する
// useSelector((state) => state.counter.value) のようにインラインで定義もできる

export const getGroup = state => state.group.groups;

// レデューサーをエクスポートする
export default groupSlice.reducer;

