import { useState }  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSideMenu } from '../../../store/slicer/0501_menu';
import { getVisible } from '../../../store/slicer/0102_messagebox';
import { useHistory,useLocation } from 'react-router-dom'
import Header from '../../0003_components/0001_header';
import Sidemenu from '../../0003_components/0002_sidemenu';
import MessageBox from '../../0003_components/0004_message_ok_only';
import GroupList from '../../0003_components/1901_group_list';
import Pagination from '../../0003_components/0003_pagination';
import { toast,Toaster } from 'react-hot-toast';

export default function Group(props) {
	const dispatch = useDispatch();
	const history = useHistory();

	// メッセージボックス
	const storeVisible = useSelector(getVisible);

	// 初期化
	const [state, setState] = useState(() => {
		dispatch(setSideMenu(4));
		
		if(history.location.state === undefined){
			toast.dismiss()
		}
		
		return 0;
	});

	return(
		<div class="wrapper">
			<Header />
			<div class="container">
				<div class="contents">
					{storeVisible && <div id="modal_overlay"></div>}
					<Sidemenu />
					{storeVisible && <MessageBox />}
					{!storeVisible && 
						<div class="in_contents group-initial">
						<style jsx="true" />
							<Toaster position="top-center" reverseOrder="false" containerStyle={{ marginTop:'75px'}} />
							<GroupList />
							<Pagination />
						</div>
					}
				</div>
			</div>
		</div>
	);
}
