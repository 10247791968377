import React, { useState, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserProfile, } from '../../store/slicer/0503_room';
import * as CONST from '../../const';
import { getAccessToken, getRefreshToken, setAccessToken, logout } from '../../store/slicer/0101_session';
import { SetErrorMessage } from '../../store/slicer/0101_logout_message';
import toast from 'react-hot-toast';
import { FormattedMessage, useIntl } from "react-intl";

export default function UserProfileBody() {
    const dispatch = useDispatch();
    


    // プロフィール
    const storeProfile = useSelector(getUserProfile);

    const intl = useIntl();

    var access_token = useSelector(getAccessToken);
    var refresh_token = useSelector(getRefreshToken);
        // 初期化
    const [state, setState] = useState(() => {

        let temp = {};

        temp.edit = false;
        temp.password = "************"; //storeProfile.password;

        //masking
        temp.pass_class = true;
        temp.type = "password";

        temp.form = [
            { message: 'EMPTY' },
            { message: 'EMPTY' },
        ];
        temp.focus_initial_escape = false;
        return temp;
    });

    // onClickイベントハンドラ
    const handleOnClick = event => {
        event.preventDefault(false);
        const type = event.target.getAttribute('data-type');
        const editing = state.edit ? false : true;
        let error = [...state.form];

        switch (type) {
            case "edit":

                setState({ ...state, edit: editing, focus_initial_escape: true });

                break;

            case "cancel":
                error[0]['message'] = 'EMPTY';
                error[1]['message'] = 'EMPTY';
                setState({ ...state, edit: editing, pass_class: true, form: error, });

                break;

            case "submit":
                error[0]['message'] = 'EMPTY';
                error[1]['message'] = 'EMPTY';

                //新パスワード
                var obj_newpass = document.getElementById("new_password");
                var newpass = obj_newpass.value;

                if (newpass.length === 0) {
                    error[0]['message'] = 'ERROR_0027';
                    document.getElementById('new_password').focus();
                    error[1]['message'] = "EMPTY";
                    setState({ ...state, form: error, });
                    return;
                }

                if (newpass === state.password) {
                    error[0]['message'] = 'ERROR_0028';
                    document.getElementById('new_password').focus();
                    error[1]['message'] = "EMPTY";
                    setState({ ...state, form: error, });
                    return;
                }

                var regex = new RegExp(/^(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])/);
                
                if ((newpass.length < 8) || (newpass.length > 32) || (!regex.test(newpass))) {
                    error[0]['message'] = 'ERROR_0019';
                    document.getElementById('new_password').focus();
                    error[1]['message'] = "EMPTY";
                    setState({ ...state, form: error, });
                    return;
                }
                
                //確認用パスワード
                var obj_confirmpass = document.getElementById("confirm_password");
                if (obj_confirmpass.value !== newpass) {
                    error[1]['message'] = 'ERROR_0024';
                    document.getElementById('confirm_password').focus();
                    error[0]['message'] = "EMPTY";
                    setState({ ...state, form: error, });
                    return;
                }

                //更新データ
                let senddata = {
                    'password': newpass,
                };

                const fetchHttpRefresh = async () => {
                    let senddata = {
                    };
                    let url_tokref = CONST.BASEURL + '/authorize/refresh';
                    var httpResponse_tokref = await fetch(url_tokref, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + refresh_token
                        },
                        body: JSON.stringify(senddata),
                    });
                    switch (httpResponse_tokref.status) {
                        case 200:
                            var httpResponse_tokref_json = await httpResponse_tokref.json();
                            access_token = httpResponse_tokref_json.access_token;
                            refresh_token = httpResponse_tokref_json.refresh_token;
                            // トークンをデコードする
                            //const decode_token = jwt(access_token);
                            // 問題ない場合は、storeに保存
                            dispatch(setAccessToken(access_token));
                            //dispatch(setRefreshToken(refresh_token));
                            fetchHttp();
                            return;                                             
                        default:
                            dispatch(SetErrorMessage('ERROR_0001'));
                            dispatch(logout());
                            return;
                    }
                }
                //fetchHttpRefresh();
                //更新処理
                const fetchHttp = async () => {
                    fetch(CONST.BASEURL + '/users/' + storeProfile.user_id + '/password', {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: "Bearer " + access_token
                        },
                        body: JSON.stringify(senddata),
                    })
                        .then(response => {
                            switch (response.status) {
                                case 204:
                                    //成功通知
                                    error[0]['message'] = 'EMPTY';
                                    error[1]['message'] = 'EMPTY';
                                    setState({ ...state, edit: false, form: error, });

                                    toast(intl.formatMessage({ id: 'SUCCESS_0002' }),{style:{background:  CONST.HOT_TOAST_SUCCESS}});
                                    break;
                                case 401:
                                    fetchHttpRefresh();
                                    return;
                                case 409:
                                    dispatch(SetErrorMessage('ERROR_0039'));  
                                    dispatch(logout());
                                    return; 
                                default:
                                    response.json().then(data => ({
                                        data: data,
                                        status: response.status
                                    })
                                    ).then(res => {
                                        //Error出力
                                        toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                                    }).catch((err) => {
                                        //Error出力
                                        toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                                    });
                            }
                        })
                        .catch((err) => {
                            //Error出力
                            toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                        });
                }
                fetchHttp();
                break;
            default:
                break;
        }
    }

    // ダイアログ初期カーソル
    useLayoutEffect(() => {
        if (state.edit == true) {
            document.getElementById('new_password').focus();
        } else if (state.edit == false && state.focus_initial_escape == true) {
            document.getElementById('edit_btn7').focus();
            setState({ ...state, focus_initial_escape: false });
        }
    }, [state.edit])

    // レンダリング
    switch (state.edit) {
        // 表示画面
        case false:
            return (
                <tbody class="lump">
                    <tr>
                        <td><p><FormattedMessage id="profile-view_signin-password_lbl" /></p></td>
                        <td><input class="masking" id="masking3" disabled="disabled" type="text" value={state.password} /></td>
                        <td class="edit_button_cell">
                            <div class="element_width_ms">
                                <a id="edit_btn7" href="javascript:void(0);" class="buttons_common btn_wht" data-type="edit" onClick={handleOnClick}><FormattedMessage id="profile-view_edit_btn" /></a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            );

        // 編集画面
        case true:
            return (
                <tbody class="lump" id="sign_in_password">
                    <tr>
                        <td><label for="new_password"><FormattedMessage id="profile-view_signin-password_lbl" /></label></td>
                        <td colspan="2">
                            <div class="flex_box bottom_justified password_alignment">
                                <div class="element_width_xxl">
                                    <FormattedMessage id="profile-edit_new_password1_txt">{value =>
                                        <input id="new_password" class={state.form[0].message !=='EMPTY' ? 'has-error' : ''} type="password" name="name" minlength="8" placeholder={value} />}
                                    </FormattedMessage></div>                                
                                <div class="element_width_xxl">
                                    <div class="label_alignment">
                                        <label for="confirm_password"><FormattedMessage id="profile-edit_sentence4_lbl" /></label>
                                        <FormattedMessage id="profile-edit_new_password2_txt">{value => <input class={state.form[1].message !=='EMPTY' ? 'has-error blank_above_s' : 'blank_above_s'} id="confirm_password" type="password" name="name" minlength="8" placeholder={value} />}</FormattedMessage>

                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr class="has-error" style={{ display: state.form[0].message !=='EMPTY' ? '' : 'none' }}>
                        <td />
                        <div class="has-error" style={{ display: state.form[0].message !=='EMPTY' ? '' : 'none' }}><FormattedMessage id={state.form[0].message} /></div>
                    </tr>
                    <tr class="has-error" style={{ display: state.form[1].message !=='EMPTY' ? '' : 'none' }}>
                        <td>
                        </td>
                        <td>
                            <div class="flex_box bottom_justified password_alignment">
                                <div class="element_width_xxl"></div>
                                <div class="element_width_xxl">
                                    <div class="label_alignment">
                                        <div class="has-error" style={{ display: state.form[1].message !=='EMPTY' ? '' : 'none' }}><FormattedMessage id={state.form[1].message} /></div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr class="flexible_height_row">
                        <td></td>
                        <td class="flexible_height">
                            <p class="precautions blank_above_s">
                                <FormattedMessage id="profile-edit_sentence5_lbl" values={{ br: <br /> }}/>
                            </p>
                        </td>
                        <td class="save_and_cancel">
                            <div class="delete_invisible_space">
                                <div class="inline_block element_width_ms"><FormattedMessage id="profile-edit_save_btn">{value => <input class="buttons_common btn_blue" type="submit" value={value} data-type="submit" onClick={handleOnClick} />}</FormattedMessage></div>
                                    <div class="inline_block element_width_ms blank_space_m"><button class="buttons_common btn_wht" data-type="cancel" onClick={handleOnClick}><FormattedMessage id="profile-edit_cancel_btn" /></button></div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            );
            

        default:
            break;
    }

    return (<tbody class="lump"></tbody>);
}
