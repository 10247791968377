import { useState }  from 'react';
import { useSelector, useDispatch } from 'react-redux';


import { setSideMenu } from '../../../store/slicer/0501_menu';
import { getVisible } from '../../../store/slicer/0102_messagebox';

import Header from '../../0003_components/0001_header';
import Sidemenu from '../../0003_components/0002_sidemenu';

import GroupEdit from '../../0003_components/2001_group_edit';
import { toast,Toaster } from 'react-hot-toast';

export default function GroupEditContacts(props) {
	const dispatch = useDispatch();


	// メッセージボックス
	const storeVisible = useSelector(getVisible);

	// 初期化
	const [state, setState] = useState(() => {
		dispatch(setSideMenu(4));
		toast.dismiss();
		return 0
	});

	return(
		<div class="wrapper">
			<Header />
			<div class="container">
				<div class="contents">
					{storeVisible && <div id="modal_overlay"></div>}
					<style jsx="true" />
					<Toaster position="top-center" reverseOrder="false" containerStyle={{ marginTop:'75px'}} />
					<Sidemenu />
					<GroupEdit />
				</div>
			</div>
		</div>
	);
}
