const LangFR = {
	'EMPTY': "",
	'signin_signin_lbl': "S'identifier",
	'signin_account_txt': "Compte",
	'signin_password_txt': "Mot de passe",
	'signin_forget_password_lnk': "Si vous oubliez votre mot de passe",
	'signin_stay_password_chk': "Rester connecté",
	'signin_privacy_policy_lnk': "Politique de Confidentialité",
	'signin_terms_of_use_lnk': "Conditions d'Utilisation",
	'signin_signin_btn': "S'identifier",
	'send-email-1_sentence1_lbl': "Avez-vous oublié votre mot de passe?",
	'send-email-1_sentence2_lbl': "Ne vous inquiétez pas. La réinitialisation de votre mot de passe est facile, indiquez-nous simplement le compte que vous avez enregistré avec EZT.",
	'send-email-1_account_txt': "Compte",
	'send-email-1_send_btn': "Envoyer",
	'send-email-2_sentence1_lbl': "Réinitialiser le mot de passe",
	'send-email-2_sentence2_lbl': "Un e-mail contenant le mot de passe de réinitialisation a été envoyé à l'adresse enregistrée sur le service EZT. Veuillez cliquer sur le lien de réinitialisation du mot de passe pour définir un nouveau mot de passe.",
	'send-email-2_sentence3_lbl': "Veuillez vérifier l'application de messagerie que vous utilisez. Si vous ne voyez pas l'e-mail, vérifiez votre dossier de courrier indésirable ",
	'send-email-2_retry_lnk': "ou réessayez.",
	'password-reset-1_sentence2_lbl': "{customer_name}, veuillez définir un nouveau mot de passe pour la connexion.",
	'password-reset-1_password_txt': "Nouveau mot de passe",
	'password-reset-1_sentence3_lbl': "Le mot de passe doit:",
	'password-reset-1_sentence4_lbl': "- Avoir au moins 8 caractères",
	'password-reset-1_sentence5_lbl': "- Avoir au moins 1 lettre (a, b, c...){br}- Avoir au moins 1 chiffre (1, 2, 3...){br}- Inclure à la fois des caractères majuscules et minuscules",
	'password-reset-1_passwordchk_txt': "Confirmation du nouveau mot de passe",
	'password-reset-1_save_btn': "Sauvegarder les modifications",
	'password-reset-1_sentence1_lbl': "Réinitialiser le mot de passe",
	'password-reset-2_sentence1_lbl': "Réinitialisation du mot de passe terminée.",
	'password-reset-2_sentence2_lbl': "{customer_name}, votre mot de passe a été modifié avec succès.",
	'password-reset-2_intoezt_btn': "Connectez-vous à EZT",
	'main_signout_btn': "Se déconnecter",
	'main_title1_lbl': "Salle",
	'main_title2_lnk': "Liste",
	'main_title3_lnk': "Enregistrement",
	'main_title4_lbl': "Information de gestion",
	'main_title5_lnk': "Emplacement",
	'main_title6_lnk': "Coordonnées",
	'main_title7_lnk': "Groupe",
	'main_title8_lnk': "Salle de réunion",
	'main_privacy_policy_lnk': "Politique de Confidentialité",
	'main_terms_of_use_lnk': "Conditions d'Utilisation",
	'list_location_pll': "Sélectionnez un emplacement",
	'list_locationuser_txt': "Recherche par nom de lieu ou nom de salle de réunion",
	'list_locationname_lbl': "Nom de la localisation",
	'list_username_lbl': "Nom de la salle de réunion",
	'list_startdate_lbl': "Date de début d'utilisation",
	'list_enddate_lbl': "Date de fin d'utilisation",
	'list_profile_btn': "Profil",
	'list_setting_btn': "Réglages",
	'profile-view_title1_lbl': "Profil",
	'profile-view_title2_lbl': "Réunion",
	'profile-view_title3_lbl': "Enregistrement",
	'profile-view_username_lbl': "Nom de la salle de réunion",
	'profile-view_locationname_lbl': "Nom de la localisation",
	'profile-view_usage_period_lbl': "Période d'utilisation",
	'profile-view_pmi_lbl': "ID de réunion personnel",
	'profile-view_pmi_password': "Mot de passe",
	'profile-view_sentence1_lbl': "Utilisez cet identifiant pour une réunion instantanée",
	'profile-view_language_lbl': "Langue",
	'profile-view_datetime_lbl': "Date et l'heure",
	'profile-view_timezone_lbl': "Fuseau horaire",
	'profile-view_calendar_lbl': "Intégration du calendrier",
	'profile-view_signin-password_lbl': "Mot de passe de connexion",
	'profile-view_edit_btn': "Éditer",
	'profile-view_calendar_btn': "Configurer le service pour le calendrier",
	'profile-edit_sentence3_lbl': "Nous prenons en charge les services suivants : Google Calendar, Microsoft Exchanger, Microsoft Office 365",
	'profile-edit_new_password1_txt': "Nouveau mot de passe",
	'profile-edit_sentence4_lbl': "Confirmation du nouveau mot de passe",
	'profile-edit_new_password2_txt': "Nouveau mot de passe",
	'profile-edit_sentence5_lbl': "Veuillez définir le mot de passe comme suit :{br}- 8 caractères ou plus.{br}- Utilisez au moins une lettre minuscule (a à z) et une lettre majuscule (A à Z) et un chiffre (0 à 9).",
	'profile-edit_setting_password_txt': "Déverrouiller le code d'accès",
	'profile-edit_host_key_txt': "Clé d'hôte",
	'profile-edit_host_key_setting_txt': "La clé d'hôte est un numéro à 6 chiffres.",
	'profile-edit_save_btn': "Sauvegarder les modifications",
	'profile-edit_cancel_btn': "Annuler",
	'settings_title1_lbl': "Réglages",
	'settings_title2_lbl': "Sécurité",
	'settings_title3_lbl': "Programmer une réunion",
	'settings_title4_lbl': "Basic",
	'settings_title5_lbl': "Avancée",
	'settings_title6_lbl': "Enregistrement",
	'settings_applies_btn': "S'applique à toutes les salles",
	'meeting_security_lbl': "Sécurité",
	'meeting_security_sentence1_lbl': "Salle d'attente",
	'meeting_security_sentence2_lbl': "Lorsqu'un participant assiste à une réunion, placez-le dans la salle d'attente et demandez à l'hôte d'autoriser le participant à entrer individuellement dans la réunion.L'activation de la salle d'attente désactive automatiquement le paramètre qui permet aux participants de se joindre avant l'hôte.",
	'meeting_security_sentence3_lbl': "Intégrer le mot de passe dans le lien d'invitation pour rejoindre en un clic",
	'meeting_security_sentence4_lbl': "Le mot de passe de la réunion sera crypté et inclus dans le lien d'invitation pour permettre aux participants de se joindre en un seul clic sans avoir à saisir le mot de passe.",
	'meeting_security_sentence5_lbl': "Code d'accès pour l'ID de réunion personnel",
	'meeting_security_passcode_txt': "Mot de passe",
	'meeting_security_edit_btn': "Éditer",
	'meeting_security_save_btn': "Sauvegarder les modifications",
	'meeting_security_cancel_btn': "Annuler",
	'meeting_schedule_lbl': "Programmer une réunion",
	'meeting_schedule_sentence1_lbl': "Héberger la vidéo",
	'meeting_schedule_sentence2_lbl': "Démarrer des réunions avec la vidéo de l'hôte activée",
	'meeting_schedule_sentence3_lbl': "Vidéo des participants",
	'meeting_schedule_sentence4_lbl': "Démarrez les réunions avec la vidéo des participants activée. Les participants peuvent modifier cela pendant la réunion.",
	'meeting_schedule_sentence9_lbl': "Activer l'ID de réunion personnel",
	'meeting_schedule_sentence10_lbl': "Un identifiant personnel de réunion (PMI) est un numéro de 9 à 11 chiffres qui est attribué à votre compte. Vous pouvez visiter la salle de réunion personnelle pour modifier vos paramètres de réunion personnels.",
	'meeting_schedule_sentence11_lbl': "Utiliser l'ID de réunion personnel (PMI) lors de la planification d'une réunion",
	'meeting_schedule_sentence12_lbl': "Vous pouvez visiter la salle de réunion personnelle pour modifier vos paramètres de réunion personnelle.",
	'meeting_schedule_sentence13_lbl': "Utiliser l'ID de réunion personnel (PMI) lors du démarrage d'une réunion instantanée",
	'meeting_basic_lbl': "Basic ",
	'meeting_basic_sentence1_lbl': "Discuter",
	'meeting_basic_sentence2_lbl': "Autoriser les participants à la réunion à envoyer un message à tous les participants.",
	'meeting_basic_sentence5_lbl': "Enregistrement automatique des discussions",
	'meeting_basic_sentence6_lbl': "Enregistrez automatiquement toutes les discussions en réunion afin que les hôtes n'aient pas besoin d'enregistrer manuellement le texte de la discussion après le début de la réunion.",
	'meeting_basic_sentence7_lbl': "Notification sonore lorsque quelqu'un rejoint ou quitte",
	'meeting_basic_sentence8_lbl': "Transfert de fichier",
	'meeting_basic_sentence9_lbl': "Les hôtes et les participants peuvent envoyer des fichiers via le chat en réunion.",
	'meeting_basic_sentence19_lbl': "Partage d'écran",
	'meeting_basic_sentence20_lbl': "Autoriser l'hôte et les participants à partager leur écran ou leur contenu pendant les réunions",
	'meeting_basic_sentence21_lbl': "Qui peut partager ?",
	'meeting_basic_sentence22_rbt': "Hôte uniquement",
	'meeting_basic_sentence23_rbt': "Tous les participants",
	'meeting_basic_sentence38_lbl': "Télécommande",
	'meeting_basic_sentence39_lbl': "Pendant le partage d'écran, la personne qui partage peut autoriser d'autres personnes à contrôler le contenu partagé",
	'meeting_basic_sentence43_lbl': "Autoriser les participants à se renommer",
	'meeting_basic_sentence44_lbl': "Autorisez les participants à la réunion et les panélistes aux webinaires à se renommer.",
	'meeting_detail_sentence1_lbl': "Avancée",
	'meeting_detail_sentence4_lbl': "Afficher un lien \"Rejoindre depuis votre navigateur\"",
	'meeting_detail_sentence5_lbl': "Autorisez les participants à contourner le processus de téléchargement de l'application Zoom et à rejoindre une réunion directement depuis leur navigateur. Il s'agit d'une solution de contournement pour les participants qui ne peuvent pas télécharger, installer ou exécuter des applications. Notez que l'expérience de réunion à partir du navigateur est limitée.",
	'meeting_detail_save_btn': "Sauvegarder les modifications",
	'meeting_detail_cancel_btn': "Annuler",
	'recording_sentence1_lbl': "Enregistrement",
	'recording_sentence2_lbl': "Enregistrement cloud ",
	'recording_sentence3_lbl': "Autoriser les hôtes à enregistrer et sauvegarder la réunion/le webinaire dans le Cloud",
	'recording_sentence4_chk': "Enregistrer un locuteur actif avec un écran partagé",
	'recording_sentence5_chk': "Enregistrer la vue de la galerie avec un écran partagé",
	'recording_sentence10_chk': "Enregistrer un fichier audio uniquement",
	'recording_sentence11_chk': "Enregistrer les messages de discussion de la réunion / du webinaire",
	'recording_sentence12_lbl': "Paramètres avancés d'enregistrement dans le cloud",
	'recording_sentence13_chk': "Ajouter un horodatage à l'enregistrement",
	'recording_sentence17_chk': "Transcription audio",
	'recording_save_btn': "Sauvegarder les modifications",
	'recording_cancel_btn': "Annuler",
	'record-initial_location_pll': "Sélectionnez l'emplacement",
	'record-initial_startdate_cal': "Date de début",
	'record-initial_enddate_cal': "Date de fin",
	'record-initial_calendar_date': " {year} Année {month} Mois",
	'record-initial_topic_txt': "Recherche par sujet",
	'record-initial_search_btn': "Rechercher",
	'record-initial_selectdelete_btn': "Supprimer sélectionnée",
	'record-delete_sentence2_lbl': "La ligne sélectionnée sera supprimée. {br} Voulez-vous continuer ?",
	'record-initial_selectall_chk': "Sujet",
	'record-initial_starttime_lbl': "Date et heure de début",
	'record-initial_filecount_lbl': "Nombre de fichiers",
	'record-initial_delete_deadline_lbl': "Supprimer la date limite",
	'record-initial_delete_daysafter_lbl': "Après {after_days} jours",
	'record-initial_delete_btn': "Supprimer",
	'record-initial_cancel_btn': "Annuler",
	'record-details_share_btn': "Partager",
	'record-details_recording': "Enregistrement",
	'record-details_sentence1_lbl': "Cet enregistrement sera supprimé automatiquement dans {days} jours.",
	'record-details_playtime_lbl': "Temps de lecture { times }",
	'record-details_filisize_lbl': " {count} Fichier ({size})",
	'record-details_download_btn': "Télécharger（ {count} Fichier）",
	'record-details_shareablelink_btn': "Copier le lien partageable",
	'record-details_videoaudio_lbl': "Vidéo + Audio",
	'record-details_audio_lbl': "Audio seulement",
	'record-details_selectdelete_btn': "Supprimer l'enregistrement",
	'record-details_delete_btn': "Supprimer",
	'record-details_cancel_btn': "Annuler",
	'record-details_clipboard_copy': "Le lien partageable a été copié dans le presse-papiers.",
	'record-share_sentence1_lbl': "Partager cet enregistrement cloud",
	'record-share_sentence2_lbl': "Partager cet enregistrement",
	'record-share_display_btn': "Montrer ",
	'record-share_display_non_btn': "Cacher",
	'record-share_sentence4_lbl': "Sujet:",
	'record-share_sentence5_lbl': "Date:",
	'record-share_sentence6_lbl': "Enregistrement de réunion :",
	'record-share_sentence7_lbl': "Code d'accès :",
	'record-share_sentence8_lbl': "Partage d'informations",
	'record-share_sentence9_lbl': "Personne n'est autorisé à accéder à cet enregistrement.",
	'record-share_sentence10_lbl': "Protéger le code d'accès",
	'record-share_save_btn': "Sauvegarder les modifications",
	'record-share_cancel_btn': "Annuler",
	'record-share_clipboard_copy': "Les informations partageables ont été copiées dans le presse-papiers.",
	'record-copysharing_btn': "Copier les informations de partage",
	'record-complete_btn': "Compléter",
	'base-initial_locationuser_txt': "Recherche par nom d'emplacement",
	'base-initial_addlocation_btn': "Ajouter un emplacement",
	'base-initial_locationname_lbl': "Nom de la localisation",
	'base-initial_roomcount_lbl': "Nombre de salles",
	'base-initial_selectdelete_btn': "Supprimer sélectionnée",
	'base-initial_edit_btn': "Éditer",
	'base-additional_sentence1_lbl': "Ajouter un emplacement",
	'base-additional_locationname_txt': "Nom de la localisation",
	'base-edit_sentence1_lbl': "Modifier l'emplacement",
	'base-edit_locationname_txt': "Nom de la localisation",
	'base-edit_save_btn': "Sauvegarder les modifications",
	'base-edit_addlocation_btn': "Ajouter",
	'base-edit_cancel_btn': "Annuler",
	'base-delete_sentence1_lbl': "Supprimer l'emplacement",
	'base-delete_sentence2_lbl': "La ligne sélectionnée sera supprimée. {br} Voulez-vous continuer ?",
	'base-delete_delete_lbl': "Supprimer",
	'base-delete_cancel_lbl': "Annuler",
	'contact-initial_emailcontactname_txt': "Recherche par adresse e-mail, nom complet",
	'contact-initial_import_bll': "Importer",
	'contact-initial_import_addition': "Ajouter",
	'contact-initial_import_washing': "Entrées d'annulation",
	'contact-initial_export_btn': "Exporter",
	'contact-initial_addcontact_btn': "Ajouter le contact",
	'contact-initial_selectdelete_btn': "Supprimer sélectionnée",
	'contact-initial_selectall_chk': "Nom",
	'contact-initial_email_lbl': "Adresse mail",
	'contact-initial_edit_btn': "Éditer",
	'contact-additional_sentence1_lbl': "Ajouter le contact",
	'contact-additional_email_txt': "Adresse mail",
	'contact-additional_contactname_txt': "Nom",
	'contact-additional_addcontact_btn': "Ajouter",
	'contact-additional_cancel_btn': "Annuler",
	'contact-edit_sentence1_lbl': "Modifier le contact",
	'contact-edit_email_txt': "Adresse mail",
	'contact-edit_contactname_txt': "Nom",
	'contact-edit_save_btn': "Sauvegarder les modifications",
	'contact-edit_cancel_btn': "Annuler",
	'contact-delete_sentence1_lbl': "Effacer le contact",
	'contact-delete_sentence2_lbl': "La ligne sélectionnée sera supprimée. {br} Voulez-vous continuer ?",
	'contact-delete_delete_btn': "Supprimer ",
	'contact-delete_cancel_btn': "Annuler",
	'contact-csv_name': "Nom",
	'contact-csv_sentence': "Contacts",
	'contact-csv_washing_title': "Message",
	'contact-csv_washing_message': "Le remplacement des contacts supprimera tous les contacts existants avant d'importer un nouveau fichier de contacts. Tous les contacts enregistrés dans le groupe {br} seront également supprimés.{br} Voulez-vous continuer ?",
	'contact-import_ok_btn': "OK",
	'contact-import_cancel_btn': "Annuler",
	'group-initial_groupname_txt': "Recherche par nom de groupe",
	'group-initial_addgroup_btn': "Ajouter un groupe",
	'group-initial_selectdelete_btn': "Supprimer sélectionnée",
	'group-delete_sentence2_lbl': "La ligne sélectionnée sera supprimée. {br} Voulez-vous continuer ?",
	'group-initial_selectall_chk': "Nom de groupe",
	'group-initial_membercount_lbl': "Nombre de membres",
	'group-initial_edit_btn': "Éditer",
	'group-initial_delete_btn': "Supprimer",
	'group-initial_cancel_btn': "Annuler",
	'group-additional_sentence1_lbl': "Ajouter un groupe",
	'group-additional_save_btn': "Sauvegarder les modifications",
	'group-additional_cancel_btn': "Annuler",
	'group-additional_delete_btn': "Supprimer",
	'group-edit_sentence1_lbl': "Modifier le groupe",
	'group-edit_delete_btn': "Supprimer",
	'group-edit_emailcontactname_txt': "Recherche par adresse e-mail, nom complet",
	'group-delete_sentence1_lbl': "Supprimer le groupe",
	'expiration-date_check': "Réinitialiser le mot de passe",
	'expiration-date_message': "La date d'expiration est dépassée.",
	'tab_title_signin': "S'identifier",
	'tab_title_mail_send': "Envoyer un e-mail",
	'tab_title_send_complete': "Bien envoyé",
	'tab_title_password_reset': "Réinitialisation du mot de passe",
	'tab_title_reset_complete': "Réinitialisation du mot de passe réussie",
	'tab_title_meeting_list': "Liste des salles de réunion",
	'tab_title_profile': "Profil",
	'tab_title_meeting_setting': "Réglages de réunion",
	'tab_title_record_setting': "Réglages d'enregistrement",
	'tab_title_record_list': "Liste des enregistrements",
	'tab_title_record_details': "Détails de l'enregistrement",
	'tab_title_location_list': "Liste des emplacements",
	'tab_title_contact_list': "Liste des contacts",
	'tab_title_group_list': "Liste des groupes",
	'tab_title_group_edit': "Enregistrement du Groupe",
	'tab_title_meeting_room': "Salle de réunion",
	'meeting-room_url_txt': "URL de la salle de réunion",
	'meeting-room_copyUrl_btn': "Copiez l'URL",
	'meeting-room_edit_btn': "Éditer",
	'meeting-room_save_btn': "Sauvegarder les modifications",
	'meeting-room_cancel_btn': "Annuler",
	'meeting-room_password_txt': "Mot de passe à afficher",
	'meeting-list-check_title_txt': "Salle de réunion",
	'meeting-list-check_placeholder': "Mot de passe",
	'meeting-list-check_password_save_txt': "Ne demandez plus le mot de passe.",
	'meeting-list-check_show_btn': "Montrer ",
	'meeting-list_title_txt': "Salle de réunion",
	'meeting-list_locationname_txt': "Nom de la localisation",
	'meeting-list_meetingRoom_txt': "Nom de la salle de réunion",
	'meeting-list_meetingID_txt': "ID de réunion",
	'meeting-list_passcode_txt': "Code secret",
	'meeting-list_goto_meeting_btn': "Rejoignez la réunion",
	'meeting-list_email_copy_btn': "Copiez l'invitation",
	'meeting-list_disabled_pmi': "PMI n'est pas utilisé.",
	'meeting-list_location_pll': "Sélectionnez un emplacement",
	'meeting-list_locationuser_txt': "Recherche par nom de lieu ou nom de salle de réunion",
	'meeting-list_email_txt': "Rejoignez la réunion depuis EZT ou Zoom.",
	'meeting-list_email_id': "ID de réunion:",
	'meeting-list_email_pwd': "Code secret:",
	'ERROR_0001': "Erreur système",
	'ERROR_0002': "Compte incorrect",
	'ERROR_0003': "Compte incorrect. Veuillez saisir à nouveau vos informations de connexion ou demander un e-mail contenant les informations pour accéder à votre compte.",
	'ERROR_0004': "Veuillez saisir les informations de votre compte.",
	'ERROR_0005': "Le nom du groupe doit être saisi.",
	'ERROR_0006': "Le compte est introuvable.",
	'ERROR_0008': "Ce nom de salle de réunion est déjà utilisé.",
	'ERROR_0009': "Ce nom d'emplacement est déjà enregistré. Veuillez spécifier un autre nom d'emplacement.",
	'ERROR_0010': "Ce nom de groupe est déjà utilisé.",
	'ERROR_0012': "Cet ID de réunion personnel est déjà utilisé.",
	'ERROR_0013': "Cette adresse email est déjà utilisée.",
	'ERROR_0014': "Échec de la suppression des données.",
	'ERROR_0015': "Échec de l'enregistrement des données.",
	'ERROR_0016': "Échec de la mise à jour des données. ",
	'ERROR_0017': "L'ID de réunion personnel doit être saisi et être un numéro à 10 chiffres.",
	'ERROR_0019': "Votre nouveau mot de passe ne répond pas aux exigences de la politique de mot de passe.",
	'ERROR_0020': "S'il vous plaît entrer le mot de passe.",
	'ERROR_0021': "La clé hôte doit être saisie et être un numéro à 6 chiffres. Il ne peut pas être dans un format simple comme l'exemple suivant : 111111, 222222, 123456",
	'ERROR_0022': "L'adresse e-mail doit être saisie.",
	'ERROR_0023': "Le nom de la salle de réunion doit être saisi.",
	'ERROR_0024': "Le mot de passe de confirmation ne correspond pas.",
	'ERROR_0025': "Le nom de l'emplacement doit être saisi.",
	'ERROR_0026': "Le nom complet doit être saisi.",
	'ERROR_0027': "Un nouveau mot de passe doit être saisi.",
	'ERROR_0028': "Le nouveau mot de passe doit être différent de l'ancien mot de passe.",
	'ERROR_0029': "Le code de déverrouillage doit être saisi.",
	'ERROR_0030': "Sélectionnez le fichier csv.",
	'ERROR_0031': "Échec de l'exportation des données.",
	'ERROR_0032': "Échec de l'importation des données.",
	'ERROR_0033': "Il n'y a pas de données à exporter.",
	'ERROR_0034': "Ce fichier ne peut pas être lu.",
	'ERROR_0036': "{success_cnt} des éléments sélectionnés ont été supprimés mais {error_cnt} des éléments sélectionnés n'ont pas pu être supprimés.",
	'ERROR_0037': "Veuillez saisir la bonne adresse e-mail.",
	'ERROR_0038': "Si l'enregistrement dans le cloud est activé, vous devez sélectionner un ou plusieurs fichiers à enregistrer dans le cloud.",
	'ERROR_0039': "Un accès non autorisé a été détecté.",
	'ERROR_0040': "Vous ne pouvez pas changer l'emplacement car le nombre de salles devient 0.",
	'ERROR_0041': "Le mot de passe est incorrect. Entrez le mot de passe correct.",
	'SUCCESS_0001': "Les paramètres ont été mis à jour.",
	'SUCCESS_0002': "Les informations utilisateur ont été enregistrées avec succès.",
	'SUCCESS_0003': "Données supprimées avec succès.",
	'SUCCESS_0004': "Données enregistrées avec succès.",
	'SUCCESS_0005': "Données mises à jour avec succès.",
	'SUCCESS_0006': "Données exportées avec succès.",
	'SUCCESS_0007': "Données importées avec succès.",
	'SUCCESS_0008': "URL copié.",
	'SUCCESS_0009': "E-mail d'invitation copié.",
	'TIMEZONE_0001': "(GMT-11:00) Îles Midway, Samoa",
	'TIMEZONE_0002': "(GMT-11:00) Pago Pago",
	'TIMEZONE_0003': "(GMT-10:00) Hawaii",
	'TIMEZONE_0004': "(GMT-8:00) Alaska",
	'TIMEZONE_0005': "(GMT-8:00) Juneau",
	'TIMEZONE_0006': "(GMT-7:00) Vancouver",
	'TIMEZONE_0007': "(GMT-7:00) Heure normale du Pacifique (États-Unis et Canada)",
	'TIMEZONE_0008': "(GMT-7:00) Tijuana",
	'TIMEZONE_0009': "(GMT-7:00) Arizona",
	'TIMEZONE_0010': "(GMT-6:00) Edmonton",
	'TIMEZONE_0011': "(GMT-6:00) Heure normale des Rocheuses (États-Unis et Canada)",
	'TIMEZONE_0012': "(GMT-6:00) Mazatlán",
	'TIMEZONE_0013': "(GMT-6:00) Saskatchewan",
	'TIMEZONE_0014': "(GMT-6:00) Guatemala",
	'TIMEZONE_0015': "(GMT-6:00) El Salvador",
	'TIMEZONE_0016': "(GMT-6:00) Managua",
	'TIMEZONE_0017': "(GMT-6:00) Costa Rica",
	'TIMEZONE_0018': "(GMT-6:00) Tegucigalpa",
	'TIMEZONE_0019': "(GMT-6:00) Chihuahua",
	'TIMEZONE_0020': "(GMT-5:00) Winnipeg",
	'TIMEZONE_0021': "(GMT-5:00) Heure normale du Centre (États-Unis et Canada)",
	'TIMEZONE_0022': "(GMT-5:00) Mexico",
	'TIMEZONE_0023': "(GMT-5:00) Panama",
	'TIMEZONE_0024': "(GMT-5:00) Bogota",
	'TIMEZONE_0025': "(GMT-5:00) Lima",
	'TIMEZONE_0026': "(GMT-5:00) Monterrey",
	'TIMEZONE_0027': "(GMT-4:00) Montréal",
	'TIMEZONE_0028': "(GMT-4:00) Heure normale de l’Est (États-Unis et Canada)",
	'TIMEZONE_0029': "(GMT-4:00) Indiana (Est)",
	'TIMEZONE_0030': "(GMT-4:00) Puerto Rico",
	'TIMEZONE_0031': "(GMT-4:00) Caracas",
	'TIMEZONE_0032': "(GMT-4:00) Santiago",
	'TIMEZONE_0033': "(GMT-4:00) La Paz",
	'TIMEZONE_0034': "(GMT-4:00) Guyana",
	'TIMEZONE_0035': "(GMT-3:00) Halifax",
	'TIMEZONE_0036': "(GMT-3:00) Montevideo",
	'TIMEZONE_0037': "(GMT-3:00) Récife",
	'TIMEZONE_0038': "(GMT-3:00) Buenos Aires, Georgetown",
	'TIMEZONE_0039': "(GMT-3:00) São Paulo",
	'TIMEZONE_0040': "(GMT-3:00) Heure de l’Atlantique (Canada)",
	'TIMEZONE_0041': "(GMT-2:30) Terre-Neuve-et-Labrador",
	'TIMEZONE_0042': "(GMT-2:00) Groenland",
	'TIMEZONE_0043': "(GMT-1:00) Îles du Cap-Vert",
	'TIMEZONE_0044': "(GMT+0:00) Açores",
	'TIMEZONE_0045': "(GMT+0:00) Temps universel UTC",
	'TIMEZONE_0046': "(GMT+0:00) Temps universel coordonné",
	'TIMEZONE_0047': "(GMT+0:00) Reykjavik",
	'TIMEZONE_0048': "(GMT+0:00) Nouakchott",
	'TIMEZONE_0049': "(GMT+1:00) Casablanca",
	'TIMEZONE_0050': "(GMT+1:00) Dublin",
	'TIMEZONE_0051': "(GMT+1:00) Londres",
	'TIMEZONE_0052': "(GMT+1:00) Lisbonne",
	'TIMEZONE_0053': "(GMT+1:00) Afrique centrale de l’Ouest",
	'TIMEZONE_0054': "(GMT+1:00) Alger",
	'TIMEZONE_0055': "(GMT+1:00) Tunis",
	'TIMEZONE_0056': "(GMT+2:00) Belgrade, Bratislava, Ljubljana",
	'TIMEZONE_0057': "(GMT+2:00) Sarajevo, Skopje, Zagreb",
	'TIMEZONE_0058': "(GMT+2:00) Oslo",
	'TIMEZONE_0059': "(GMT+2:00) Copenhague",
	'TIMEZONE_0060': "(GMT+2:00) Bruxelles",
	'TIMEZONE_0061': "(GMT+2:00) Amsterdam, Berlin, Rome, Stockholm, Vienne",
	'TIMEZONE_0062': "(GMT+2:00) Amsterdam",
	'TIMEZONE_0063': "(GMT+2:00) Rome",
	'TIMEZONE_0064': "(GMT+2:00) Stockholm",
	'TIMEZONE_0065': "(GMT+2:00) Vienne",
	'TIMEZONE_0066': "(GMT+2:00) Luxembourg",
	'TIMEZONE_0067': "(GMT+2:00) Paris",
	'TIMEZONE_0068': "(GMT+2:00) Zurich",
	'TIMEZONE_0069': "(GMT+2:00) Madrid",
	'TIMEZONE_0070': "(GMT+2:00) Harare, Prétoria",
	'TIMEZONE_0071': "(GMT+2:00) Varsovie",
	'TIMEZONE_0072': "(GMT+2:00) Prague Bratislava",
	'TIMEZONE_0073': "(GMT+2:00) Budapest",
	'TIMEZONE_0074': "(GMT+2:00) Tripoli",
	'TIMEZONE_0075': "(GMT+2:00) Le Caire",
	'TIMEZONE_0076': "(GMT+2:00) Johannesburg",
	'TIMEZONE_0077': "(GMT+2:00) Khartoum",
	'TIMEZONE_0078': "(GMT+3:00) Helsinki",
	'TIMEZONE_0079': "(GMT+3:00) Nairobi",
	'TIMEZONE_0080': "(GMT+3:00) Sofia",
	'TIMEZONE_0081': "(GMT+3:00) Istanbul",
	'TIMEZONE_0082': "(GMT+3:00) Athènes",
	'TIMEZONE_0083': "(GMT+3:00) Bucarest",
	'TIMEZONE_0084': "(GMT+3:00) Nicosie",
	'TIMEZONE_0085': "(GMT+3:00) Beyrouth",
	'TIMEZONE_0086': "(GMT+3:00) Damas",
	'TIMEZONE_0087': "(GMT+3:00) Jérusalem",
	'TIMEZONE_0088': "(GMT+3:00) Amman",
	'TIMEZONE_0089': "(GMT+3:00) Moscou",
	'TIMEZONE_0090': "(GMT+3:00) Bagdad",
	'TIMEZONE_0091': "(GMT+3:00) Koweït",
	'TIMEZONE_0092': "(GMT+3:00) Riyad",
	'TIMEZONE_0093': "(GMT+3:00) Bahreïn",
	'TIMEZONE_0094': "(GMT+3:00) Qatar",
	'TIMEZONE_0095': "(GMT+3:00) Aden",
	'TIMEZONE_0096': "(GMT+3:00) Djibouti",
	'TIMEZONE_0097': "(GMT+3:00) Mogadiscio",
	'TIMEZONE_0098': "(GMT+3:00) Kiev",
	'TIMEZONE_0099': "(GMT+3:00) Minsk",
	'TIMEZONE_0100': "(GMT+4:00) Dubaï",
	'TIMEZONE_0101': "(GMT+4:00) Muscat",
	'TIMEZONE_0102': "(GMT+4:00) Bakou, Tbilissi, Erevan",
	'TIMEZONE_0103': "(GMT+4:30) Téhéran",
	'TIMEZONE_0104': "(GMT+4:30) Kaboul",
	'TIMEZONE_0105': "(GMT+5:00) Yekaterinburg",
	'TIMEZONE_0106': "(GMT+5:00) Islamabad, Karachi, Tachkent",
	'TIMEZONE_0107': "(GMT+5:30) Inde",
	'TIMEZONE_0108': "(GMT+5:30) Mumbai, Kolkata, New Delhi",
	'TIMEZONE_0109': "(GMT+5:30) Asia/Colombo",
	'TIMEZONE_0110': "(GMT+5:45) Katmandou",
	'TIMEZONE_0111': "(GMT+6:00) Almaty",
	'TIMEZONE_0112': "(GMT+6:00) Dacca",
	'TIMEZONE_0113': "(GMT+6:00) Astana, Dhaka",
	'TIMEZONE_0114': "(GMT+6:30) Rangoun",
	'TIMEZONE_0115': "(GMT+7:00) Novosibirsk",
	'TIMEZONE_0116': "(GMT+7:00) Krasnoyarsk",
	'TIMEZONE_0117': "(GMT+7:00) Bangkok",
	'TIMEZONE_0118': "(GMT+7:00) Vietnam",
	'TIMEZONE_0119': "(GMT+7:00) Jakarta",
	'TIMEZONE_0120': "(GMT+8:00) Irkoutsk, Oulan-Bator",
	'TIMEZONE_0121': "(GMT+8:00) Beijing, Shanghai",
	'TIMEZONE_0122': "(GMT+8:00) RAS de Hong Kong",
	'TIMEZONE_0123': "(GMT+8:00) Taipei",
	'TIMEZONE_0124': "(GMT+8:00) Kuala Lumpur",
	'TIMEZONE_0125': "(GMT+8:00) Singapour",
	'TIMEZONE_0126': "(GMT+8:00) Perth",
	'TIMEZONE_0127': "(GMT+9:00) Yakutsk",
	'TIMEZONE_0128': "(GMT+9:00) Séoul",
	'TIMEZONE_0129': "(GMT+9:00) Osaka, Sapporo, Tokyo",
	'TIMEZONE_0130': "(GMT+9:30) Darwin",
	'TIMEZONE_0131': "(GMT+9:30) Adélaïde",
	'TIMEZONE_0132': "(GMT+10:00) Vladivostok",
	'TIMEZONE_0133': "(GMT+10:00) Guam, Port Moresby",
	'TIMEZONE_0134': "(GMT+10:00) Brisbane",
	'TIMEZONE_0135': "(GMT+10:00) Canberra, Melbourne, Sydney",
	'TIMEZONE_0136': "(GMT+10:00) Hobart",
	'TIMEZONE_0137': "(GMT+11:00) Magadan",
	'TIMEZONE_0138': "(GMT+11:00) Îles Salomon",
	'TIMEZONE_0139': "(GMT+11:00) Nouvelle-Calédonie",
	'TIMEZONE_0140': "(GMT+12:00) Kamchatka",
	'TIMEZONE_0141': "(GMT+12:00) Îles Fidji, Îles Marshall",
	'TIMEZONE_0142': "(GMT+12:00) Auckland, Wellington",
	'TIMEZONE_0143': "(GMT+13:00) Independent State of Samoa",
};
export default LangFR;
