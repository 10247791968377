import { FormattedMessage, useIntl } from "react-intl";
import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserProfile, getUserSettingUpdate, setUserSettingUpdate } from '../../store/slicer/0503_room';
import * as CONST from '../../const';
import { getAccessToken, getRefreshToken, setAccessToken, logout } from '../../store/slicer/0101_session';
import toast from 'react-hot-toast';
import { SetErrorMessage } from '../../store/slicer/0101_logout_message';



export default function UserMeetingBody(props) {
    const dispatch = useDispatch();
    //ミーティング
    const storeUserProfile = useSelector(getUserProfile);
    const storeSetting = useSelector(getUserSettingUpdate);
    const intl = useIntl();

    var access_token = useSelector(getAccessToken);
    var refresh_token = useSelector(getRefreshToken);


    // 初期化
    const [state, setState] = useState(() => {
        let temp = {};
        temp.edit = false;
        temp.pmi_password = storeSetting.schedule_meeting.pmi_password
        temp.new_password = "";
        temp.form = [
            { message: 'EMPTY' },
        ];
        temp.focus_initial_escape = false;

        return temp;
    });    


    const onClickBtn = useCallback(event => {
        event.preventDefault(false);
        const type = event.target.getAttribute('data-type');
        const editing = state.edit ? false : true;
        let view = document.getElementById("passcode_for_PMI")
        let error = [...state.form];

        switch(type){
            case "edit":
                setState({ ...state, edit: editing,new_password: state.pmi_password})
                break;

            case "cancel":
                error[0]['message'] = 'EMPTY';
                setState({ ...state, edit: editing})
                break;

            case "submit":
                var pwd = view.value;

                //入力チェック
                if ( pwd.length === 0 ) {
                    error[0]['message'] = 'ERROR_0020';
                    view.focus();
                    setState({ ...state, form: error, });
                    return;
                }

                /*if (pwd.length === 0) {
                    error[0]['message'] = 'ERROR_0041';
                    view.focus();
                    setState({ ...state, form: error, });
                    return;
                }*/

                //更新データ
                const fetchHttpRefresh = async () => {
                    let senddata = {
                    };
                    let url_tokref = CONST.BASEURL + '/authorize/refresh';
                    var httpResponse_tokref = await fetch(url_tokref, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + refresh_token
                        },
                        body: JSON.stringify(senddata),
                    });
                    switch (httpResponse_tokref.status) {
                        case 200:
                            var httpResponse_tokref_json = await httpResponse_tokref.json();
                            access_token = httpResponse_tokref_json.access_token;
                            refresh_token = httpResponse_tokref_json.refresh_token;
                            // トークンをデコードする
                            //const decode_token = jwt(access_token);
                            // 問題ない場合は、storeに保存
                            dispatch(setAccessToken(access_token));
                            //dispatch(setRefreshToken(refresh_token));
                            fetchHttp();
                            return;                              
                        default:
                            dispatch(SetErrorMessage('ERROR_0001'));
                            dispatch(logout());
                            return;
                    }
                }
                
                //fetchHttpRefresh();
                // ユーザー設定更新
                const senddata = { ...storeSetting, schedule_meeting: { ...storeSetting.schedule_meeting, pmi_password: pwd } };
                //const zoom_senddata = { 'pmi_password': { pmi_password: pwd } };

                //更新処理
                const fetchHttp = async () => {
                    fetch(CONST.BASEURL + '/users/' + storeUserProfile.user_id + '/settings', {
                        method: 'PATCH',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: "Bearer " + access_token
                        },
                        body: JSON.stringify(senddata),
                    })
                    .then(response => {
                        switch (response.status) {
                            case 204:
                                //成功通知
                                error[0]['message'] = 'EMPTY';
                                //正常終了
                                dispatch(setUserSettingUpdate(senddata));
                                setState({...state, edit: false, pmi_password: pwd});

                                toast(intl.formatMessage({ id: 'SUCCESS_0001' }),{style:{background:  CONST.HOT_TOAST_SUCCESS}});
                                return;

                            case 401:
                                fetchHttpRefresh();
                                return

                            case 409:
                                dispatch(SetErrorMessage('ERROR_0039'));  
                                dispatch(logout());
                                return; 

                            default:
                                response.json().then(data => ({
                                    data: data,
                                    status: response.status
                                })
                                ).then(res => {
                                    //Error出力
                                    toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                                }).catch((err) => {
                                    //Error出力
                                    toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                                });
                        }
                    })
                    .catch((err) => {
                        //Error出力
                        toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});
                        setState({...state, edit: false});
                    });
                }
                fetchHttp();
                break;
            }	
    });


    // 表示用パスワード変更テキストボックスのイベント
	const handleInputChange = event => {
        let error = [...state.form];
        let str=event.target.value
        error[0]['message'] = 'EMPTY';
        //スペース、全角文字入力制限
        for(var i=0;i<str.length;i++){
            let code = str.charCodeAt(i)
            if(code >127 || code == 32) {
                return
            }
        } 
        setState({ ...state, new_password: str });
	};


    switch(state.edit){
        case false:
            return(
                <tbody class="lump">
                    <tr>
                        <td>
                            <div class="flex_box">
                                <p class="save_itme_name"><FormattedMessage id="meeting_security_sentence5_lbl" /></p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="flex_box left_justified">
                                <span class="blank_space_m"><FormattedMessage id="meeting_security_passcode_txt" /></span>
                                <span class="blank_space_m" style={{paddingTop: '5px'}}>**********</span>
                                <div class="blank_space_ml" style={{zIndex: 5}}>
                                    <a id="editBtn" href="javascript:void(0);" class="buttons_common btn_wht" data-type="edit" onClick={onClickBtn} >
                                        <FormattedMessage id="meeting_security_edit_btn" />
                                    </a>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            );
        case true:
            return(
                <tbody class="lump">
                    <tr>
                        <td>
                            <div class="flex_box">
                                <p class="save_itme_name"><FormattedMessage id="meeting_security_sentence5_lbl" /></p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="flex_box left_justified">
                                <span class="blank_right_m blank_space_m" style={{minWidth: '75px'}}><FormattedMessage id="meeting_security_passcode_txt" /></span>
                                <div class="element_width_m" style={{zIndex: 5}}>
                                    <input id="passcode_for_PMI" class={state.form[0].message !=='EMPTY' ? 'has-error' : ''} type="text" onChange={handleInputChange} value={state.new_password} maxLength={10} ></input>
                                </div>
                                <div class="save_and_cancel delete_invisible_space" style={{zIndex: 5, minWidth: '170px'}}>
                                    <div class="inline_block element_width_s blank_space_m"><FormattedMessage id="meeting_security_save_btn">{value => <input class="buttons_common btn_blue" type="submit" value={value} data-type="submit" onClick={onClickBtn} />}</FormattedMessage></div>
                                    <div class="inline_block element_width_s blank_space_m"><button class="buttons_common btn_wht" data-type="cancel" onClick={onClickBtn}><FormattedMessage id="meeting_security_cancel_btn" /></button></div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="has-error blank_space_m" style={{ display: state.form[0].message !=='EMPTY' ? '' : 'none' }}><FormattedMessage id={state.form[0].message} /></div>
                        </td>
                    </tr>
                </tbody>
            );
    }
    
}