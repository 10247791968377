import { createSlice } from '@reduxjs/toolkit';


// Redux Toolkitを使用すると、reducersで「変更」ロジックを記述できます。 
// 「draft state」への変更を検出しそれらの変更に基づいて
// まったく新しい不変の状態を生成するImmerライブラリを使用するため
// 実際には状態を変更しません。
export const menuSlice = createSlice({
	name: 'menu',
	initialState: {
		side: 0,
		profile: {
			top: 0,
			side: 0,
		}
	},
	reducers: {
		clearMenu: (state) => {
			state.side = 0;
			state.profile.top = 0;
			state.profile.side = 0;
		},
		setSideMenu: (state, action) => {
			state.side = Number(action.payload);
		},
		setProfileMenu: (state, action) => {
			state.profile.top = Number(action.payload.top);
			state.profile.side = Number(action.payload.side);
		},
	},
});

export const { clearMenu, setSideMenu, setProfileMenu } = menuSlice.actions;


// サンク：非同期ロジックの実行
// dispatch(incrementByAmount(amount))
// 最初の引数として dispatch関数を使用してサンクが呼び出されます。
// その後、非同期コードを実行し、他のアクションをディスパッチできます
/*
export const incrementAsync = amount => dispatch => {
  setTimeout(() => {
    dispatch(incrementByAmount(amount));
  }, 1000);
};
*/

// セレクター：state から値を取得する
// useSelector((state) => state.counter.value) のようにインラインで定義もできる

export const getSideMenu = state => state.menu.side;
export const getProfileMenu = state => state.menu.profile;


// レデューサーをエクスポートする
export default menuSlice.reducer;

