import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { checkLogin } from "../../store/slicer/0101_session";


export default function PrivateRoute(props) {
	const loggedin = useSelector(checkLogin);
	return loggedin ? <Route {...props} />: <Redirect to="/login" />;
}

