import { createSlice } from '@reduxjs/toolkit';


// Redux Toolkitを使用すると、reducersで「変更」ロジックを記述できます。 
// 「draft state」への変更を検出しそれらの変更に基づいて
// まったく新しい不変の状態を生成するImmerライブラリを使用するため
// 実際には状態を変更しません。
export const meetingRoomSessionSlice = createSlice({
	name: 'meetingRoomSession',
	initialState: {
		loggedInMeetingRoom: false, 
		access_token: '',
		refresh_token: '',
		signinForEver: false,
		user: {
			id: '',
			name: '',
			role: '',
			account: '',
			email: ''
		}
	},
	reducers: {
		loginMeetingRoom: (state, action) => {
			state.loggedInMeetingRoom = true;
			state.access_token = action.payload.access_token;
			state.refresh_token = action.payload.refresh_token;
			state.signinForEver = action.payload.signinForEver;
			state.user.id = action.payload.id;
			state.user.name = action.payload.name;
			state.user.role = action.payload.role;
			state.user.account = action.payload.account;
			state.user.email = action.payload.email;
		},
		logoutMeetingRoom: state => {
			state.loggedInMeetingRoom = false;
			state.access_token = '';
			state.refresh_token = '';
			state.user.id = '';
			state.user.name = '';
			state.user.role = '';
			state.user.account = '';
			state.user.email = '';
		},
	
		setAccessTokenForMeetingRoom: (state, action) => {
			state.access_token = action.payload;
        },

		setRefreshTokenForMeetignRoom: (state, action) => {
			state.refresh_token = action.payload;
		},

	},
});

export const { 
    loginMeetingRoom, 
    logoutMeetingRoom, 
    setAccessTokenForMeetingRoom, 
    setRefreshTokenForMeetignRoom 
} = meetingRoomSessionSlice.actions;


// サンク：非同期ロジックの実行
// dispatch(incrementByAmount(amount))
// 最初の引数として dispatch関数を使用してサンクが呼び出されます。
// その後、非同期コードを実行し、他のアクションをディスパッチできます
/*
export const incrementAsync = amount => dispatch => {
  setTimeout(() => {
	dispatch(incrementByAmount(amount));
  }, 1000);
};
*/

// セレクター：state から値を取得する
// useSelector((state) => state.counter.value) のようにインラインで定義もできる

//会議室情報ページ認証用
export const getUserDataOfMeetingRoom = state => state.meetingRoomSession.user;
export const checkLoginOfMeetingRoom = state => state.meetingRoomSession.loggedInMeetingRoom;
export const getAccessTokenForMeetingRoom = state => state.meetingRoomSession.access_token;
export const getRefreshTokenForMeetingRoom = state => state.meetingRoomSession.refresh_token;
export const getSignCheckForMeetingRoom = state => state.meetingRoomSession.signinForEver;


// レデューサーをエクスポートする
export default meetingRoomSessionSlice.reducer;


