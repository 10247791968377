import { createSlice } from '@reduxjs/toolkit';


// Redux Toolkitを使用すると、reducersで「変更」ロジックを記述できます。 
// 「draft state」への変更を検出しそれらの変更に基づいて
// まったく新しい不変の状態を生成するImmerライブラリを使用するため
// 実際には状態を変更しません。
export const sessionSlice = createSlice({
	name: 'session',
	initialState: {
		loggedIn: false, 
		access_token: '',
		refresh_token: '',
		signinForEver: false,
		user: {
			id: '',
			name: '',
			role: '',
			account: '',
			email: ''
		}
	},
	reducers: {
		login: (state, action) => {
			state.loggedIn = true;
			state.access_token = action.payload.access_token;
			state.refresh_token = action.payload.refresh_token;
			state.signinForEver = action.payload.signinForEver;
			state.user.id = action.payload.id;
			state.user.name = action.payload.name;
			state.user.role = action.payload.role;
			state.user.account = action.payload.account;
			state.user.email = action.payload.email;
		},
		logout: state => {
			state.loggedIn = false;
			state.access_token = '';
			state.refresh_token = '';
			state.user.id = '';
			state.user.name = '';
			state.user.role = '';
			state.user.account = '';
			state.user.email = '';
		},
		setAccessToken: (state, action) => {
			state.access_token = action.payload;
        },
		setRefreshToken: (state, action) => {
			state.refresh_token = action.payload;
		},

	},
});

export const { login, logout, setAccessToken, setRefreshToken } = sessionSlice.actions;


// サンク：非同期ロジックの実行
// dispatch(incrementByAmount(amount))
// 最初の引数として dispatch関数を使用してサンクが呼び出されます。
// その後、非同期コードを実行し、他のアクションをディスパッチできます
/*
export const incrementAsync = amount => dispatch => {
  setTimeout(() => {
	dispatch(incrementByAmount(amount));
  }, 1000);
};
*/

// セレクター：state から値を取得する
// useSelector((state) => state.counter.value) のようにインラインで定義もできる

export const checkLogin = state => state.session.loggedIn;
export const getUserData = state => state.session.user;
export const getAccessToken = state => state.session.access_token;
export const getRefreshToken = state => state.session.refresh_token;
export const getSignCheck = state => state.session.signinForEver;


// レデューサーをエクスポートする
export default sessionSlice.reducer;

