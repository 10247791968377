import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as CONST from '../../const';
import { getProfile, getUserProfile } from '../../store/slicer/0503_room';
import { getAccessToken, getRefreshToken, setAccessToken, logout } from '../../store/slicer/0101_session';
import toast from 'react-hot-toast';
import { FormattedMessage, useIntl } from "react-intl";
import { SetErrorMessage } from '../../store/slicer/0101_logout_message';

export default function UserMeetingHeader(props) {
    // プロフィール
    const storeProfile = useSelector(getProfile);
    const storeUserProfile = useSelector(getUserProfile);
    const dispatch = useDispatch();

    const intl = useIntl();

    var access_token = useSelector(getAccessToken);
    var refresh_token = useSelector(getRefreshToken);

    // onClickイベントハンドラ
    const handleOnClick = useCallback(event => {
        const type = event.target.getAttribute('data-type');
        switch (type) {

            case 'all-submit':

                //更新データ
                const senddata = {
                    "user_id": storeProfile.user_id
                };

                const fetchHttpRefresh = async () => {
                    let senddata = {};
                    let url_tokref = CONST.BASEURL + '/authorize/refresh';
                    var httpResponse_tokref = await fetch( url_tokref, {
                        method:'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + refresh_token
                        },
                        body: JSON.stringify(senddata),
                    });
                    switch (httpResponse_tokref.status) {
                        case 200:
                            var httpResponse_tokref_json = await httpResponse_tokref.json();
                            access_token = httpResponse_tokref_json.access_token;
                            refresh_token = httpResponse_tokref_json.refresh_token;
                            // トークンをデコードする
                            //const decode_token = jwt(access_token);
                            // 問題ない場合は、storeに保存
                            dispatch(setAccessToken(access_token));
                            //dispatch(setRefreshToken(refresh_token));
                            fetchHttp();
                            return;                               
                        default:
                            dispatch(SetErrorMessage('ERROR_0001'));
                            dispatch(logout());
                            return;
                    }
                }
                //fetchHttpRefresh();
                //更新処理
                const fetchHttp = async () => {
	                fetch(CONST.BASEURL + '/customers/' + storeUserProfile.customer_id + '/users/settings', {
	                    method: 'PATCH',
	                    headers: {
	                        'Content-Type': 'application/json',
	                        Authorization: "Bearer " + access_token
	                    },
	                    body: JSON.stringify(senddata),
	                })
                    .then(response => {
                        switch (response.status) {
                            case 204:
                                //成功通知
                                toast(intl.formatMessage({ id: 'SUCCESS_0001' }),{style:{background:  CONST.HOT_TOAST_SUCCESS}});

                                break;
                            case 401:
                                fetchHttpRefresh();
                                return;
                            case 409:
                                dispatch(SetErrorMessage('ERROR_0039'));  
                                dispatch(logout());
                                return;
                                
                            default:
                                response.json().then(data => ({
                                    data: data,
                                    status: response.status
                                })
                                ).then(res => {
                                    //Error出力
                                    toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                                }).catch((err) => {
                                    //Error出力
                                    toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                                });
                        }
                    })
                    .catch((err) => {
                        //Error出力
                        toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                    });
                }
                fetchHttp();
                break;
        }

    }, []);


    // 描画
    return (
        <div class="flex_box meeting_form">
            <h2 class="title">{storeProfile.user_name}</h2>
            <div class="flex_box right_justified">
                <div class="element_width_m blank_space_ml">
                	<FormattedMessage id="settings_applies_btn">{value => <input type="button" value={value} class="buttons_common btn_wht" onClick={handleOnClick} data-type="all-submit" />}</FormattedMessage>
                </div>
            </div>
        </div>
    );
}
