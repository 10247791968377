import * as CONST from '../../const';
import { FormattedMessage, useIntl } from 'react-intl';
import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserDataOfMeetingRoom, getAccessTokenForMeetingRoom, getRefreshTokenForMeetingRoom, setAccessTokenForMeetingRoom, logoutMeetingRoom } from '../../store/slicer/2201_meetingroom_session';
import toast from 'react-hot-toast';
import copy from 'copy-to-clipboard'
import { setPageNumber, setPageMax, getPage } from '../../store/slicer/0502_page';
import { SetErrorMessage } from '../../store/slicer/0101_logout_message';


export default function MeetingRoomList(){

    const dispatch = useDispatch();
	const userdata = useSelector(getUserDataOfMeetingRoom);
	const storePage = useSelector(getPage);
	let drawdata = [];
	var access_token = useSelector(getAccessTokenForMeetingRoom);
	var refresh_token = useSelector(getRefreshTokenForMeetingRoom);

	const intl = useIntl();

	const [state, setState] = useState(() => {
		// 初期化
		let options = [
			{ value: '', name: '', text: intl.formatMessage({ id: 'meeting-list_location_pll' }) },
		];

		const fetchHttpRefresh = async () => {
			let senddata = {};
			let url_tokref = CONST.BASEURL + '/authorize/refresh';
			var httpResponse_tokref = await fetch(url_tokref, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': "Bearer " + refresh_token
				},
				body: JSON.stringify(senddata),
			});
			switch (httpResponse_tokref.status) {
				case 200:
					var httpResponse_tokref_json = await httpResponse_tokref.json();
					access_token = httpResponse_tokref_json.access_token;
					refresh_token = httpResponse_tokref_json.refresh_token;

					// 問題ない場合は、storeに保存
					dispatch(setAccessTokenForMeetingRoom(access_token));
					fetchHttp();
					return;				       
				default:
					dispatch(SetErrorMessage('ERROR_0001'));
					dispatch(logoutMeetingRoom());
					return;
			}
		}

		// 拠点一覧取得
		const fetchHttp = async () => {
			fetch(CONST.BASEURL + '/customers/' + userdata.id + '/locations?page_size=99999&page_number=1', {
				method: 'GET',
				headers: {
					Authorization: "Bearer " + access_token
				}
			})
			.then(response =>
				response.json().then(data => ({
					data: data,
					status: response.status
				})
				).then(res => {
					switch (res.status) {
						case 200:
							// データ取得
							for (let i = 0; i < res.data.locations.length; i++) {
								options[i + 1] = {
									value: res.data.locations[i].location_id.trim(),
									name: "",
									text: res.data.locations[i].location_name.trim(),
								};
							}
							// 初期化
							setState({ ...state, drawdata: drawdata, options: options, list: '', text: '', search_text: '' });
							return;

						case 401:
							fetchHttpRefresh();
							return;
						case 409:
							dispatch(SetErrorMessage('ERROR_0039'));
							dispatch(logoutMeetingRoom());
							return; 
						default:
							//Error出力
							toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

							break;
					}

				}).catch((err) => {
					//Error出力
					toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

				})
			)
			.catch((err) => {
				//Error出力
				toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

			});
		}
		fetchHttp();

		return {
			page_size: "50",
			page_number: 1,
			page_max: 1,
			drawdata: [{}],
			list: '',
			text: '',
			search_text: '',
			options: options,
			search_form: '',
			pmi: '',
            pmiPassword:'',
            url:''
		}
    });

	useEffect(() => {
		let now_page;
		now_page = storePage.page_number;
		if (now_page < 1) {
			now_page = 1;
		}

		// ユーザー一覧取得
		let url_data = CONST.BASEURL + '/customers/' + userdata.id + '/users?page_size=' + state.page_size + '&page_number=' + now_page;

		if (state.list !==undefined && state.list !=='') {
			url_data += '&location_id=' + state.list;
		}
		if (state.search_text !==undefined && state.search_text !=='') {
			url_data += '&search=' + state.search_text;
		}

		url_data += '&target_users=' + CONST.TARGET_USERS_ALIVE

		const fetchHttpRefresh = async () => {
			let senddata = {};
			let url_tokref = CONST.BASEURL + '/authorize/refresh';
			var httpResponse_tokref = await fetch(url_tokref, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': "Bearer " + refresh_token
				},
				body: JSON.stringify(senddata),
			});
			switch (httpResponse_tokref.status) {
				case 200:
					var httpResponse_tokref_json = await httpResponse_tokref.json();
					access_token = httpResponse_tokref_json.access_token;
					refresh_token = httpResponse_tokref_json.refresh_token;

					// 問題ない場合は、storeに保存
					dispatch(setAccessTokenForMeetingRoom(access_token));
					fetchHttp();
					return;				     
				default:
					dispatch(SetErrorMessage('ERROR_0001'));
					dispatch(logoutMeetingRoom());
					return;
			}
		}

		// 一覧取得
		const fetchHttp = async () => {
			fetch(url_data, {
				method: 'GET',
				headers: {
					Authorization: "Bearer " + access_token
				}
			})
			.then(response =>
				response.json().then(data => ({
					data: data,
					status: response.status
				})
				).then(res => {
					switch (res.status) {
						case 200:
							let error = state.search_form;
							error = ""

							drawdata = [];

							//検索結果の表示用リストデータを設定する
							drawdata = res.data.users;
							for (let i = 0; i < drawdata.length; i++) {
								drawdata[i].check = false;
								drawdata[i].user_id = drawdata[i].user_id
								drawdata[i].pmi = handlePmi(drawdata[i].pmi)
								drawdata[i].join_url = drawdata[i].join_url
							}

							// ページ数を算出する
							const page_max = Math.ceil(res.data.user_count / state.page_size);

							// 最大ページ数を変更
							dispatch(setPageMax(page_max));

							if (drawdata.length === 0) {
								error = intl.formatMessage({ id: 'ERROR_0006' });
								document.getElementById('sbox2').focus();
							}

							setState({ ...state, drawdata: drawdata, search_form: error });
							return;
						case 401:
							fetchHttpRefresh();
							return;
						case 409:
							dispatch(SetErrorMessage('ERROR_0039'));  
							dispatch(logoutMeetingRoom());							
							return;   
						default:
                            //Error出力
                            toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

                            break;
					}

				}).catch((err) => {
					//Error出力
					toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

				})
			)
			.catch((err) => {
                //Error出力
                toast(intl.formatMessage({ id: 'ERROR_0001' }),{style:{background:  CONST.HOT_TOAST_ERROR}});

			});
		}
		fetchHttp();
	}, [state.list, state.search_text, storePage.page_number]);

    // セレクトボックス変更ハンドラ
	const handleSelectChange = useCallback(event => {
		event.preventDefault(true);
		setState({ ...state, list: event.target.value, search_text: '', text: '' });
		dispatch(setPageNumber(1));
	}, [state.list]);

	// テキストボックス入力ハンドラ
	const handleInputChange = event => {
		setState({ ...state, text: event.target.value });
	};

	// サブミットボタンハンドラ
	const handleSubmit = event => {
		event.preventDefault(false);
		setState({ ...state, list: state.list, search_text: state.text });
		dispatch(setPageNumber(1));
	};

	// PMI表示を変換する
	const handlePmi = pmi => {
		var text_no = pmi.replace(/\s+/g, "");

		var text_digit3 = "";
		var text_digit6 = "";
		var text_digit10 = "";

		//数字のみ反映
		if (!isNaN(text_no)) {

			//3文字以上
			if (text_no.length > 3) {
				text_digit3 = text_no.substr(0, 3) + " ";
				text_digit6 = text_no.substr(3);

			} else {
				text_digit3 = text_no;
			}

			//6文字以上
			if (text_no.length > 6) {
				text_digit6 = text_no.substr(3, 3) + " ";
				text_digit10 = text_no.substr(6);
			}

			//10文字以上
			if (text_no.length > 10) {
				text_digit6 = text_no.substr(3, 4) + " ";
				text_digit10 = text_no.substr(7, 4);
			}
			var pmi_text = text_digit3 + text_digit6 + text_digit10;
			return pmi_text;
		}

	};

	//招待メールをコピーする
	const handleCopy = event => {
		let index = event.target.id
		let data = state.drawdata[index]
		
		let email_txt = "";
		email_txt += intl.formatMessage({ id: "meeting-list_email_txt" }) + '\n';
		email_txt += data.join_url + '\n\n';
		email_txt += intl.formatMessage({ id: "meeting-list_email_id" }) + '\u0020' + data.pmi + '\n';
		email_txt += intl.formatMessage({ id: "meeting-list_email_pwd" }) + '\u0020' + data.pmi_password + '\n';
		
        copy(email_txt);
        toast(intl.formatMessage({ id: 'SUCCESS_0009' }),{style:{background:  CONST.HOT_TOAST_SUCCESS}});
	}
	

    return(
        <div class="scroll_range">
			<title><FormattedMessage id="meeting-list_title_txt"/></title>
			<header class="in_contents_header">
					<div class="flex_box left_justified">
						<div class="selecter_down_arrow select_option element_width_xl">
							<label>
								<img class="svg_img-Pulldown" src="img/EZTP_Pulldown.svg" alt="▽" />
								<select value={state.list} id="location_select" onChange={handleSelectChange}>
									{
										state.options.map((data, index) => {
											return (
												<option value={data.value} >{data.text}</option>
											);
										})
									}
								</select>
							</label>
						</div>
						<div class="element_width_xl blank_space_l">
							<div class="search_box">
								<button tabIndex="-1" class="" type="button" onClick={handleSubmit} id="sbtn2"><img src={`${process.env.PUBLIC_URL}/img/EZTP_search.svg`} alt="" /></button>
                                <FormattedMessage id="meeting-list_locationuser_txt">{placeholder => <input id="sbox2" name="s" type="text" placeholder={placeholder} maxlength='64' value={state.text} onChange={handleInputChange} />}</FormattedMessage>
							</div>
						</div>
					</div>

			</header>
			<div class="search_result_table_box">
				<table class="search_result_table list_initial_table">
					<tbody>
						<tr>
							<th><FormattedMessage id="meeting-list_locationname_txt" /></th>
							<th><FormattedMessage id="meeting-list_meetingRoom_txt" /></th>
							<th><FormattedMessage id="meeting-list_meetingID_txt" /></th>
							<th><FormattedMessage id="meeting-list_passcode_txt" /></th>
							<th></th>
							<th></th>
						</tr>
                        {
							(() => {
								if (state.search_form !=='') {
									return (
										<tr>
											<td colspan="4">
												<div class="has-error">{state.search_form}</div>
											</td>
											<td />
										</tr>
									);
								}
							})()
						}
						{
							state.drawdata.map((data, index) => {
								if (data.location_name === undefined) {
									return (<tr />);
								}
								if(data.join_url !== ''){
									return(
										<tr style={{height: '34px'}}>
											<td>{data.location_name}</td>
											<td>{data.user_name}</td>
											<td>{ data.pmi }</td>
											<td>{ data.pmi_password }</td>
											<td></td>
											<td>
												<div class="element_width_m inline_block" >
													<a class="buttons_common btn_wht" href={ data.join_url } target='_blank' >
														<FormattedMessage id="meeting-list_goto_meeting_btn" />
													</a>
												</div>
												<div class="element_width_m inline_block blank_space_m">
													<a href="javascript:void(0);" class="buttons_common btn_wht" id={index} onClick={handleCopy} >
														<FormattedMessage id="meeting-list_email_copy_btn" />
													</a>
												</div>
											</td>
										</tr>
									);
								} else {
									return (
										<tr style={{height: '34px'}}>
											<td>{data.location_name}</td>
											<td>{data.user_name}</td>
											<td colSpan='2'>
												<FormattedMessage id="meeting-list_disabled_pmi"/>
											</td>
											<td></td>
											<td></td>
										</tr>
									);
								}
							})
						}
					</tbody>
				</table>
			</div>
		</div>
    );
}